import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './card.scss';

export function Card(props) {
  const classes = classNames({
    Card: true,
    'Card--collapsed': props.collapsed,
    'Card--transparent': props.transparent,
    'Card--hover': props.hover,
    'Card--dark': props.theme === 'dark',
    'Card--air': props.theme === 'air',
    'Card--full-height': props.fullHeight,
    'Card--selected': props.selected,
    'Card--selectable': props.selectable,
  });

  return <div className={classes}>{props.children}</div>;
}

Card.propTypes = {
  collapsed: PropTypes.bool,
  children: PropTypes.any,
  transparent: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light', 'air']),
};

Card.defaultProps = {
  collapsed: false,
  children: null,
  transparent: false,
  fullHeight: false,
  selected: false,
  theme: 'light',
};

export default Card;
