import React from 'react';
import { NavLink } from 'react-router-dom';
import { Default, Mobile } from '../../layout';
import SelectInput from '../../ui/inputs/select/select';

import history from '../../../util/history';

export function SecondaryNav(props) {
  const options = [
    { label: 'Profile', value: '/dashboard/my-profile' },
    process.env.REACT_APP_PAYOUT_ENABLED
      ? { label: 'Payment Details', value: '/dashboard/my-payout-details' }
      : null,
    { label: 'Completed Reviews', value: '/dashboard/my-reviews' },
  ];

  const main = [
    { label: 'Dashboard', value: '/dashboard', exact: true },
    process.env.REACT_APP_CHAT_ENABLED
      ? { label: 'Chat', exact: true, value: '/dashboard/chat' }
      : null,
  ];

  const active = options
    .filter(opt => opt !== null)
    .filter(opt => {
      if (opt.exact) {
        if (opt.value === history.location.pathname) return true;
      } else {
        return history.location.pathname.indexOf(opt.value) > -1;
      }
      return false;
    })
    .map(opt => {
      return opt.value;
    });

  function handleChange(e) {
    const { value } = e.target;
    history.push(value);
  }

  return (
    <div className="SecondaryNav">
      <Default>
        <ul className="DashboardMenu--Main">
          {main
            .filter(opt => opt !== null)
            .map((option, i) => {
              return (
                <li key={i}>
                  <NavLink exact to={option.value}>
                    {option.label}
                  </NavLink>
                </li>
              );
            })}
        </ul>
        <ul className="DashboardMenu">
          {options
            .filter(opt => opt !== null)
            .map((option, i) => {
              return (
                <li key={i}>
                  <NavLink exact to={option.value}>
                    {option.label}
                  </NavLink>
                </li>
              );
            })}
        </ul>
      </Default>
      <Mobile>
        <SelectInput
          value={active.length ? active[0] : ''}
          onChange={handleChange}
          options={[...main, ...options].filter(opt => opt !== null)}
          spaced
        />
      </Mobile>
    </div>
  );
}

export default SecondaryNav;
