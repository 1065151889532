import { useState } from 'react';

export function useForm(defaultData, opts) {
  const [form, setForm] = useState(defaultData || {});
  const [errors, setErrors] = useState({});

  function handleFormChange(e) {
    const { name: key, value } = e.target;

    setForm(form => ({
      ...form,
      [key]: value,
    }));

    if (errors[key] && opts.validate) {
      setErrors(errors => ({
        ...(opts.validate({ ...form, [key]: value }) || {}),
      }));
    }
  }

  function handleNestedFormChange(nestedKey, index, e) {
    const { name: key, value } = e.target;

    setForm(form => ({
      ...form,
      [nestedKey]: form[nestedKey].map((member, memberIndex) =>
        memberIndex === index ? { ...member, [key]: value } : member
      ),
    }));
  }

  function handleRemoveNestedForm(nestedKey, index) {
    setForm(form => ({
      ...form,
      [nestedKey]: form[nestedKey].filter(
        (member, memberIndex) => memberIndex !== index
      ),
    }));
  }

  function handleAddNestedForm(nestedKey, data) {
    setForm(form => ({
      ...form,
      [nestedKey]: [...form[nestedKey], data],
    }));
  }

  return {
    form,
    setForm,
    errors,
    setErrors,
    handleFormChange,
    handleNestedFormChange,
    handleRemoveNestedForm,
    handleAddNestedForm,
  };
}
