import { createGlobalState } from 'react-hooks-global-state';
import store from 'store';

const state = {
  cart: store.get('cart') || null,
  notifications: [],
  filters: {
    page: 1,
    text: '',
    industry: '',
    subIndusttry: '',
    sortMasked: 'PRICE_DESC',
    sort: { on: 'PRICE', order: 'DESC' },
    tags: [],
    open: {
      industrial: false,
      verticals: false,
      sdg: false,
      offering: false,
      investment: false,
      location: false,
      type: false,
      organization: false,
    },
  },
  adminFiltersPeople: {
    page: 1,
    text: '',
  },
  adminFiltersInvites: {
    page: 1,
    text: '',
  },
  activeChannel: null,
  activeMessages: [],
};

export const { GlobalStateProvider, useGlobalState } = createGlobalState(state);
