import React from 'react';
import {
  Grid,
  Cell,
  Heading,
  TextareaInput,
  TextInput,
  SelectInput,
  LogoInput,
  Label,
} from '../../../components/ui';
import countryOptions from '../../../util/country-options';
import stateOptions from '../../../util/state-options';
import TagsInput from '../../../components/ui/inputs/tags/tags';
import { Help } from '../../../components/ui/inputs/label/label';

export function FormStepOne(props) {
  const { form, errors, handleFormChange } = props;

  return (
    <div>
      <Heading level={1}>
        About <b>Your Opportunity</b>
      </Heading>
      <Heading level={2}>
        Please provide some background about the idea you’re pitching.
      </Heading>
      <hr />
      <TextInput
        value={form.name}
        name={'name'}
        errors={errors.name}
        onChange={handleFormChange}
        placeholder={'Enter your company name'}
        label={'Company Name'}
        spaced
      />
      <Grid>
        <Cell>
          <LogoInput
            name={'logo'}
            value={form.logo}
            crop={form.logoCrop}
            onCropChange={handleFormChange}
            cropName="logoCrop"
            cropType="round"
            onChange={handleFormChange}
            label={'Add Your Logo'}
            spaced
          />
        </Cell>
      </Grid>
      <hr />
      <TextareaInput
        spaced
        label={'What opportunity are you pitching?'}
        helpTextTop={
          'This section provides reviewers with additional background that might not be otherwise covered in your pitch video. You can edit this at any time'
        }
        limit={1000}
        value={form.description}
        errors={errors.description}
        name={'description'}
        onChange={handleFormChange}
      />

      <Grid margin>
        <Cell large={12}>
          <TextInput
            label={'Company Website'}
            placeholder="https://yourcompanywebsite.com"
            name={'website'}
            value={form.website}
            onChange={handleFormChange}
            spaced
          />
        </Cell>
      </Grid>
      <SelectInput
        name={'country'}
        label={'Country'}
        value={form.country}
        options={countryOptions}
        emptyOption={'Select Country'}
        errors={errors.country}
        onChange={handleFormChange}
        spaced
      />
      {form.country === 'US' && (
        <SelectInput
          name={'state'}
          label={'State'}
          value={form.state}
          options={stateOptions}
          errors={errors.state}
          emptyOption={'Select State'}
          onChange={handleFormChange}
          spaced
        />
      )}
      <TextInput
        label={'City'}
        value={form.city}
        errors={errors.city}
        name={'city'}
        onChange={handleFormChange}
      />
      <hr />
      <Label>Tags</Label>
      <Help>
        Select all that apply to help Vintro match you with the right audience.
      </Help>
      <TagsInput value={form.tags} name={'tags'} onChange={handleFormChange} />
    </div>
  );
}

export const validationRules = {
  name: {
    presence: { allowEmpty: false },
  },
  description: {
    presence: { allowEmpty: false },
  },
  country: {
    presence: { allowEmpty: false },
  },
  city: {
    presence: { allowEmpty: false },
  },
};

export default FormStepOne;
