import React, { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import baseInput from '../base-input/base-input';
import './video.scss';

import gql from 'graphql-tag';
import { useMutation } from 'react-apollo-hooks';
import { Heading, Paragraph } from '../../typography';
import { Grid, Cell } from '../../grid/grid';
import Button from '../../button/button';
import { useNotifications } from '../../../../hooks/notifications';

import axios from 'axios';

const GET_SIGNED_URL = gql`
  mutation($key: String) {
    getSignedVideoUpload(key: $key) {
      xAmzPolicy
      xAmzAlgorithm
      xAmzCredential
      xAmzDate
      xAmzSecurityToken
      xAmzSignature
      xAmzMetaUser
      xAmzMetaJob
      bucket
      key
      user
      job
    }
  }
`;

const GET_VENTURE_PITCH_JOB = gql`
  query($id: ID!) {
    getVenturePitchJob(id: $id) {
      id
      status
      percentComplete
      video {
        url
        width
        height
      }
      thumbnail {
        url
      }
    }
  }
`;

export function calculateVideoSize(wrapper, video) {
  const maxWidth = wrapper.current.offsetWidth - 32;
  const maxHeight = 720 - 32;
  const { width, height } = video;

  var ratio = Math.min(maxWidth / width, maxHeight / height);

  return { width: width * ratio, height: height * ratio };
}

export function VideoInput(props) {
  const notifications = useNotifications();
  const input = useRef(null);
  const [preview, setPreview] = useState(props.preview || null);
  const [cover, setCover] = useState(props.cover || null);
  const [loading, setLoading] = useState(null);
  const getSignedUpload = useMutation(GET_SIGNED_URL);
  const getVenturePitchJob = useMutation(GET_VENTURE_PITCH_JOB);
  const wrapper = useRef(null);
  const [job, setJob] = useState(props.job || {});
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [durationVideo, setDurationVideo] = useState(null);
  const [tempVideo, setTempVideo] = useState(null);
  const metaDataVideo = useRef(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [checkInterval, setCheckInterval] = useState(null);

  useEffect(() => {
    if (preview && props.job) {
      const size = calculateVideoSize(wrapper, props.job.video);

      setSize(size);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    function onResize() {
      if (preview) {
        const size = calculateVideoSize(wrapper, job.video);

        setSize(size);
      }
    }

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [preview, job, wrapper, size, setSize]);

  useEffect(() => {
    return () => {
      clearInterval(checkInterval);
    };
    // eslint-disable-next-line
  }, []);

  function checkJobStatus(id) {
    return async () => {
      try {
        const { data } = await getVenturePitchJob({ variables: { id } });

        if (data.getVenturePitchJob.status !== 'COMPLETE') {
          console.log('set percent complete', data);
          setLoading(
            `Encoding your video (${data.getVenturePitchJob.percentComplete}%)`
          );
          setUploadProgress(data.getVenturePitchJob.percentComplete);
          return;
        }

        clearInterval(checkInterval);

        const size = calculateVideoSize(wrapper, data.getVenturePitchJob.video);
        setJob(data.getVenturePitchJob);
        setSize(size);
        setPreview(data.getVenturePitchJob.video.url);
        setCover(data.getVenturePitchJob.thumbnail.url);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
  }

  async function upload(file) {
    try {
      clearInterval(checkInterval);

      setUploadProgress(0);
      setLoading(`Uploading your video`);
      setSize({});
      const { data } = await getSignedUpload({
        variables: { key: file.name },
      });

      const params = data.getSignedVideoUpload;

      let formData = new FormData();

      formData.append('Policy', params.xAmzPolicy);
      formData.append('X-Amz-Algorithm', params.xAmzAlgorithm);
      formData.append('X-Amz-Credential', params.xAmzCredential);
      formData.append('X-Amz-Date', params.xAmzDate);
      if (params.xAmzSecurityToken)
        formData.append('X-Amz-Security-Token', params.xAmzSecurityToken);
      formData.append('X-Amz-Signature', params.xAmzSignature);
      formData.append('X-Amz-acl', 'public-read');
      formData.append('bucket', params.bucket);
      formData.append('key', params.key);

      // meta data...
      formData.append('x-amz-meta-uuid', params.user);
      formData.append('x-amz-meta-job', params.job);

      formData.append('file', file);

      await axios.post(
        // `https://${params.bucket}.s3-accelerate.amazonaws.com`,
        //`https://dev.files.myvintro.com`,
        process.env.REACT_APP_UPLOAD_BUCKET,
        formData,
        {
          onUploadProgress: function(progressEvent) {
            const percent = Math.ceil(
              (progressEvent.loaded / progressEvent.total) * 100
            );

            setUploadProgress(percent);
            setLoading(`Uploading your video (${percent}%)`);
          },
        }
      );

      setUploadProgress(0);

      /*await fetch(`https://${params.bucket}.s3-accelerate.amazonaws.com`, {
        method: 'POST',
        body: formData,
      });
      */

      setLoading(`Encoding your video`);

      const interval = setInterval(checkJobStatus(params.job), 2500);
      setCheckInterval(interval);

      // checkJobStatus(params.job);

      props.onChange({ target: { value: params.job, name: props.name } });
    } catch (e) {
      notifications.notify('There was a problem uploading your video', {
        error: true,
        time: 3000,
      });
    }
  }

  const onDrop = acceptedFiles => {
    setPreview(null);
    setTempVideo(acceptedFiles[0]);

    const reader = new FileReader();

    reader.addEventListener('loadend', function() {
      setDurationVideo(URL.createObjectURL(acceptedFiles[0]));
    });

    reader.readAsDataURL(acceptedFiles[0]);

    // upload(acceptedFiles[0]);
  };

  function openFileDialog() {
    input.current.click();
  }

  function metaDataLoaded(e) {
    console.log('on meta data load');
    if (!metaDataVideo.current.duration) {
      setDurationVideo(null);
      setTempVideo(null);
      return notifications.notify(
        `Could not extract video duration. Please try a different video.`,
        { error: true, time: 5000 }
      );
    }

    if (metaDataVideo.current.duration > 90) {
      setDurationVideo(null);
      setTempVideo(null);
      return notifications.notify(
        'Video is longer than 90 seconds. Please upload a video no longer then 90 seconds.',
        { error: true, time: 5000 }
      );
    }

    setDurationVideo(null);
    upload(tempVideo);
    setTempVideo(null);
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    // onDrop,
    multiple: false,
    noClick: true,
    accept: ['video/mp4', 'video/quicktime'],
    onDropRejected() {
      notifications.notify('Only valid video files are allowed.', {
        error: true,
        time: 3000,
      });
    },
    onDropAccepted: onDrop,
  });

  return (
    <div ref={wrapper}>
      {durationVideo && (
        <div className="VideoInput__DurationVideo">
          <video
            ref={metaDataVideo}
            preload="metadata"
            onLoadedMetadata={metaDataLoaded}>
            <source src={durationVideo} />
          </video>
        </div>
      )}
      <Grid margin>
        <Cell small={12} medium={12} large={12}>
          <Paragraph>
            We’re looking for a 60-90 second pitch video.{' '}
            <a
              rel="noopener noreferrer"
              href="https://support.myvintro.com/hc/en-us/articles/360034006873-Guidelines-to-a-GREAT-pitch-video"
              target="_blank">
              Browse our tips
            </a>{' '}
            and other successful videos.
          </Paragraph>
        </Cell>
      </Grid>
      <div className="VideoInputWrapper" {...getRootProps()}>
        <Grid margin>
          <Cell small={12} medium={12} large={12}>
            <div className="VideoInputContainer">
              <div
                style={{ height: size.height ? size.height + 32 : 'auto' }}
                className="VideoInput">
                <input {...getInputProps()} ref={input} />
                {preview && !isDragActive && (
                  <div
                    style={{ height: size.height ? size.height + 32 : 'auto' }}
                    className="Preview">
                    <video
                      width={size.width}
                      height={size.height}
                      poster={cover}
                      controls
                      playsInline>
                      <source src={preview} type="video/mp4" />
                    </video>
                  </div>
                )}
                {loading && !isDragActive && (
                  <div className="Loading">
                    <div className="ProgressBar">
                      <div
                        style={{ width: `${uploadProgress * 2}px` }}
                        className="ProgressBar__progress"
                      />
                    </div>
                    <span className="LoadingMessage">
                      <Paragraph>{loading}</Paragraph>
                    </span>
                  </div>
                )}
                {isDragActive ? (
                  <p className="Paragraph">Drop the files here ...</p>
                ) : !loading && !preview ? (
                  <div className="text-center">
                    <Heading level={2}>
                      Your moment awaits. Upload your video now.
                    </Heading>
                    <Paragraph>
                      <u onClick={e => input.current.click()}>Browse</u> or Drag
                      and Drop to Upload
                    </Paragraph>
                  </div>
                ) : null}
              </div>
            </div>
          </Cell>
          <Cell small={12} medium={12} large={12}>
            {preview ? (
              <Button onClick={openFileDialog}>
                Choose Another Video to Upload
              </Button>
            ) : null}
            {/*<Heading level={5}>Successful Video Examples</Heading>*/}
            {/*<Grid>
            <Cell small={3} large={3} />
          </Grid>*/}
          </Cell>
        </Grid>
      </div>
    </div>
  );
}

export default baseInput(VideoInput);
