import './loader.scss';
import React, { useState, useEffect } from 'react';
import cc from 'classnames';

export function Loader(props) {
  const [show, setShow] = useState(false);
  const classes = cc({
    Loader: true,
    'Loader--show': show,
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, 250);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return <div className={classes}>{props.children}</div>;
}

export default Loader;
