import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './pagination.scss';

function getPager(totalItems, currentPage, pageSize, maxPages) {
  let totalPages = Math.ceil(totalItems / pageSize);
  let startPage = 0;
  let endPage = 0;

  if (currentPage < 1) {
    currentPage = 1;
  } else if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  if (totalPages <= maxPages) {
    startPage = 1;
    endPage = totalPages;
  } else {
    let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      startPage = 1;
      endPage = maxPages;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      startPage = totalPages - maxPages + 1;
      endPage = totalPages;
    } else {
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }

  let startIndex = (currentPage - 1) * pageSize;
  let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
    i => startPage + i
  );

  return {
    totalItems: totalItems,
    currentPage: currentPage,
    pageSize: pageSize,
    totalPages: totalPages,
    startPage: startPage,
    endPage: endPage,
    startIndex: startIndex,
    endIndex: endIndex,
    pages: pages,
  };
}

export function Pagination(props) {
  const {
    totalItems,
    currentPage,
    pageSize,
    maxPages,
    onPageChange,
    simple,
  } = props;

  const pager = getPager(totalItems, currentPage, pageSize, maxPages);

  console.log(pager);

  function handlePreviousPageClick() {
    if (pager.currentPage === 1) return;
    onPageChange(pager.currentPage - 1);
  }

  function handleNextPageClick() {
    if (pager.currentPage === pager.totalPages) return;
    onPageChange(pager.currentPage + 1);
  }

  function getDefaultPagination() {
    return (
      <nav className="Pagination Pagination--extended">
        <ul className="Pagination__list">
          <li
            className={classNames({
              'Pagination__item Pagination__item--previous': true,
              'Pagination__item Pagination__item--previous--disabled':
                pager.currentPage === 1,
            })}
            onClick={handlePreviousPageClick}
          >
            Prev
          </li>
          {pager.pages.map(page => {
            const itemClasses = classNames({
              Pagination__item: true,
              'Pagination__item--current': page === pager.currentPage,
            });
            return (
              <li
                key={page}
                className={itemClasses}
                onClick={() => onPageChange(page)}
              >
                {page}
              </li>
            );
          })}
          <li
            className={classNames({
              'Pagination__item Pagination__item--next': true,
              'Pagination__item Pagination__item--next--disabled':
                pager.currentPage === pager.totalPages,
            })}
            onClick={handleNextPageClick}
          >
            Next
          </li>
        </ul>
      </nav>
    );
  }

  function getSimplePagination() {
    return (
      <div className="Pagination Pagination--simple">
        <ul className="Pagination__list">
          <li
            className="Pagination__item Pagination__item--prev"
            onClick={handlePreviousPageClick}
          >
            Prev
          </li>
          <li className="Pagination__item Pagination__item--meta">
            {pager.currentPage} / {pager.totalPages}
          </li>
          <li
            className="Pagination__item Pagination__item--next"
            onClick={handleNextPageClick}
          >
            Next
          </li>
        </ul>
      </div>
    );
  }

  if (totalItems === 0) return null;

  return simple ? getSimplePagination() : getDefaultPagination();
}

Pagination.propTypes = {
  totalItems: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  maxPages: PropTypes.number,
  onPageChange: PropTypes.func,
  simple: PropTypes.bool,
};

Pagination.defaultProps = {
  totalItems: 0,
  currentPage: 1,
  pageSize: 10,
  maxPages: 10,
  onPageChange: () => null,
  simple: false,
};

export default Pagination;
