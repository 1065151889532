import React from 'react';

export function HeartIcon() {
  return (
    <svg width="22px" height="19px" viewBox="0 0 22 19">
      <g
        id="Desktop"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <g
          id="Expert-Profile"
          transform="translate(-1276.000000, -544.000000)"
          fill="#FF5D5D"
          stroke="#FF5D5D"
        >
          <g id="Pay-Bar" transform="translate(1177.000000, 286.000000)">
            <g id="Charity">
              <path
                d="M109.999806,276.498411 L101.476708,267.608657 C99.9417253,266.073623 99.5617035,263.728365 100.533401,261.787152 C101.258148,260.337494 102.635639,259.324658 104.235454,259.065122 C105.835269,258.805585 107.462308,259.330999 108.608178,260.477188 L109.999806,261.868816 L111.391434,260.477188 C112.537625,259.331014 114.16488,258.805634 115.764917,259.065153 C117.364953,259.324672 118.742725,260.337454 119.467877,261.787152 C120.438076,263.728876 120.057483,266.073521 118.522903,267.608657 L109.999806,276.498411 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default HeartIcon;
