export default [
  {
    code: 201,
    name: 'No Poverty',
    type: 'sdg',
  },
  {
    code: 210,
    name: 'Reduced Inequality',
    type: 'sdg',
  },

  {
    code: 202,
    name: 'Zero Hunger',
    type: 'sdg',
  },
  {
    code: 211,
    name: 'Sustainable Cities and Communities',
    type: 'sdg',
  },

  {
    code: 203,
    name: 'Good Health and Well-being',
    type: 'sdg',
  },
  {
    code: 212,
    name: 'Responsible Consumption and Production',
    type: 'sdg',
  },

  {
    code: 204,
    name: 'Quality Education',
    type: 'sdg',
  },
  {
    code: 213,
    name: 'Climate Action',
    type: 'sdg',
  },

  {
    code: 205,
    name: 'Gender Equality',
    type: 'sdg',
  },
  {
    code: 214,
    name: 'Life Below Water',
    type: 'sdg',
  },
  {
    code: 206,
    name: 'Clean Water and Sanitation',
    type: 'sdg',
  },
  {
    code: 215,
    name: 'Life on Land',
    type: 'sdg',
  },
  {
    code: 207,
    name: 'Affordable and Clean Energy',
    type: 'sdg',
  },
  {
    code: 216,
    name: 'Peace, Justice and Strong Institutions',
    type: 'sdg',
  },
  {
    code: 208,
    name: 'Decent Work and Economic Growth',
    type: 'sdg',
  },
  {
    code: 217,
    name: 'Partnerships to achieve the Goal',
    type: 'sdg',
  },
  {
    code: 209,
    name: 'Industry, Innovation and Infrastructure',
    type: 'sdg',
  },
];
