import './checkout.scss';
import React from 'react';
// import { useGlobalState } from '../../store';
import history from '../../util/history';
import { useSession } from '../../hooks/session';
import { CheckoutItem } from '../../components/checkout';
import {
  Grid,
  Cell,
  Container,
  Heading,
  Divider,
  Card,
  Button,
  Loader,
  Paragraph,
} from '../../components/ui';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { GET_CART, REMOVE_FROM_CART } from '../../graphql/cart';
import * as store from 'store';
import { AppError } from '../../components/ui/errors/errors';
import { useGlobalState } from '../../store';
import { Link } from 'react-router-dom';
import emptyCartIcon from '../../images/empty-cart.png';

export function Checkout(props) {
  const session = useSession();
  const cart = props.cart || { items: [] };
  const removeFromCart = useMutation(REMOVE_FROM_CART);
  const cartSubtotal = cart.items.reduce((result, item) => {
    result += item.price;
    return result;
  }, 0);

  async function handleRemoveItemFromCart(item) {
    try {
      await removeFromCart({
        variables: {
          input: {
            person: item.id,
            id: store.get('cart'),
          },
        },
      });
    } catch (e) {
      return e;
    }
  }

  function handleBillingNavigation() {
    if (session.isAuthenticated) {
      history.push('/billing');
    } else {
      history.push('/sign-in');
    }
  }

  function handleContinueShoppingNavigation() {
    history.push('/reviewers');
  }

  return (
    <section className="Checkout">
      <Container>
        <Grid align="center" padding>
          <Cell small={12} large={10}>
            <header>
              <Heading level={1}>
                Your <b>Cart</b>
              </Heading>
              <Divider />
            </header>
            <Grid padding>
              <Cell large={8} small={12}>
                {cart.items.map((item, idx) => {
                  return (
                    <CheckoutItem
                      {...item}
                      onRemove={handleRemoveItemFromCart}
                      key={idx}
                    />
                  );
                })}
              </Cell>
              <Cell small={12} large={4}>
                <div className="Checkout__totals">
                  <Card>
                    <Heading level={5}>Subtotal</Heading>
                    <Heading level={1}>{`$${cartSubtotal}`}</Heading>

                    <Button
                      full
                      size={'large'}
                      onClick={handleBillingNavigation}
                    >
                      {session.isAuthenticated
                        ? 'Checkout'
                        : 'Login to Checkout'}
                    </Button>
                    <div style={{ marginTop: '16px' }}>
                      <Button
                        theme={'primary'}
                        hollow
                        size={'large'}
                        full
                        margin
                        onClick={handleContinueShoppingNavigation}
                      >
                        Continue Shopping
                      </Button>
                    </div>
                  </Card>
                </div>
                <br />
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export function CheckoutLoader(props) {
  const { data, error, loading } = useQuery(GET_CART, {
    variables: { id: store.get('cart') },
  });

  if (loading)
    return (
      <section className="Checkout">
        <Container>
          <Grid align="center" padding>
            <Cell small={12} large={10}>
              <header>
                <Heading level={1}>
                  Your <b>Cart</b>
                </Heading>
                <Divider />
              </header>
              <Grid padding>
                <Cell large={8} small={12}>
                  <Loader />
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </Container>
      </section>
    );
  if (error) return <AppError />;

  if (data.getCart.items.length === 0) {
    return <EmptyCart />;
  }

  return <Checkout {...props} cart={data.getCart} />;
}

export function EmptyCart(props) {
  return (
    <section className="EmptyCart">
      <Container>
        <Grid align={'center'}>
          <Cell small={12} medium={8} large={6}>
            <div className="callout">
              <img alt="empty-cart" src={emptyCartIcon} />
              <Heading level={2}>Your Cart is Empty</Heading>
              <Paragraph>
                <Link to="/">
                  <Button>Browse Reviewers</Button>
                </Link>
              </Paragraph>
            </div>
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export function CheckoutSplitter() {
  const [cartID] = useGlobalState('cart');

  if (!cartID) {
    return <EmptyCart />;
  }

  return <CheckoutLoader />;
}

export default CheckoutSplitter;
