import React from 'react';
import { Heading } from '../../';

import { useSession } from '../../../../hooks/session';

export function ReviewerDashboardHeader(props) {
  const session = useSession();

  return (
    <Heading level={1}>
      Welcome{' '}
      <b>
        {session && session.profile ? session.profile.user.firstName : null}{' '}
        {session && session.profile ? session.profile.user.lastName : null}
      </b>
    </Heading>
  );
}
