import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Grid, Cell, Image } from '../../ui';
import { Default, Mobile } from '../../layout';
import './primary-nav.scss';
import logo from '../../../images/logowithslogan.png';
import { useSession } from '../../../hooks/session';
import Cart from '../../cart/cart';
import history from '../../../util/history';
import client from '../../../apollo/client';
import { useGlobalState } from '../../../store';
// import { useMyProfileStatus } from '../../../graphql/scouts';
import { createPrimaryLinks, UploadPitch } from './links';

function XIcon() {
  return (
    <svg id="close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs />
      <title>close</title>
      <line className="a" x1="0.75" y1="23.249" x2="23.25" y2="0.749" />
      <line className="a" x1="23.25" y1="23.249" x2="0.75" y2="0.749" />
    </svg>
  );
}

function MenuIcon() {
  return (
    <svg
      width="24px"
      height="24px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <defs
        style={{
          fill: 'none',
          stroke: '#000',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.5px',
        }}
      />
      <title>navigation-menu-1</title>
      <rect
        className="a"
        x="0.75"
        y="4.753"
        width="22"
        height="1"
        style={{
          fill: '#1266ad',
          stroke: '#1266ad',
        }}
      />
      <rect
        className="a"
        x="0.75"
        y="12.753"
        width="22"
        height="1"
        style={{
          fill: '#1266ad',
          stroke: '#1266ad',
        }}
      />
      <rect
        className="a"
        x="0.75"
        y="20.753"
        width="22"
        height="1"
        style={{
          fill: '#1266ad',
          stroke: '#1266ad',
        }}
      />
    </svg>
  );
}

function DesktopLink({ link }) {
  const { component, children } = link;
  const LinkComponent = component;
  const hasChildren = children.length > 0;

  return (
    <li className="PrimaryNav__item">
      <LinkComponent className="PrimaryNav__link" {...link.attributes}>
        <span className="PrimaryNav__link-text">{link.text}</span>
      </LinkComponent>
      {hasChildren && (
        <ul className="PrimaryNav__submenu">
          {children.map((child, childIdx) => {
            const LinkSubComponent = child.component;

            return (
              <li className="PrimaryNav__submenuItem" key={childIdx}>
                <LinkSubComponent
                  className="PrimaryNav__link"
                  {...child.attributes}
                >
                  <span className="PrimaryNav__link-text">{child.text}</span>
                </LinkSubComponent>
              </li>
            );
          })}
        </ul>
      )}
    </li>
  );
}

function MobileLink({ link, menuToggle }) {
  const [open, setOpen] = useState(false);
  const { component, children } = link;
  const LinkComponent = component;
  const hasChildren = children.length > 0;

  function handleClick() {
    hasChildren ? setOpen(open => !open) : menuToggle();
  }

  return (
    <li className="PrimaryNav__item">
      <LinkComponent
        onClick={handleClick}
        className="PrimaryNav__link"
        {...link.attributes}
      >
        <Grid>
          <Cell auto>
            <span className="PrimaryNav__link-text">{link.text}</span>
          </Cell>
          {hasChildren && (
            <Cell shrink>
              <button type="button" className="PrimaryNav__menuToggle">
                {open ? '-' : '+'}
              </button>
            </Cell>
          )}
        </Grid>
      </LinkComponent>
      {hasChildren && open && (
        <ul className="PrimaryNav__submenu">
          {children.map((child, childIdx) => {
            const LinkSubComponent = child.component;

            return (
              <li className="PrimaryNav__submenuItem" key={childIdx}>
                <LinkSubComponent
                  className="PrimaryNav__link"
                  {...child.attributes}
                  onClick={menuToggle}
                >
                  <span className="PrimaryNav__link-text">{child.text}</span>
                </LinkSubComponent>
              </li>
            );
          })}
        </ul>
      )}
    </li>
  );
}

function NavList(props) {
  const [, setActiveMessages] = useGlobalState('activeMessages');
  const session = useSession({});
  const profile = session.isAuthenticated ? session.profile : { features: {} };
  const links = createPrimaryLinks(session, profile).filter(link => link.show);

  function logout(e) {
    e.preventDefault();
    if (props.handleNavToggle) props.handleNavToggle();

    session.removeSession();
    client.clearStore();

    setActiveMessages([]);
    history.push('/');
  }

  function toggle() {
    if (props.handleNavToggle) props.handleNavToggle();
    window.scrollTo(0, 0);
    return true;
  }

  return (
    <ul className="PrimaryNav__list">
      <Default>
        {links.map((link, idx) => {
          return <DesktopLink link={link} key={idx} />;
        })}
        {session.isAuthenticated && <UploadPitch />}
        {session.isAuthenticated && (
          <li className="PrimaryNav__item">
            <span onClick={logout} className="PrimaryNav__link">
              <span className="PrimaryNav__link-text">Logout</span>
            </span>
          </li>
        )}
      </Default>
      <Mobile>
        {links.map((link, idx) => (
          <MobileLink link={link} key={idx} menuToggle={toggle} />
        ))}
        {session.isAuthenticated && <UploadPitch />}
        {session.isAuthenticated && (
          <li className="PrimaryNav__item">
            <span onClick={logout} className="PrimaryNav__link">
              <span className="PrimaryNav__link-text">Logout</span>
            </span>
          </li>
        )}
      </Mobile>
    </ul>
  );
}

/*function ContinueApplicationButton({ toggle }) {
  const { data, loading, error } = useMyProfileStatus();

  if (loading) return null;
  if (error) return null;

  if (data && data.myProfile && !data.myProfile.familiar) {
    return (
      <li className="PrimaryNav__item PrimaryNav__item--callout">
        <NavLink
          onClick={toggle}
          className="PrimaryNav__link"
          activeClassName="PrimaryNav__link--active"
          to="/apply/step/onboarding"
        >
          <span className="PrimaryNav__link-text">Continue Application</span>
        </NavLink>
      </li>
    );
  }

  return null;
}*/

function DefaultNav() {
  const session = useSession({});
  const { profile = {} } = session;

  function canAccessCart() {
    return (
      (!profile.user && process.env.REACT_APP_CART_ENABLED) ||
      (profile.user &&
        profile.user.type !== 'SCOUT' &&
        process.env.REACT_APP_CART_ENABLED) ||
      (profile && profile.features && profile.features.cart === true)
    );
  }

  return (
    <div className="PrimaryNav PrimaryNav--desktop">
      <Container fullHeight>
        <Grid padding align={'middle'} fullHeight>
          <Cell shrink>
            <NavLink to={'/'}>
              <Image src={logo} maxWidth={110} alt={'Logo'} />
            </NavLink>
          </Cell>
          <Cell auto />
          <Cell shrink>
            <NavList />
          </Cell>
          {canAccessCart() && (
            <Cell shrink>
              <Cart />
            </Cell>
          )}
        </Grid>
      </Container>
    </div>
  );
}

function MobileNav() {
  const [showNav, setShowNav] = useState(false);

  function handleNavToggle() {
    setShowNav(showNav => !showNav);
  }

  return (
    <div className="PrimaryNav PrimaryNav--mobile">
      <Container fluid fullHeight>
        <Grid padding align={'middle'} fullHeight>
          <Cell auto>
            <NavLink to={'/'}>
              <Image src={logo} maxWidth={80} alt={'Logo'} />
            </NavLink>
          </Cell>
          <Cell shrink>
            <div className="Controls">
              {process.env.REACT_APP_CART_ENABLED && <Cart />}
              <button type="button" onClick={handleNavToggle}>
                {showNav ? <XIcon /> : <MenuIcon />}
              </button>
            </div>
          </Cell>
        </Grid>
      </Container>
      {showNav && <NavList handleNavToggle={handleNavToggle} />}
    </div>
  );
}

export function PrimaryNav(props) {
  return (
    <div className="PrimaryNav__container">
      <Default>
        <DefaultNav />
      </Default>
      <Mobile>
        <MobileNav />
      </Mobile>
    </div>
  );
}

export default PrimaryNav;
