import React, { useEffect, useState } from 'react';
import './chat-sidebar.scss';
import { ChatInstance, ChatMobileToggle } from '../../chat';
import { Grid, Cell } from '../../ui';
import classNames from 'classnames';
import { useGlobalState } from '../../../store';

export function useGetChannels(sb) {
  const [, setActiveChat] = useGlobalState('activeChannel');
  const [channels, setChannels] = useState([]);

  useEffect(() => {
    var channelListQuery = sb.GroupChannel.createMyGroupChannelListQuery();
    channelListQuery.includeEmpty = true;
    channelListQuery.limit = 20; // The value of pagination limit could be set up to 100.

    if (channelListQuery.hasNext) {
      channelListQuery.next(function(channelList, error) {
        if (error) {
          return;
        }
        setActiveChat(channelList[0]);
        setChannels(channelList);
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    var ChannelHandler = new sb.ChannelHandler();

    ChannelHandler.onChannelChanged = function(channel, message) {
      console.log('a change happened');
      setChannels(channels => {
        return channels.map(existingChannel => {
          if (existingChannel.url === channel.url) {
            return channel;
          }

          return existingChannel;
        });
      });
    };

    sb.addChannelHandler('chat-sidebar', ChannelHandler);

    return () => {
      sb.removeChannelHandler('chat-sidbar');
    };

    // eslint-disable-next-line
  }, []);

  return channels;
}

export function ChatSidebar(props) {
  const [activeChannel, setActiveChat] = useGlobalState('activeChannel');
  const channels = useGetChannels(props.sendbird);

  const classes = classNames({
    ChatSidebar: true,
    'ChatSidebar--active': props.menuOpen,
  });

  function handleClickChatInstance(channel) {
    setActiveChat(channel);
    props.onMobileToggle();
  }

  return (
    <div className={classes}>
      <Grid grid="y" fullHeight>
        <Cell shrink>
          <div className="ChatSidebar__filter">
            <Grid margin>
              <Cell auto></Cell>
              <Cell shrink>
                <ChatMobileToggle
                  menuOpen={props.menuOpen}
                  onMobileToggle={props.onMobileToggle}
                />
              </Cell>
            </Grid>
          </div>
        </Cell>
        <Cell auto>
          <div className="ChatSidebar__content">
            <div className="ChatSidebar__messageList">
              {channels.map((channel, idx) => (
                <ChatInstance
                  handleClick={e => handleClickChatInstance(channel)}
                  key={idx}
                  active={
                    activeChannel && activeChannel.url === channel.url
                      ? true
                      : false
                  }
                  channel={channel}
                />
              ))}
            </div>
          </div>
        </Cell>
      </Grid>
    </div>
  );
}

export default ChatSidebar;
