import './sort.scss';
import React from 'react';
import SelectInput from '../select/select';

export function SortInput(props) {
  return (
    <div className="SelectSort">
      <SelectInput {...props} />
    </div>
  );
}

export default SortInput;
