import React, { useState } from 'react';
import { Heading, TextInput, VideoInput, Button } from '../../../components/ui';
import Modal from '../../../components/ui/modal/modal';
import { Link } from 'react-router-dom';

import OfferingInput from '../../../components/ui/inputs/offering/offering';

export function FormStepThree(props) {
  const [showModal, setShowModal] = useState(props.showModal || false);
  const { form, errors = {}, handleFormChange } = props;

  console.log(errors);

  return (
    <div>
      <TextInput
        spaced
        label={'Pitch Title'}
        value={form.ideaName}
        onChange={handleFormChange}
        name={'ideaName'}
        errors={errors.ideaName}
      />

      <OfferingInput
        spaced
        errors={errors.offering}
        name="offering"
        label={"What I'm looking for"}
        value={form.offering}
        onChange={handleFormChange}
      />

      <Heading level={2}>Upload Your Pitch</Heading>

      <VideoInput
        errors={errors.job}
        preview={form.preview}
        cover={form.cover}
        job={form.video}
        value={form.job}
        name={'job'}
        onChange={handleFormChange}
      />
      {showModal && (
        <Modal>
          <Heading level={1}>Have a video ready?</Heading>
          <Button onClick={e => setShowModal(false)} hollow>
            Upload Pitch
          </Button>
          <Heading level={1} style={{ marginTop: '40px' }}>
            Want to browse the people you now have access to?
          </Heading>
          <Link to="/">
            <Button>Browse Network</Button>
          </Link>
        </Modal>
      )}
    </div>
  );
}

export const validationRules = {
  ideaName: {
    presence: {
      allowEmpty: false,
      message: `Pitch title can't be blank`,
    },
  },
  job: {
    presence: {
      allowEmpty: false,
      message: `Pitch can't be blank. Please upload a video.`,
    },
  },
  offering: {
    presence: {
      allowEmpty: false,
      message: `Choose at least one option`,
    },
    length: {
      minimum: 1,
      message: 'Choose at least one option',
    },
  },
};

export default FormStepThree;
