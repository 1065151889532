import React, { useRef } from 'react';
import { AdminSecondaryNav } from '../../../components/layout';
import {
  Container,
  Grid,
  Cell,
  Heading,
  Loader,
  Button,
  Card,
} from '../../../components/ui';
import { useGetPersonInvite } from '../../../graphql/scouts';
import { AppError } from '../../../components/ui/errors/errors';
import PersonForm, { Spaced } from './components/person.form';
import { useNotifications } from '../../../hooks/notifications';
import { Link } from 'react-router-dom';

export function Invite(props) {
  const notifications = useNotifications();
  const copy = useRef();
  const link = `https://${window.location.hostname}/invite/${props.invite.id}`;

  function copyLink() {
    copy.current.select();
    document.execCommand('copy');
    notifications.notify('Copied Invite Link', { time: 3000 });
  }

  return (
    <Grid margin>
      {!props.invite.accepted && (
        <Cell small={12}>
          <Spaced>
            <Card>
              <div className="text-center">
                <Button onClick={copyLink} size={'small'}>
                  {link}
                </Button>
                <input
                  onChange={e => {}}
                  ref={copy}
                  value={link}
                  style={{
                    position: 'absolute',
                    top: '-9999px',
                    left: '-9999px',
                  }}
                />
              </div>
            </Card>
          </Spaced>
        </Cell>
      )}
      {!props.invite.accepted && (
        <Cell small={12}>
          <PersonForm
            profile={{ ...props.invite.person, id: props.invite.id }}
          />
        </Cell>
      )}
      {props.invite.accepted && (
        <Cell small={12}>
          <Spaced>
            <Card>
              <div className="text-center">
                <Heading level={1}>Invite Accepted</Heading>
                <Heading level={6}>{props.invite.acceptedAt}</Heading>
                <Link to={`/admin/reviewers/view/${props.invite.acceptedBy}`}>
                  <Button size={'small'}>View Reviewer</Button>
                </Link>
              </div>
            </Card>
          </Spaced>
        </Cell>
      )}
    </Grid>
  );
}

export function InviteLoader(props) {
  const { data, loading, error } = useGetPersonInvite(props.id);

  if (loading) return <Loader />;
  if (error) return <AppError />;

  return <Invite id={props.id} invite={data.getPersonInvite} />;
}

export function InviteWrapper(props) {
  return (
    <Container>
      <Grid>
        <Cell small={6}>
          <Heading level={1}>
            <b>Vintro</b> Admin
          </Heading>
        </Cell>
        <Cell small={6} />
        <Cell>
          <hr />
        </Cell>
      </Grid>
      <Grid margin>
        <Cell small={12} medium={3}>
          <AdminSecondaryNav />
        </Cell>
        <Cell small={12} medium={9}>
          <InviteLoader id={props.match.params.id} />
        </Cell>
      </Grid>
    </Container>
  );
}

export default InviteWrapper;
