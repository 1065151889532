import './button.scss';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loader from '../loader/loader';

export function Button(props) {
  const classes = classNames({
    Button: true,
    [`Button--${props.theme}`]: true,
    [`Button--${props.size}`]: true,
    'Button--full': props.full,
    'Button--disabled': props.disabled,
    'Button--hollow': props.hollow,
    'Button--primary--hollow': props.hollow && props.theme === 'primary',
    [props.className]: props.className,
  });

  return (
    <button
      type={props.type}
      className={classes}
      onClick={props.type === 'button' ? props.onClick : null}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}

Button.defaultProps = {
  type: 'button',
  size: 'normal',
  theme: 'primary',
  disabled: false,
  full: false,
};

Button.propTypes = {
  theme: PropTypes.oneOf([
    'alert',
    'hollow',
    'warning',
    'success',
    'primary',
    'tertiary',
    'secondary',
  ]),
  type: PropTypes.oneOf(['button', 'submit']),
  size: PropTypes.oneOf(['normal', 'small', 'tiny', 'large']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  full: PropTypes.bool,
  hollow: PropTypes.bool,
  className: PropTypes.string,
};

export function ButtonLoading(props) {
  return (
    <span className="Button--Loading">
      <span className="Button--Loading__LoaderContainer">
        <span className="Button--Loading__Loader">
          <Loader />
        </span>
      </span>
      <span className="Button--Loading__Text">{props.children}</span>
    </span>
  );
}

export default Button;
