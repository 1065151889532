import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import store from 'store';

export const REGISTER_AS_VENTURE = gql`
  mutation($input: RegisterAsVentureInput) {
    registerAsVenture(input: $input) {
      token
    }
  }
`;

export const MY_VENTURE = gql`
  query {
    myVenture {
      id
      name
      description
      website
      country
      city
      state
      logo
      logoCrop {
        url
      }
      team {
        firstName
        lastName
        bio
        photo
        title
        social {
          linkedIn
        }
      }
      tags {
        id
        name
      }
      pitches {
        id
        name
        industry {
          id
        }
        subIndustry {
          id
        }
        job {
          id
          video {
            url
          }
          thumbnail {
            url
          }
          thumbnails {
            url
          }
        }
      }
    }
  }
`;

export function useMyVenture() {
  const { data, error, loading } = useQuery(MY_VENTURE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    metadata: {
      t: store.get('jwt'),
    },
    options: {
      fetchPolicy: 'no-cache',
    },
  });

  return { data, error, loading };
}

export const UPDATE_VENTURE = gql`
  mutation UPDATE_VENTURE($input: VentureInput) {
    updateVenture(input: $input) {
      id
      description
    }
  }
`;

export function convertVentureToForm(venture) {
  return {
    id: venture.id,
    name: venture.name,
    description: venture.description || '',
    website: venture.website || '',
    country: venture.country || '',
    state: venture.state || '',
    city: venture.city || '',
    logo: venture.logo || '',
    tags: venture.tags ? venture.tags.map(tag => tag.id) : [],
    team: (venture.team && venture.team.length > 0
      ? venture.team
      : [{ firstName: '', lastName: '', social: {} }]
    ).map(member => {
      return {
        firstName: member.firstName || '',
        lastName: member.lastName || '',
        title: member.title || '',
        bio: member.bio || '',
        photo: member.photo || '',
        linkedIn: member.social.linkedIn,
      };
    }),
  };
}

export function convertFormToVentureInput(form) {
  return {
    id: form.id,
    name: form.name,
    description: form.description,
    website: form.website,
    logo: form.logo,
    country: form.country,
    state: form.state,
    city: form.city,
    tags: form.tags ? form.tags.map(tag => tag.toString()) : [],
    team: form.team.map(member => ({
      firstName: member.firstName || '',
      lastName: member.lastName || '',
      title: member.title || '',
      bio: member.bio || '',
      photo: member.photo || null,
      social: {
        linkedIn: member.linkedIn,
      },
    })),
  };
}

export function useUpdateVenture() {
  const [updateVenture] = useMutation(UPDATE_VENTURE);

  async function exec(input) {
    return await updateVenture({ variables: { input } });
  }

  return exec;
}

export const ADD_VENTURE_PITCH = gql`
  mutation ADD_VENTURE_PITCH($input: VenturePitchInput) {
    addVenturePitch(input: $input) {
      id
    }
  }
`;

export const UPDATE_VENTURE_PITCH = gql`
  mutation UPDATE_VENTURE_PITCH($input: VenturePitchInput) {
    updateVenturePitch(input: $input) {
      id
    }
  }
`;

export const REMOVE_VENTURE_PITCH = gql`
  mutation UPDATE_VENTURE_PITCH($input: VenturePitchInput) {
    removeVenturePitch(input: $input)
  }
`;

export function useUpdateVenturePitch(id) {
  const [updateVenturePitch] = useMutation(UPDATE_VENTURE_PITCH);

  async function exec(input) {
    try {
      await updateVenturePitch({
        variables: {
          input: {
            id,
            ...input,
          },
        },
      });
    } catch (e) {
      throw e;
    }
  }

  return exec;
}

export function useRemoveVenturePitch(id) {
  const [removeVenturePitch] = useMutation(REMOVE_VENTURE_PITCH);

  async function exec(input) {
    try {
      await removeVenturePitch({
        variables: {
          input: {
            id,
          },
        },
      });
    } catch (e) {
      throw e;
    }
  }

  return exec;
}

export const MY_VENTURE_PITCHES = gql`
  query {
    myVenture {
      id
      name
      pitches {
        id
        name
        description
        job {
          id
          video {
            url
            width
            height
          }
          thumbnail {
            url
          }
        }
      }
    }
  }
`;

export function useMyVenturePitches() {
  const { data, loading, error } = useQuery(MY_VENTURE_PITCHES, {
    fetchPolicy: 'network-only',
  });
  return { data, loading, error };
}

export const GET_VENTURE_PITCH = gql`
  query GET_VENTURE_PITCH($id: ID!) {
    getVenturePitch(id: $id) {
      id
      name
      description
      industry {
        id
        name
      }
      subIndustry {
        id
        name
      }
      offering
      job {
        id

        video {
          url
          width
          height
        }
        thumbnail {
          url
        }
      }
    }
  }
`;

export const SEARCH_VENTURES = gql`
  query {
    searchVentures {
      ventures {
        createdAt
        updatedAt
        id
        name
        description
        website
        country
        city
        state
        logo
        logoCrop {
          url
        }
        user {
          features {
            dashboard
            cart
          }
        }
      }
    }
  }
`;

export function useSearchVentures() {
  const { data, error, loading } = useQuery(SEARCH_VENTURES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    options: {
      fetchPolicy: 'no-cache',
    },
  });

  return { data, error, loading };
}

export const GET_VENTURE = gql`
  query($id: ID!) {
    getVenture(id: $id) {
      id
      name
      description
      website
      country
      city
      state
      logo
      logoCrop {
        url
      }
      team {
        firstName
        lastName
        bio
        photo
        title
        social {
          linkedIn
        }
      }
      user {
        id
        features {
          dashboard
          cart
        }
      }
      tags {
        id
        name
      }
      pitches {
        id
        name
        industry {
          id
        }
        subIndustry {
          id
        }
        job {
          id
          video {
            url
          }
          thumbnail {
            url
          }
          thumbnails {
            url
          }
        }
      }
    }
  }
`;

export function useGetVenture(id) {
  const { data, error, loading } = useQuery(GET_VENTURE, {
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
  });

  return { data, error, loading };
}

export const UPDATE_VENTURE_FEATURES = gql`
  mutation($id: ID!, $input: VentureFeaturesInput) {
    updateVentureFeatures(id: $id, input: $input) {
      id
      features {
        cart
      }
    }
  }
`;

export function useUpdateVentureFeatures(id) {
  const [mutation] = useMutation(UPDATE_VENTURE_FEATURES, {
    refetchQueries: [{ query: GET_VENTURE, variables: { id } }],
  });

  async function exec(features) {
    return await mutation({ variables: { id, input: features } });
  }

  return exec;
}
