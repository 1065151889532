import React from 'react';

import {
  Loader,
  Grid,
  Cell,
  Container,
  Heading,
  Button,
} from '../../../components/ui';
import { AdminSecondaryNav } from '../../../components/layout';
import {
  useGetVenture,
  useUpdateVentureFeatures,
} from '../../../graphql/ventures';
import { useNotifications } from '../../../hooks/notifications';

export function AdminBuyer(props) {
  const { venture } = props;
  const { user } = venture;

  const notificaitons = useNotifications();
  const updateVentureFeatures = useUpdateVentureFeatures(venture.id);

  async function enabledVentureCart() {
    try {
      await updateVentureFeatures({ cart: true });
      notificaitons.notify(`Enabled Shopping Cart for ${venture.name}`, {
        time: 3000,
      });
    } catch (e) {
      console.log(e);
      // e
    }
  }

  async function disableVentureCart() {
    try {
      await updateVentureFeatures({ cart: false });
      notificaitons.notify(`Disabled Shopping Cart for ${venture.name}`, {
        time: 3000,
      });
    } catch (e) {
      console.log(e);
      // e
    }
  }

  return (
    <section className="EditReviewer">
      <Grid>
        <Cell small={6}>
          <Heading level={1}>{venture.name}</Heading>
        </Cell>
        <Cell className="text-right" small={6}>
          {!user.features.cart ? (
            <Button onClick={enabledVentureCart}>Enable Shopping Cart</Button>
          ) : (
            <Button onClick={disableVentureCart}>Disable Shopping Cart</Button>
          )}
        </Cell>
        <Cell>
          <hr />
        </Cell>
      </Grid>
    </section>
  );
}

export function AdminBuyerLoader(props) {
  const { data, loading, error } = useGetVenture(props.match.params.id);

  if (loading) return <Loader />;
  if (error) return null;

  return <AdminBuyer venture={data.getVenture} />;
}

export function AdminBuyerWrapper(props) {
  return (
    <Container>
      <Grid>
        <Cell small={6}>
          <Heading level={1}>
            <b>Vintro</b> Admin
          </Heading>
        </Cell>

        <Cell>
          <hr />
        </Cell>
      </Grid>
      <Grid margin>
        <Cell small={12} medium={3}>
          <AdminSecondaryNav />
        </Cell>
        <Cell small={12} medium={9}>
          <AdminBuyerLoader {...props} />
        </Cell>
      </Grid>
    </Container>
  );
}

export default AdminBuyerWrapper;
