import { useState, useEffect } from 'react';
import store from 'store';
import eventsPlugin from 'store/plugins/events';
import * as decode from 'jwt-decode';

store.addPlugin(eventsPlugin);

export const TOKEN_KEY = 'vintro_jwt_v1';

export function useSession() {
  const token = store.get(TOKEN_KEY);
  const profile = token ? decode(token) : { features: {} };

  if (!profile.features) {
    profile.features = {
      dashboard: true,
      admin: false,
    };
  }

  const [session, setSession] = useState({
    isAuthenticated: token ? true : false,
    profile,
  });

  function startSession(token) {
    console.log('start session..');
    store.set(TOKEN_KEY, token);
    return decode(token);
  }

  function removeSession() {
    store.remove(TOKEN_KEY);
  }

  useEffect(() => {
    store.watch(TOKEN_KEY, function(v) {
      if (v === undefined) {
        setSession({
          isAuthenticated: false,
          profile: { features: {} },
        });
      } else {
        const profile = decode(v);

        if (!profile.features) {
          profile.features = {
            dashboard: true,
            admin: false,
          };
        }

        setSession({
          isAuthenticated: true,
          profile,
        });
      }
    });
  }, []);

  return { ...session, startSession, removeSession };
}

export function useUpdateSession() {
  return token => {
    store.set(TOKEN_KEY, token);
  };
}
