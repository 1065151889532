import './my-orders.scss';

import React from 'react';
import {
  Container,
  Grid,
  Cell,
  Loader,
  Card,
  Heading,
  Paragraph,
  Callout,
  Button,
} from '../../components/ui';

import { Link } from 'react-router-dom';
import { SecondaryNav } from '../../components/layout/secondary-nav/secondary-nav';
import { useGetMyOrders } from '../../graphql/orders';
import moment from 'moment';

export function MyOrderCard(props) {
  const { pitch, requests = [] } = props;

  return (
    <div className="MyOrders--card">
      <Link to={`/dashboard/my-orders/${props.id}`}>
        <Card theme={'air'} hover>
          <Heading level={1}>{pitch.name}</Heading>
          <Heading level={6}>Order Placed</Heading>
          <Heading level={5}>
            {moment(new Date(props.createdAt)).format(
              'dddd, MMMM Do, YYYY hh:mm a'
            )}
          </Heading>
          <Heading level={6}>Responses</Heading>
          <Heading level={5}>
            <b>
              {
                requests.filter(
                  r =>
                    r.status === 'FULFILLED' ||
                    r.status === 'COMPLETED' ||
                    r.fulfilled
                ).length
              }{' '}
              of
            </b>{' '}
            {requests.length} Responses
          </Heading>
        </Card>
      </Link>
    </div>
  );
}

export function MyOrders(props) {
  const { orders } = props;

  return (orders || []).map(order => {
    return <MyOrderCard {...order} key={order.id} />;
  });
}

export function MyOrdersNoResults() {
  return (
    <Callout>
      <div className="text-center">
        <Heading style={{ margin: '1rem' }} level={4}>
          You Don't have any orders
        </Heading>
        <Paragraph>
          Our platform allows you to access to some of the most amazing minds in
          business that for the right price will weigh in on your venture,
          product or service
        </Paragraph>
        <Link to="/reviewers">
          <Button>Browse Reviewers</Button>
        </Link>
      </div>
    </Callout>
  );
}

export function MyOrdersLoader(props) {
  const { data, loading, error } = useGetMyOrders();

  if (loading) return <Loader />;
  if (error) return null;

  if (data.myOrders.orders.length === 0) {
    return <MyOrdersNoResults />;
  }

  return <MyOrders orders={data.myOrders.orders} />;
}

export function MyOrdersWrapper(props) {
  return (
    <section className="MyOrders">
      <Container>
        <Grid>
          <Cell>
            <Heading level={1}>
              My <b>Orders</b>
            </Heading>
            <hr />
          </Cell>
        </Grid>
        <Grid>
          <Cell small={12} medium={3}>
            <SecondaryNav />
          </Cell>
          <Cell small={12} medium={9}>
            <MyOrdersLoader />
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export default MyOrdersWrapper;
