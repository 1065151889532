import React from 'react';
import { NavLink } from 'react-router-dom';

export function SecondaryNav() {
  return (
    <div>
      <ul className="DashboardMenu--Main">
        <li>
          <NavLink to="/admin/orders">Orders</NavLink>
        </li>
        <li>
          <NavLink to="/admin/reviewers">Reviewers</NavLink>
        </li>
        <li>
          <NavLink to="/admin/buyers">Buyers</NavLink>
        </li>
      </ul>
      <ul className="DashboardMenu">
        <li>
          <NavLink to="/admin/invites">Concierge Invites</NavLink>
        </li>

        <li>
          <NavLink to="/admin/networks">Affiliate Networks</NavLink>
        </li>
        <li>
          <NavLink to="/admin/affiliates">Affiliates</NavLink>
        </li>
        <li>
          <NavLink to="/admin/previews">Preview Tokens</NavLink>
        </li>
      </ul>
    </div>
  );
}

export default SecondaryNav;
