import React, { useEffect } from 'react';
import history from './util/history';
import { PrimaryNav, SiteFooter } from './components/layout';
import { Router, Route, Switch } from 'react-router-dom';
import { GlobalStateProvider } from './store';
import * as Screens from './screens';
import './App.scss';

import { ApolloProvider as ReactApolloProvider } from 'react-apollo';
import { ApolloProvider } from 'react-apollo-hooks';
import {
  ApolloProvider as ApolloProviderHooks,
  useApolloClient,
} from '@apollo/react-hooks';
import apolloClient from './apollo/client';
import { useSession, useUpdateSession } from './hooks/session';

import Notifications from './components/layout/notifications/notifications';
import {
  AppSecurityError,
  AppMissingError,
} from './components/ui/errors/errors';

import './util/validators';
import { SESSION } from './graphql/auth';

history.listen(() => {
  window.scrollTo(0, 0);
});

function Protected(props) {
  const session = useSession({});

  if (!session.isAuthenticated) {
    return <AppSecurityError />;
  }

  return <props.Component {...props} />;
}

function protectedRoute(Component) {
  return props => {
    return <Protected {...props} Component={Component} />;
  };
}

function AdminProtected(props) {
  const session = useSession({});

  if (!session.isAuthenticated) {
    return <AppSecurityError />;
  }

  if (
    session.profile.type === 'ADMIN' ||
    session.profile.type === 'SUPERADMIN'
  ) {
    return <props.Component {...props} />;
  } else {
    return <AppSecurityError />;
  }
}

function adminRoute(Component) {
  return props => {
    return <AdminProtected {...props} Component={Component} />;
  };
}

function SuperAdminProtected(props) {
  const session = useSession({});

  if (!session.isAuthenticated) {
    return <AppSecurityError />;
  }

  if (session.profile.type === 'SUPERADMIN') {
    return <props.Component {...props} />;
  } else {
    return <AppSecurityError />;
  }
}

function superAdminRoute(Component) {
  return props => {
    return <SuperAdminProtected {...props} Component={Component} />;
  };
}

function AppRoutes() {
  return (
    <Router history={history}>
      <div className="App__content">
        <PrimaryNav />
        <Notifications />
        <Switch>
          <Route path="/" exact component={Screens.Reviewers} />
          <Route path="/terms" exact component={Screens.Terms} />
          <Route path="/privacy" exact component={Screens.Privacy} />
          <Route path="/sign-up" component={Screens.SignUp} />
          <Route
            path="/reset-password"
            exact
            component={Screens.RequestResetPassword}
          />
          <Route
            exact
            path="/reset-password/:token"
            component={Screens.ResetPassword}
          />
          <Route path="/sign-in" component={Screens.SignIn} />
          <Route exact path="/invite/:id" component={Screens.Invite} />
          <Route
            exact
            path="/invite/:id/payout"
            component={Screens.InvitePayout}
          />
          <Route exact path="/apply" component={Screens.Apply} />
          <Route
            exact
            path="/apply/step/onboarding"
            component={Screens.ApplyWizard}
          />
          <Route
            exact
            path="/apply/step/onboarding/identity-success"
            component={Screens.IdenititySuccess}
          />
          <Route exact path="/apply/:id" component={Screens.Apply} />
          <Route
            exact
            path="/dashboard"
            render={protectedRoute(Screens.DashboardShopper)}
          />
          <Route
            exact
            path="/dashboard/my-company"
            render={protectedRoute(Screens.DashboardMyCompany)}
          />
          <Route
            exact
            path="/dashboard/my-team"
            render={protectedRoute(Screens.DashboardMyTeam)}
          />
          <Route
            path="/dashboard/my-pitches"
            exact
            render={protectedRoute(Screens.DashboardMyPitches)}
          />
          <Route
            path="/dashboard/my-pitches/view/:id"
            exact
            render={protectedRoute(Screens.DashboardMyPitch)}
          />
          <Route
            path="/dashboard/my-pitches/new"
            exact
            render={protectedRoute(Screens.DashboardNewPitch)}
          />
          <Route
            path="/dashboard/my-orders"
            exact
            render={protectedRoute(Screens.DashboardMyOrders)}
          />
          <Route
            path="/dashboard/my-payout-details"
            exact
            render={protectedRoute(Screens.DashboardMyPaymentDetails)}
          />
          <Route
            path="/dashboard/my-orders/:id"
            exact
            render={protectedRoute(Screens.DashboardMyOrder)}
          />
          <Route
            path="/dashboard/my-reviews"
            exact
            render={protectedRoute(Screens.DashboardMyReviews)}
          />
          <Route
            path="/dashboard/my-profile"
            exact
            render={protectedRoute(Screens.DashboardMyProfile)}
          />
          <Route
            path="/dashboard/my-request/:id"
            exact
            component={Screens.MyRequest}
          />
          <Route path="/dashboard/chat" exact component={Screens.Chat} />
          <Route
            path="/create-company"
            render={protectedRoute(Screens.CreateCompany)}
          />
          <Route
            path="/reviewers/:id"
            exact
            component={Screens.ReviewerDetail}
          />
          <Route path="/reviewers" exact component={Screens.Reviewers} />
          <Route path="/company/:id" exact component={Screens.CompanyDetail} />
          <Route path="/checkout" exact component={Screens.Checkout} />
          <Route
            path="/billing"
            exact
            render={protectedRoute(Screens.Billing)}
          />
          <Route path="/order/:id" exact component={Screens.OrderPlaced} />
          <Route path="/help" exact component={Screens.Help} />
          {/* ADMIN ROUTES */}
          <Route
            path="/admin"
            exact
            component={adminRoute(Screens.AdminOrders)}
          />
          <Route
            path="/admin/reviewers"
            exact
            component={adminRoute(Screens.AdminReviewers)}
          />
          <Route
            path="/admin/networks"
            exact
            component={adminRoute(Screens.AdminNetworks)}
          />
          <Route
            path="/admin/networks/view/:id"
            exact
            component={adminRoute(Screens.AdminNetwork)}
          />
          <Route
            path="/admin/buyers"
            exact
            component={adminRoute(Screens.AdminBuyers)}
          />
          <Route
            path="/admin/buyers/view/:id"
            exact
            component={adminRoute(Screens.AdminViewBuyer)}
          />
          <Route
            path="/admin/networks/new"
            exact
            component={adminRoute(Screens.AdminAddNetwork)}
          />
          <Route
            path="/admin/affiliates"
            exact
            component={adminRoute(Screens.AdminAffiliates)}
          />
          <Route
            path="/admin/affiliates/view/:id"
            exact
            component={adminRoute(Screens.AdminAffiliate)}
          />
          <Route
            path="/admin/invites"
            exact
            component={adminRoute(Screens.AdminInvites)}
          />
          <Route
            path="/admin/invites/new"
            exact
            component={adminRoute(Screens.AdminAddInvite)}
          />
          <Route
            path="/admin/invites/view/:id"
            exact
            component={adminRoute(Screens.AdminInvite)}
          />
          <Route
            path="/admin/orders"
            exact
            component={adminRoute(Screens.AdminOrders)}
          />
          <Route
            path="/admin/orders/view/:id"
            exact
            component={adminRoute(Screens.AdminViewOrder)}
          />

          <Route
            path="/admin/reviewers/view/:id"
            exact
            component={adminRoute(Screens.AdminViewReviewer)}
          />

          <Route
            path="/admin/reviewers/edit/:id"
            exact
            component={adminRoute(Screens.AdminEditReviewer)}
          />
          <Route
            path="/admin/users/edit/:id"
            exact
            component={superAdminRoute(Screens.AdminEditUser)}
          />

          <Route
            path="/admin/previews"
            exact
            component={adminRoute(Screens.AdminPreviewTokens)}
          />

          <Route component={AppMissingError} />
        </Switch>
      </div>
      <SiteFooter />
    </Router>
  );
}

function SessionRefresher() {
  const client = useApolloClient();
  const updateSession = useUpdateSession();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await client.query({ query: SESSION });

        if (data.session) {
          updateSession(data.session.token);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [client, updateSession]);

  return null;
}

function App() {
  return (
    <GlobalStateProvider>
      <ReactApolloProvider client={apolloClient}>
        <ApolloProviderHooks client={apolloClient}>
          <ApolloProvider client={apolloClient}>
            <div className="App">
              <AppRoutes />
              <SessionRefresher />
            </div>
          </ApolloProvider>
        </ApolloProviderHooks>
      </ReactApolloProvider>
    </GlobalStateProvider>
  );
}

export default App;
