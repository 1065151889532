import React from 'react';
import { NavLink } from 'react-router-dom';
import { Grid, Cell, Image, Container } from '../../ui';
import logo from '../../../images/logowithslogan.png';
import './site-footer.scss';

export function SiteFooter() {
  const year = new Date().getFullYear();
  return (
    <footer className="SiteFooter">
      <Container>
        <Grid>
          <Cell medium="auto">
            <div className="SiteFooter__links">
              <h5 className="SiteFooter__heading">Help</h5>
              <ul className="SiteFooter__linkList">
                <li className="SiteFooter__linkListItem">
                  <a
                    href="https://support.myvintro.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Support
                  </a>
                </li>
              </ul>
            </div>
          </Cell>
          <Cell medium="auto">
            <div className="SiteFooter__links">
              <h5 className="SiteFooter__heading">Company</h5>
              <ul className="SiteFooter__linkList">
                <li className="SiteFooter__linkListItem">
                  <a
                    href="https://support.myvintro.com/hc/en-us/articles/360035919713"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Mission
                  </a>
                </li>
                <li className="SiteFooter__linkListItem">
                  <a
                    href="https://support.myvintro.com/hc/en-us/articles/360035135854"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Vision
                  </a>
                </li>
                <li className="SiteFooter__linkListItem">
                  <a
                    href="https://support.myvintro.com/hc/en-us/articles/360035735353"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Origin Story
                  </a>
                </li>
                <li className="SiteFooter__linkListItem">
                  <a
                    href="https://support.myvintro.com/hc/en-us/sections/360005441574"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blog
                  </a>
                </li>
              </ul>
            </div>
          </Cell>
          <Cell medium="auto">
            <div className="SiteFooter__links">
              <h5 className="SiteFooter__heading">Legal</h5>
              <ul className="SiteFooter__linkList">
                <li className="SiteFooter__linkListItem">
                  <NavLink to="/terms">TOS</NavLink>
                </li>
                <li className="SiteFooter__linkListItem">
                  <NavLink to="/privacy">Privacy</NavLink>
                </li>
              </ul>
            </div>
          </Cell>
          <Cell medium="shrink">
            <div>
              <Image src={logo} maxWidth={110} alt={'Logo'} />
            </div>
            <br />
            <span>&copy; {year} The Krowd, Inc.</span>
          </Cell>
        </Grid>
      </Container>
    </footer>
  );
}

export default SiteFooter;
