import './my-inbox.scss';
import React from 'react';
import {
  Container,
  Grid,
  Cell,
  Loader,
  Card,
  Heading,
  Paragraph,
  Callout,
  Button,
} from '../../components/ui';

import { Link } from 'react-router-dom';
import { SecondaryNav } from '../../components/layout/secondary-nav/secondary-nav';
import { useGetMyInbox } from '../../graphql/inbox';
import moment from 'moment';

import { MyOrderRequest } from '../my-order/my-order';

export function MyInboxCard(props) {
  const { scout, order } = props;

  return (
    <div className="MyInbox__Card">
      <Link to={`/dashboard/my-orders/${props.id}`}>
        <Card hover>
          <Grid>
            <Cell small={12}>
              <Heading level={1}>{order.pitch.name}</Heading>
            </Cell>
            <Cell small={12} medium={3}>
              <Heading level={2}>
                {scout.firstName} {scout.lastName}
              </Heading>
              <Heading level={5}>{scout.company}</Heading>
              <Heading level={5}>
                {moment(new Date(props.createdAt)).format(
                  'dddd, MMMM Do, YYYY hh:mm a'
                )}
              </Heading>
            </Cell>
          </Grid>
        </Card>
      </Link>
    </div>
  );
}

export function MyInbox(props) {
  const { messages = [] } = props;

  return (messages || []).map(order => {
    return <MyOrderRequest {...order} key={order.id} />;
  });
}

export function MyInboxNoResults() {
  return (
    <Callout>
      <div className="text-center">
        <Heading style={{ margin: '1rem' }} level={4}>
          You Don't have any reviews in your inbox
        </Heading>
        <Paragraph>
          Our platform allows you to access to some of the most amazing minds in
          business that for the right price will weigh in on your venture,
          product or service
        </Paragraph>
        <Link to="/reviewers">
          <Button>Browse Reviewers</Button>
        </Link>
      </div>
    </Callout>
  );
}

export function MyInboxLoader(props) {
  const { data, error, loading } = useGetMyInbox();

  console.log(data, error, loading);

  if (loading) return <Loader />;
  if (error) return null;

  if (data.myInbox.items.length === 0) {
    return <MyInboxNoResults />;
  }

  return <MyInbox messages={data.myInbox.items} />;
}

export function MyInboxWrapper(props) {
  return (
    <section className="MyInbox">
      <Container>
        <Grid>
          <Cell>
            <Heading level={1}>
              My <b>Inbox</b>
            </Heading>
            <hr />
          </Cell>
        </Grid>
        <Grid>
          <Cell small={12} medium={3}>
            <SecondaryNav />
          </Cell>
          <Cell small={12} medium={9}>
            <MyInboxLoader />
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export default MyInboxWrapper;
