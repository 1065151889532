import React from 'react';
import {
  Container,
  Grid,
  Cell,
  Heading,
  Loader,
  Card,
} from '../../components/ui';

import { ReviewerNav } from '../../components/layout';
import { useMyReviews } from '../../graphql/scouts';
import { AppError } from '../../components/ui/errors/errors';
import { Link } from 'react-router-dom';
import { ReviewerDashboardHeader } from '../../components/ui/dashboard/header/header';

export function MyReviewCard(props) {
  const { order } = props;
  const { pitch } = order;

  return (
    <Link to={`/dashboard/my-request/${props.id}`}>
      <Card>
        <Heading level={2}>{pitch.name}</Heading>
      </Card>
    </Link>
  );
}

export function MyReviews(props) {
  const { requests } = props.reviews;

  return (
    <div>
      {requests.map(review => {
        return <MyReviewCard {...review} key={review.id} />;
      })}
    </div>
  );
}

export function MyReviewsLoader() {
  const { data, error, loading } = useMyReviews();

  if (error) return <AppError />;
  if (loading) return <Loader />;

  return <MyReviews reviews={data.myReviews} />;
}

export function MyReviewsWrapper(props) {
  return (
    <section className="MyReviews">
      <Container>
        <Grid>
          <Cell>
            <ReviewerDashboardHeader />
            <hr />
          </Cell>
        </Grid>
        <Grid margin>
          <Cell small={12} medium={3}>
            <ReviewerNav />
          </Cell>
          <Cell small={12} medium={9}>
            <MyReviewsLoader />
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export default MyReviewsWrapper;
