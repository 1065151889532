import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Cell,
  TextInput,
  TextareaInput,
  Button,
  LogoInput,
} from '../../ui';

export function TeamMember(props) {
  return (
    <div className="TeamMember">
      <Grid margin>
        <Cell large={12}>
          <LogoInput
            name={'photo'}
            value={props.photo}
            onChange={props.onChange}
            label={'Add Photo'}
          />
        </Cell>
        <Cell large={12}>
          <hr />
        </Cell>
        <Cell large={6}>
          <TextInput
            spaced
            label={'First Name'}
            value={props.firstName}
            onChange={props.onChange}
            name={'firstName'}
          />
        </Cell>
        <Cell large={6}>
          <TextInput
            spaced
            label={'Last Name'}
            value={props.lastName}
            onChange={props.onChange}
            name={'lastName'}
          />
        </Cell>
      </Grid>
      <TextInput
        spaced
        label={'Title'}
        value={props.title}
        name={'title'}
        onChange={props.onChange}
      />
      <TextInput
        spaced
        label={'LinkedIn'}
        value={props.linkedIn}
        name={'linkedIn'}
        onChange={props.onChange}
      />
      <TextareaInput
        spaced
        label={'Bio'}
        value={props.bio}
        limit={250}
        name={'bio'}
        onChange={props.onChange}
      />
      <div>
        {props.showRemoveButton && (
          <Button
            size="small"
            theme={'tertiary'}
            onClick={props.removeTeamMember}
          >
            Remove Person
          </Button>
        )}
      </div>
      <br />
    </div>
  );
}

TeamMember.propTypes = {
  onChange: PropTypes.func,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  bio: PropTypes.string,
  linkedin: PropTypes.string,
  title: PropTypes.string,
  showRemoveButton: PropTypes.bool,
};

TeamMember.defaultProps = {
  onChange: () => null,
  removeTeamMember: () => null,
  first_name: '',
  last_name: '',
  bio: '',
  linkedin: '',
  title: '',
  showRemoveButton: false,
};

export default TeamMember;
