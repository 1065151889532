import './networks.scss';
import React, { useState } from 'react';

import {
  Loader,
  Button,
  Grid,
  Cell,
  Container,
  Heading,
  Card,
  Pagination,
  SearchInput,
} from '../../../components/ui';
import { AdminSecondaryNav } from '../../../components/layout';
import { useQuery } from 'react-apollo-hooks';
import { Link } from 'react-router-dom';
import { SEARCH_AFFILIATE_NETWORKS } from '../../../graphql/affiliates';

export function AdminReviewers(props) {
  return props.scouts.map(scout => {
    return (
      <Link key={scout.id} to={`/admin/networks/view/${scout.id}`}>
        <Card hover>
          <Heading level={3}>{scout.name}</Heading>
        </Card>
      </Link>
    );
  });
}

export function AdminReviewersLoader(props) {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    text: '',
    industry: '',
    subIndusttry: '',
  });

  const { data, loading, error } = useQuery(SEARCH_AFFILIATE_NETWORKS, {
    variables: {
      page: page,
      search: { type: 'ScoutPerson', text: filters.text },
    },
  });

  function handleChange(event) {
    const key = event.target.name;
    const value = event.target.value;

    setFilters(filters => ({
      ...filters,
      [key]: value,
    }));
  }

  // if (loading) return <Loader />;
  if (error) return null;

  return (
    <div>
      <Grid margin>
        <Cell small={12} medium={12}>
          <SearchInput
            value={filters.text}
            name="text"
            onChange={handleChange}
            spaced
            placeholder="Search Affiliate Networks"
          />
        </Cell>
      </Grid>

      {!loading ? (
        <AdminReviewers scouts={data.searchAffiliateNetworks.results} />
      ) : (
        <Loader />
      )}

      <div className="text-center">
        {!loading && data.searchAffiliateNetworks.pages > 1 && (
          <Pagination
            currentPage={data.searchAffiliateNetworks.page}
            totalItems={data.searchAffiliateNetworks.total}
            pageSize={data.searchAffiliateNetworks.limit}
            maxPages={data.searchAffiliateNetworks.pages}
            onPageChange={page => setPage(page)}
          />
        )}
      </div>
    </div>
  );
}

export function AdminReviewersWrapper(props) {
  return (
    <Container>
      <Grid>
        <Cell small={6}>
          <Heading level={1}>
            <b>Vintro</b> Admin
          </Heading>
        </Cell>
        <Cell small={6}>
          <div className="text-right">
            <Link to="/admin/networks/new">
              <Button>Add Affiliate Network</Button>
            </Link>
          </div>
        </Cell>
        <Cell>
          <hr />
        </Cell>
      </Grid>
      <Grid margin>
        <Cell small={12} medium={3}>
          <AdminSecondaryNav />
        </Cell>
        <Cell small={12} medium={9}>
          <AdminReviewersLoader />
        </Cell>
      </Grid>
    </Container>
  );
}

export default AdminReviewersWrapper;
