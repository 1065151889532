import React from 'react';
import PropTypes from 'prop-types';
import baseInput from '../base-input/base-input';

export function RangeInput(props) {
  return (
    <div className="Field Field--range">
      <input
        type="range"
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        required={props.required}
        min={props.min}
        max={props.max}
        step={props.step}
      />
    </div>
  );
}

RangeInput.defaultProps = {
  value: 0,
  min: 0,
  max: 10,
  step: 1,
};

RangeInput.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
};

export default baseInput(RangeInput);
