import { useGlobalState } from '../store';

export function useNotifications() {
  const [, setNotifications] = useGlobalState('notifications');

  function notify(message, opts = { time: 3000 }) {
    setNotifications([
      {
        message,
        confirm: opts.confirm,
        error: opts.error || false,
        time: opts.time || 3000,
      },
    ]);
  }

  function clear() {
    setNotifications([]);
  }

  return { notify, clear };
}
