import validate from 'validate.js';
import passwordValidator from 'password-validator';

const schema = new passwordValidator();

schema
  .is()
  .min(8)
  .is()
  .max(100)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .symbols();

validate.validators.password = function(value, options, key, attributes) {
  const response = schema.validate(value);

  if (response === false) {
    return 'must contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character';
  }
};
