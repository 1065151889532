import './my-pitch.scss';
import React from 'react';
import {
  Heading,
  Button,
  Container,
  Grid,
  Cell,
  Loader,
} from '../../components/ui';

import {
  GET_VENTURE_PITCH,
  useUpdateVenturePitch,
  useRemoveVenturePitch,
} from '../../graphql/ventures';

import { FormStep } from '../../components/forms';

import { useQuery } from 'react-apollo-hooks';
import { SecondaryNav } from '../../components/layout/secondary-nav/secondary-nav';
import { AppSecurityError, AppError } from '../../components/ui/errors/errors';
import FormStepThree from '../create-company/steps/step.three';
import { useForm } from '../../hooks/forms';
import { useNotifications } from '../../hooks/notifications';
import history from '../../util/history';

export function MyPitch(props) {
  const notificaitons = useNotifications();
  const updateVenturePitch = useUpdateVenturePitch(props.pitch.id);
  const removeVenturePitch = useRemoveVenturePitch(props.pitch.id);
  const { pitch } = props;
  const { form, errors, handleFormChange } = useForm({
    offering: props.pitch.offering || [],
    ideaName: props.pitch.name || '',
    ideaDescription: props.pitch.description || '',
    industry: props.pitch.industry.id,
    subIndustry: props.pitch.subIndustry.id,
    job: pitch.job ? pitch.job.id : null,
    video: pitch.job ? pitch.job : null,
    preview: pitch.job && pitch.job.video ? pitch.job.video.url : null,
    cover: pitch.job && pitch.job.thumbnail ? pitch.job.thumbnail.url : null,
  });

  async function handleSubmit() {
    try {
      await updateVenturePitch({
        name: form.ideaName,
        offering: form.offering,
        job: form.job,
      });

      notificaitons.notify('Your Pitch has been updated.', { time: 3000 });
    } catch (e) {
      notificaitons.notify(`There was an error saving your pitch.`, {
        time: 3000,
        error: true,
      });

      return e;
    }
  }

  function handleRemovePitch() {
    notificaitons.notify(`Are you sure you want to remove this pitch?`, {
      time: 30000,
      confirm: handleConfirmRemovePitch,
    });
  }

  async function handleConfirmRemovePitch() {
    try {
      await removeVenturePitch();
      notificaitons.notify(`Your pitch has been deleted.`, {
        time: 3000,
      });

      history.push('/dashboard/my-pitches');
    } catch (e) {
      notificaitons.notify(`There was an error deleting your pitch.`, {
        time: 3000,
        error: true,
      });
    }
  }

  return (
    <section className="MyPitch">
      <Container>
        <Grid spaced align="center">
          <Cell large={12}>
            <FormStep currentStep={1} step={1}>
              <FormStepThree
                form={form}
                errors={errors}
                handleFormChange={handleFormChange}
              />
            </FormStep>
          </Cell>
          <Cell large={12}>
            <div className="Controls text-right">
              <Button hollow onClick={handleRemovePitch}>
                Delete Pitch
              </Button>
              <Button onClick={handleSubmit}>Update Pitch</Button>
            </div>
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export function MyPitchLoader(props) {
  const { data, loading, error } = useQuery(GET_VENTURE_PITCH, {
    variables: { id: props.match.params.id },
  });

  if (loading) return <Loader />;
  if (error) {
    console.log(error);
    const code =
      error.graphQLErrors && error.graphQLErrors[0]
        ? error.graphQLErrors[0].code
        : null;

    switch (code) {
      case 401: {
        return <AppSecurityError message={error.graphQLErrors[0].message} />;
      }
      default: {
        return <AppError />;
      }
    }
  }

  return <MyPitch pitch={data.getVenturePitch} />;
}

export function MyPitchWrapper(props) {
  return (
    <Container>
      <Grid>
        <Cell>
          <Heading level={1}>
            My <b>Pitches</b>
          </Heading>
          <hr />
        </Cell>
      </Grid>
      <Grid>
        <Cell small={12} medium={3}>
          <SecondaryNav />
        </Cell>
        <Cell small={12} medium={9}>
          <MyPitchLoader {...props} />
        </Cell>
      </Grid>
    </Container>
  );
}

export default MyPitchWrapper;
