import React from 'react';
import PropTypes from 'prop-types';
import baseInput from '../base-input/base-input';

export function FileInput(props) {
  return (
    <div className="Field Field--file">
      <input
        type="file"
        name={props.name}
        accept={props.accept}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
      />
    </div>
  );
}

FileInput.defaultProps = {
  accept: null,
};

FileInput.propTypes = {
  accept: PropTypes.string,
};

export default baseInput(FileInput);
