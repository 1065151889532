import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './price.scss';

export function Price(props) {
  const classes = classNames({
    Price: true,
    'Price--large': props.size === 'large',
  });

  return <div className={classes}>${props.value}</div>;
}

Price.propTypes = {
  value: PropTypes.any,
  size: PropTypes.oneOf(['large']),
};

Price.defaultProps = {
  value: 0,
  size: null,
};

export default Price;
