import React, { useState } from 'react';
import validate from 'validate.js';
import {
  Grid,
  Cell,
  Button,
  Heading,
  Container,
  EmailInput,
  PasswordInput,
  Paragraph,
} from '../../components/ui';
import { ButtonLoading } from '../../components/ui/button/button';

import { FormError } from '../../components/forms';

import { useSession } from '../../hooks/session';

import { useRegisterAsScout } from '../../graphql/scouts';
import PhoneInput from '../../components/ui/inputs/phone/phone';
import { decidePostLoginRoute } from '../../graphql/auth';
import { useNotifications } from '../../hooks/notifications';
import CheckboxInput from '../../components/ui/inputs/checkbox/checkbox';

export function Invite(props) {
  const registerAsScout = useRegisterAsScout();
  const notifications = useNotifications();
  const session = useSession();
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    company: '',
    email: '',
    password: '',
    phone: '',
  });

  const [errors, setErrors] = useState({
    company: [],
    email: [],
    password: [],
    phone: [],
    terms: [],
    server: null,
  });

  const validationRules = {
    email: {
      presence: { allowEmpty: false, message: `Email can't be blank` },
      email: { message: `Email is not a valid email` },
    },
    phone: {
      presence: { allowEmpty: false, message: `Phone can't be blank` },
    },
    password: {
      presence: true,
      length: {
        minimum: 6,
        message: 'Password must be at least 6 characters',
      },
      password: {
        message:
          'Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character',
      },
    },
    confirmPassword: {
      presence: { allowEmpty: false, message: `Password can't be blank` },
      equality: 'password',
    },
    terms: {
      presence: {
        allowEmpty: false,
        fullMessages: false,
        message: 'You must agree to the terms and conditions',
      },
    },
  };

  function handleFormChange(event) {
    const key = event.target.name;
    const value = event.target.value;

    if (errors[key] && errors[key].length > 0) {
      const result = validate({ ...form, [key]: value }, validationRules);
      setErrors({
        ...errors,
        [key]: result ? result[key] : [],
      });
    }

    setForm(form => ({ ...form, [key]: value }));
  }

  function handleTermsChange(event) {
    handleFormChange({
      target: { name: 'terms', value: form.terms === 'yes' ? '' : 'yes' },
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (loading) return false;

    setErrors(errors => ({
      ...errors,
      server: null,
    }));

    const formIsValid = validateForm();
    if (formIsValid) {
      const input = {
        email: form.email ? form.email.toLowerCase() : form.email,
        password: form.password,
        invite: props.match.params.id,
        phone: form.phone,
      };
      try {
        setLoading(true);
        const { data } = await registerAsScout(input);

        if (data.registerAsPerson) {
          session.startSession(data.registerAsPerson.token);
          decidePostLoginRoute(data.registerAsPerson.token);
        }
      } catch (e) {
        /*if (e.graphQLErrors && e.graphQLErrors[0]) {
          setErrors(errors => ({
            ...errors,
            server: e.graphQLErrors[0].message,
          }));}*/
        notifications.notify(`There was an error. Email might be in use.`, {
          time: 3000,
          error: true,
        });
      }
      setLoading(false);
    }
  }

  function validateForm() {
    const result = validate(form, validationRules, { fullMessages: false });
    const isValid = result === undefined;
    const fields = result || {};

    setErrors({
      company: fields.company || [],
      email: fields.email || [],
      password: fields.password || [],
      confirmPassword: fields.confirmPassword || [],
      phone: fields.phone || [],
      terms: fields.terms || [],
    });

    return isValid;
  }

  return (
    <section className="SignUp">
      <Container>
        <Grid align="center">
          <Cell large={5} medium={8} small={12}>
            <Heading level={1}>
              You've been invited to <b>Vintro</b>
            </Heading>
            <Heading level={2}>
              Sell your time to people who want to pitch to you. Share insights
              and build relationships with exciting companies and people.{' '}
            </Heading>
            <Paragraph>
              More information on Vintro & the review process is available at{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.myvintro.com"
              >
                support.myvintro.com
              </a>
            </Paragraph>
            <hr />
            {errors.server && <FormError>{errors.server}</FormError>}
            <form onSubmit={handleSubmit}>
              <Grid margin>
                <Cell small={12}>
                  <EmailInput
                    value={form.email}
                    name={'email'}
                    errors={errors.email}
                    onChange={handleFormChange}
                    placeholder={'Enter your preferred email'}
                    label={'Your Email'}
                    helpText="Only our internal team has access to your email or phone number to send transaction info. 
                    We never share your direct contact info with buyers."
                    spaced
                  />
                </Cell>
                <Cell small={12}>
                  <PhoneInput
                    spaced
                    value={form.phone}
                    errors={errors.phone}
                    onChange={handleFormChange}
                    name="phone"
                    label="Phone Number"
                  />
                </Cell>
                <Cell small={12} medium={6}>
                  <PasswordInput
                    value={form.password}
                    name={'password'}
                    errors={errors.password}
                    onChange={handleFormChange}
                    placeholder={'Enter your password'}
                    label={'Password'}
                    showHelp={true}
                    spaced
                    canToggle
                  />
                </Cell>
                <Cell small={12} medium={6}>
                  <PasswordInput
                    value={form.confirmPassword}
                    name={'confirmPassword'}
                    errors={errors.confirmPassword}
                    onChange={handleFormChange}
                    placeholder={'Confirm Password'}
                    label={'Re-Enter your password'}
                    spaced
                  />
                </Cell>
                <Cell small={12}>
                  <CheckboxInput
                    value={'yes'}
                    name={'terms'}
                    onChange={handleTermsChange}
                    errors={errors.terms}
                    checked={form.terms === 'yes'}
                    text={
                      <span>
                        I agree to the{' '}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/privacy"
                          // href="https://app.termly.io/document/privacy-policy/ee6f38d5-af00-4dae-bef1-cb14b708a2a2"
                        >
                          Privacy Policy
                        </a>{' '}
                        and{' '}
                        <a target="_blank" href="/terms">
                          Terms of Service
                        </a>
                      </span>
                    }
                  />
                </Cell>
              </Grid>

              <Grid margin align={'center'} style={{ alignItems: 'center' }}>
                <Cell small={12} medium={12}>
                  <div className="text-right">
                    <Button type="submit">
                      {loading ? (
                        <ButtonLoading>Submitting</ButtonLoading>
                      ) : (
                        <span>Submit</span>
                      )}
                    </Button>
                  </div>
                </Cell>
              </Grid>
              {/*<Link>Forgot Password?</Link>*/}
            </form>
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export default Invite;
