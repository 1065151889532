import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './image.scss';

export function Image(props) {
  const styles = props.style || {};
  const classes = classNames({
    Image: true,
    'Image--round': props.round,
    'Image--radius': props.radius,
  });

  if (props.maxWidth) {
    styles.maxWidth = `${props.maxWidth}px`;
  }

  return (
    <img src={props.src} style={styles} className={classes} alt={props.alt} />
  );
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  style: PropTypes.any,
  maxWidth: PropTypes.number,
  round: PropTypes.bool,
};

Image.defaultProps = {
  src: null,
  alt: null,
  style: null,
  maxWidth: null,
  round: false,
};

export default Image;
