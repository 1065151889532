import React from 'react';
import PropTypes from 'prop-types';
import baseInput from '../base-input/base-input';
import { Grid, Cell } from '../../../ui';
import './checkbox.scss';

export function CheckboxInput(props) {
  return (
    <div className="Field Field--checkbox">
      <Grid align={'top'}>
        <Cell shrink>
          <input
            type="checkbox"
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
            checked={props.checked}
          />
        </Cell>
        {props.text && (
          <Cell auto>
            <span className="Field--checkbox__text">{props.text}</span>
          </Cell>
        )}
      </Grid>
    </div>
  );
}

CheckboxInput.defaultProps = {
  value: '',
  checked: false,
  text: '',
};

CheckboxInput.propTypes = {
  value: PropTypes.any,
  checked: PropTypes.bool,
  text: PropTypes.string,
};

export default baseInput(CheckboxInput);
