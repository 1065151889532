import './tags.scss';
import React, { useState } from 'react';
import cc from 'classnames';
import { Heading, StrongText } from '../../../ui';

import { Tags, Tag } from '../../tags/tags';
import sdg from '../../../../util/sdg';
import verticals from '../../../../util/verticals';
import sectorOptions from '../../../../util/sector-options';

export function FilterGroup(props) {
  const { filters, setFilters } = props;
  const className = cc([
    'ReviewerFilters-FilterGroup',
    filters.open[props.id] ? 'open' : null,
    props.fixedHeight ? 'ReviewerFilters-FilterGroup--fixed' : null,
  ]);

  function toggle() {
    setFilters(filters => {
      return {
        ...filters,
        open: {
          ...filters.open,
          [props.id]: !filters.open[props.id],
        },
      };
    });
  }

  return (
    <div className={className}>
      <div onClick={toggle} className="ReviewerFilters-FilterGroup__name">
        <Heading level={5}>{props.name}</Heading>
        <i
          className={`fa fa-chevron-${filters.open[props.id] ? 'up' : 'down'}`}
        ></i>
      </div>
      <div className="ReviewerFilters-FilterGroup__filters">
        {filters.open[props.id] ? props.children : null}
      </div>
    </div>
  );
}

export function TagsInput(props) {
  const tags = props.value || [];
  const name = props.name;
  const onChange = props.onChange;
  const [filters, setFilters] = useState({ open: {} });

  const classNames = cc(['ReviewerFilters', 'open']);

  function selectTag(id) {
    let _tags = [...tags];

    if (hasTag(id)) {
      _tags = _tags.filter(tag => tag.toString() !== id.toString());
    } else {
      _tags = [...tags, id.toString()];
    }

    onChange({ target: { name: name, value: _tags } });
  }

  function hasTag(id) {
    const tag = tags.find(tag => tag.toString() === id.toString());
    if (tag) return true;
    return false;
  }

  return (
    <div className={classNames}>
      <div className="ReviewerFilters-FilterContent">
        <FilterGroup
          filters={filters}
          setFilters={setFilters}
          fixedHeight={true}
          id="industrial"
          name="Industrial Interests"
        >
          {sectorOptions.map(tag => {
            return (
              <div key={tag.code}>
                <span className="TagCategory">
                  <StrongText>{tag.name}</StrongText>
                </span>
                <Tags>
                  {tag.list.map(tag => {
                    return (
                      <Tag
                        onClick={selectTag}
                        selected={hasTag(tag.code)}
                        value={tag.code}
                        key={tag.code}
                      >
                        {tag.name}
                      </Tag>
                    );
                  })}
                </Tags>
              </div>
            );
          })}
        </FilterGroup>
        <FilterGroup
          filters={filters}
          setFilters={setFilters}
          fixedHeight={true}
          id="vertical"
          name="Emerging Verticals"
        >
          <Tags>
            {verticals.map(tag => {
              return (
                <Tag
                  onClick={selectTag}
                  selected={hasTag(tag.code)}
                  value={tag.code}
                  key={tag.code}
                >
                  {tag.name}
                </Tag>
              );
            })}
          </Tags>
        </FilterGroup>
        <FilterGroup
          filters={filters}
          setFilters={setFilters}
          fixedHeight={true}
          id="sdg"
          name="Sustainable Development Goals"
        >
          <Tags>
            {sdg.map(tag => {
              return (
                <Tag
                  onClick={selectTag}
                  selected={hasTag(tag.code)}
                  value={tag.code}
                  key={tag.code}
                >
                  {tag.name}
                </Tag>
              );
            })}
          </Tags>
        </FilterGroup>
      </div>
    </div>
  );
}

export function Spaced(props) {
  return <div className="Spaced">{props.children}</div>;
}

export default TagsInput;
