import React from 'react';

import { CheckoutItem } from '../../components/checkout';

import {
  Grid,
  Cell,
  Container,
  Heading,
  Divider,
  Button,
  Paragraph,
  Loader,
} from '../../components/ui';

import { useGetOrder } from '../../graphql/orders';
import { Link } from 'react-router-dom';

export function OrderDetails(props) {
  const { order } = props;
  return (
    <div>
      <Grid margin>
        <Cell large={12} small={12}>
          {order.items.map((item, idx) => {
            return <CheckoutItem {...item} key={idx} />;
          })}
        </Cell>
      </Grid>
    </div>
  );
}

export function OrderDetailsLoader(props) {
  const { error, loading, data } = useGetOrder(props.id);

  if (error) return null;
  if (loading) return <Loader />;

  return <OrderDetails {...props} order={data.getOrder} />;
}

export function OrderPlaced(props) {
  return (
    <section className="OrderPlaced">
      <Container>
        <Grid align="center" padding>
          <Cell small={12} large={10}>
            <header>
              <Heading level={3}>Thank you!</Heading>
              <Divider />
            </header>
            <Grid padding>
              <Cell large={7} small={12}>
                <Paragraph>
                  Your order is confirmed. Once your order has been approved by
                  our moderation team your selected reviewers will provide
                  feedback on your pitch.
                </Paragraph>
                <br />
                <OrderDetailsLoader id={props.match.params.id} />
              </Cell>
              <Cell large={5} small={12}>
                <Link to="/dashboard/my-orders">
                  <Button full>Dashboard</Button>
                </Link>
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export default OrderPlaced;
