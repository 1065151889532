import React from 'react';
import PropTypes from 'prop-types';
import baseInput from '../base-input/base-input';
import './search.scss';

import searchIcon from '../../../../images/icons/search.svg';

export function TextInput(props) {
  return (
    <div className="Field Field--text Field--search">
      <span>
        <img alt="Search" src={searchIcon} />
      </span>
      <input
        type="text"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        required={props.required}
      />
    </div>
  );
}

TextInput.defaultProps = {
  value: '',
};

TextInput.propTypes = {
  value: PropTypes.string,
};

export default baseInput(TextInput);
