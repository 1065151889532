import React from 'react';
import {
  Container,
  Grid,
  Cell,
  Heading,
  Loader,
  Card,
} from '../../components/ui';

import { ReviewerNav } from '../../components/layout';
import { useMyProfile } from '../../graphql/scouts';
import { AppError } from '../../components/ui/errors/errors';
import { Link } from 'react-router-dom';
import PersonForm from './components/person-form';
import { ReviewerDashboardHeader } from '../../components/ui/dashboard/header/header';

export function MyReviewCard(props) {
  const { order } = props;
  const { pitch } = order;

  return (
    <Link to={`/dashboard/my-request/${props.id}`}>
      <Card>
        <Heading level={2}>{pitch.name}</Heading>
      </Card>
    </Link>
  );
}

export function MyProfile(props) {
  const scout = props.profile;

  return <PersonForm profile={scout} />;
}

export function MyProfileLoader() {
  const { data, error, loading } = useMyProfile();

  if (error) return <AppError />;
  if (loading) return <Loader />;

  return <MyProfile profile={data.myProfile} />;
}

export function MyProfileWrapper(props) {
  return (
    <section className="MyReviews">
      <Container>
        <Grid>
          <Cell>
            <ReviewerDashboardHeader />
            <hr />
          </Cell>
        </Grid>
        <Grid margin>
          <Cell small={12} medium={3}>
            <ReviewerNav />
          </Cell>
          <Cell small={12} medium={9}>
            <MyProfileLoader />
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export default MyProfileWrapper;
