import './reviewers.scss';
import React from 'react';
import {
  Container,
  Grid,
  Cell,
  Heading,
  SortInput,
  SearchInput,
  Pagination,
  Loader,
  Paragraph,
} from '../../components/ui';
import ReviewerCard from '../../components/ui/reviewer-card/reviewer-card';
// import SelectInput from '../../components/ui/inputs/select/select';
import { useQuery } from '@apollo/react-hooks';
import { SEARCH } from '../../graphql/scouts';
import { AppError } from '../../components/ui/errors/errors';
import { useGlobalState } from '../../store';
import queryString from 'query-string';
import store from 'store';
import gql from 'graphql-tag';
import ReviewerFilters from './components/reviewers-filters';

const sortOptions = [
  {
    value: 'PRICE_ASC',
    label: 'Price: Low to High',
  },
  {
    value: 'PRICE_DESC',
    label: 'Price: High to Low',
  },
  /*{
    value: 'RATING_ASC',
    label: 'Rating: Low to High',
  },
  {
    value: 'RATING_DESC',
    label: 'Rating: High to Low',
  },*/
];

export function Reviewers(props) {
  const grid = true;
  const [filters, setFilters] = useGlobalState('filters');

  const { data, loading, error } = useQuery(SEARCH, {
    variables: {
      approved: true,
      page: filters.page,
      sort: filters.sort,
      search: {
        type: 'ScoutPerson',
        text: filters.text,
        tags: filters.tags ? filters.tags.map(tag => tag.toString()) : [],
        investmentDesiredStage: filters.investmentDesiredStage || [],
        offering: filters.offering || [],
        types: filters.types || [],
        country: filters.country,
        city: filters.city,
        company: filters.company,
      },
    },
    fetchPolicy: 'network-only',
  });

  function handleChange(event) {
    const key = event.target.name;
    const value = event.target.value;

    setFilters(filters => ({
      ...filters,
      page: 1,
      [key]: value,
    }));
  }

  function handleSortChange(event) {
    const { value } = event.target;

    switch (value) {
      case 'PRICE_DESC': {
        return setFilters(filter => ({
          ...filters,
          page: 1,
          sortMasked: value,
          sort: { on: 'PRICE', order: 'DESC' },
        }));
      }

      case 'PRICE_ASC': {
        return setFilters(filter => ({
          ...filters,
          page: 1,
          sortMasked: value,
          sort: { on: 'PRICE', order: 'ASC' },
        }));
      }

      case 'RATING_DESC': {
        return setFilters(filter => ({
          ...filters,
          page: 1,
          sortMasked: value,
          sort: { on: 'RATING', order: 'DESC' },
        }));
      }

      case 'RATING_ASC': {
        return setFilters(filter => ({
          ...filters,
          page: 1,
          sortMasked: value,
          sort: { on: 'RATING', order: 'ASC' },
        }));
      }

      default: {
        return setFilters(filter => ({
          ...filters,
          sortMasked: value,
          sort: null,
        }));
      }
    }
  }

  if (error) {
    return <AppError error={`An application error occured!`} />;
  }

  const search = !loading && data && data.search ? data.search : null;

  const showFilters = process.env.REACT_APP_SHOW_FILTERS || false;

  return (
    <section className="Reviewers">
      <Container>
        {/*<Callout>
          <Grid align={'center'}>
            <Cell large={8}>
              <Heading level={3}>Congratulations! Pick some reviewers</Heading>
              <Paragraph>
                Our team will review your submission to find any hiccups that
                might damage your chances and inform within the next 78 hours.
                Once you're approved we will send your pitch to the reviewers
                and you can experience the power of The Crowd.
              </Paragraph>
              <Paragraph>
                Don't worry you won't be charged until you are approved.
              </Paragraph>
            </Cell>
          </Grid>
        </Callout>*/}

        <Grid margin>
          <Cell>
            <Heading level={1}>
              Browse <b>Reviewers</b>
            </Heading>
          </Cell>
        </Grid>
        <Grid margin>
          <Cell small={12} medium={12}>
            <SearchInput
              value={filters.text}
              name="text"
              onChange={handleChange}
              spaced
              placeholder="Search People, Companies, Locations, Interests"
            />
          </Cell>
        </Grid>
        <ReviewersFeatureBlock {...props}>
          <Grid margin>
            {showFilters && (
              <Cell small={12} medium={3}>
                <ReviewerFilters />
              </Cell>
            )}
            <Cell small={12} medium={showFilters ? 9 : 12}>
              <Grid margin>
                <Cell large={12}>
                  <Grid margin align={'middle'}>
                    <Cell small={5} large={'auto'}>
                      {search && data && data.search && (
                        <Heading level={6}>
                          Showing{' '}
                          <b>
                            {data.search.total < data.search.limit
                              ? data.search.total
                              : data.search.limit}
                          </b>{' '}
                          of {data.search.total}
                        </Heading>
                      )}
                    </Cell>
                    <Cell small={7} large={'shrink'}>
                      <div className="text-right">
                        <SortInput
                          value={filters.sortMasked}
                          options={sortOptions}
                          onChange={handleSortChange}
                          emptyOption={'Sort'}
                        />
                      </div>
                    </Cell>
                  </Grid>
                  <br />
                  <Grid>
                    <Cell>{loading && <Loader />}</Cell>
                  </Grid>
                  <Grid margin mediumBlock={2} largeBlock={grid ? 3 : 1}>
                    {search &&
                      (data && data.search.results
                        ? data.search.results
                        : []
                      ).map(reviewer => {
                        return (
                          <Cell key={reviewer.id}>
                            <ReviewerCard compact={grid} {...reviewer} />
                          </Cell>
                        );
                      })}
                  </Grid>

                  <br />
                  <Grid margin>
                    <Cell>
                      {search && data.search.pages > 1 && (
                        <div className="text-center">
                          <Pagination
                            currentPage={data.search.page}
                            totalItems={data.search.total}
                            pageSize={data.search.limit}
                            maxPages={data.search.pages}
                            onPageChange={page =>
                              setFilters(f => ({ ...f, page }))
                            }
                          />
                        </div>
                      )}
                    </Cell>
                  </Grid>
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </ReviewersFeatureBlock>
      </Container>
    </section>
  );
}

export const CHECK_FEATURE_TOKEN = gql`
  query($id: ID) {
    checkFeatureToken(id: $id)
  }
`;

export function useCanViewReviewers(token) {
  if (process.env.REACT_APP_PREVIEW_ENABLED || token) {
    if (token) {
      store.set('preview', token);
    }

    return false;
  }

  return true;
}

export function useCheckFeatureToken() {
  const token = store.get('preview');
  const { data, loading, error } = useQuery(CHECK_FEATURE_TOKEN, {
    variables: { id: token },
  });

  const canView = !token
    ? false
    : loading || error || !data
    ? false
    : data.checkFeatureToken;

  return { canView, loading };
}

export function ReviewersFeatureSwitch(props) {
  const { canView, loading } = useCheckFeatureToken();

  if (loading) {
    return <Loader />;
  }

  if (canView) {
    return props.children;
  }

  return (
    <div>
      <Heading level={1}>Vintro is Coming Soon!</Heading>
      <Paragraph>
        We are currently in Beta testing. If you have received a preview link to
        view this page, please note that these links expire 4 hours after they
        are first used. If you have any questions or concerns, please email{' '}
        <a href="mailto:support@myvintro.com">support@myvintro.com</a>
      </Paragraph>
    </div>
  );
}

export function ReviewersFeatureBlock(props) {
  const q = queryString.parse(props.location.search);
  const canView = useCanViewReviewers(q.preview);

  if (!canView) {
    return <ReviewersFeatureSwitch {...props} />;
  }

  return props.children;
}

export default Reviewers;
