import './my-order.scss';
import React, { useState } from 'react';
import { useGetOrderDetails, useRateOrderRequest } from '../../graphql/orders';
import classNames from 'classnames';

import {
  Container,
  Grid,
  Cell,
  Loader,
  Heading,
  Paragraph,
  Card,
  StarRating,
  Button,
} from '../../components/ui';
import { SecondaryNav } from '../../components/layout/secondary-nav/secondary-nav';

import ReviewerCard from '../../components/ui/reviewer-card/reviewer-card';
import { useForm } from '../../hooks/forms';
import { useNotifications } from '../../hooks/notifications';

import VideoPlayer from '../../components/ui/video-player/video-player';
import { AppError } from '../../components/ui/errors/errors';
import { Link } from 'react-router-dom';

export function MyOrderRequest(props) {
  const { scout, status, response = {} } = props;
  const disabled =
    props.status === 'PENDING_REVIEW' ||
    props.status === 'PENDING_MODERATION' ||
    props.status === 'PENDING_ORDER_MODERATION' ||
    props.status === 'CANCELED' ||
    props.status === 'REFUNDED';

  const classes = classNames({
    'MyOrder--card': true,
    'MyOrder--card--pending': disabled,
  });

  return (
    <div className={classes}>
      <Card>
        <Grid margin>
          <Cell small={12} medium={4}>
            <ReviewerCard disableCart={true} {...scout} />
          </Cell>

          <Cell small={12} medium={3}>
            {!disabled && (
              <div>
                <Heading level={5}>Pitch Quality</Heading>
                <StarRating rating={response.pitchQuality} />
                <Heading level={5}>Commercial Viability</Heading>
                <StarRating rating={response.commercialViability} />
                {!disabled && response.openToCommunication && (
                  <Link to="/dashboard/chat">
                    <Cell small={12}>
                      <Button>Chat with {scout.firstName}</Button>
                    </Cell>
                  </Link>
                )}
              </div>
            )}
          </Cell>

          <Cell small={12} medium={5}>
            {!disabled ? (
              <ReviewFeedback response={response} />
            ) : (
              <div>
                <Heading level={5}>Status</Heading>
                <RequestStatusMessage
                  status={status}
                  scout={scout}
                  response={response}
                />
              </div>
            )}
          </Cell>
          <Cell small={12}>
            {!disabled && props.status !== 'COMPLETED' && (
              <OrderRequestRatingCluster id={props.id} />
            )}
          </Cell>
        </Grid>
      </Card>
    </div>
  );
}

export function ReviewFeedback(props) {
  const { response } = props;
  const { job = {} } = response;

  if (response.type === 'AUDIO') {
    return (
      <div>
        <Heading level={5}>Review</Heading>
        <audio controls>
          <source src={job.audio.url} type="audio/mpeg" />
        </audio>
      </div>
    );
  }

  if (response.type === 'VIDEO' && job.status === 'COMPLETE') {
    return (
      <div>
        <Heading level={5}>Review</Heading>
        <VideoPlayer
          video={job.video.url}
          poster={job.thumbnail.url}
          job={job}
        />
      </div>
    );
  }

  if (response.type === 'VIDEO') {
    return (
      <div>
        <Heading level={5}>Review</Heading>
        <Paragraph>W'ere encoding this video response right now.</Paragraph>
      </div>
    );
  }

  return (
    <div>
      <Heading level={5}>Review</Heading>
      {response &&
        (response.comment || '').split('\n').map((text, i) => {
          return <Paragraph key={i}>{text}</Paragraph>;
        })}
    </div>
  );
}

export function OrderRequestRatingCluster(props) {
  const rateOrderRequest = useRateOrderRequest();
  const notifications = useNotifications();
  const [showRating, setShowRating] = useState(false);
  const { form, handleFormChange } = useForm({ rating: 0 });

  function changeRating(e) {
    handleFormChange({
      target: { value: e.target.value, name: 'rating' },
    });
  }

  async function submit() {
    try {
      await rateOrderRequest(props.id, form.rating);
      notifications.notify(`Thank you for reviewing ${props.firstName}.`, {
        time: 3000,
      });
    } catch (e) {
      notifications.notify('There was an error submitting your review.', {
        error: true,
        time: 3000,
      });
    }
  }

  if (!showRating) {
    return (
      <div className="MyOrder--card__controls">
        <div className="text-center">
          <Button onClick={e => setShowRating(true)} size={'small'} hollow>
            Rate this Review
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="MyOrder--card__controls">
      <div className="text-center">
        <StarRating rating={form.rating} handleClick={changeRating} />

        <Button
          disabled={form.rating > 0 ? false : true}
          hollow
          size={'small'}
          onClick={submit}
        >
          Submit Rating
        </Button>
      </div>
    </div>
  );
}

export function RequestStatusMessage(props) {
  const { scout, status } = props;
  const cancelled = status === 'CANCELED';
  const refunded = status === 'REFUNDED';

  if (cancelled) {
    return (
      <Paragraph>
        This request for review has been cancelled by the moderator. You will
        not be charged for this request.
      </Paragraph>
    );
  }

  if (refunded) {
    return (
      <Paragraph>
        The reviewer has declined to review your pitch. You have been refunded
        this reviewers fee.
      </Paragraph>
    );
  }

  return (
    <Paragraph>
      Waiting for {scout.firstName} {scout.lastName} to review your pitch.
    </Paragraph>
  );
}

export function MyOrder(props) {
  const { order } = props;

  return (
    <Grid>
      <Cell small={12}>
        {order.requests.map(request => {
          return <MyOrderRequest key={request.id} {...request} />;
        })}
      </Cell>
    </Grid>
  );
}

export function MyOrderCard(props) {}

export function MyOrderLoader(props) {
  const { error, loading, data } = useGetOrderDetails(props.match.params.id);

  if (error) return <AppError />;
  if (loading) return <Loader />;
  if (!data.getOrder) return <AppError />;

  return <MyOrder order={data.getOrder} />;
}

export function MyOrderWrapper(props) {
  return (
    <section className="MyOrder">
      <Container>
        <Grid>
          <Cell small={12}>
            <Heading level={1}>
              My <b>Orders</b>
            </Heading>
          </Cell>
          <Cell small={12}>
            <hr />
          </Cell>
        </Grid>
        <Grid>
          <Cell small={12} medium={3}>
            <SecondaryNav />
          </Cell>
          <Cell small={12} medium={9}>
            <MyOrderLoader {...props} />
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export default MyOrderWrapper;
