import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './star-rating.scss';
import InputError from '../inputs/input-error/input-error';

export function StarRating(props) {
  const starRatingClasses = classNames({
    StarRating: true,
  });

  const totalStars = Array.apply(null, { length: props.stars });

  function handleClick(value) {
    return () => {
      if (props.handleClick) {
        props.handleClick({
          target: {
            name: props.name,
            value: value,
          },
        });
      }
    };
  }

  function renderErrors(errors = []) {
    if (errors.length === 0) return null;
    return errors.map((error, idx) => <InputError message={error} key={idx} />);
  }

  return (
    <div className="StarRating__Container">
      <div className={starRatingClasses}>
        {totalStars.map((_, idx) => {
          const starValue = idx + 1;
          const isActive = props.rating >= starValue;
          const starClasses = classNames({
            StarRating__star: true,
            'StarRating__star--active': isActive,
            'StarRating__star--isClickable': props.handleClick,
          });

          return (
            <div
              className={starClasses}
              key={idx}
              onClick={handleClick(starValue)}
            >
              ★
            </div>
          );
        })}
      </div>
      <div>{renderErrors(props.errors || [])}</div>
    </div>
  );
}

StarRating.propTypes = {
  name: PropTypes.string,
  rating: PropTypes.number,
  stars: PropTypes.number,
  handleClick: PropTypes.func,
  errors: PropTypes.array,
};

StarRating.defaultProps = {
  name: '',
  rating: 0,
  stars: 5,
  errors: [],
  handleClick: null,
};

export default StarRating;
