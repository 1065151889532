import React, { useState } from 'react';
import { AdminSecondaryNav } from '../../../components/layout';
import {
  Container,
  Grid,
  Cell,
  Heading,
  Loader,
  Button,
} from '../../../components/ui';

import {
  useGetAffiliateNetwork,
  SEARCH_AFFILIATES,
  useAddAffiliate,
  useExportAffiliateNetwork,
} from '../../../graphql/affiliates';
import { AppError } from '../../../components/ui/errors/errors';
import NumberInput from '../../../components/ui/inputs/number/number';
import TextInput from '../../../components/ui/inputs/text/text';
import { useQuery } from '@apollo/react-hooks';

import { AffiliateCard } from '../affiliates/affiliates';
import { ButtonLoading } from '../../../components/ui/button/button';
import { useNotifications } from '../../../hooks/notifications';

export function Affiliates(props) {
  return props.response.results.map(affiliate => {
    return <AffiliateCard key={affiliate.id} affiliate={affiliate} />;
  });
}

export function AffiliatesLoader(props) {
  const { data, loading, error } = useQuery(SEARCH_AFFILIATES, {
    variables: { search: { network: props.network } },
  });

  if (loading) return <Loader />;
  if (error) return <Loader />;

  return <Affiliates response={data.searchAffiliates} />;
}

export function AffiliateNetwork(props) {
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [form, setForm] = useState({ count: 1 });
  const addAffilaite = useAddAffiliate();
  const notifications = useNotifications();
  const exportAffiliateNetwork = useExportAffiliateNetwork();

  async function onExport(e) {
    setLoading(true);
    e.preventDefault();
    try {
      const url = await exportAffiliateNetwork(props.network.id);
      window.open(url, '_blank');
    } catch (e) {}
    setLoading(false);
  }

  function updateNumber(e) {
    const value = e.target.value;

    setForm(form => {
      return { count: value ? parseInt(value) : '' };
    });
  }

  async function createAffiliates(e) {
    setCreating(true);
    try {
      await addAffilaite({ network: props.network.id }, form.count);

      notifications.notify(`Created ${form.count} Affiliates`, { time: 3000 });
      setForm({ count: 1 });
    } catch (e) {
      notifications.error(`There was an error creating Affiliates`, {
        time: 3000,
        error: true,
      });
    }
    setCreating(false);
  }

  return (
    <Grid margin>
      <Cell small={12}>
        <TextInput value={props.network.name} />
      </Cell>
      <Cell small={12}>
        <hr />
      </Cell>

      <Cell small={12} medium={3}>
        <NumberInput value={form.count} onChange={updateNumber} />
      </Cell>
      <Cell small={12} medium={9}>
        <Button onClick={createAffiliates}>
          {creating ? (
            <ButtonLoading>Creating...</ButtonLoading>
          ) : (
            `Add ${form.count} ${form.count > 1 ? 'Affiliates' : 'Affilite'}`
          )}
        </Button>
      </Cell>

      <Cell small={12} medium={12}>
        <hr />
      </Cell>

      <Cell small={12} medium={6}>
        <Heading level={2}>Network Affiliates</Heading>
      </Cell>

      <Cell small={12} medium={6}>
        <div className="text-right">
          <Button onClick={onExport}>
            {loading ? <ButtonLoading>Exporting</ButtonLoading> : 'Export'}
          </Button>
        </div>
      </Cell>

      <Cell small={12} medium={12}>
        <hr />
      </Cell>

      <Cell small={12} medium={12}>
        <AffiliatesLoader network={props.network.id} />
      </Cell>
    </Grid>
  );
}

export function AffiliateNetworkLoader(props) {
  const { data, loading, error } = useGetAffiliateNetwork(props.id);

  if (loading) return <Loader />;
  if (error) return <AppError />;

  return <AffiliateNetwork network={data.getAffiliateNetwork} />;
}

export function AffiliateNetworkWrapper(props) {
  return (
    <section className="AdminNetwork">
      <Container>
        <Grid>
          <Cell small={6}>
            <Heading level={1}>
              <b>Vintro</b> Admin
            </Heading>
          </Cell>
          <Cell small={6} />
          <Cell>
            <hr />
          </Cell>
        </Grid>
        <Grid margin>
          <Cell small={12} medium={3}>
            <AdminSecondaryNav />
          </Cell>
          <Cell small={12} medium={9}>
            <AffiliateNetworkLoader id={props.match.params.id} />
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export default AffiliateNetworkWrapper;
