import React, { useState } from 'react';
import {
  Container,
  Grid,
  Cell,
  Heading,
  Subheading,
  Paragraph,
  Divider,
  Card,
  StarRating,
  TextareaInput,
  RadioInput,
  Button,
  Loader,
  Callout,
} from '../../components/ui';

import {
  useGetOrderRequest,
  useFulfillOrderRequest,
  useRejectOrderRequest,
} from '../../graphql/orders';
import { VideoPlayer } from '../../components/ui/video-player/video-player';
import { useMyNextOrderRequest } from '../../graphql/scouts';
import { Link } from 'react-router-dom';
import { AppSecurityError, AppError } from '../../components/ui/errors/errors';
import { useForm } from '../../hooks/forms';

import './my-request.scss';
import { Default, Mobile } from '../../components/layout';

import validate from 'validate.js';
import { ButtonLoading } from '../../components/ui/button/button';
import Modal from '../../components/ui/modal/modal';
import { useNotifications } from '../../hooks/notifications';

export function NextPitch(props) {
  const { data, error, loading } = useMyNextOrderRequest();

  if (error) return null;
  if (loading)
    return (
      <Callout>
        <Loader />
      </Callout>
    );

  const total = data.myNextOrderRequest.total;
  const next =
    total > 0 && data.myNextOrderRequest.next
      ? data.myNextOrderRequest.next.id
      : null;

  return (
    <Callout>
      <Heading level={1}>Your Review has been Completed!</Heading>
      {next ? (
        <Paragraph>
          You have {total} {total > 1 ? 'Pitches' : 'Pitch'} to review
        </Paragraph>
      ) : (
        <Paragraph>You have no more pitches to review.</Paragraph>
      )}
      {next ? (
        <Link to={`/dashboard/my-request/${next}`}>
          <Button>Review Next Pitch</Button>
        </Link>
      ) : (
        <Link to={`/dashboard`}>
          <Button>Dashboard</Button>
        </Link>
      )}
    </Callout>
  );
}

export function NextStep(props) {
  return null;
  /*
  return (
    <Callout>
      <Heading level={1}>Take the next step</Heading>
      <Paragraph>Hello Wrold</Paragraph>
    </Callout>
  );*/
}

export function RequestDetails(props) {
  const { pitch, venture } = props;

  return (
    <div className="ReviewDetails">
      <Heading level={3}>{pitch.name}</Heading>
      <Paragraph>{pitch.description}</Paragraph>
      <Divider />
      <Subheading>About the Company</Subheading>
      <Heading collapsed level={1}>
        {venture.name}
      </Heading>
      <Heading level={5}>
        {venture.city},{venture.state ? ` ${venture.state}` : ''}
        {venture.country}
      </Heading>
      <Paragraph>{venture.description}</Paragraph>
    </div>
  );
}

export const validationRules = {
  comment: {
    presence: { allowEmpty: false },
  },
  pitchQuality: {
    numericality: { greaterThan: 0, notGreaterThan: 'Required' },
  },
  commercialViability: {
    numericality: { greaterThan: 0, notGreaterThan: 'Required' },
  },
};

export function RequestReviewInput(props) {
  const notifications = useNotifications();
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reviewed, setReviewed] = useState(false);
  const fulfillOrderRequest = useFulfillOrderRequest();
  const rejectOrderRequest = useRejectOrderRequest();
  const { pitch, request, setReviewCompleted, videoWasWatched } = props;
  const { form, setForm, errors, setErrors, handleFormChange } = useForm(
    {
      commercialViability: 0,
      pitchQuality: 0,
      comment: '',
      openToCommunication: false,
      declineReason: '',
    },
    {
      validate: validateForm,
    }
  );

  console.log(form);

  function validateForm(form) {
    const response = validate(form, validationRules);
    return response;
  }

  function handleCanContactChange(event) {
    const { name, value } = event.target;
    setForm(form => {
      return { ...form, [name]: value === 'true' };
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const response = validateForm(form);

    setErrors({
      ...response,
    });

    if (response !== undefined) return;
    if (loading) return;

    setLoading(true);

    try {
      await fulfillOrderRequest(request.id, {
        comment: form.comment,
        pitchQuality: form.pitchQuality,
        commercialViability: form.commercialViability,
        openToCommunication: form.openToCommunication,
      });
      setReviewed(true);
      setReviewCompleted({ can_contact: form.openToCommunication });
    } catch (e) {}

    setLoading(false);
  }

  function handleDecline() {
    setShowDeclineModal(true);
  }

  function handleDeclineCancel() {
    setShowDeclineModal(false);
  }

  async function submitDecline() {
    try {
      setLoading(true);

      await rejectOrderRequest(request.id, form.declineReason);
      setLoading(false);
      setShowDeclineModal(false);
      setReviewed(true);
      setReviewCompleted({ can_contact: false });
    } catch (e) {
      notifications.notify(`There was an error declining your review`);
    }

    setLoading(false);
  }

  if (reviewed) {
    return (
      <Card lassName="ReviewForm">
        <Heading level={1}>Review Completed</Heading>
      </Card>
    );
  }

  return (
    <div>
      <form className="ReviewForm" onSubmit={handleSubmit}>
        <Card>
          <Heading level={1}>Your Review</Heading>
          <Default>
            <Paragraph>
              Please watch the pitch video on your left. Once you've watched the
              video you can submit your review of <b>{pitch.name}</b>.
            </Paragraph>
          </Default>
          <Mobile>
            <Paragraph>
              Please watch the pitch video. Once you've watched the video you
              can submit your review of <b>{pitch.name}</b>.
            </Paragraph>
          </Mobile>
          <br />
          <Grid>
            <Cell>
              <Heading level={6}>Commercial Viability</Heading>
              <StarRating
                name={'commercialViability'}
                errors={errors.commercialViability}
                rating={form.commercialViability}
                handleClick={handleFormChange}
              />
            </Cell>
          </Grid>
          <Divider />
          <Grid>
            <Cell>
              <Heading level={6}>Pitch Quality</Heading>
              <StarRating
                name={'pitchQuality'}
                errors={errors.pitchQuality}
                rating={form.pitchQuality}
                handleClick={handleFormChange}
              />
            </Cell>
          </Grid>
          <Divider />
          <TextareaInput
            spaced
            label={'Thoughts & Explanation'}
            value={form.comment}
            errors={errors.comment}
            name={'comment'}
            onChange={handleFormChange}
          />
          <Paragraph>
            Would you be open to being contacted by the company?
          </Paragraph>
          <Grid margin>
            <Cell shrink>
              <RadioInput
                spaced
                label={'Yes'}
                value={'true'}
                name={'openToCommunication'}
                onChange={handleCanContactChange}
                checked={form.openToCommunication === true}
              />
            </Cell>
            <Cell shrink>
              <RadioInput
                spaced
                label={'No'}
                value={'false'}
                name={'openToCommunication'}
                onChange={handleCanContactChange}
                checked={form.openToCommunication === false}
              />
            </Cell>
          </Grid>
          <Button disabled={!videoWasWatched} full type={'submit'}>
            {loading ? (
              <ButtonLoading>Submitting your response</ButtonLoading>
            ) : (
              <span>Submit</span>
            )}
          </Button>
          <br />
          <Button full theme={'primary'} hollow onClick={handleDecline}>
            Decline
          </Button>
        </Card>
      </form>
      {showDeclineModal && (
        <Modal>
          <Heading level={1}>Decline Pitch</Heading>
          <Heading level={2}>
            Tell us why your declining to reveiw this pitch.
          </Heading>
          <TextareaInput
            name={'declineReason'}
            value={form.declineReason}
            onChange={handleFormChange}
          />
          <div className="text-right">
            <Button onClick={handleDeclineCancel} hollow={true}>
              Cancel
            </Button>
            <Button onClick={submitDecline}>
              {loading ? <ButtonLoading>Submit</ButtonLoading> : 'Submit'}
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
}

export function RequestReviewInputCompleted(props) {
  const { pitch } = props;
  /*const { form, errors, handleFormChange } = useForm(
    {
      message: '',
    },
    {
      validate: validateForm,
    }
  );*/

  /*function validateForm(form) {
    const response = validate(form, validationRules);
    return response;
  }*/

  return (
    <Card>
      <div lassName="ReviewForm">
        <Heading level={1}>Review Completed</Heading>
        <Paragraph>
          You've already completed your reivew of <b>{pitch.name}</b>.
        </Paragraph>
        {/*<Divider />
        <Paragraph>
          Interested in connecting with <b>{pitch.name}</b>? Use the form below
          to start a conversation.
        </Paragraph>
        <form>
          <TextareaInput
            spaced
            label={'Your Message'}
            value={form.message}
            errors={errors.message}
            name={'message'}
            onChange={handleFormChange}
          />
          <Button full type={'submit'}>
            Send Message
        </Button>
        </form>
        */}
      </div>
    </Card>
  );
}

export function OrderRequest(props) {
  const [reviewCompleted, setReviewCompleted] = useState(false);
  const [videoWasWatched, setVideoWasWatched] = useState(false);

  const { request } = props;
  const { order } = request;
  const { pitch, venture } = order;

  function onVideoEnded() {
    setVideoWasWatched(true);
  }

  if (reviewCompleted) {
    return (
      <section className="MyReview">
        <Container>
          <Grid margin>
            <Cell large={4} small={12}>
              <NextPitch id={request.id} />
            </Cell>
            <Cell large={4} small={12}>
              {reviewCompleted.can_contact && <NextStep />}
            </Cell>
          </Grid>
        </Container>
      </section>
    );
  }

  return (
    <section className="MyReview">
      <Container>
        <Grid margin>
          <Cell large={4} small={12}>
            <VideoPlayer
              job={pitch.job}
              poster={pitch.job.thumbnail ? pitch.job.thumbnail.url : null}
              controls={videoWasWatched}
              onEnded={onVideoEnded}
              video={pitch.job.video.url}
            />
          </Cell>
          <Cell large={4} small={12}>
            <RequestDetails pitch={pitch} venture={venture} />
          </Cell>
          {request.status === 'PENDING_REVIEW' ? (
            <Cell large={4} small={12}>
              <RequestReviewInput
                videoWasWatched={videoWasWatched}
                setReviewCompleted={setReviewCompleted}
                request={request}
                pitch={pitch}
              />
            </Cell>
          ) : (
            <Cell large={4} small={12}>
              <RequestReviewInputCompleted request={request} pitch={pitch} />
            </Cell>
          )}
        </Grid>
      </Container>
    </section>
  );
}

export function OrderRequestLoader(props) {
  const { error, loading, data } = useGetOrderRequest(props.match.params.id);

  if (error) {
    const code =
      error.graphQLErrors && error.graphQLErrors[0]
        ? error.graphQLErrors[0].code
        : null;
    switch (code) {
      case 401: {
        return <AppSecurityError message={error.graphQLErrors[0].message} />;
      }
      default: {
        return <AppError />;
      }
    }
  }

  if (loading)
    return (
      <Container>
        <Loader />
      </Container>
    );

  return <OrderRequest request={data.getOrderRequest} />;
}

export default OrderRequestLoader;
