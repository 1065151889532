import React from 'react';
import './secondary-nav.scss';
import { NavLink } from 'react-router-dom';
import { Default, Mobile } from '../../layout';
import SelectInput from '../../ui/inputs/select/select';

import history from '../../../util/history';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Animated } from 'react-animated-css';

export function SecondaryNav(props) {
  const options = [
    { label: 'Inbox', value: '/dashboard', exact: true },
    process.env.REACT_APP_CHAT_ENABLED
      ? { label: 'Chat', value: '/dashboard/chat' }
      : null,
    { label: 'Orders', value: '/dashboard/my-orders' },
    { label: 'My Profile', value: '/dashboard/my-company', exact: true },
    { label: 'My Pitches', value: '/dashboard/my-pitches' },
  ];

  const active = options
    .filter(opt => opt !== null)
    .filter(opt => {
      if (opt.exact) {
        if (opt.value === history.location.pathname) return true;
      } else {
        return history.location.pathname.indexOf(opt.value) > -1;
      }
      return false;
    })
    .map(opt => {
      return opt.value;
    });

  function handleChange(e) {
    const { value } = e.target;
    history.push(value);
  }

  return (
    <div className="SecondaryNav">
      <Default>
        <ul className="DashboardMenu--Main">
          <li>
            <NavLink exact to="/dashboard">
              Inbox
            </NavLink>
          </li>
          {process.env.REACT_APP_CHAT_ENABLED && (
            <li>
              <NavLink exact to="/dashboard/chat">
                <NavLinkBeacon />
              </NavLink>
            </li>
          )}
          <li>
            <NavLink to="/dashboard/my-orders">Orders</NavLink>
          </li>
        </ul>

        <ul className="DashboardMenu">
          <li>
            <NavLink exact to="/dashboard/my-company">
              My Profile
            </NavLink>
          </li>
          {/*<li>
            <NavLink exact to="/dashboard/my-team">
              My Team
            </NavLink>
          </li>
          */}
          <li>
            <NavLink to="/dashboard/my-pitches">My Pitches</NavLink>
          </li>
        </ul>
      </Default>
      <Mobile>
        <SelectInput
          value={active.length ? active[0] : ''}
          onChange={handleChange}
          options={options.filter(opt => opt !== null)}
          spaced
        />
      </Mobile>
    </div>
  );
}

export const GET_NOTIFICATIONS = gql`
  query {
    getNotifications {
      messages
    }
  }
`;

export function NavLinkBeacon() {
  const { data, loading, error } = useQuery(GET_NOTIFICATIONS, {
    // fetchPolicy: 'network-an',
  });

  let hasNotifications = false;
  let total = 0;

  if (!loading && !error && data) {
    console.log(data);
    if (data.getNotifications.messages > 0) {
      hasNotifications = true;
      total = data.getNotifications.messages;
    }
  }

  return (
    <span>
      Chat
      {hasNotifications && (
        <Animated
          animateOnMount={true}
          className="BeaconAnimated"
          animationOut={'fadeOut'}
          animationIn={'fadeIn'}
          isVisible={true}
        >
          <span className="Beacon">{total}</span>{' '}
        </Animated>
      )}
    </span>
  );
}

export default SecondaryNav;
