import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Cell } from '../../../ui';
import baseInput from '../base-input/base-input';

export function RadioInput(props) {
  return (
    <div className="Field Field--radio">
      <Grid align={'top'}>
        <Cell shrink>
          <input
            type="radio"
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
            checked={props.checked}
          />
        </Cell>
        {props.text && (
          <Cell auto>
            <span className="Field--checkbox__text">{props.text}</span>
          </Cell>
        )}
      </Grid>
    </div>
  );
}

RadioInput.defaultProps = {
  value: '',
  checked: false,
  text: '',
};

RadioInput.propTypes = {
  value: PropTypes.string,
  checked: PropTypes.bool,
  text: PropTypes.string,
};

export default baseInput(RadioInput);
