import gql from 'graphql-tag';
import history from '../util/history';
import { useMutation } from 'react-apollo-hooks';
import { useSession } from '../hooks/session';
import decode from 'jwt-decode';

export const LOGIN = gql`
  mutation LOGIN($input: LoginInput) {
    login(input: $input) {
      token
      user {
        id
        type
        roles
      }
    }
  }
`;

export function useLogin() {
  const login = useMutation(LOGIN);
  const session = useSession({});

  async function exec(input) {
    try {
      const { data } = await login({ variables: { input } });

      if (data.login) {
        session.startSession(data.login.token);

        const decoded = decode(data.login.token);
        const { features = {} } = decoded;

        if (decoded.loginRoute) {
          return history.push(decoded.loginRoute);
        }

        switch (data.login.user.type) {
          case 'VENTURE': {
            history.push('/create-company');
            break;
          }
          case 'SCOUT': {
            console.log('scout');
            if (!features.dashboard) {
              console.log('push onboard');
              history.push('/apply/step/onboarding');
              break;
            }
            console.log('push dash');

            history.push('/dashboard');
            break;
          }

          case 'ADMIN': {
            history.push('/admin');
            break;
          }

          case 'SUPERADMIN': {
            history.push('/admin');
            break;
          }

          default: {
            history.push('/');
          }
        }
      }
    } catch (e) {
      throw e;
    }
  }

  return exec;
}

export function decidePostLoginRoute(token) {
  const decoded = decode(token);
  const { features = {}, type = null } = decoded;

  switch (type) {
    case 'VENTURE': {
      history.push('/create-company');
      break;
    }

    case 'SCOUT': {
      if (!features.dashboard) {
        history.push('/apply/step/onboarding');
        break;
      }

      history.push('/dashboard');
      break;
    }

    case 'ADMIN': {
      history.push('/admin');
      break;
    }

    default: {
      history.push('/');
    }
  }
}

export const REQUEST_PASSWORD_RESET = gql`
  mutation REQUEST_PASSWORD_RESET($email: String) {
    requestResetPassword(email: $email)
  }
`;

export function useRequestPasswordReset() {
  const requestPasswordReset = useMutation(REQUEST_PASSWORD_RESET);

  async function exec(email) {
    try {
      await requestPasswordReset({ variables: { email } });
    } catch (e) {
      throw e;
    }
  }

  return exec;
}

export const PASSWORD_RESET = gql`
  mutation PASSWORD_RESET($input: ResetPasswordInput) {
    resetPassword(input: $input)
  }
`;

export function usePasswordReset() {
  const passwordReset = useMutation(PASSWORD_RESET);

  async function exec(token, password) {
    try {
      await passwordReset({ variables: { input: { token, password } } });
    } catch (e) {
      throw e;
    }
  }

  return exec;
}

export const SESSION = gql`
  query {
    session {
      token
      user {
        id
        type
        roles
      }
    }
  }
`;
