import React, { useState } from 'react';
import {
  Container,
  Grid,
  Cell,
  Heading,
  Paragraph,
  StrongText,
  Button,
  Loader,
  Card,
} from '../../../components/ui';

import { VideoPlayer } from '../../../components/ui/video-player/video-player';

import { AdminSecondaryNav } from '../../../components/layout';
import { useGlobalState } from '../../../store';
import { Link } from 'react-router-dom';

import missingImage from '../../../images/photo-missing.png';

import {
  useGetAdminOrderDetail,
  useApproveOrder,
  useCancelOrderRequest,
} from '../../../graphql/orders';
import { RequestDetails } from '../../my-request/my-request';
import Modal from '../../../components/ui/modal/modal';
import { useForm } from '../../../hooks/forms';
import TextareaInput from '../../../components/ui/inputs/textarea/textarea';
import { useNotifications } from '../../../hooks/notifications';
import { ButtonLoading } from '../../../components/ui/button/button';

export function AdminCartItems(props) {
  const { items } = props;
  return (
    <ul className="Cart__items">
      {items.map(item => (
        <AdminOrderItem
          handleCartToggle={props.handleCartToggle}
          order={props.order}
          item={item}
          key={item.id}
        />
      ))}
    </ul>
  );
}

export function AdminOrderItem(props) {
  const { item, order } = props;
  const notifications = useNotifications();
  const cancelOrderRequest = useCancelOrderRequest(item.id, order.id);
  const { scout } = item;

  function prompt() {
    notifications.notify(
      `Are you sure you want to remove ${scout.firstName} ${scout.lastName} from the order?`,
      { confirm: confirm, time: 30000 }
    );
  }

  async function confirm() {
    try {
      await cancelOrderRequest();
      notifications.notify(
        `${scout.firstName} ${scout.lastName} has been removed from the order`,
        { time: 3000 }
      );
    } catch (e) {}
  }

  return (
    <Card key={item.id}>
      <div className="Cart__item">
        <Grid padding>
          <Cell shrink>
            <div className="Cart__photo">
              <div
                className="Cart__photo_background"
                style={{
                  backgroundImage: `url(${scout.photo || missingImage})`,
                }}
              />
            </div>
          </Cell>
          <Cell auto>
            <StrongText>{`$${item.price}`}</StrongText>
            <Heading level={6}>
              <Link to={`/reviewers/${item.id}`}>
                {scout.firstName} {scout.lastName}
              </Link>
            </Heading>
            <Paragraph>
              <b>{scout.title}</b> @ {scout.company}
            </Paragraph>
          </Cell>
          <Cell shrink>
            {item.status === 'PENDING_ORDER_MODERATION' && (
              <Button onClick={prompt} size={'small'}>
                Remove {scout.firstName} {scout.lastName}
              </Button>
            )}
          </Cell>
        </Grid>
      </div>
    </Card>
  );
}

export function AdminOrder(props) {
  const [loading, setLoading] = useState({ approve: false, cancel: false });
  const [, setNotifications] = useGlobalState('notifications');
  const { form, setForm, handleFormChange } = useForm({
    open: false,
    reason: '',
  });
  const { order } = props;
  const { pitch, requests, venture } = order;
  const approveOrder = useApproveOrder(order.id);

  async function handleApproveOrder() {
    if (loading.approve) return;

    setLoading(loading => ({ ...loading, approve: true }));

    try {
      await approveOrder();
      setNotifications([{ message: 'Approved Order', time: 3000 }]);
    } catch (e) {
      setNotifications([
        {
          message: 'There was an error approving the order',
          time: 3000,
          error: true,
        },
      ]);
    }

    setLoading(loading => ({ ...loading, approve: false }));
  }

  function submitRejectOrder(e) {
    e.preventDefault();
    return false;
  }

  return (
    <section className="AdminOrder">
      {form.open && (
        <Modal close={e => setForm({ ...form, open: false })}>
          <Heading level={1}>Reject Order</Heading>
          <Heading level={2}>
            Please give a detailed explanation of why the order is rejected.
          </Heading>
          <form onSubmit={submitRejectOrder}>
            <TextareaInput
              name={'reason'}
              onChange={handleFormChange}
              minHeight={200}
              spaced
            />
            <div className="text-right">
              <Button type={'submit'}>Submit</Button>
            </div>
          </form>
        </Modal>
      )}
      <Container>
        {order.status === 'PENDING_MODERATION' && (
          <Grid margin>
            <Cell large={12}>
              {order.status === 'PENDING_MODERATION' && (
                <div className="text-right">
                  <Button
                    onClick={e => setForm({ ...form, open: true })}
                    margin
                    spaced
                    hollow
                  >
                    Reject Order
                  </Button>
                  <Button onClick={handleApproveOrder}>
                    {loading.approve ? (
                      <ButtonLoading>Approving</ButtonLoading>
                    ) : (
                      'Approve Order'
                    )}
                  </Button>
                </div>
              )}
            </Cell>
            <Cell large={12}>
              <hr />
            </Cell>
          </Grid>
        )}
        <Grid margin>
          <Cell small={12}>
            <Heading level={1}>
              The <b>Pitch</b>
            </Heading>
          </Cell>
          <Cell large={12}>
            <hr />
          </Cell>
          <Cell large={6} small={12}>
            <VideoPlayer job={pitch.job} video={pitch.job.video.url} />
          </Cell>
          <Cell large={6} small={12}>
            <RequestDetails pitch={pitch} venture={venture} />
          </Cell>
        </Grid>
      </Container>
      <Container>
        <Grid margin>
          <Cell small={12}>
            <hr />
          </Cell>

          <Cell small={12}>
            <Heading level={1}>
              The <b>Reviewers</b>
            </Heading>
          </Cell>
          <Cell small={12}>
            <hr />
          </Cell>
          <Cell small={12}>
            <AdminCartItems order={order} items={requests} />
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export function AdminOrderLoader(props) {
  const { data, loading, error } = useGetAdminOrderDetail(
    props.match.params.id
  );

  if (loading) return <Loader />;
  if (error) return null;

  return <AdminOrder order={data.getOrder} />;
}

export function AdminOrdersWrapper(props) {
  return (
    <Container>
      <Grid>
        <Cell small={6}>
          <Heading level={1}>
            <b>Vintro</b> Admin
          </Heading>
        </Cell>

        <Cell>
          <hr />
        </Cell>
      </Grid>
      <Grid margin>
        <Cell small={12} medium={3}>
          <AdminSecondaryNav />
        </Cell>
        <Cell small={12} medium={9}>
          <AdminOrderLoader {...props} />
        </Cell>
      </Grid>
    </Container>
  );
}

export default AdminOrdersWrapper;
