import React from 'react';
import { AdminSecondaryNav } from '../../../components/layout';
import { Container, Grid, Cell, Heading } from '../../../components/ui';
import PersonForm from './components/person.form';

export function AddInvite(props) {
  return (
    <Grid margin>
      <Cell small={12}>
        <PersonForm profile={props.person} />
      </Cell>
    </Grid>
  );
}

export function AddInviteLoader(props) {
  return <AddInvite person={{}} />;
}

export function AddInviteWrapper(props) {
  return (
    <Container>
      <Grid>
        <Cell small={6}>
          <Heading level={1}>
            <b>Vintro</b> Admin
          </Heading>
        </Cell>
        <Cell small={6} />
        <Cell>
          <hr />
        </Cell>
      </Grid>
      <Grid margin>
        <Cell small={12} medium={3}>
          <AdminSecondaryNav />
        </Cell>
        <Cell small={12} medium={9}>
          <AddInviteLoader />
        </Cell>
      </Grid>
    </Container>
  );
}

export default AddInviteWrapper;
