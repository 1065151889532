import './tags.scss';
import cc from 'classnames';
import React from 'react';

export function Tags(props) {
  return <div className="Tags">{props.children}</div>;
}

export function Tag(props) {
  const classNames = cc([
    'Tag',
    props.selected ? 'selected' : null,
    props.onClick ? 'selectable' : null,
  ]);

  function onClick(e) {
    if (props.onClick) props.onClick(props.value);
  }

  return (
    <span onClick={onClick} className={classNames}>
      {props.children}
    </span>
  );
}
