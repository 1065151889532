import React from 'react';
import { AdminSecondaryNav } from '../../../components/layout';
import { Container, Grid, Cell, Heading, Loader } from '../../../components/ui';
import { useGetAffiliate } from '../../../graphql/affiliates';

export function Affiliate(props) {
  const { affiliate } = props;

  return (
    <Grid>
      <Cell small={12}>
        <Heading>{affiliate.shortid}</Heading>
      </Cell>
    </Grid>
  );
}

export function AffiliateLoader(props) {
  const { data, loading, error } = useGetAffiliate(props.id);

  if (loading) return <Loader />;
  if (error) return null;

  console.log(data);

  return <Affiliate affiliate={data.getAffiliate} />;
}

export function AffiliateWrapper(props) {
  return (
    <Container>
      <Grid>
        <Cell small={6}>
          <Heading level={1}>
            <b>Vintro</b> Admin
          </Heading>
        </Cell>
        <Cell small={6} />
        <Cell>
          <hr />
        </Cell>
      </Grid>
      <Grid margin>
        <Cell small={12} medium={3}>
          <AdminSecondaryNav />
        </Cell>
        <Cell small={12} medium={9}>
          <AffiliateLoader id={props.match.params.id} />
        </Cell>
      </Grid>
    </Container>
  );
}

export default AffiliateWrapper;
