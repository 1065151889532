import React, { useState } from 'react';
import {
  Loader,
  Grid,
  Cell,
  Container,
  Heading,
  Card,
  Pagination,
} from '../../../components/ui';
import { AdminSecondaryNav } from '../../../components/layout';

import { Link } from 'react-router-dom';
import './buyers.scss';

import { Tags, Tag } from '../../../components/ui/tags/tags';
import moment from 'moment';
import { useSearchVentures } from '../../../graphql/ventures';

export function AdminBuyers(props) {
  return props.scouts.map(venture => {
    return (
      <Link key={venture.id} to={`/admin/buyers/view/${venture.id}`}>
        <Card hover>
          <Heading level={3}>{venture.name}</Heading>
          <Tags>
            <Tag>
              Created at:{' '}
              {moment(venture.createdAt).format('MM/DD/YYYY hh:mm a')}
            </Tag>
          </Tags>
        </Card>
      </Link>
    );
  });
}

export function AdminBuyersLoader(props) {
  const [page, setPage] = useState(1);
  const { data, loading, error } = useSearchVentures({ page });

  if (error) return null;

  return (
    <div>
      {!loading ? (
        <AdminBuyers scouts={data.searchVentures.ventures || []} />
      ) : (
        <Loader />
      )}

      <div className="text-center">
        {!loading && (
          <Pagination
            currentPage={data.searchVentures.page}
            totalItems={data.searchVentures.total}
            pageSize={data.searchVentures.limit}
            maxPages={data.searchVentures.pages}
            onPageChange={page => setPage(page)}
          />
        )}
      </div>
    </div>
  );
}

export function AdminBuyersWrapper(props) {
  return (
    <Container>
      <Grid>
        <Cell small={6}>
          <Heading level={1}>
            <b>Vintro</b> Admin
          </Heading>
        </Cell>
        <Cell small={6}></Cell>
        <Cell>
          <hr />
        </Cell>
      </Grid>
      <Grid margin>
        <Cell small={12} medium={3}>
          <AdminSecondaryNav />
        </Cell>
        <Cell small={12} medium={9}>
          <AdminBuyersLoader />
        </Cell>
      </Grid>
    </Container>
  );
}

export default AdminBuyersWrapper;
