import ApolloClient from 'apollo-boost';
import store from 'store';
import { TOKEN_KEY } from '../hooks/session';

import { InMemoryCache } from 'apollo-cache-inmemory';

import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from './fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({ fragmentMatcher });

const client = new ApolloClient({
  // uri: 'https://usmchnlns2.execute-api.us-east-1.amazonaws.com/dev/graphql',
  uri: process.env.REACT_APP_API || 'http://localhost:3000/graphql',
  cache,
  // defaultOptions,
  request: async operation => {
    if (store.get(TOKEN_KEY)) {
      operation.setContext({
        headers: {
          authorization: store.get(TOKEN_KEY),
        },
      });
    }
  },
});

export default client;
