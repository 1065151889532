import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';
import { Link } from 'react-router-dom';
import './reviewer-card.scss';
import { Grid, Cell, Card, StarRating, Button } from '../../ui';
import { useAddToCart, useItemIsInCart } from '../../../graphql/cart';
import missingImage from '../../../images/photo-missing.png';
import { ButtonLoading } from '../button/button';
import { osName, osVersion } from 'react-device-detect';
import cc from 'classnames';
import { useSession } from '../../../hooks/session';

function getPhoto(props) {
  if (props.photoCrop && props.photoCrop.url) {
    return props.photoCrop.url;
  }

  return props.photo || missingImage;
}

function getLogo(props) {
  if (props.companyLogoCrop && props.companyLogoCrop.url) {
    return props.companyLogoCrop.url;
  }

  return props.companyLogo || null;
}

export function ReviewerCard(props) {
  const photo = getPhoto(props);
  const logo = getLogo(props);

  return (
    <div className="ReviewerCard ReviewerCard--compact">
      <Card transparent={props.transparent} fullHeight>
        <Grid margin>
          <Cell large={'auto'}>
            <Link to={`/reviewers/${props.id}`}>
              <div className="ReviewerCard__image">
                <div
                  className={`ReviewerCard__image__background ${
                    process.env.REACT_APP_SHOW_FILTERS
                      ? 'ReviewerCard__image__background--with-filters'
                      : ''
                  }`}
                  style={{
                    backgroundImage: `url(${photo})`,
                  }}
                />
              </div>
            </Link>
            {logo && (
              <div
                className={`ReviewerCard__logo ${
                  process.env.REACT_APP_SHOW_FILTERS
                    ? 'ReviewerCard__logo--with-filters'
                    : ''
                }`}
              >
                <div
                  className="ReviewerCard__logo__background"
                  style={{
                    backgroundImage: `url(${logo})`,
                  }}
                />
              </div>
            )}
            <br />

            <header className="ReviewerCard__header">
              <Grid>
                <ReviewerCardCountryFlag
                  value={props.locality ? props.locality.country : null}
                />
                <Cell shrink>
                  <StarRating rating={props.averageRating || 5} />
                </Cell>
              </Grid>
              <div className="ReviewerCard__heading">
                <Grid margin>
                  <Cell auto>
                    <Link to={`/reviewers/${props.id}`}>
                      {props.firstName} {props.lastName}
                    </Link>
                  </Cell>
                </Grid>
              </div>
              <div className="ReviewerCard__subheading">
                <ReviewerCardCompany value={props.company} />
              </div>
              <hr />
              <div className="ReviewerCard__subheading">
                <ReviewerCardTitle value={props.title} />
              </div>
            </header>

            <div className="ReviewerCard__body ReviewerCard__purchase">
              <div className="ReviewerCard__meta">
                <div className="ReviewerCard__action">
                  {props.disableCart !== true ? (
                    <ReviewerAddToCartButton reviewer={props} />
                  ) : null}
                </div>
              </div>
            </div>
          </Cell>
        </Grid>
      </Card>
    </div>
  );
}

function getAddToCartText(reviewer, itemIsInCart, loading) {
  if (loading) {
    return <ButtonLoading>Adding {reviewer.firstName}</ButtonLoading>;
  }

  if (itemIsInCart) {
    return <span>Added</span>;
  }

  return (
    <span>
      Pitch to {reviewer.firstName} for ${reviewer.price}
    </span>
  );
}

function ReviewerAddToCartButton({ reviewer }) {
  const session = useSession({});
  const { profile = {} } = session;
  const [loading, setLoading] = useState(false);
  const addToCart = useAddToCart();
  const itemIsInCart = useItemIsInCart(reviewer.id);

  function canAddToCart() {
    if (reviewer.away) return false;

    return (
      (!profile.user && process.env.REACT_APP_CART_ENABLED) ||
      (profile.user &&
        profile.user.type !== 'SCOUT' &&
        process.env.REACT_APP_CART_ENABLED) ||
      (profile && profile.features && profile.features.cart === true)
    );
  }

  async function handleCartAddition() {
    if (!canAddToCart()) return null;
    if (loading) return null;

    setLoading(true);

    try {
      await addToCart(reviewer.id, reviewer);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const buttonText = getAddToCartText(reviewer, itemIsInCart, loading);
  return (
    <Button
      full
      onClick={handleCartAddition}
      disabled={itemIsInCart || reviewer.away}
    >
      {buttonText}
    </Button>
  );
}

function ReviewerCardTitle({ value }) {
  if (!value) return null;

  return <span className="ReviewerCard__title">{value}</span>;
}

function ReviewerCardCompany({ value }) {
  if (!value) return null;

  return <span className="ReviewerCard__company">{value}</span>;
}

function ReviewerCardCountryFlag({ value }) {
  if (!value) return null;

  // Hack to display SVG only on browsers running on Windows 10
  const isWindowsTen = osName === 'Windows' && osVersion === '10';

  const className = cc([
    'ReviewerCard__country',
    isWindowsTen ? 'ReviewerCard__country--svg' : null,
  ]);

  return (
    <Cell shrink>
      <div className={className}>
        <ReactCountryFlag code={value} svg={isWindowsTen} />
      </div>
    </Cell>
  );
}

ReviewerCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  company: PropTypes.string,
  sectors: PropTypes.array,
  pitches: PropTypes.array,
  image: PropTypes.string,
  rating: PropTypes.number,
  price: PropTypes.number,
  service: PropTypes.string,
  transparent: PropTypes.bool,
  compact: PropTypes.bool,
};

ReviewerCard.defaultProps = {
  id: 1,
  name: '',
  title: '',
  company: '',
  sectors: [],
  pitches: [],
  image: '',
  rating: 0,
  service: '',
  price: 0,
  transparent: false,
  compact: false,
};

export default ReviewerCard;
