import React, { useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Cell,
  Heading,
  Loader,
} from '../../components/ui';
import { FormWizard, FormStep } from '../../components/forms';

import { useMutation } from 'react-apollo-hooks';
import {
  ADD_VENTURE_PITCH,
  useUpdateVenture,
  convertFormToVentureInput,
  useMyVenture,
  convertVentureToForm,
} from '../../graphql/ventures';
import validate from 'validate.js';
import { useForm } from '../../hooks/forms';

import FormStepOne, {
  validationRules as validationRulesStepOne,
} from './steps/step.one';

import FormStepThree, {
  validationRules as validationRulesStepThree,
} from './steps/step.three';

import { useGlobalState } from '../../store';
import { AppError } from '../../components/ui/errors/errors';

export function CreateAccount(props) {
  const [, setNotifications] = useGlobalState('notifications');
  const updateVenture = useUpdateVenture();
  const addVenturePitch = useMutation(ADD_VENTURE_PITCH);
  const [currentStep, setCurrentStep] = useState(1);
  const { form, errors, setErrors, handleFormChange } = useForm(
    {
      ...convertVentureToForm(props.venture),
      ideaName: '',
      ideaDescription: '',
      industry: '',
      subIndustry: '',
      ask: [],
      job: '',
      pitch: '',
    },
    {
      validate: validateStep,
    }
  );

  function validateStep(form) {
    switch (currentStep) {
      case 1: {
        const rules = { ...validationRulesStepOne };

        if (form.country === 'US') {
          rules.state = {
            presence: { allowEmpty: false },
          };
        }

        return validate(form, rules);
      }

      case 2: {
        return validate(form, validationRulesStepThree, {
          fullMessages: false,
        });
      }

      default: {
        return {};
      }
    }
  }

  async function handleSubmit() {
    const response = validateStep(form);

    setErrors({
      ...(response || {}),
    });

    if (response !== undefined) return;

    const input = {
      venture: props.venture.id,
      name: form.ideaName,
      offering: form.offering,
      // tags: form.tags || [],
      // description: form.ideaDescription,
      // industry: form.industry,
      // subIndustry: form.subIndustry,
      // ask: form.ask,
      job: form.job,
    };

    try {
      await addVenturePitch({ variables: { input } });
      props.history.push('/reviewers');
    } catch (e) {
      setNotifications([
        {
          message: 'There was an error creating your pitch.',
          time: 3000,
          error: true,
        },
      ]);
    }
  }

  async function handleNextStep() {
    const response = validateStep(form);

    setErrors({
      ...(response || {}),
    });

    if (response !== undefined) return;

    window.scrollTo(0, 0);

    const input = convertFormToVentureInput(form);

    try {
      await updateVenture(input);
      setCurrentStep(step => step + 1);
    } catch (e) {
      setNotifications([
        {
          message: 'There was an error saving your company.',
          time: 3000,
          error: true,
        },
      ]);
    }
  }

  function handlePrevStep() {
    setCurrentStep(step => step - 1);
  }

  function renderCallout() {
    /*switch (currentStep) {
      case 1:
        return (
          <Grid spaced>
            <Cell small={12}>
              <Callout>
                <Grid align={'center'}>
                  <Cell small={12} large={10}>
                    <Heading level={2}>Welcome to the Crowd</Heading>
                    <Paragraph>
                      Our platform allows you to access to some of the most
                      amazing minds in business that for the right price will
                      weigh in on your venture, product or service. But first we
                      need you to tell us about your company and the idea.
                    </Paragraph>
                  </Cell>
                </Grid>
              </Callout>
            </Cell>
          </Grid>
        );
      case 3:
        return (
          <Grid spaced>
            <Cell small={12}>
              <Callout>
                <Grid align={'center'}>
                  <Cell small={12} large={8}>
                    <Heading level={2}>Your company is ready to pitch!</Heading>
                    <Paragraph>
                      Our platform allows you to access to some of the most
                      amazing minds in business that for the right price will
                      weigh in on your venture, product or service. But first we
                      need you to tell us about your company and the idea.
                    </Paragraph>
                  </Cell>
                </Grid>
              </Callout>
            </Cell>
          </Grid>
        );
      default:
        return null;
    }*/
    return null;
  }

  const lang = ['Next', 'Next - Create Your Pitch'];

  return (
    <section className="CreateAccount">
      <Container>
        {renderCallout()}
        <Grid spaced align="center">
          <Cell large={6}>
            <FormWizard
              nextText={lang[currentStep - 1]}
              submitText={'Submit & Find Reviewers'}
              totalSteps={2}
              currentStep={currentStep}
              handleSubmit={handleSubmit}
              handleNextStep={handleNextStep}
              handlePrevStep={handlePrevStep}
            >
              <FormStep step={1} currentStep={currentStep}>
                <FormStepOne
                  venture={props.venture}
                  form={form}
                  errors={errors}
                  handleFormChange={handleFormChange}
                />
              </FormStep>

              <FormStep currentStep={currentStep} step={2}>
                <Grid margin>
                  <Cell auto>
                    <Heading level={1}>Create Your Pitch</Heading>
                    <Heading level={2}>
                      The moment is here. Tell us your big idea.
                    </Heading>
                  </Cell>
                  <Cell shrink>
                    <Button
                      size={'small'}
                      hollow
                      onClick={() => props.history.push('/reviewers')}
                    >
                      Skip for now
                    </Button>
                  </Cell>
                  <Cell small={12}>
                    <hr />
                  </Cell>
                </Grid>
                <FormStepThree
                  showModal={true}
                  form={form}
                  errors={errors}
                  handleFormChange={handleFormChange}
                />
              </FormStep>
            </FormWizard>
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export function CreateCompanyLoader(props) {
  const { data, error, loading } = useMyVenture();

  if (loading) {
    return (
      <Container>
        <Grid spaced align="center">
          <Cell large={6}>
            <Loader />
          </Cell>
        </Grid>
      </Container>
    );
  }

  if (error) return <AppError error={`An application error occured`} />;
  if (!data.myVenture) return null;

  return <CreateAccount venture={data.myVenture} {...props} />;
}

export default CreateCompanyLoader;
