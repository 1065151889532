import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';

export const SEARCH = gql`
  query SEARCH(
    $page: Int
    $approved: Boolean
    $sort: SearchSortInput
    $search: SearchInput = {}
  ) {
    search(search: $search, approved: $approved, page: $page, sort: $sort) {
      total
      limit
      pages
      page
      results {
        ... on Person {
          createdAt
          id
          firstName
          lastName
          photo
          away
          photoCrop {
            url
          }
          companyLogo
          companyLogoCrop {
            url
          }
          title
          price
          company
          averageRating
          locality {
            country
          }
          tags {
            id
            name
          }
        }
        ... on Company {
          id
        }
      }
    }
  }
`;

export const SEARCH_PREVIEW_TOKENS = gql`
  query {
    searchFeatureTokens {
      results {
        id
        expires
        createdAt
        user {
          id
          email
        }
      }
    }
  }
`;

export const ADD_PREVIEW_TOKEN = gql`
  mutation {
    addFeatureToken {
      id
    }
  }
`;

export const SEARCH_INVITES = gql`
  query SEARCH_INVITES(
    $page: Int
    $approved: Boolean
    $sort: SearchSortInput
    $search: SearchInput = {}
  ) {
    searchPersonInvites(
      search: $search
      approved: $approved
      page: $page
      sort: $sort
    ) {
      total
      limit
      pages
      page
      results {
        ... on Person {
          id
          firstName
          lastName
          photo
          companyLogo
          title
          price
          company
          averageRating
          tags {
            id
            name
          }
        }
        ... on Company {
          id
        }
      }
    }
  }
`;

export const ADD_PERSON = gql`
  mutation ADD_PERSON($input: PersonInput) {
    addPerson(input: $input) {
      id
    }
  }
`;

export const UPDATE_PERSON = gql`
  mutation UPDATE_PERSON($input: PersonInput, $updateSendbird: Boolean) {
    updatePerson(input: $input, updateSendbird: $updateSendbird) {
      id
      familiar
      known
      identified
    }
  }
`;

export function useUpdatePerson() {
  const [updatePerson] = useMutation(UPDATE_PERSON);

  async function exec(input, updateSendbird = false) {
    try {
      return await updatePerson({ variables: { input, updateSendbird } });
    } catch (e) {
      throw e;
    }
  }

  return exec;
}

export const APPROVE_PERSON = gql`
  mutation APPROVE_PERSON($id: ID!) {
    approvePerson(id: $id) {
      id
      approved
    }
  }
`;

export function useApprovePerson() {
  const [approvePerson] = useMutation(APPROVE_PERSON);

  async function exec(id) {
    try {
      return await approvePerson({ variables: { id } });
    } catch (e) {
      throw e;
    }
  }

  return exec;
}

export const UN_APPROVE_PERSON = gql`
  mutation APPROVE_PERSON($id: ID!) {
    unApprovePerson(id: $id) {
      id
      approved
    }
  }
`;

export function useUnApprovePerson() {
  const [unApprovePerson] = useMutation(UN_APPROVE_PERSON);

  async function exec(id) {
    try {
      return await unApprovePerson({ variables: { id } });
    } catch (e) {
      throw e;
    }
  }

  return exec;
}

export const CONVERT_PERSON_TO_INVITE = gql`
  mutation CONVERT_PERSON_TO_INVITE($id: ID!) {
    convertPersonToInvite(id: $id) {
      id
    }
  }
`;

export function useConvertPersonToInvite() {
  const [convertPersonToInvite] = useMutation(CONVERT_PERSON_TO_INVITE);

  async function exec(id) {
    try {
      return await convertPersonToInvite({ variables: { id } });
    } catch (e) {
      throw e;
    }
  }

  return exec;
}

export const UPDATE_PERSON_INVITE = gql`
  mutation UPDATE_PERSON($input: PersonInput) {
    updatePersonInvite(input: $input) {
      id
    }
  }
`;

export function useUpdatePersonInvite() {
  const [updatePersonInvite] = useMutation(UPDATE_PERSON_INVITE);

  async function exec(input) {
    try {
      return await updatePersonInvite({ variables: { input } });
    } catch (e) {
      throw e;
    }
  }

  return exec;
}

export const ADD_PERSON_INVITE = gql`
  mutation UPDATE_PERSON($input: PersonInput) {
    addPersonInvite(input: $input) {
      id
    }
  }
`;

export function useAddPersonInvite() {
  const [addPersonInvite] = useMutation(ADD_PERSON_INVITE);

  async function exec(input) {
    try {
      return await addPersonInvite({ variables: { input } });
    } catch (e) {
      throw e;
    }
  }

  return exec;
}

export const GET_PERSON = gql`
  query GET_PERSON($id: ID!) {
    getPerson(id: $id) {
      id
      firstName
      lastName
      photo
      photoCrop {
        url
      }
      away
      title
      price
      company
      familiar
      payable
      bio
      bioLookingFor
      bioCanProvide
      companyLogo
      companyLogoCrop {
        url
      }
      isDonatingToCharity
      donatingToCharityName
      isFollowable
      linkedIn
      investmentDesiredRange {
        high
        low
      }
      investmentLargestRange {
        high
        low
      }
      types(humanize: true)
      cost
      offering(humanize: true)
      locality {
        country
        city
        postal
      }
      averageRating
      totalRatings
      averageResponseTime
      tags {
        id
        name
        type
      }
    }
  }
`;

export const GET_PERSON_ADMIN = gql`
  query GET_PERSON($id: ID!) {
    getPerson(id: $id) {
      id
      user {
        id
        email
      }
      firstName
      lastName
      photo
      photoCrop {
        url
      }
      away
      title
      price
      company
      familiar
      payable
      approved
      bio
      bioLookingFor
      bioCanProvide
      companyLogo
      companyLogoCrop {
        url
      }
      isDonatingToCharity
      donatingToCharityName
      isFollowable
      investmentDesiredRange {
        high
        low
      }
      investmentLargestRange {
        high
        low
      }
      investmentDesiredStage
      types
      cost
      offering
      locality {
        country
        city
        postal
      }
      averageRating
      totalRatings
      averageResponseTime
      linkedIn
      tags {
        id
        name
      }
      affiliate {
        id
        shortid
        parent {
          id
          shortid
          network {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_PERSON_INVITE = gql`
  query GET_PERSON_INVITE($id: ID!) {
    getPersonInvite(id: $id) {
      id
      accepted
      acceptedAt
      acceptedBy
      person {
        id
        firstName
        lastName
        photo
        photoCrop {
          url
        }
        away
        title
        price
        company
        familiar
        identified
        payable
        bio
        bioLookingFor
        bioCanProvide
        companyLogo
        companyLogoCrop {
          url
        }
        isDonatingToCharity
        donatingToCharityName
        isFollowable
        investmentDesiredRange {
          high
          low
        }
        investmentLargestRange {
          high
          low
        }
        investmentDesiredStage
        types
        cost
        offering
        locality {
          country
          city
          postal
        }
        averageRating
        totalRatings
        averageResponseTime
        tags {
          id
          name
        }
        linkedIn
      }
    }
  }
`;

export function useGetPersonInvite(id) {
  const { error, loading, data } = useQuery(GET_PERSON_INVITE, {
    fetchPolicy: 'network-only',
    variables: { id },
  });

  return { error, loading, data };
}

export const MY_PROFILE = gql`
  query MY_PROFILE {
    myProfile {
      id
      firstName
      lastName
      photo
      photoCrop {
        url
      }
      title
      price
      company
      familiar
      known
      identified
      payable
      approved
      bio
      bioLookingFor
      bioCanProvide
      companyLogo
      companyLogoCrop {
        url
      }
      isDonatingToCharity
      donatingToCharityName
      isFollowable
      linkedIn
      investmentDesiredRange {
        high
        low
      }
      investmentLargestRange {
        high
        low
      }
      investmentDesiredStage
      types
      cost
      offering
      locality {
        country
        city
        postal
      }
      averageRating
      totalRatings
      averageResponseTime
      tags {
        id
        name
      }
    }
  }
`;

export function useMyProfile() {
  const { error, loading, data } = useQuery(MY_PROFILE, {
    fetchPolicy: 'network-only',
  });

  return { error, loading, data };
}

export const MY_PROFILE_STATUS = gql`
  query MY_PROFILE {
    myProfile {
      id
      familiar
      payable
      approved
    }
  }
`;

export function useMyProfileStatus() {
  const { error, loading, data } = useQuery(MY_PROFILE_STATUS, {
    fetchPolicy: 'network-only',
  });

  return { error, loading, data };
}

export const REGISTER_AS_SCOUT = gql`
  mutation REGISTER_AS_SCOUT($input: RegisterAsPersonInput) {
    registerAsPerson(input: $input) {
      token
      user {
        id
        type
      }
    }
  }
`;

export function useRegisterAsScout() {
  const [registerAsScout] = useMutation(REGISTER_AS_SCOUT);

  async function exec(input) {
    try {
      return await registerAsScout({ variables: { input } });
    } catch (e) {
      throw e;
    }
  }

  return exec;
}

export const MY_ORDER_REQUESTS = gql`
  query MY_ORDER_REQUESTS {
    myOrderRequests(search: { status: PENDING_REVIEW }) {
      total
      requests {
        id
        status
      }
    }
  }
`;

export function useMyOrderRequests() {
  const { error, loading, data } = useQuery(MY_ORDER_REQUESTS, {
    fetchPolicy: 'network-only',
  });

  return { error, loading, data };
}

export const MY_NEXT_ORDER_REQUEST = gql`
  query MY_NEXT_ORDER_REQUEST {
    myNextOrderRequest {
      total
      next {
        id
        status
      }
    }
  }
`;

export function useMyNextOrderRequest() {
  const { error, loading, data } = useQuery(MY_NEXT_ORDER_REQUEST, {
    fetchPolicy: 'network-only',
  });

  return { error, loading, data };
}

export const MY_REVIEWS = gql`
  query MY_REVIEWS {
    myReviews {
      total
      requests {
        id
        status
        order {
          id
          pitch {
            id
            name
          }
        }
      }
    }
  }
`;

export function useMyReviews() {
  const { error, loading, data } = useQuery(MY_REVIEWS, {
    fetchPolicy: 'network-only',
  });

  return { error, loading, data };
}

export const MY_ORDER_REQUEST_REVENUE = gql`
  query MY_ORDER_REQUEST_REVENUE {
    myOrderRequestRevenue {
      total
      revenue
    }
  }
`;

export function useMyOrderRequestRevenue() {
  const { error, loading, data } = useQuery(MY_ORDER_REQUEST_REVENUE, {
    fetchPolicy: 'network-only',
  });

  return { error, loading, data };
}

export const JUMIO_VERIFICATION = gql`
  query {
    jumioVerify
  }
`;

export function useJumioVerification() {
  const { data, loading, error } = useQuery(JUMIO_VERIFICATION);

  return { data, loading, error };
}
