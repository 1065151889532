import './my-requests.scss';
import React from 'react';
import {
  Container,
  Grid,
  Cell,
  Loader,
  Card,
  Heading,
  Paragraph,
  Callout,
  Button,
} from '../../components/ui';

import { Link } from 'react-router-dom';

import {
  useMyNextOrderRequest,
  useMyOrderRequestRevenue,
} from '../../graphql/scouts';
import { ReviewerNav } from '../../components/layout';
import { ReviewerDashboardHeader } from '../../components/ui/dashboard/header/header';

export function MyOrderCard(props) {
  return (
    <Link to={`/dashboard/my-request/${props.id}`}>
      <Card hover>
        <Heading level={4}>{props.name}</Heading>
        <Paragraph>{props.description}</Paragraph>
      </Card>
    </Link>
  );
}

export function MyRequests(props) {
  const { request } = props;

  return (
    <Card>
      <Heading level={2}>
        You have <b>{request.total}</b>{' '}
        {request.total > 1 || request.total === 0 ? 'Pitches' : 'Pitch'} to
        review.
      </Heading>
      {request.total > 0 && (
        <Link to={`/dashboard/my-request/${request.next.id}`}>
          <Button>Review Pitches</Button>
        </Link>
      )}
    </Card>
  );
}

export function MyRequestsNoResults() {
  return (
    <Callout>
      <div className="text-center">
        <Heading style={{ margin: '1rem' }} level={4}>
          You Don't have any requests
        </Heading>
        <Paragraph>
          Our platform allows you to access to some of the most amazing minds in
          business that for the right price will weigh in on your venture,
          product or service
        </Paragraph>
        <Link to="/reviewers">
          <Button>Browse Reviewers</Button>
        </Link>
      </div>
    </Callout>
  );
}

export function MyRequestsLoader(props) {
  const { data, loading, error } = useMyNextOrderRequest();

  if (loading) return <Loader />;
  if (error) return null;

  /*if (data.myRequests.orders.length === 0) {
    return <MyRequestsNoResults />;
  }*/

  return <MyRequests request={data.myNextOrderRequest} />;
}

export function MyRevenue(props) {
  const { revenue } = props;

  return (
    <Card>
      <Heading level={2}>Activity</Heading>
      <Heading level={1}>
        <b>${revenue.revenue}</b> Earned
      </Heading>
      <Heading level={6}>
        <Link to="/dashboard/my-reviews">
          <b>
            {revenue.total}{' '}
            {revenue.total > 1 || revenue.total === 0 ? 'Pitches' : 'Pitch'}
          </b>
        </Link>{' '}
        Reviewed
      </Heading>
    </Card>
  );
}

export function MyRevenueLoader(props) {
  const { data, loading, error } = useMyOrderRequestRevenue();
  if (loading) return <Loader />;
  if (error) return null;

  return <MyRevenue revenue={data.myOrderRequestRevenue} />;
}

export function MyAccount(props) {
  return (
    <Card>
      <Heading level={2}>My Account</Heading>
      <Heading level={1}>
        <b /> Earned
      </Heading>
      <Heading level={6}>
        <Link to="/dashboard/my-reviews">
          <b>Pitches</b>
        </Link>{' '}
        Reviewed
      </Heading>
    </Card>
  );
}

export function MyRequestsWrapper(props) {
  return (
    <section className="MyRequests">
      <Container>
        <Grid>
          <Cell>
            <ReviewerDashboardHeader />
            <hr />
          </Cell>
        </Grid>
        <Grid margin>
          <Cell small={12} medium={3}>
            <ReviewerNav />
          </Cell>
          <Cell small={12} medium={9}>
            <Grid margin>
              <Cell small={12} medium={6}>
                <MyRequestsLoader />
              </Cell>
              <Cell small={12} medium={6}>
                <MyRevenueLoader />
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export default MyRequestsWrapper;
