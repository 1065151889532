import React, { useEffect } from 'react';
import './chat-window.scss';
import { ChatMessage } from '..';
import { useGlobalState } from '../../../store';

export function useGetChannelMessages(sendbird, activeChannel) {
  const [messages, setMessages] = useGlobalState('activeMessages');
  const url = activeChannel ? activeChannel.url : null;

  useEffect(() => {
    if (activeChannel) {
      var prevMessageListQuery = activeChannel.createPreviousMessageListQuery();
      prevMessageListQuery.limit = 30;
      // prevMessageListQuery.reverse = true;

      prevMessageListQuery.load(function(messages, error) {
        if (error) {
          return;
        }

        activeChannel.markAsRead();

        setMessages(messages);
      });
    }
    // eslint-disable-next-line
  }, [url]);

  useEffect(() => {
    if (activeChannel) {
      var ChannelHandler = new sendbird.ChannelHandler();

      ChannelHandler.onMessageReceived = function(channel, message) {
        if (channel.url === url) {
          setMessages([...messages, message]);
          activeChannel.markAsRead();
        }
      };

      sendbird.addChannelHandler('chat-window', ChannelHandler);

      return () => {
        sendbird.removeChannelHandler('chat-window');
      };
    }
    // eslint-disable-next-line
  }, [url, messages]);

  return messages;
}

export function ChatWindow(props) {
  const [activeChannel] = useGlobalState('activeChannel');
  const messages = useGetChannelMessages(props.sendbird, activeChannel);

  useEffect(() => {
    handleScroll();
  });

  function handleScroll(animate = false) {
    const container = document.querySelector('.ChatWindow__content');
    container.scrollTo({
      top: container.scrollHeight,
      left: 0,
      behavior: animate ? 'smooth' : 'auto',
    });
  }

  return (
    <div className="ChatWindow">
      <div className="ChatWindow__content">
        <div className="ChatWindow__messages">
          {messages.map((item, idx) => {
            return <ChatMessage message={item} key={idx} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default ChatWindow;
