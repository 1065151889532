import './billing.scss';
import React, { useState, useEffect } from 'react';
import history from '../../util/history';
import {
  Grid,
  Cell,
  Button,
  Container,
  Heading,
  Divider,
  Paragraph,
  Card,
  Label,
  TextInput,
  SelectInput,
  PitchInput,
  Loader,
} from '../../components/ui';
import stateOptions from '../../util/state-options';
import {
  Elements,
  CardElement,
  StripeProvider,
  injectStripe,
} from 'react-stripe-elements';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { GET_CART } from '../../graphql/cart';
import store from 'store';
import { ADD_ORDER } from '../../graphql/orders';
import { useGlobalState } from '../../store';

import { CartItems } from '../../components/cart/cart';
import countryOptions from '../../util/country-options';

import { useNotifications } from '../../hooks/notifications';
import { useMyVenturePitches, ADD_VENTURE_PITCH } from '../../graphql/ventures';
import { FormStep } from '../../components/forms';
import {
  default as CreatePitchStep,
  validationRules as createPitchValidationRules,
} from '../create-company/steps/step.three';
import { useForm } from '../../hooks/forms';
import validate from 'validate.js';
import { ButtonLoading } from '../../components/ui/button/button';

export const STRIPE_STYLES = {
  base: { border: '2px solid red', padding: '2rem', fontSize: '1rem' },
};

export const validationRules = {
  addressLineOne: {
    presence: {
      allowEmpty: false,
    },
  },
  firstName: {
    presence: {
      allowEmpty: false,
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
    },
  },
  country: {
    presence: {
      allowEmpty: false,
    },
  },
  city: {
    presence: {
      allowEmpty: false,
    },
  },
  cardName: {
    presence: {
      allowEmpty: false,
    },
  },
};

export function Billing(props) {
  const [orderLoading, setOrderLoading] = useState(false);
  const notifications = useNotifications();
  const [cartId, setCartId] = useGlobalState('cart');
  const addOrder = useMutation(ADD_ORDER);
  const { data, loading } = useQuery(GET_CART, {
    variables: { id: store.get('cart'), checkout: true },
  });

  const cart = !loading ? data.getCart : { items: [] };
  const { form, setErrors, errors, handleFormChange } = props;

  const cartTotal = cart.items.reduce((result, item) => {
    result += item.price;
    return result;
  }, 0);

  const checkoutValidationRules = {
    ...validationRules,
  };

  if (form.country === 'US' || form.country === 'USA') {
    checkoutValidationRules.state = {
      presence: {
        allowEmpty: false,
      },
    };
  }

  function validateForm() {
    const result = validate(form, checkoutValidationRules);
    const isValid = result === undefined;

    setErrors({
      ...(result || {}),
    });

    return isValid;
  }

  async function submit() {
    if (!validateForm()) return;

    setOrderLoading(true);

    try {
      const result = await props.stripe.handleCardPayment(
        cart.stripePaymentIntentClientSecret,
        {
          payment_method_data: {
            billing_details: {
              name: `${form.firstName} ${form.lastName}`,
              address: {
                city: form.city,
                country: form.country,
                line1: form.addressLineOne,
                line2: form.addressLineTwo,
                postal_code: null,
                state: form.state || null,
              },
            },
          },
        }
      );

      if (result.error) {
        setOrderLoading(false);
        return notifications.notify('Your payment could not be processed', {
          time: 3000,
          error: true,
        });
      }

      try {
        const { data } = await addOrder({
          variables: {
            input: {
              cart: cartId,
              pitch: form.pitch,
              address: {
                firstName: form.firstName,
                lastName: form.lastName,
                addressLineOne: form.addressLineOne,
                addressLineTwo: form.addressLineTwo,
                country: form.country,
                city: form.city,
                state: form.state,
              },
            },
          },
        });

        if (data.addOrder.id) {
          setCartId(null);
          store.remove('cart');
        }

        history.push(`/order/${data.addOrder.id}`);
      } catch (e) {
        notifications.notify(`Something wen't wrong`, {
          error: true,
          time: 3000,
        });
      }
    } catch (e) {
      notifications.notify(`Something wen't wrong`, {
        error: true,
        time: 3000,
      });
    }

    setOrderLoading(false);
  }

  return (
    <section className="Checkout Checkout__billing">
      <Grid align="center">
        <Cell small={12} large={12}>
          <header>
            <Heading level={1}>
              Your <b>Billing</b> Information
            </Heading>
            <Divider />
          </header>
          <Grid padding>
            <Cell large={7} small={12}>
              <Heading level={2}>Credit Card Information</Heading>
              <TextInput
                label={'Name on card'}
                placeholder="Name on card"
                spaced
                value={form.cardName}
                onChange={handleFormChange}
                name={'cardName'}
                errors={errors.cardName}
              />

              <div
                style={{
                  padding: '1rem',
                  border: '1px solid #c6cce4',
                  borderRadius: '4px',
                  marginBottom: '20px',
                }}
              >
                <CardElement style={STRIPE_STYLES} />
              </div>

              <Heading level={2}>Billing Information</Heading>
              <Grid margin>
                <Cell small={12} medium={6}>
                  <TextInput
                    label={'First Name'}
                    placeholder={'First Name'}
                    spaced
                    value={form.firstName}
                    name={'firstName'}
                    onChange={handleFormChange}
                    errors={errors.firstName}
                  />
                </Cell>
                <Cell small={12} medium={6}>
                  <TextInput
                    label={'Last Name'}
                    placeholder={'Last Name'}
                    value={form.lastName}
                    name={'lastName'}
                    onChange={handleFormChange}
                    errors={errors.lastName}
                    spaced
                  />
                </Cell>
              </Grid>

              <TextInput
                label={'Address Line One'}
                placeholder={'Address Line One'}
                value={form.addressLineOne}
                name={'addressLineOne'}
                onChange={handleFormChange}
                errors={errors.addressLineOne}
                spaced
              />
              <TextInput
                label={'Address Line Two'}
                placeholder={'Address Line Two'}
                value={form.addressLineTwo}
                name={'addressLineTwo'}
                onChange={handleFormChange}
                spaced
              />
              <SelectInput
                options={countryOptions}
                label={'Country'}
                placeholder={'Placeholder'}
                value={form.country}
                emptyOption={'Select a Country'}
                name={'country'}
                errors={errors.country}
                onChange={handleFormChange}
                spaced
              />
              {form.country === 'US' && (
                <Grid padding>
                  <Cell large={6}>
                    <SelectInput
                      label={'State'}
                      options={stateOptions}
                      placeholder={'Placeholder'}
                      value={form.state}
                      errors={errors.state}
                      name={'state'}
                      emptyOption={'-- Select --'}
                      onChange={handleFormChange}
                      spaced
                    />
                  </Cell>
                </Grid>
              )}
              <Grid>
                <Cell>
                  <TextInput
                    label={'City'}
                    placeholder={'City'}
                    errors={errors.city}
                    value={form.city}
                    name={'city'}
                    onChange={handleFormChange}
                    spaced
                  />
                </Cell>
              </Grid>
              <Button
                size={'small'}
                hollow={true}
                onClick={props.handlePrevStep}
              >
                Choose Another Pitch
              </Button>
            </Cell>
            <Cell large={5} small={12}>
              <Card theme={'air'}>
                <Heading level={2}>Cart Overview</Heading>

                <Divider />
                <div>
                  <Label>Your Pitch</Label>
                  <PitchInput
                    preview={true}
                    errors={errors.pitch}
                    emptyOption={'Select a Pitch'}
                    value={props.pitch}
                    placeholder="Selct a Pitch"
                    name="pitch"
                    onChange={handleFormChange}
                  />
                </div>
                <Divider />

                <CartItems items={cart.items} />
                <div className="text-right">
                  <Heading level={5}>Total</Heading>
                  <Heading level={1}>{`$${cartTotal}`}</Heading>
                </div>
                <br />

                <Button size={'large'} full onClick={submit}>
                  {orderLoading ? (
                    <ButtonLoading>Placing your order</ButtonLoading>
                  ) : (
                    <span>Place Order</span>
                  )}
                </Button>
              </Card>
            </Cell>
          </Grid>
        </Cell>
      </Grid>
    </section>
  );
}

export const BillingInjected = injectStripe(Billing);

export function BillingLoader(props) {
  return (
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
      <Elements>
        <BillingInjected {...props} />
      </Elements>
    </StripeProvider>
  );
}

export function PitchLoader(props) {
  const { data, loading, error } = useMyVenturePitches();

  if (loading || !data || !data.myVenture) return <Loader />;
  if (error) return null;

  return data.myVenture.pitches.length > 0 ? (
    <SelectPitch {...props} venture={data.myVenture} />
  ) : (
    <CreatePitch {...props} venture={data.myVenture} />
  );
}

export function CreatePitch(props) {
  const [loading, setLoading] = useState(false);
  const notifications = useNotifications();
  const addVenturePitch = useMutation(ADD_VENTURE_PITCH);
  const { form, handleFormChange, errors, setErrors } = useForm({});

  useEffect(function() {
    props.handleFormChange({ target: { name: 'new', value: true } });
    // eslint-disable-next-line
  }, []);

  async function submit() {
    try {
      const response = validate(form, createPitchValidationRules);
      setErrors(errors => {
        return response || [];
      });

      if (response !== undefined) return;
      if (loading) return;

      setLoading(true);

      const input = {
        venture: props.venture.id,
        name: form.ideaName,
        offering: form.offering,
        job: form.job,
      };

      const { data } = await addVenturePitch({ variables: { input } });

      props.handleFormChange({
        target: { value: data.addVenturePitch.id, name: 'pitch' },
      });

      setLoading(false);

      props.handleNextStep();
    } catch (e) {
      notifications.notify(`There was an error creating your pitch`, {
        error: true,
        time: 3000,
      });
      setLoading(false);
    }
  }

  return (
    <Grid margin>
      <Cell auto>
        <Heading level={1}>Create Your Pitch</Heading>
        <Heading level={2}>The moment is here. Tell us your big idea.</Heading>
        <CreatePitchStep
          handleFormChange={handleFormChange}
          errors={errors}
          form={form}
        />
      </Cell>
      <Cell>
        <div className="text-right">
          <Button onClick={submit}>
            {loading ? (
              <ButtonLoading>Creating your Pitch</ButtonLoading>
            ) : (
              'Create your Pitch & Checkout'
            )}
          </Button>
        </div>
      </Cell>
    </Grid>
  );
}

export function MyPitchCard(props) {
  const job = props.job || { video: {}, thumbnail: {} };

  function selectCard() {
    props.handleFormChange({
      target: {
        name: 'pitch',
        value: props.id === props.selected ? null : props.id,
      },
    });
  }

  return (
    <Cell>
      <div onClick={selectCard}>
        <Card
          selected={props.id === props.selected}
          selectable={true}
          hover
          theme={'air'}
          fullHeight
        >
          <div
            style={{
              height: '200px',
              width: 'calc(100% + 40px)',
              backgroundPosition: 'center',
              margin: '-20px -20px 20px -20px',
              borderTopLeftRadius: props.id === props.selected ? '0px' : '4px',
              borderTopRightRadius: props.id === props.selected ? '0px' : '4px',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundColor: '#000',
              backgroundImage: `url(${job.thumbnail.url ||
                'https://dev-scout-uploads.s3.amazonaws.com/encoded/1d534519-19e2-4229-bf05-7755f13c411d_next_file_frame.0000001.jpg'})`,
            }}
          />

          <Heading level={4}>{props.name}</Heading>
          <Paragraph>{props.description}</Paragraph>
        </Card>
      </div>
    </Cell>
  );
}

export function SelectPitch(props) {
  return (
    <Grid margin>
      <Cell small={12}>
        <Heading level={1}>Select Your Pitch</Heading>
        <Heading level={2}>Choose the pitch you want to send.</Heading>
      </Cell>
      <Cell small={12}>
        <Grid mediumBlock={3}>
          {props.venture.pitches.map(pitch => {
            return (
              <MyPitchCard
                selected={props.form.pitch}
                handleFormChange={props.handleFormChange}
                key={pitch.id}
                {...pitch}
              />
            );
          })}
        </Grid>
      </Cell>
      <Cell small={12}>
        <div className="text-right">
          <Button onClick={props.handleNextStep}>Checkout</Button>
        </div>
      </Cell>
    </Grid>
  );
}

export function PitchSelector(props) {
  const { form, errors, setErrors, handleFormChange } = useForm(
    { new: false, pitch: null },
    { validate: validateForm }
  );
  const [currentStep, setCurrentStep] = useState(1);

  function handleNextStep() {
    window.scrollTo(0, 0);
    return setCurrentStep(currentStep + 1);
  }

  function handlePrevStep() {
    window.scrollTo(0, 0);
    return setCurrentStep(currentStep - 1);
  }

  const checkoutValidationRules = {
    ...validationRules,
  };

  if (form.country === 'US' || form.country === 'USA') {
    checkoutValidationRules.state = {
      presence: {
        allowEmpty: false,
      },
    };
  }

  function validateForm(values) {
    const result = validate(values, checkoutValidationRules);

    setErrors({
      ...(result || {}),
    });
  }

  return (
    <Container>
      <Grid padding align={'center'}>
        <Cell small={12} large={10}>
          <Heading level={6}>
            <b>Step {currentStep}</b> of 2
          </Heading>
        </Cell>
        <Cell small={12} large={10}>
          <FormStep step={1} currentStep={currentStep}>
            <PitchLoader
              handleNextStep={handleNextStep}
              form={form}
              handleFormChange={handleFormChange}
            />
          </FormStep>

          <FormStep currentStep={currentStep} step={2}>
            <BillingLoader
              pitch={form.pitch}
              form={form}
              errors={errors}
              setErrors={setErrors}
              handlePrevStep={handlePrevStep}
              handleFormChange={handleFormChange}
            />
          </FormStep>
        </Cell>
      </Grid>
    </Container>
  );
}

export default PitchSelector;
