import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Loader, Container, Grid, Cell } from '../../components/ui';
import { ReviewerNav } from '../../components/layout';
import { ReviewerDashboardHeader } from '../../components/ui/dashboard/header/header';

export function MyPaymentDetails() {
  return 'Payment Details';
}

export function MyPaymentDetailsLoader() {
  const { data, loading, error } = useQuery(gql`
    query {
      getPaymentWidgetUrl
    }
  `);

  if (loading || !data) return <Loader />;
  if (error) return null;

  return (
    <Container>
      <iframe
        title={'Paymenet Details'}
        style={{ minHeight: '1024px', width: '100%', border: 'none' }}
        src={data.getPaymentWidgetUrl}
      />
    </Container>
  );
}

export function MyPaymentDetailsWrapper(props) {
  return (
    <section className="MyRequests">
      <Container>
        <Grid>
          <Cell>
            <ReviewerDashboardHeader />
            <hr />
          </Cell>
        </Grid>
        <Grid margin>
          <Cell small={12} medium={3}>
            <ReviewerNav />
          </Cell>
          <Cell small={12} medium={9}>
            <MyPaymentDetailsLoader />
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export default MyPaymentDetailsWrapper;
