import React, { useState } from 'react';
import {
  Loader,
  Grid,
  Cell,
  Container,
  Heading,
  Card,
  SearchInput,
  SelectInput,
  Callout,
  Paragraph,
} from '../../../components/ui';
import { AdminSecondaryNav } from '../../../components/layout';
import { useQuery } from 'react-apollo-hooks';
import { Link } from 'react-router-dom';
import './orders.scss';
import { SEARCH_ORDERS } from '../../../graphql/orders';
import moment from 'moment';

const orderStatusOptions = [
  {
    label: 'Pending Moderation',
    value: 'PENDING_MODERATION',
  },
  {
    label: 'Pending Reviewer',
    value: 'PENDING_REVIEW',
  },
  {
    label: 'Completed',
    value: 'COMPLETED',
  },
];

export function AdminOrders(props) {
  return props.orders.map(order => {
    return (
      <Link key={order.id} to={`/admin/orders/view/${order.id}`}>
        <div class="AdminOrders__Card">
          <Card hover>
            <Heading level={5}>
              {moment(new Date(order.createdAt)).format(
                'dddd, MMM Do, YYYY hh:mm a'
              )}
            </Heading>
            <Heading level={1}>${order.price}.00</Heading>
            <Heading level={5}>Reviewers:</Heading>
            <div className="Reviewers__List">
              {order.items.map(scout => {
                return (
                  <span key={scout.id}>
                    {scout.firstName} {scout.lastName}
                  </span>
                );
              })}
            </div>
          </Card>
        </div>
      </Link>
    );
  });
}

export function AdminOrdersLoader(props) {
  const { data, loading, error } = useQuery(SEARCH_ORDERS, {
    fetchPolicy: 'network-only',
    variables: {
      search: {
        status: props.status,
      },
    },
  });

  if (loading) return <Loader />;
  if (error) return null;

  if (data.searchOrders.total === 0) {
    return (
      <Callout>
        <div className="text-center">
          <Heading style={{ margin: '1rem' }} level={4}>
            We Don't have any orders
          </Heading>
          <Paragraph>
            Let's get to work and make sure our reviewers are getting booked!
          </Paragraph>
        </div>
      </Callout>
    );
  }

  return <AdminOrders orders={data.searchOrders.orders} />;
}

export function AdminOrdersWrapper(props) {
  const [form, setForm] = useState({
    status: 'PENDING_MODERATION',
  });

  function handleFormChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    setForm(form => {
      return {
        ...form,
        [name]: value,
      };
    });
  }

  return (
    <section className="AdminOrders">
      <Container>
        <Grid>
          <Cell small={6}>
            <Heading level={1}>
              <b>Vintro</b> Admin
            </Heading>
          </Cell>

          <Cell>
            <hr />
          </Cell>
        </Grid>
        <Grid margin>
          <Cell small={12} medium={3}>
            <AdminSecondaryNav />
          </Cell>
          <Cell small={12} medium={9}>
            <Grid margin>
              <Cell small={12} medium={9}>
                <SearchInput name="search" placeholder="Search Orders" />
              </Cell>
              <Cell small={12} medium={3}>
                <SelectInput
                  onChange={handleFormChange}
                  name="status"
                  value={form.status}
                  options={orderStatusOptions}
                />
              </Cell>
              <Cell small={12}>
                <hr />
              </Cell>
              <Cell small={12} medium={12}>
                <AdminOrdersLoader status={form.status} />
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export default AdminOrdersWrapper;
