import React from 'react';
import {
  Heading,
  Container,
  Grid,
  Cell,
  TextInput,
  VideoInput,
  Loader,
} from '../../components/ui';

import { MY_VENTURE, ADD_VENTURE_PITCH } from '../../graphql/ventures';

import { FormWizard, FormStep } from '../../components/forms';

import { useQuery } from 'react-apollo-hooks';
import { SecondaryNav } from '../../components/layout/secondary-nav/secondary-nav';
import OfferingInput from '../../components/ui/inputs/offering/offering';
import { useForm } from '../../hooks/forms';
import { useNotifications } from '../../hooks/notifications';
import { useMutation } from '@apollo/react-hooks';

export function NewPitch(props) {
  const [addVenturePitch] = useMutation(ADD_VENTURE_PITCH);
  const notifications = useNotifications();
  const { form, handleFormChange } = useForm(
    {
      name: '',
      offering: [],
      job: '',
    },
    {}
  );

  async function handleSubmit() {
    const input = {
      venture: props.venture.id,
      name: form.name,
      offering: form.offering,
      job: form.job,
    };

    try {
      await addVenturePitch({ variables: { input } });
      props.history.push('/dashboard/my-pitches');
    } catch (e) {
      notifications.notify(`There was an error creating your pitch.`, {
        time: 3000,
        error: true,
      });
    }
  }

  return (
    <section className="CreateAccount">
      <Container>
        <Grid spaced align="center">
          <Cell large={12}>
            <FormWizard
              submitText={'Add Pitch'}
              totalSteps={1}
              currentStep={1}
              handleSubmit={handleSubmit}
            >
              <FormStep currentStep={1} step={1}>
                <TextInput
                  spaced
                  label={'Pitch Title'}
                  value={form.name}
                  onChange={handleFormChange}
                  name={'name'}
                />

                <OfferingInput
                  spaced
                  name="offering"
                  label={"What I'm looking for"}
                  value={form.offering}
                  onChange={handleFormChange}
                />

                <Heading level={4}>Upload Your Pitch</Heading>
                <Grid margin>
                  <Cell small={12}>
                    <VideoInput name={'job'} onChange={handleFormChange} />
                  </Cell>
                </Grid>
              </FormStep>
            </FormWizard>
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export function NewPitchLoader(props) {
  const { data, loading, error } = useQuery(MY_VENTURE, {
    variables: { id: props.match.params.id },
  });

  if (loading) return <Loader />;
  if (error) return null;

  return <NewPitch history={props.history} venture={data.myVenture} />;
}

export function NewPitchWrapper(props) {
  return (
    <Container>
      <Grid>
        <Cell>
          <Heading level={2}>Dashboard</Heading>
          <hr />
        </Cell>
      </Grid>
      <Grid>
        <Cell small={12} medium={3}>
          <SecondaryNav />
        </Cell>
        <Cell small={12} medium={9}>
          <NewPitchLoader {...props} />
        </Cell>
      </Grid>
    </Container>
  );
}

export default NewPitchWrapper;
