import React from 'react';
import PropTypes from 'prop-types';
import './reviewer-photo.scss';

function getPhoto(props) {
  if (props.photoCrop && props.photoCrop.url) {
    return props.photoCrop.url;
  }

  return props.photo;
}

function getLogo(props) {
  if (props.companyLogoCrop && props.companyLogoCrop.url) {
    return props.companyLogoCrop.url;
  }

  return props.logo || null;
}

export function ReviewerPhoto(props) {
  const photo = getPhoto(props);
  const logo = getLogo(props);

  const photoStyles = {
    backgroundImage: `url(${photo})`,
    height: `${props.height}px`,
  };

  const logoStyles = {
    backgroundImage: `url(${logo})`,
  };

  return (
    <div className="ReviewerPhoto" style={photoStyles}>
      {props.logo && <div className="ReviewerPhoto__logo" style={logoStyles} />}
    </div>
  );
}

ReviewerPhoto.propTypes = {
  photo: PropTypes.string,
  logo: PropTypes.string,
  height: PropTypes.number,
};

ReviewerPhoto.defaultProps = {
  photo: null,
  logo: null,
  height: 220,
};

export default ReviewerPhoto;
