import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import baseInput from '../base-input/base-input';
import './logo.scss';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo-hooks';
import Loader from '../../loader/loader';
import { Grid, Cell } from '../../grid/grid';
import { useNotifications } from '../../../../hooks/notifications';
import Cropper from 'react-easy-crop';
import Button, { ButtonLoading } from '../../button/button';

const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'use-credentials'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = `${url}?t=3`;
  });

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */
export async function getCroppedImg(imageSrc, pixelCrop, getSignedUpload) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext('2d');

  ctx.fillStyle = '#ffffff';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(async file => {
      const url = await uploadCrop(file, getSignedUpload);
      // resolve(URL.createObjectURL(file));
      resolve(url);
    }, 'image/jpeg');
  });
}

async function uploadCrop(file, getSignedUpload) {
  try {
    const { data } = await getSignedUpload({
      variables: { key: 'crop.jpeg' },
    });

    const params = data.getSignedUpload;

    let formData = new FormData();

    formData.append('Policy', params.xAmzPolicy);
    formData.append('X-Amz-Algorithm', params.xAmzAlgorithm);
    formData.append('X-Amz-Credential', params.xAmzCredential);
    formData.append('X-Amz-Date', params.xAmzDate);
    if (params.xAmzSecurityToken)
      formData.append('X-Amz-Security-Token', params.xAmzSecurityToken);
    formData.append('X-Amz-Signature', params.xAmzSignature);
    formData.append('X-Amz-acl', 'public-read');
    formData.append('bucket', params.bucket);
    formData.append('key', params.key);

    // meta data...
    formData.append('x-amz-meta-uuid', params.user);

    formData.append('file', file);

    await fetch(process.env.REACT_APP_UPLOAD_BUCKET, {
      method: 'POST',
      body: formData,
    });

    const url = `${process.env.REACT_APP_UPLOAD_BUCKET}/${params.key}`;
    console.log(url);
    return url;
  } catch (e) {}
}

const GET_SIGNED_URL = gql`
  mutation($key: String) {
    getSignedUpload(key: $key) {
      xAmzPolicy
      xAmzAlgorithm
      xAmzCredential
      xAmzDate
      xAmzSecurityToken
      xAmzSignature
      xAmzMetaUser
      xAmzMetaJob
      bucket
      key
      user
      job
    }
  }
`;

export function LogoInput(props) {
  const notifications = useNotifications();
  const [preview, setPreview] = useState(props.value || null);
  const [loading, setLoading] = useState(false);
  const getSignedUpload = useMutation(GET_SIGNED_URL);
  const [croppedImage, setCroppedImage] = useState(
    props.crop && props.crop.url
      ? props.crop.url
      : props.value
      ? props.value
      : null
  );

  const minZoom = 0.2;

  const [cropper, setCropper] = useState({
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: props.cropType === 'round' ? 1 : 1.25,
  });

  const [cropping, setCropping] = useState(false);
  const [croppingLoading, setCroppingLoading] = useState(false);

  const onCropChange = crop => {
    setCropper(cropper => ({ ...cropper, crop }));
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedAreaPixels.width / croppedAreaPixels.height);
    setCropper(cropper => ({ ...cropper, croppedAreaPixels }));
  };

  const onZoomChange = zoom => {
    setCropper(cropper => ({ ...cropper, zoom }));
  };

  async function upload(file) {
    try {
      setLoading(true);
      const { data } = await getSignedUpload({
        variables: { key: file.name },
      });

      const params = data.getSignedUpload;

      let formData = new FormData();

      formData.append('Policy', params.xAmzPolicy);
      formData.append('X-Amz-Algorithm', params.xAmzAlgorithm);
      formData.append('X-Amz-Credential', params.xAmzCredential);
      formData.append('X-Amz-Date', params.xAmzDate);
      if (params.xAmzSecurityToken)
        formData.append('X-Amz-Security-Token', params.xAmzSecurityToken);
      formData.append('X-Amz-Signature', params.xAmzSignature);
      formData.append('X-Amz-acl', 'public-read');
      formData.append('bucket', params.bucket);
      formData.append('key', params.key);

      // meta data...
      formData.append('x-amz-meta-uuid', params.user);

      formData.append('file', file);

      await fetch(process.env.REACT_APP_UPLOAD_BUCKET, {
        method: 'POST',
        body: formData,
      });

      setPreview(`${process.env.REACT_APP_UPLOAD_BUCKET}/${params.key}`);
      props.onChange({
        target: {
          value: `${process.env.REACT_APP_UPLOAD_BUCKET}/${params.key}`,
          name: props.name,
        },
      });

      if (props.onBlur) {
        props.onBlur({
          target: {
            value: `${process.env.REACT_APP_UPLOAD_BUCKET}/${params.key}`,
            name: props.name,
          },
        });
      }

      setLoading(false);
      setCropping(true);
    } catch (e) {
      notifications.notify('There was an error uploading your image.', {
        error: true,
        time: 3000,
      });
      setLoading(false);
      setPreview(null);
    }
  }

  const onDrop = acceptedFiles => {
    var reader = new FileReader();

    reader.onload = function(e) {
      // setPreview(e.target.result);
      upload(acceptedFiles[0]);
    };
    reader.readAsDataURL(acceptedFiles[0]);
  };

  const onDropRejected = () => {
    notifications.notify('Only valid image files are accepted.', {
      error: true,
      time: 3000,
    });
  };

  const saveCrop = async () => {
    setCroppingLoading(true);
    const image = await getCroppedImg(
      preview,
      cropper.croppedAreaPixels,
      getSignedUpload
    );
    setCroppedImage(image);
    setCroppingLoading(false);
    setCropping(false);
    if (props.onCropSuccessMessage) {
      notifications.notify(props.onCropSuccessMessage, { time: 3000 });
    }

    if (props.onCropChange) {
      props.onCropChange({
        target: {
          name: props.cropName,
          value: {
            url: image,
            // ...cropper.croppedAreaPixels,
          },
        },
      });
    }

    if (props.onCropBlur) {
      props.onCropBlur({
        target: {
          name: props.cropName,
          value: {
            url: image,
            // ...cropper.croppedAreaPixels,
          },
        },
      });
    }
  };

  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    onDropAccepted: onDrop,
    single: true,
    accept: ['image/*'],
    onDropRejected,
  });

  return (
    <div className="LogoWrapper">
      {preview && !cropping && (
        <div className="CropButton">
          <Button onClick={e => inputRef.current.click()} size={'small'}>
            Replace Photo
          </Button>
          <Button onClick={e => setCropping(true)} size={'small'}>
            Edit Photo
          </Button>
        </div>
      )}
      {!cropping && (
        <div className="LogoInputContainer">
          <div className="help">
            File must be JPEG, GIF or PNG. Please upload <b>500px x 500px</b> or
            larger. <b>Square</b> images will look best.
          </div>
          <div className="LogoInput">
            <Grid margin>
              <Cell small={12} medium={4} className="text-center">
                <div onClick={e => setCropping(true)} className="Preview">
                  {!loading && croppedImage && (
                    <div>
                      {
                        <div
                          className="PreviewImage"
                          style={{
                            backgroundImage: `url(${croppedImage}?t=1)`,
                          }}
                        />
                      }
                      {/*<Cropper
                        restrictPosition={false}
                        minZoom={minZoom}
                        image={preview}
                        crop={cropper.crop}
                        zoom={cropper.zoom}
                        aspect={cropper.aspect}
                        cropShape={props.cropType || 'square'}
                        showGrid={false}
                        onCropChange={onCropChange}
                        onCropComplete={onCropComplete}
                        onZoomChange={onZoomChange}
                      />*/}
                    </div>
                  )}
                  {loading && <Loader />}
                </div>
              </Cell>
              <Cell small={12} medium={8}>
                <div className="LogoInput__container">
                  <div className="LogoInput__dropzone" {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      loading ? (
                        <p className="Paragraph">Uploading your image</p>
                      ) : (
                        <p className="Paragraph">Drop the files here ...</p>
                      )
                    ) : loading ? (
                      <div>
                        <p className="Paragraph">Uploading your image.</p>
                      </div>
                    ) : (
                      <p className="Paragraph">
                        <u>Browse</u> or drag and drop an image here to upload a
                        photo.
                      </p>
                    )}
                  </div>
                </div>
              </Cell>
            </Grid>
          </div>
        </div>
      )}
      {!loading && preview && cropping && (
        <Grid>
          <Cell small={12}>
            <div className="CroppingInput">
              <div className="help">
                Please drag to reposition your photo. You can also adjust the
                photo zoom by scrolling or pinching with two fingers.
              </div>
              <div className="CroppingInputContainer">
                <div
                  className="Cropper"
                  style={{ position: 'relative', width: '100%', height: 300 }}
                >
                  <Cropper
                    restrictPosition={false}
                    minZoom={minZoom}
                    image={`${preview}?t=2`}
                    crop={cropper.crop}
                    zoom={cropper.zoom}
                    aspect={cropper.aspect}
                    cropShape={props.cropType || 'square'}
                    showGrid={false}
                    onCropChange={onCropChange}
                    onCropComplete={onCropComplete}
                    onZoomChange={onZoomChange}
                  />
                </div>
                <div className="CroppingControls">
                  <Button
                    onClick={e => setCropping(false)}
                    hollow={true}
                    size={'small'}
                  >
                    Cancel
                  </Button>
                  <Button onClick={saveCrop} size={'small'}>
                    {croppingLoading ? (
                      <ButtonLoading>Saving</ButtonLoading>
                    ) : (
                      'Save Photo'
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </Cell>
        </Grid>
      )}
    </div>
  );
}

export default baseInput(LogoInput);
