import './input-error.scss';
import React from 'react';
import PropTypes from 'prop-types';

export function InputError(props) {
  return <div className="InputError">{props.message}</div>;
}

InputError.defaultProps = {
  message: '',
};

InputError.propTypes = {
  message: PropTypes.string,
};

export default InputError;
