import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './heading.scss';

export function Heading(props) {
  const Level = 'h' + props.level;
  const classes = classNames({
    Heading: true,
    'Heading--collapsed': props.collapsed,
  });
  return (
    <Level style={props.style || {}} className={classes}>
      {props.children}
    </Level>
  );
}

Heading.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  children: PropTypes.any,
  collapsed: PropTypes.bool,
};

Heading.defaultProps = {
  level: 1,
  children: null,
  collapsed: false,
};

export default Heading;
