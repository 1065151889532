import './invite-payout.scss';

import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { AppError } from '../../components/ui/errors/errors';
import pusher from '../../pusher/client';

import {
  Loader,
  Grid,
  Cell,
  Container,
  Heading,
  Button,
} from '../../components/ui';

export const WIDGET_URL = gql`
  query {
    getPaymentWidgetUrl
  }
`;

export function useListenForPaymentMethod() {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    console.log('use effect');
    var channel = pusher.subscribe('paymentrails');
    channel.bind('account-added', function(data) {
      console.log('ACCOUNT ADDED!');
      setEnabled(true);
    });
  }, [enabled, setEnabled]);

  return enabled;
}

export function InvitePayout() {
  const enabled = useListenForPaymentMethod();
  const { data, error, loading } = useQuery(WIDGET_URL);

  if (error) return <AppError />;
  if (loading) return <Loader />;

  return (
    <section id="ApplyPayments">
      <Container>
        <Grid align={'center'}>
          <Cell small={12} medium={6}>
            <Heading level={1}>
              <b>Payment</b> Details
            </Heading>
            <Heading level={2}>
              Tell us how you would like to receive your payments.
            </Heading>
          </Cell>
        </Grid>
        <Grid align={'center'}>
          <Cell auto small={12} medium={6}>
            <iframe
              title="paymentrails"
              width="100%"
              src={data.getPaymentWidgetUrl}
            />
          </Cell>
        </Grid>
        <Grid align={'center'}>
          <Cell auto small={12} medium={6}>
            <div className="text-right">
              <Button disabled={!enabled}>Next</Button>
            </div>
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export default InvitePayout;
