import React from 'react';
import { SEARCH_INVITES } from '../../../graphql/scouts';
import {
  Loader,
  Button,
  Grid,
  Cell,
  Container,
  Heading,
  Card,
  Pagination,
  SearchInput,
} from '../../../components/ui';
import { AdminSecondaryNav } from '../../../components/layout';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { useGlobalState } from '../../../store';

export function AdminReviewers(props) {
  return props.scouts.map(scout => {
    return (
      <Link key={scout.id} to={`/admin/invites/view/${scout.id}`}>
        <Card hover>
          <Heading level={3}>
            {scout.firstName} {scout.lastName}
          </Heading>
        </Card>
      </Link>
    );
  });
}

export function AdminReviewersLoader(props) {
  // const [page, setPage] = useState(1);
  const [filters, setFilters] = useGlobalState('adminFiltersInvites');

  const { data, loading, error } = useQuery(SEARCH_INVITES, {
    variables: {
      page: filters.page,
      search: { type: 'ScoutPerson', text: filters.text },
    },
    fetchPolicy: 'network-only',
  });

  function handleChange(event) {
    const key = event.target.name;
    const value = event.target.value;

    setFilters(filters => ({
      ...filters,
      [key]: value,
    }));
  }

  function setPage(page) {
    setFilters(filters => ({ ...filters, page }));
  }

  // if (loading) return <Loader />;
  if (error) return null;

  return (
    <div>
      <Grid margin>
        <Cell small={12} medium={12}>
          <SearchInput
            value={filters.text}
            name="text"
            onChange={handleChange}
            spaced
            placeholder="Search Invites"
          />
        </Cell>
      </Grid>

      {!loading ? (
        <AdminReviewers scouts={data.searchPersonInvites.results} />
      ) : (
        <Loader />
      )}

      <div className="text-center">
        {!loading && data.searchPersonInvites.pages > 1 && (
          <Pagination
            currentPage={data.searchPersonInvites.page}
            totalItems={data.searchPersonInvites.total}
            pageSize={data.searchPersonInvites.limit}
            maxPages={data.searchPersonInvites.pages}
            onPageChange={page => setPage(page)}
          />
        )}
      </div>
    </div>
  );
}

export function AdminReviewersWrapper(props) {
  return (
    <Container>
      <Grid>
        <Cell small={6}>
          <Heading level={1}>
            <b>Vintro</b> Admin
          </Heading>
        </Cell>
        <Cell small={6}>
          <div className="text-right">
            <Link to="/admin/invites/new">
              <Button>Add Invite</Button>
            </Link>
          </div>
        </Cell>
        <Cell>
          <hr />
        </Cell>
      </Grid>
      <Grid margin>
        <Cell small={12} medium={3}>
          <AdminSecondaryNav />
        </Cell>
        <Cell small={12} medium={9}>
          <AdminReviewersLoader />
        </Cell>
      </Grid>
    </Container>
  );
}

export default AdminReviewersWrapper;
