import React from 'react';

export function WorldIcon() {
  return (
    <svg width="16px" height="15px" viewBox="0 0 16 15">
      <g
        id="Desktop"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <g
          id="Expert-Profile"
          transform="translate(-95.000000, -597.000000)"
          stroke="#7480AE"
        >
          <g id="Associated" transform="translate(96.000000, 576.000000)">
            <g id="icon-/-world" transform="translate(0.000000, 21.000000)">
              <g id="network-users">
                <circle id="Oval" cx="7" cy="7.5" r="6.75"></circle>
                <line
                  x1="0.634"
                  y1="9.75"
                  x2="13.366"
                  y2="9.75"
                  id="Path"
                ></line>
                <line
                  x1="0.634"
                  y1="5.25"
                  x2="13.366"
                  y2="5.25"
                  id="Path"
                ></line>
                <path
                  d="M5.544,0.908 C4.33272801,5.27909489 4.51095978,9.91871252 6.054,14.184"
                  id="Path"
                ></path>
                <path
                  d="M8.456,0.908 C8.98478748,2.81022975 9.25191126,4.77564173 9.25,6.75 C9.25419899,9.2849097 8.81421321,11.8009515 7.95,14.184"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default WorldIcon;
