export default [
    {
        "code":10,
        "name":"Energy",
        "type":"sector",
        "list":[
            {
                "code":10101010,
                "name":"Oil & Gas Drilling",
                "type":"subindustry"
            },
            {
                "code":10101020,
                "name":"Oil & Gas Equipment & Services",
                "type":"subindustry"
            },
            {
                "code":10102010,
                "name":"Integrated Oil & Gas",
                "type":"subindustry"
            },
            {
                "code":10102020,
                "name":"Oil & Gas Exploration & Production",
                "type":"subindustry"
            },
            {
                "code":10102030,
                "name":"Oil & Gas Refining & Marketing",
                "type":"subindustry"
            },
            {
                "code":10102040,
                "name":"Oil & Gas Storage & Transportation",
                "type":"subindustry"
            },
            {
                "code":10102050,
                "name":"Coal & Consumable Fuels",
                "type":"subindustry"
            }
        ]
    },
    {
        "code":15,
        "name":"Materials",
        "type":"sector",
        "list":[
            {
                "code":15101010,
                "name":"Commodity Chemicals",
                "type":"subindustry"
            },
            {
                "code":15101020,
                "name":"Diversified Chemicals",
                "type":"subindustry"
            },
            {
                "code":15101030,
                "name":"Fertilizers & Agricultural Chemicals",
                "type":"subindustry"
            },
            {
                "code":15101040,
                "name":"Industrial Gases",
                "type":"subindustry"
            },
            {
                "code":15101050,
                "name":"Specialty Chemicals",
                "type":"subindustry"
            },
            {
                "code":15102010,
                "name":"Construction Materials",
                "type":"subindustry"
            },
            {
                "code":15103010,
                "name":"Metal & Glass Containers",
                "type":"subindustry"
            },
            {
                "code":15103020,
                "name":"Paper Packaging",
                "type":"subindustry"
            },
            {
                "code":15104010,
                "name":"Aluminum",
                "type":"subindustry"
            },
            {
                "code":15104020,
                "name":"Diversified Metals & Mining",
                "type":"subindustry"
            },
            {
                "code":15104025,
                "name":"Copper",
                "type":"subindustry"
            },
            {
                "code":15104030,
                "name":"Gold",
                "type":"subindustry"
            },
            {
                "code":15104040,
                "name":"Precious Metals & Minerals",
                "type":"subindustry"
            },
            {
                "code":15104045,
                "name":"Silver",
                "type":"subindustry"
            },
            {
                "code":15104050,
                "name":"Steel",
                "type":"subindustry"
            },
            {
                "code":15105010,
                "name":"Forest Products",
                "type":"subindustry"
            },
            {
                "code":15105020,
                "name":"Paper Products",
                "type":"subindustry"
            }
        ]
    },
    {
        "code":20,
        "name":"Industrials",
        "type":"sector",
        "list":[
            {
                "code":20101010,
                "name":"Aerospace & Defense",
                "type":"subindustry"
            },
            {
                "code":20102010,
                "name":"Building Products",
                "type":"subindustry"
            },
            {
                "code":20103010,
                "name":"Construction & Engineering",
                "type":"subindustry"
            },
            {
                "code":20104010,
                "name":"Electrical Components & Equipment",
                "type":"subindustry"
            },
            {
                "code":20104020,
                "name":"Heavy Electrical Equipment",
                "type":"subindustry"
            },
            {
                "code":20105010,
                "name":"Industrial Conglomerates",
                "type":"subindustry"
            },
            {
                "code":20106010,
                "name":"Construction Machinery & Heavy Trucks",
                "type":"subindustry"
            },
            {
                "code":20106015,
                "name":"Agricultural & Farm Machinery",
                "type":"subindustry"
            },
            {
                "code":20106020,
                "name":"Industrial Machinery",
                "type":"subindustry"
            },
            {
                "code":20107010,
                "name":"Trading Companies & Distributors",
                "type":"subindustry"
            },
            {
                "code":20201010,
                "name":"Commercial Printing",
                "type":"subindustry"
            },
            {
                "code":20201050,
                "name":"Environmental & Facilities Services",
                "type":"subindustry"
            },
            {
                "code":20201060,
                "name":"Office Services & Supplies",
                "type":"subindustry"
            },
            {
                "code":20201070,
                "name":"Diversified Support Services",
                "type":"subindustry"
            },
            {
                "code":20201080,
                "name":"Security & Alarm Services",
                "type":"subindustry"
            },
            {
                "code":20202010,
                "name":"Human Resource & Employment Services",
                "type":"subindustry"
            },
            {
                "code":20202020,
                "name":"Research & Consulting Services",
                "type":"subindustry"
            },
            {
                "code":20301010,
                "name":"Air Freight & Logistics",
                "type":"subindustry"
            },
            {
                "code":20302010,
                "name":"Airlines",
                "type":"subindustry"
            },
            {
                "code":20303010,
                "name":"Marine",
                "type":"subindustry"
            },
            {
                "code":20304010,
                "name":"Railroads",
                "type":"subindustry"
            },
            {
                "code":20304020,
                "name":"Trucking",
                "type":"subindustry"
            },
            {
                "code":20305010,
                "name":"Airport Services",
                "type":"subindustry"
            },
            {
                "code":20305020,
                "name":"Highways & Railtracks",
                "type":"subindustry"
            },
            {
                "code":20305030,
                "name":"Marine Ports & Services",
                "type":"subindustry"
            }
        ]
    },
    {
        "code":25,
        "name":"Consumer Discretionary",
        "type":"sector",
        "list":[
            {
                "code":25101010,
                "name":"Auto Parts & Equipment",
                "type":"subindustry"
            },
            {
                "code":25101020,
                "name":"Tires & Rubber",
                "type":"subindustry"
            },
            {
                "code":25102010,
                "name":"Automobile Manufacturers",
                "type":"subindustry"
            },
            {
                "code":25102020,
                "name":"Motorcycle Manufacturers",
                "type":"subindustry"
            },
            {
                "code":25201010,
                "name":"Consumer Electronics",
                "type":"subindustry"
            },
            {
                "code":25201020,
                "name":"Home Furnishings",
                "type":"subindustry"
            },
            {
                "code":25201030,
                "name":"Homebuilding",
                "type":"subindustry"
            },
            {
                "code":25201040,
                "name":"Household Appliances",
                "type":"subindustry"
            },
            {
                "code":25201050,
                "name":"Housewares & Specialties",
                "type":"subindustry"
            },
            {
                "code":25202010,
                "name":"Leisure Products",
                "type":"subindustry"
            },
            {
                "code":25203010,
                "name":"Apparel, Accessories & Luxury Goods",
                "type":"subindustry"
            },
            {
                "code":25203020,
                "name":"Footwear",
                "type":"subindustry"
            },
            {
                "code":25203030,
                "name":"Textiles",
                "type":"subindustry"
            },
            {
                "code":25301010,
                "name":"Casinos & Gaming",
                "type":"subindustry"
            },
            {
                "code":25301020,
                "name":"Hotels, Resorts & Cruise Lines",
                "type":"subindustry"
            },
            {
                "code":25301030,
                "name":"Leisure Facilities",
                "type":"subindustry"
            },
            {
                "code":25301040,
                "name":"Restaurants",
                "type":"subindustry"
            },
            {
                "code":25302010,
                "name":"Education Services",
                "type":"subindustry"
            },
            {
                "code":25302020,
                "name":"Specialized Consumer Services",
                "type":"subindustry"
            },
            {
                "code":25501010,
                "name":"Distributors",
                "type":"subindustry"
            },
            {
                "code":25502020,
                "name":"Internet & Direct Marketing Retail",
                "type":"subindustry"
            },
            {
                "code":25503010,
                "name":"Department Stores",
                "type":"subindustry"
            },
            {
                "code":25503020,
                "name":"General Merchandise Stores",
                "type":"subindustry"
            },
            {
                "code":25504010,
                "name":"Apparel Retail",
                "type":"subindustry"
            },
            {
                "code":25504020,
                "name":"Computer & Electronics Retail",
                "type":"subindustry"
            },
            {
                "code":25504030,
                "name":"Home Improvement Retail",
                "type":"subindustry"
            },
            {
                "code":25504040,
                "name":"Specialty Stores",
                "type":"subindustry"
            },
            {
                "code":25504050,
                "name":"Automotive Retail",
                "type":"subindustry"
            },
            {
                "code":25504060,
                "name":"Homefurnishing Retail",
                "type":"subindustry"
            }
        ]
    },
    {
        "code":30,
        "name":"Consumer Staples",
        "type":"sector",
        "list":[
            {
                "code":30101010,
                "name":"Drug Retail",
                "type":"subindustry"
            },
            {
                "code":30101020,
                "name":"Food Distributors",
                "type":"subindustry"
            },
            {
                "code":30101030,
                "name":"Food Retail",
                "type":"subindustry"
            },
            {
                "code":30101040,
                "name":"Hypermarkets & Super Centers",
                "type":"subindustry"
            },
            {
                "code":30201010,
                "name":"Brewers",
                "type":"subindustry"
            },
            {
                "code":30201020,
                "name":"Distillers & Vintners",
                "type":"subindustry"
            },
            {
                "code":30201030,
                "name":"Soft Drinks",
                "type":"subindustry"
            },
            {
                "code":30202010,
                "name":"Agricultural Products",
                "type":"subindustry"
            },
            {
                "code":30202030,
                "name":"Packaged Foods & Meats",
                "type":"subindustry"
            },
            {
                "code":30203010,
                "name":"Tobacco",
                "type":"subindustry"
            },
            {
                "code":30301010,
                "name":"Household Products",
                "type":"subindustry"
            },
            {
                "code":30302010,
                "name":"Personal Products",
                "type":"subindustry"
            }
        ]
    },
    {
        "code":35,
        "name":"Health Care",
        "type":"sector",
        "list":[
            {
                "code":35101010,
                "name":"Health Care Equipment",
                "type":"subindustry"
            },
            {
                "code":35101020,
                "name":"Health Care Supplies",
                "type":"subindustry"
            },
            {
                "code":35102010,
                "name":"Health Care Distributors",
                "type":"subindustry"
            },
            {
                "code":35102015,
                "name":"Health Care  Services",
                "type":"subindustry"
            },
            {
                "code":35102020,
                "name":"Health Care Facilities",
                "type":"subindustry"
            },
            {
                "code":35102030,
                "name":"Managed Health Care",
                "type":"subindustry"
            },
            {
                "code":35103010,
                "name":"Health Care Technology",
                "type":"subindustry"
            },
            {
                "code":35201010,
                "name":"Biotechnology",
                "type":"subindustry"
            },
            {
                "code":35202010,
                "name":"Pharmaceuticals",
                "type":"subindustry"
            },
            {
                "code":35203010,
                "name":"Life Sciences Tools & Services",
                "type":"subindustry"
            }
        ]
    },
    {
        "code":40,
        "name":"Financials",
        "type":"sector",
        "list":[
            {
                "code":40101010,
                "name":"Diversified Banks",
                "type":"subindustry"
            },
            {
                "code":40101015,
                "name":"Regional Banks",
                "type":"subindustry"
            },
            {
                "code":40102010,
                "name":"Thrifts & Mortgage Finance",
                "type":"subindustry"
            },
            {
                "code":40201020,
                "name":"Other Diversified Financial Services",
                "type":"subindustry"
            },
            {
                "code":40201030,
                "name":"Multi-Sector Holdings",
                "type":"subindustry"
            },
            {
                "code":40201040,
                "name":"Specialized Finance",
                "type":"subindustry"
            },
            {
                "code":40202010,
                "name":"Consumer Finance",
                "type":"subindustry"
            },
            {
                "code":40203010,
                "name":"Asset Management & Custody Banks",
                "type":"subindustry"
            },
            {
                "code":40203020,
                "name":"Investment Banking & Brokerage",
                "type":"subindustry"
            },
            {
                "code":40203030,
                "name":"Diversified Capital Markets",
                "type":"subindustry"
            },
            {
                "code":40203040,
                "name":"Financial Exchanges & Data",
                "type":"subindustry"
            },
            {
                "code":40204010,
                "name":"Mortgage REITs",
                "type":"subindustry"
            },
            {
                "code":40301010,
                "name":"Insurance Brokers",
                "type":"subindustry"
            },
            {
                "code":40301020,
                "name":"Life & Health Insurance",
                "type":"subindustry"
            },
            {
                "code":40301030,
                "name":"Multi-line Insurance",
                "type":"subindustry"
            },
            {
                "code":40301040,
                "name":"Property & Casualty Insurance",
                "type":"subindustry"
            },
            {
                "code":40301050,
                "name":"Reinsurance",
                "type":"subindustry"
            },
            {
                "code":40990001,
                "name":"Hedge Fund",
                "type":"subindustry"
            },
            {
                "code":40990002,
                "name":"Private Equity",
                "type":"subindustry"
            }
        ]
    },
    {
        "code":45,
        "name":"Information Technology",
        "type":"sector",
        "list":[
            {
                "code":45102010,
                "name":"IT Consulting & Other Services",
                "type":"subindustry"
            },
            {
                "code":45102020,
                "name":"Data Processing & Outsourced Services",
                "type":"subindustry"
            },
            {
                "code":45102030,
                "name":"Internet Services & Infrastructure",
                "type":"subindustry"
            },
            {
                "code":45103010,
                "name":"Application Software",
                "type":"subindustry"
            },
            {
                "code":45103020,
                "name":"Systems Software",
                "type":"subindustry"
            },
            {
                "code":45201020,
                "name":"Communications Equipment",
                "type":"subindustry"
            },
            {
                "code":45202030,
                "name":"Technology Hardware, Storage & Peripherals",
                "type":"subindustry"
            },
            {
                "code":45203010,
                "name":"Electronic Equipment & Instruments",
                "type":"subindustry"
            },
            {
                "code":45203015,
                "name":"Electronic Components",
                "type":"subindustry"
            },
            {
                "code":45203020,
                "name":"Electronic Manufacturing Services",
                "type":"subindustry"
            },
            {
                "code":45203030,
                "name":"Technology Distributors",
                "type":"subindustry"
            },
            {
                "code":45301010,
                "name":"Semiconductor Equipment",
                "type":"subindustry"
            },
            {
                "code":45301020,
                "name":"Semiconductors",
                "type":"subindustry"
            }
        ]
    },
    {
        "code":50,
        "name":"Communication Services",
        "type":"sector",
        "list":[
            {
                "code":50101010,
                "name":"Alternative Carriers",
                "type":"subindustry"
            },
            {
                "code":50101020,
                "name":"Integrated Telecommunication Services",
                "type":"subindustry"
            },
            {
                "code":50102010,
                "name":"Wireless Telecommunication Services",
                "type":"subindustry"
            },
            {
                "code":50201010,
                "name":"Advertising",
                "type":"subindustry"
            },
            {
                "code":50201020,
                "name":"Broadcasting",
                "type":"subindustry"
            },
            {
                "code":50201030,
                "name":"Cable & Satellite",
                "type":"subindustry"
            },
            {
                "code":50201040,
                "name":"Publishing",
                "type":"subindustry"
            },
            {
                "code":50202010,
                "name":"Movies & Entertainment",
                "type":"subindustry"
            },
            {
                "code":50202020,
                "name":"Interactive Home Entertainment",
                "type":"subindustry"
            },
            {
                "code":50203010,
                "name":"Interactive Media & Services",
                "type":"subindustry"
            }
        ]
    },
    {
        "code":55,
        "name":"Utilities",
        "type":"sector",
        "list":[
            {
                "code":55101010,
                "name":"Electric Utilities",
                "type":"subindustry"
            },
            {
                "code":55102010,
                "name":"Gas Utilities",
                "type":"subindustry"
            },
            {
                "code":55103010,
                "name":"Multi-Utilities",
                "type":"subindustry"
            },
            {
                "code":55104010,
                "name":"Water Utilities",
                "type":"subindustry"
            },
            {
                "code":55105010,
                "name":"Independent Power Producers & Energy Traders",
                "type":"subindustry"
            },
            {
                "code":55105020,
                "name":"Renewable Electricity",
                "type":"subindustry"
            }
        ]
    },
    {
        "code":60,
        "name":"Real Estate",
        "type":"sector",
        "list":[
            {
                "code":60101010,
                "name":"Diversified REITs",
                "type":"subindustry"
            },
            {
                "code":60101020,
                "name":"Industrial REITs",
                "type":"subindustry"
            },
            {
                "code":60101030,
                "name":"Hotel & Resort REITs",
                "type":"subindustry"
            },
            {
                "code":60101040,
                "name":"Office REITs",
                "type":"subindustry"
            },
            {
                "code":60101050,
                "name":"Health Care REITs",
                "type":"subindustry"
            },
            {
                "code":60101060,
                "name":"Residential REITs",
                "type":"subindustry"
            },
            {
                "code":60101070,
                "name":"Retail REITs",
                "type":"subindustry"
            },
            {
                "code":60101080,
                "name":"Specialized REITs",
                "type":"subindustry"
            },
            {
                "code":60102010,
                "name":"Diversified Real Estate Activities",
                "type":"subindustry"
            },
            {
                "code":60102020,
                "name":"Real Estate Operating Companies",
                "type":"subindustry"
            },
            {
                "code":60102030,
                "name":"Real Estate Development",
                "type":"subindustry"
            },
            {
                "code":60102040,
                "name":"Real Estate Services",
                "type":"subindustry"
            }
        ]
    }
]