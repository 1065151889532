import React, { useState } from 'react';

import {
  Loader,
  Grid,
  Cell,
  Container,
  Heading,
  Card,
  Pagination,
  SearchInput,
} from '../../../components/ui';
import { AdminSecondaryNav } from '../../../components/layout';
import { useQuery } from 'react-apollo-hooks';
import { Link } from 'react-router-dom';

import { SEARCH_AFFILIATES } from '../../../graphql/affiliates';
import { Tags, Tag } from '../../../components/ui/tags/tags';

export function AffiliateCard(props) {
  const { affiliate } = props;

  return (
    <Link key={affiliate.id} to={`/admin/affiliates/view/${affiliate.id}`}>
      <Card hover>
        <Heading level={3}>{affiliate.shortid}</Heading>
        {affiliate.network && (
          <div>
            <Tags>
              <Tag>{affiliate.network.name}</Tag>
            </Tags>
          </div>
        )}
        {affiliate.parent && (
          <div>
            <Tags>
              <Tag>{affiliate.parent.shortid}</Tag>
            </Tags>
          </div>
        )}
      </Card>
    </Link>
  );
}

export function AdminAffiliates(props) {
  return props.scouts.map(scout => {
    return <AffiliateCard affiliate={scout} />;
  });
}

export function AdminReviewersLoader(props) {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    text: '',
    industry: '',
    subIndusttry: '',
  });

  const { data, loading, error } = useQuery(SEARCH_AFFILIATES, {
    variables: {
      page: page,
      search: {},
    },
  });

  function handleChange(event) {
    const key = event.target.name;
    const value = event.target.value;

    setFilters(filters => ({
      ...filters,
      [key]: value,
    }));
  }

  // if (loading) return <Loader />;
  if (error) return null;

  return (
    <div>
      <Grid margin>
        <Cell small={12} medium={12}>
          <SearchInput
            value={filters.text}
            name="text"
            onChange={handleChange}
            spaced
            placeholder="Search Affiliates"
          />
        </Cell>
      </Grid>

      {!loading ? (
        <AdminAffiliates scouts={data.searchAffiliates.results} />
      ) : (
        <Loader />
      )}

      <div className="text-center">
        {!loading && data.searchAffiliates.pages > 1 && (
          <Pagination
            currentPage={data.searchAffiliates.page}
            totalItems={data.searchAffiliates.total}
            pageSize={data.searchAffiliates.limit}
            maxPages={data.searchAffiliates.pages}
            onPageChange={page => setPage(page)}
          />
        )}
      </div>
    </div>
  );
}

export function AdminReviewersWrapper(props) {
  return (
    <Container>
      <Grid>
        <Cell small={6}>
          <Heading level={1}>
            <b>Vintro</b> Admin
          </Heading>
        </Cell>
        <Cell small={6}>
          {/*<div className="text-right">
            <Link to="/admin/reviewers/new">
              <Button>Add Invite</Button>
            </Link>
  </div>*/}
        </Cell>
        <Cell>
          <hr />
        </Cell>
      </Grid>
      <Grid margin>
        <Cell small={12} medium={3}>
          <AdminSecondaryNav />
        </Cell>
        <Cell small={12} medium={9}>
          <AdminReviewersLoader />
        </Cell>
      </Grid>
    </Container>
  );
}

export default AdminReviewersWrapper;
