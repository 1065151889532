export function getRelevantGraphQLError(e) {
  if (e && e.graphQLErrors && e.graphQLErrors[0]) {
    return {
      message: e.graphQLErrors[0].message,
      code: e.graphQLErrors[0].code,
    };
  }

  return { message: `Something wen't wrong`, code: 500 };
}
