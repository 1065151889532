import React from 'react';
import PropTypes from 'prop-types';
import './strong-text.scss';

export function StrongText(props) {
  return <strong className="StrongText">{props.children}</strong>;
}

StrongText.propTypes = {
  children: PropTypes.string.isRequired,
};

export default StrongText;
