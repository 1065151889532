import React from 'react';
import { SEARCH } from '../../../graphql/scouts';
import {
  Loader,
  Grid,
  Cell,
  Container,
  Heading,
  Card,
  Pagination,
  SearchInput,
} from '../../../components/ui';
import { AdminSecondaryNav } from '../../../components/layout';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import './reviewers.scss';
import { useGlobalState } from '../../../store';
import SelectInput from '../../../components/ui/inputs/select/select';
import { Tags, Tag } from '../../../components/ui/tags/tags';
import moment from 'moment';

const statusOptions = [
  {
    label: 'Approved',
    value: true,
  },
  {
    label: 'Pending Approval',
    value: false,
  },
];

export function AdminReviewers(props) {
  return props.scouts.map(scout => {
    return (
      <Link key={scout.id} to={`/admin/reviewers/view/${scout.id}`}>
        <Card hover>
          <Heading level={3}>
            {scout.firstName} {scout.lastName}
          </Heading>
          <Tags>
            <Tag>
              Created at: {moment(scout.createdAt).format('MM/DD/YYYY hh:mm a')}
            </Tag>
          </Tags>
        </Card>
      </Link>
    );
  });
}

export function AdminReviewersLoader(props) {
  // const [page, setPage] = useState(1);
  const [filters, setFilters] = useGlobalState('adminFiltersPeople');

  const variables = {
    page: filters.page,
    search: {
      type: 'ScoutPerson',
      text: filters.text,
    },
    sort: {
      on: 'CREATED',
      order: 'DESC',
    },
  };

  if (filters.approved) {
    variables.approved =
      filters.approved === 'true'
        ? true
        : filters.approved === 'false'
        ? false
        : null;
  }

  const { data, loading, error } = useQuery(SEARCH, {
    variables,
    fetchPolicy: 'network-only',
  });

  function setPage(page) {
    setFilters(filters => ({ ...filters, page }));
  }

  function handleChange(event) {
    const key = event.target.name;
    const value = event.target.value;

    setFilters(filters => ({
      ...filters,
      [key]: value,
      page: 1,
    }));
  }

  // if (loading) return <Loader />;
  if (error) return null;

  return (
    <div>
      <Grid margin>
        <Cell small={12} medium={8}>
          <SearchInput
            value={filters.text}
            name="text"
            onChange={handleChange}
            spaced
            placeholder="Search People, Companies, Locations"
          />
        </Cell>
        <Cell small={12} medium={4}>
          <SelectInput
            name={'approved'}
            value={filters.approved}
            options={statusOptions}
            onChange={handleChange}
            emptyOption="Status"
          />
        </Cell>
      </Grid>

      {!loading ? <AdminReviewers scouts={data.search.results} /> : <Loader />}

      <div className="text-center">
        {!loading && (
          <Pagination
            currentPage={data.search.page}
            totalItems={data.search.total}
            pageSize={data.search.limit}
            maxPages={data.search.pages}
            onPageChange={page => setPage(page)}
          />
        )}
      </div>
    </div>
  );
}

export function AdminReviewersWrapper(props) {
  return (
    <Container>
      <Grid>
        <Cell small={6}>
          <Heading level={1}>
            <b>Vintro</b> Admin
          </Heading>
        </Cell>
        <Cell small={6}>
          {/*<div className="text-right">
            <Link to="/admin/reviewers/new">
              <Button>Add Reviewer</Button>
            </Link>
  </div>*/}
        </Cell>
        <Cell>
          <hr />
        </Cell>
      </Grid>
      <Grid margin>
        <Cell small={12} medium={3}>
          <AdminSecondaryNav />
        </Cell>
        <Cell small={12} medium={9}>
          <AdminReviewersLoader />
        </Cell>
      </Grid>
    </Container>
  );
}

export default AdminReviewersWrapper;
