import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Grid, Cell, Image } from '../../ui';
import './chat-instance.scss';
import { useSession } from '../../../hooks/session';

export function useGetChannelMember(channel) {
  const session = useSession();
  const members = channel.members.filter(member => {
    return member.userId !== session.profile.id;
  });

  return members[0];
}

export function ChatInstance(props) {
  const classes = classNames({
    ChatInstance: true,
    'ChatInstance--isActive': props.active,
  });

  const { channel } = props;
  const member = useGetChannelMember(channel);

  return (
    <div className={classes} onClick={props.handleClick}>
      <div className="ChatInstance__content">
        <Grid>
          <Cell shrink>
            <div className="ChatInstance__image">
              {channel.unreadMessageCount > 0 && (
                <div className="ChatInstance__indicator">
                  {channel.unreadMessageCount}
                </div>
              )}
              {member.profileUrl && (
                <Image maxWidth={50} round src={member.profileUrl} />
              )}
            </div>
          </Cell>
          <Cell auto>
            <h5 className="ChatInstance__heading">{member.nickname}</h5>
            {channel.lastMessage && (
              <p className="ChatInstance__description">
                {channel.lastMessage.message}
              </p>
            )}
          </Cell>
        </Grid>
      </div>
    </div>
  );
}

ChatInstance.propTypes = {
  active: PropTypes.bool,
  handleClick: PropTypes.func,
};

ChatInstance.defaultProps = {
  active: false,
  handleClick: () => null,
};

export default ChatInstance;
