import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';

export const SEARCH_ORDERS = gql`
  query SEARCH_ORDERS($search: OrderSearchInput) {
    searchOrders(search: $search) {
      total
      orders {
        id
        price
        createdAt
        updatedAt
        items {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const ADD_ORDER = gql`
  mutation ADD_ORDER($input: AddOrderInput) {
    addOrder(input: $input) {
      id
    }
  }
`;

export const MY_ORDERS = gql`
  query MY_ORDERS {
    myOrders {
      orders {
        createdAt
        updatedAt
        id
        pitch {
          id
          name
        }
        requests {
          id
          status
          fulfilled
        }
      }
    }
  }
`;

export function useGetMyOrders(id) {
  const { error, loading, data } = useQuery(MY_ORDERS, {
    fetchPolicy: 'network-only',
  });

  return { error, loading, data };
}

export const GET_ORDER = gql`
  query GET_ORDER($id: ID!) {
    getOrder(id: $id) {
      id
      items {
        id
        firstName
        lastName
        price
        photo
        title
        company
        averageRating
      }
    }
  }
`;

export function useGetOrder(id) {
  const { error, loading, data } = useQuery(GET_ORDER, { variables: { id } });

  return { error, loading, data };
}

export const GET_ORDER_REQUEST = gql`
  query GET_ORDER($id: ID!) {
    getOrderRequest(id: $id) {
      id
      status
      order {
        id
        pitch {
          id
          name
          description
          industry {
            id
            name
          }
          subIndustry {
            id
            name
          }
          job {
            id
            video {
              url
              width
              height
            }
            thumbnail {
              url
            }
          }
        }
        venture {
          id
          name
          description
          city
          state
          country
          team {
            firstName
            lastName
            bio
            photo
            title
            social {
              linkedIn
            }
          }
        }
      }
    }
  }
`;

export function useGetOrderRequest(id) {
  const { error, loading, data } = useQuery(GET_ORDER_REQUEST, {
    variables: { id },
  });

  return { error, loading, data };
}

export const GET_ORDER_DETAILS = gql`
  query GET_ORDER_DETAILS($id: ID!) {
    getOrder(id: $id) {
      id
      status

      items {
        id
        firstName
        lastName
        price
        photo
        title
        company
        averageRating
      }
      requests {
        status
        fulfilled
        id
        scout {
          id
          firstName
          lastName
          photo
          company
          companyLogo
          title
        }
        response {
          comment
          pitchQuality
          commercialViability
          type
          job {
            id
            status
            thumbnail {
              url
            }
            video {
              width
              height
              url
            }
            audio {
              url
            }
          }
        }
      }
    }
  }
`;

export function useGetOrderDetails(id) {
  const { error, loading, data } = useQuery(GET_ORDER_DETAILS, {
    variables: { id },
  });

  return { error, loading, data };
}

export const GET_ADMIN_ORDER_DETAILS = gql`
  query GET_ADMIN_ORDER_DETAILS($id: ID!) {
    getOrder(id: $id) {
      id
      status
      pitch {
        id
        name
        description
        industry {
          id
          name
        }
        subIndustry {
          id
          name
        }
        job {
          id
          video {
            url
            width
            height
          }
        }
      }
      venture {
        id
        name
        description
        city
        state
        country
        team {
          firstName
          lastName
          bio
          photo
          title
          social {
            linkedIn
          }
        }
      }
      items {
        id
        firstName
        lastName
        price
        photo
        title
        company
      }
      requests {
        id
        status
        price
        scout {
          id
          firstName
          lastName
          photo
          title
          company
        }
      }
    }
  }
`;

export function useGetAdminOrderDetail(id) {
  const { error, loading, data } = useQuery(GET_ADMIN_ORDER_DETAILS, {
    variables: { id },
  });

  return { error, loading, data };
}

export const APPROVE_ORDER = gql`
  mutation APPROVE_ORDER($id: ID!) {
    approveOrder(id: $id) {
      id
      status
      requests {
        id
        status
      }
    }
  }
`;

export function useApproveOrder(id) {
  const [approveOrder] = useMutation(APPROVE_ORDER, { variables: { id } });

  async function exec() {
    await approveOrder();
  }

  return exec;
}

export const CANCEL_ORDER = gql`
  mutation CANCEL_ORDER($id: ID) {
    cancelOrder(id: $id) {
      id
    }
  }
`;

export function useCancelOrder(id) {
  const [cancelOrder] = useMutation(CANCEL_ORDER, { variables: { id } });

  async function exec() {
    await cancelOrder();
  }

  return exec;
}

export const CANCEL_ORDER_REQUEST = gql`
  mutation CANCEL_ORDER_REQUEST($id: ID) {
    cancelOrderRequest(id: $id) {
      id
      status
    }
  }
`;

export function useCancelOrderRequest(id, orderId) {
  const [cancelOrderRequest] = useMutation(CANCEL_ORDER_REQUEST, {
    variables: { id },
    refetchQueries: [
      { query: GET_ADMIN_ORDER_DETAILS, variables: { id: orderId } },
    ],
  });

  async function exec() {
    await cancelOrderRequest();
  }

  return exec;
}

export const FULFILL_ORDER_REQUEST = gql`
  mutation FULFILL_ORDER_REQUEST($id: ID!, $input: OrderRequestResponseInput) {
    fulfillOrderRequest(id: $id, input: $input) {
      id
    }
  }
`;

export function useFulfillOrderRequest() {
  const [fulfillOrderRequest] = useMutation(FULFILL_ORDER_REQUEST);

  async function exec(id, input) {
    return await fulfillOrderRequest({ variables: { id, input } });
  }

  return exec;
}

export const REJECT_ORDER_REQUEST = gql`
  mutation REJECT_ORDER_REQUEST($id: ID!, $reason: String) {
    rejectOrderRequest(id: $id, reason: $reason) {
      id
    }
  }
`;

export function useRejectOrderRequest() {
  const [rejectOrderRequest] = useMutation(REJECT_ORDER_REQUEST);

  async function exec(id, reason) {
    return await rejectOrderRequest({ variables: { id, reason } });
  }

  return exec;
}

export const RATE_ORDER_REQUEST = gql`
  mutation RATE_ORDER_REQUEST($id: ID!, $rating: Int) {
    rateOrderRequest(id: $id, rating: $rating) {
      id
      status
    }
  }
`;

export function useRateOrderRequest() {
  const [rateOrderRequest] = useMutation(RATE_ORDER_REQUEST);

  async function exec(id, rating) {
    await rateOrderRequest({ variables: { id, rating } });
  }

  return exec;
}
