import React from 'react';
import {
  Tab as ReactTab,
  Tabs as ReactTabs,
  TabList as ReactTabList,
  TabPanel as ReactTabPanel,
} from 'react-tabs';
import './tabs.scss';

export function Tab(props) {
  return <ReactTab {...props} className={'Tabs__header'} />;
}

export function Tabs(props) {
  return (
    <ReactTabs
      {...props}
      className={'Tabs'}
      selectedTabClassName={'Tabs__header--active'}
      selectedTabPanelClassName={'Tabs__panel--active'}
    />
  );
}

export function TabList(props) {
  return <ReactTabList {...props} className={'Tabs__list'} />;
}

export function TabPanel(props) {
  return <ReactTabPanel {...props} className={'Tabs__panel'} />;
}

Tab.tabsRole = 'Tab';
Tabs.tabsRole = 'Tabs';
TabList.tabsRole = 'TabList';
TabPanel.tabsRole = 'TabPanel';

export default {
  Tab,
  Tabs,
  TabList,
  TabPanel,
};
