import React from 'react';
import './chat-mobile-toggle.scss';

export function ChatMobileToggle(props) {
  const buttonText = props.menuOpen ? 'Close' : 'Menu';
  return (
    <button
      className="ChatMobileToggle"
      type="button"
      onClick={props.onMobileToggle}
    >
      {buttonText}
    </button>
  );
}

export default ChatMobileToggle;
