import React from 'react';
import { Container, Grid, Cell } from '../grid/grid';
import { Heading, Paragraph } from '../typography';
import errorIcon from '../../../images/02-cloud-server.png';
import accessIcon from '../../../images/19-lock-square.png';
import missingIcon from '../../../images/04-cloud.png';
import './errors.scss';
import { Link } from 'react-router-dom';

export function AppError(props) {
  return (
    <div className="AppError">
      <Container>
        <Grid align={'center'}>
          <Cell small={12} large={6}>
            <div className="callout">
              <img alt="error" src={errorIcon} />
              <Heading level={1}>{props.error || 'Application Error'}</Heading>
              <Paragraph>We're working hard to resolve the issue.</Paragraph>
            </div>
          </Cell>
        </Grid>
      </Container>
    </div>
  );
}

export function AppSecurityError(props) {
  const message = props.message ? (
    <Paragraph>{props.message}</Paragraph>
  ) : (
    <Paragraph>
      Looks like you're somewhere your not supposed to be. Click here to{' '}
      <Link to="/sign-in">Login</Link>
    </Paragraph>
  );

  return (
    <div className="AppError">
      <Container>
        <Grid align={'center'}>
          <Cell small={12} large={6}>
            <div className="callout">
              <img alt="error" src={accessIcon} />
              <Heading level={1}>Access Denied</Heading>
              {message}
            </div>
          </Cell>
        </Grid>
      </Container>
    </div>
  );
}

export function AppMissingError(props) {
  return (
    <div className="AppError">
      <Container>
        <Grid align={'center'}>
          <Cell small={12} large={6}>
            <div className="callout">
              <img alt="error" src={missingIcon} />
              <Heading level={1}>Page Doesn't Exist</Heading>
            </div>
          </Cell>
        </Grid>
      </Container>
    </div>
  );
}

export function AppNeedsLogoutError(props) {
  return (
    <div className="AppError">
      <Container>
        <Grid align={'center'}>
          <Cell small={12} large={6}>
            <div className="callout">
              <img alt="error" src={accessIcon} />
              <Heading level={1}>{props.headline}</Heading>
              <Paragraph>{props.message}</Paragraph>
            </div>
          </Cell>
        </Grid>
      </Container>
    </div>
  );
}
