import React from 'react';
import PropTypes from 'prop-types';
import baseInput from '../base-input/base-input';
import './textarea.scss';

export function TextareaInput(props) {
  function handleChange(event) {
    if (props.limit && event.target.value.length > props.limit) {
      return;
    }

    props.onChange(event);
  }

  return (
    <div className="Field Field--textarea">
      <textarea
        value={props.value}
        name={props.name}
        onChange={handleChange}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        required={props.required}
      />
      {props.limit && (
        <span className="Field--textarea__limit">
          {props.value.length} / {props.limit} characters
        </span>
      )}
    </div>
  );
}

TextareaInput.defaultProps = {
  value: '',
  limit: null,
};

TextareaInput.propTypes = {
  value: PropTypes.string,
  limit: PropTypes.number,
};

export default baseInput(TextareaInput);
