import React from 'react';
import './form-error.scss';

export function FormError(props) {
  return (
    <div data-abide-error="" className="alert callout" aria-live="assertive">
      <p>
        <i className="fi-alert" /> {props.children}
      </p>
    </div>
  );
}

export default FormError;
