import React from 'react';
import PropTypes from 'prop-types';
import './paragraph.scss';

export function Paragraph(props) {
  return (
    <p style={props.style || {}} className="Paragraph">
      {props.children}
    </p>
  );
}

Paragraph.propTypes = {
  children: PropTypes.any,
};

Paragraph.defaultProps = {
  children: null,
};

export default Paragraph;
