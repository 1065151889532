import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './divider.scss';

export function Divider(props) {
  const classes = classNames({
    Divider: true,
    'Divider--collapsed': props.collapsed,
  });

  return <hr className={classes} />;
}

Divider.defaultProps = {
  collapsed: false,
};

Divider.propTypes = {
  collapsed: PropTypes.bool,
};

export default Divider;
