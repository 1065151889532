import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';

export const GET_USER = gql`
  query($id: ID!) {
    getUser(id: $id) {
      id
      email
      phone
      type
    }
  }
`;

export function useGetUser(id) {
  const { data, loading, error } = useQuery(GET_USER, {
    variables: { id },
    fetchPolicy: 'network-only',
  });

  return { data, loading, error };
}

export const UPDATE_USER = gql`
  mutation($input: UserInput) {
    updateUser(input: $input) {
      id
    }
  }
`;

export function useUpdateUser() {
  const [mutate] = useMutation(UPDATE_USER);

  async function updateUser(input) {
    return await mutate({ variables: { input } });
  }

  return updateUser;
}
