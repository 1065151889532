import React from 'react';
import { Image, Grid, Cell, StrongText, Paragraph } from '../../ui';
import { ChatMobileToggle } from '../../chat';
import './chat-context.scss';

import { useGlobalState } from '../../../store';
import { useSession } from '../../../hooks/session';

export function useGetChatContext() {
  const session = useSession();
  const [activeChannel] = useGlobalState('activeChannel');

  if (!activeChannel) return null;

  const members = activeChannel.members.filter(member => {
    return member.userId !== session.profile.id;
  });

  return members[0];
}

export function ChatContext(props) {
  const member = useGetChatContext();

  if (!member) {
    return (
      <div className="ChatContext">
        <Grid fullHeight margin align="middle"></Grid>
      </div>
    );
  }

  return (
    <div className="ChatContext">
      <Grid fullHeight margin align="middle">
        {member.profileUrl && (
          <Cell shrink>
            <Image maxWidth={40} round src={member.profileUrl} />
          </Cell>
        )}
        <Cell auto>
          <StrongText>{member.nickname}</StrongText>
          {member.metaData && member.metaData.company ? (
            <Paragraph>{member.metaData.company}</Paragraph>
          ) : null}
        </Cell>
        <Cell shrink>
          <ChatMobileToggle
            menuOpen={props.menuOpen}
            onMobileToggle={props.onMobileToggle}
          />
        </Cell>
      </Grid>
    </div>
  );
}

export default ChatContext;
