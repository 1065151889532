import React from 'react';
import {
  Container,
  Grid,
  Cell,
  Loader,
  Heading,
  Button,
} from '../../components/ui';
import { useQuery } from 'react-apollo-hooks';
import { MY_VENTURE, useUpdateVenture } from '../../graphql/ventures';
import 'dragula/dist/dragula.min.css';

import FormStepOne from '../create-company/steps/step.one';

import { useGlobalState } from '../../store';

import { useForm } from '../../hooks/forms';
import { AppError } from '../../components/ui/errors/errors';
import { SecondaryNav } from '../../components/layout/secondary-nav/secondary-nav';

export function CreateAccount(props) {
  const [, setNotifications] = useGlobalState('notifications');
  const updateVenture = useUpdateVenture();
  const { venture = {} } = props;
  // const updateVenture = useMutation(UPDATE_VENTURE);
  const { form, errors, handleFormChange } = useForm({
    name: venture.name || '',
    description: venture.description || '',
    website: venture.website || '',
    country: venture.country || '',
    state: venture.state || '',
    city: venture.city || '',
    logo: venture.logo || '',
    tags: venture.tags ? venture.tags.map(tag => tag.id) : [],
    logoCrop: venture.logoCrop ? { url: venture.logoCrop.url } : {},
    team: (venture.team || [{ social: {} }]).map(member => {
      return {
        firstName: member.firstName || '',
        lastName: member.lastName || '',
        title: member.title || '',
        bio: member.bio || '',
        photo: member.photo || '',
        linkedIn: member.social.linkedIn,
      };
    }),
    idea_name: '',
    idea_type: '',
    idea_description: '',
    industry: '',
    vertical: '',
    ask: [],
    pitch: '',
  });

  async function submit() {
    const input = {
      id: venture.id,
      name: form.name,
      description: form.description,
      website: form.website,
      logo: form.logo,
      logoCrop: form.logoCrop,
      country: form.country,
      state: form.state,
      city: form.city,
    };

    try {
      updateVenture(input);
      setNotifications([
        { message: `Your company has been updated.`, time: 3000 },
      ]);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <section className="CreateAccount">
      <Grid spaced align="center">
        <Cell large={12}>
          <FormStepOne
            venture={venture}
            form={form}
            errors={errors}
            handleFormChange={handleFormChange}
          />
          <hr />
        </Cell>
        <Cell>
          <Button onClick={submit}>Save Company</Button>
        </Cell>
      </Grid>
    </section>
  );
}

export function MyCompanyLoader(props) {
  const { data, error, loading } = useQuery(MY_VENTURE, {
    suspend: false,
    fetchPolicy: 'network-only',
  });

  if (loading)
    return (
      <Container>
        <Grid>
          <Cell>
            <Loader />
          </Cell>
        </Grid>
      </Container>
    );

  if (error) return <AppError />;

  return <CreateAccount venture={data.myVenture} {...props} />;
}

export function MyCompanyWrapper(props) {
  return (
    <section className="MyOrders">
      <Container>
        <Grid>
          <Cell>
            <Heading level={1}>
              My <b>Profile</b>
            </Heading>
            <hr />
          </Cell>
        </Grid>
        <Grid>
          <Cell small={12} medium={3}>
            <SecondaryNav />
          </Cell>
          <Cell small={12} medium={9}>
            <MyCompanyLoader />
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export default MyCompanyWrapper;
