import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './list.scss';

export function List(props) {
  let ListType;
  switch (props.type) {
    case 'ordered':
      ListType = 'ol';
      break;
    default:
      ListType = 'ul';
      break;
  }

  const classes = classNames({
    List: true,
    'List--ordered': props.type === 'ordered',
    'List--unordered': props.type === 'unordered',
    'List--unstyled': props.unstyled,
  });

  return <ListType className={classes}>{props.children}</ListType>;
}

List.propTypes = {
  type: PropTypes.oneOf(['ordered', 'unordered']),
  children: PropTypes.any,
  unstyled: PropTypes.bool,
};

List.defaultProps = {
  type: 'unordered',
  children: null,
  unstyled: false,
};

export default List;
