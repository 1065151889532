import React from 'react';
import {
  Container,
  Grid,
  Cell,
  Loader,
  Card,
  Heading,
  Paragraph,
  Callout,
  Button,
} from '../../components/ui';
import { useQuery } from 'react-apollo-hooks';
import { MY_VENTURE_PITCHES } from '../../graphql/ventures';
import { Link } from 'react-router-dom';
import { SecondaryNav } from '../../components/layout/secondary-nav/secondary-nav';
import { AppError } from '../../components/ui/errors/errors';
import './my-pitches.scss';

export function MyPitchCard(props) {
  const job = props.job || { video: {}, thumbnail: {} };

  return (
    <Cell>
      <Link to={`/dashboard/my-pitches/view/${props.id}`}>
        <Card hover theme={'air'} fullHeight>
          <div
            style={{
              height: '200px',
              width: 'calc(100% + 40px)',
              backgroundPosition: 'center',
              margin: '-20px -20px 20px -20px',
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundColor: '#000',
              backgroundImage: `url(${job.thumbnail.url ||
                'https://dev-scout-uploads.s3.amazonaws.com/encoded/1d534519-19e2-4229-bf05-7755f13c411d_next_file_frame.0000001.jpg'})`,
            }}
          />

          <Heading level={4}>{props.name}</Heading>
          <Paragraph>{props.description}</Paragraph>
        </Card>
      </Link>
    </Cell>
  );
}

export function MyPitches(props) {
  const { pitches = [] } = props;

  return (
    <div className="MyPitches">
      <Grid>
        <Cell small={12}>
          <div className="text-right">
            <Link to="/dashboard/my-pitches/new">
              <Button>Create a new Pitch</Button>
            </Link>
          </div>
        </Cell>
      </Grid>
      <Grid margin mediumBlock={3}>
        {(pitches || []).map(pitch => {
          return <MyPitchCard {...pitch} key={pitch.id} />;
        })}
      </Grid>
    </div>
  );
}

export function MyPitchesNoResults() {
  return (
    <Callout>
      <div className="text-center">
        <Heading style={{ margin: '1rem' }} level={4}>
          You Don't have any pitches
        </Heading>
        <Paragraph>
          Our platform allows you to access to some of the most amazing minds in
          business that for the right price will weigh in on your venture,
          product or service. But first we need you to tell us about your idea.
        </Paragraph>
        <Link to="/dashboard/my-pitches/new">
          <Button>Create a new Pitch</Button>
        </Link>
      </div>
    </Callout>
  );
}

export function MyPitchesLoader(props) {
  const { data, loading, error } = useQuery(MY_VENTURE_PITCHES, {
    fetchPolicy: 'network-only',
  });

  if (loading) return <Loader />;
  if (error) return <AppError />;

  if (data.myVenture.pitches.length === 0) {
    return <MyPitchesNoResults />;
  }

  return <MyPitches pitches={data.myVenture.pitches} />;
}

export function MyPitchesWrapper(props) {
  return (
    <Container>
      <Grid>
        <Cell small={12}>
          <Heading level={1}>
            My <b>Pitches</b>
          </Heading>
          <hr />
        </Cell>
      </Grid>
      <Grid>
        <Cell small={12} medium={3}>
          <SecondaryNav />
        </Cell>
        <Cell small={12} medium={9}>
          <MyPitchesLoader />
        </Cell>
      </Grid>
    </Container>
  );
}

export default MyPitchesWrapper;
