import React from 'react';
import { AdminSecondaryNav } from '../../../components/layout';
import { Container, Grid, Cell, Heading, Button } from '../../../components/ui';
import { useForm } from '../../../hooks/forms';
import TextInput from '../../../components/ui/inputs/text/text';
import { useAddAffiliateNetwork } from '../../../graphql/affiliates';
import history from '../../../util/history';

export function AddNetwork(props) {
  const addAffiliateNetwork = useAddAffiliateNetwork();
  const { form, handleFormChange } = useForm({ name: '' });

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const { data } = await addAffiliateNetwork({ name: form.name });

      if (data.addAffiliateNetwork) {
        history.push(`/admin/networks/view/${data.addAffiliateNetwork.id}`);
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <form action="" onSubmit={handleSubmit}>
      <Grid margin>
        <Cell small={12}>
          <TextInput
            spaced
            name="name"
            label="Name"
            placeholder="Name"
            value={form.name}
            onChange={handleFormChange}
          />
        </Cell>
        <Cell small={12}>
          <div className="text-right">
            <Button onClick={handleSubmit}>Add Affiliate Network</Button>
          </div>
        </Cell>
      </Grid>
    </form>
  );
}

export function AddNetworkLoader(props) {
  return <AddNetwork />;
}

export function AddNetworkWrapper(props) {
  return (
    <Container>
      <Grid>
        <Cell small={6}>
          <Heading level={1}>
            <b>Vintro</b> Admin
          </Heading>
        </Cell>
        <Cell small={6} />
        <Cell>
          <hr />
        </Cell>
      </Grid>
      <Grid margin>
        <Cell small={12} medium={3}>
          <AdminSecondaryNav />
        </Cell>
        <Cell small={12} medium={9}>
          <AddNetworkLoader />
        </Cell>
      </Grid>
    </Container>
  );
}

export default AddNetworkWrapper;
