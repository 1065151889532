export default [
  {
    custom_type: '',
    mentioned_users: [],
    translations: {},
    updated_at: 0,
    is_removed: false,
    user: {
      is_blocked_by_me: false,
      nickname: '6789',
      user_id: null,
      profile_url:
        'https://sendbird.com/main/img/profiles/profile_09_512px.png',
      metadata: {},
    },
    file: {},
    message:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus quasi, suscipit vero non expedita vitae nihil numquam ex voluptate molestiae, voluptatem necessitatibus velit inventore consequatur quia ducimus nesciunt sed! Adipisci',
    data: '',
    type: 'MESG',
    created_at: 1496135739724,
    mention_type: 'users',
    channel_url:
      'sendbird_group_channel_2935_8baf88c0ba356313cdc27c63358335eff0feb397',
    message_id: 347260,
  },
  {
    custom_type: '',
    mentioned_users: [],
    translations: {},
    updated_at: 0,
    is_removed: false,
    user: {
      is_blocked_by_me: false,
      nickname: 'larse',
      user_id: null,
      profile_url:
        'https://sendbird.com/main/img/profiles/profile_04_512px.png',
      metadata: {},
    },
    file: {},
    message:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus quasi, suscipit vero non expedita vitae nihil numquam ex voluptate molestiae, voluptatem necessitatibus velit inventore consequatur quia ducimus nesciunt sed! Adipisci',
    data: '',
    type: 'MESG',
    created_at: 1496135767474,
    mention_type: 'users',
    channel_url:
      'sendbird_group_channel_2935_8baf88c0ba356313cdc27c63358335eff0feb397',
    message_id: 347261,
  },
  {
    custom_type: '',
    mentioned_users: [],
    translations: {},
    updated_at: 0,
    is_removed: false,
    user: {
      is_blocked_by_me: false,
      nickname: '6789',
      user_id: null,
      profile_url:
        'https://sendbird.com/main/img/profiles/profile_09_512px.png',
      metadata: {},
    },
    file: {},
    message:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus quasi, suscipit vero non expedita vitae nihil numquam ex voluptate molestiae, voluptatem necessitatibus velit inventore consequatur quia ducimus nesciunt sed! Adipisci',
    data: '',
    type: 'MESG',
    created_at: 1496135795458,
    mention_type: 'users',
    channel_url:
      'sendbird_group_channel_2935_8baf88c0ba356313cdc27c63358335eff0feb397',
    message_id: 347264,
  },
  {
    custom_type: '',
    mentioned_users: [],
    translations: {},
    updated_at: 0,
    is_removed: false,
    user: {
      is_blocked_by_me: false,
      nickname: '6789',
      user_id: null,
      profile_url:
        'https://sendbird.com/main/img/profiles/profile_09_512px.png',
      metadata: {},
    },
    file: {},
    message:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus quasi, suscipit vero non expedita vitae nihil numquam ex voluptate molestiae, voluptatem necessitatibus velit inventore consequatur quia ducimus nesciunt sed! Adipisci',
    data: '',
    type: 'MESG',
    created_at: 1496135884319,
    mention_type: 'users',
    channel_url:
      'sendbird_group_channel_2935_8baf88c0ba356313cdc27c63358335eff0feb397',
    message_id: 347267,
  },
  {
    custom_type: '',
    mentioned_users: [],
    translations: {},
    updated_at: 0,
    is_removed: false,
    user: {
      is_blocked_by_me: false,
      nickname: '6789',
      user_id: null,
      profile_url:
        'https://sendbird.com/main/img/profiles/profile_09_512px.png',
      metadata: {},
    },
    file: {},
    message:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus quasi, suscipit vero non expedita vitae nihil numquam ex voluptate molestiae, voluptatem necessitatibus velit inventore consequatur quia ducimus nesciunt sed! Adipisci',
    data: '',
    type: 'MESG',
    created_at: 1496135888625,
    mention_type: 'users',
    channel_url:
      'sendbird_group_channel_2935_8baf88c0ba356313cdc27c63358335eff0feb397',
    message_id: 347268,
  },
  {
    custom_type: '',
    mentioned_users: [],
    translations: {},
    updated_at: 0,
    is_removed: false,
    user: {
      is_blocked_by_me: false,
      nickname: '6789',
      user_id: null,
      profile_url:
        'https://sendbird.com/main/img/profiles/profile_09_512px.png',
      metadata: {},
    },
    file: {},
    message:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus quasi, suscipit vero non expedita vitae nihil numquam ex voluptate molestiae, voluptatem necessitatibus velit inventore consequatur quia ducimus nesciunt sed! Adipisci',
    data: '',
    type: 'MESG',
    created_at: 1496138529612,
    mention_type: 'users',
    channel_url:
      'sendbird_group_channel_2935_8baf88c0ba356313cdc27c63358335eff0feb397',
    message_id: 347359,
  },
  {
    custom_type: '',
    mentioned_users: [],
    translations: {},
    updated_at: 0,
    is_removed: false,
    user: {
      is_blocked_by_me: false,
      nickname: '6789',
      user_id: null,
      profile_url:
        'https://sendbird.com/main/img/profiles/profile_09_512px.png',
      metadata: {},
    },
    file: {},
    message:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus quasi, suscipit vero non expedita vitae nihil numquam ex voluptate molestiae, voluptatem necessitatibus velit inventore consequatur quia ducimus nesciunt sed! Adipisci',
    data: '',
    type: 'MESG',
    created_at: 1496138714054,
    mention_type: 'users',
    channel_url:
      'sendbird_group_channel_2935_8baf88c0ba356313cdc27c63358335eff0feb397',
    message_id: 347368,
  },
  {
    custom_type: '',
    mentioned_users: [],
    translations: {},
    updated_at: 0,
    is_removed: false,
    user: {
      is_blocked_by_me: false,
      nickname: '6789',
      user_id: null,
      profile_url:
        'https://sendbird.com/main/img/profiles/profile_09_512px.png',
      metadata: {},
    },
    file: {},
    message:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus quasi, suscipit vero non expedita vitae nihil numquam ex voluptate molestiae, voluptatem necessitatibus velit inventore consequatur quia ducimus nesciunt sed! Adipisci',
    data: '',
    type: 'MESG',
    created_at: 1496139067281,
    mention_type: 'users',
    channel_url:
      'sendbird_group_channel_2935_8baf88c0ba356313cdc27c63358335eff0feb397',
    message_id: 347381,
  },
  {
    custom_type: '',
    mentioned_users: [],
    translations: {},
    updated_at: 0,
    is_removed: false,
    user: {
      is_blocked_by_me: false,
      nickname: '6789',
      user_id: null,
      profile_url:
        'https://sendbird.com/main/img/profiles/profile_09_512px.png',
      metadata: {},
    },
    file: {},
    message:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus quasi, suscipit vero non expedita vitae nihil numquam ex voluptate molestiae, voluptatem necessitatibus velit inventore consequatur quia ducimus nesciunt sed! Adipisci',
    data: '',
    type: 'MESG',
    created_at: 1496139132318,
    mention_type: 'users',
    channel_url:
      'sendbird_group_channel_2935_8baf88c0ba356313cdc27c63358335eff0feb397',
    message_id: 347384,
  },
  {
    custom_type: '',
    mentioned_users: [],
    translations: {},
    updated_at: 0,
    is_removed: false,
    user: {
      is_blocked_by_me: false,
      nickname: '6789',
      user_id: null,
      profile_url:
        'https://sendbird.com/main/img/profiles/profile_09_512px.png',
      metadata: {},
    },
    file: {},
    message:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus quasi, suscipit vero non expedita vitae nihil numquam ex voluptate molestiae, voluptatem necessitatibus velit inventore consequatur quia ducimus nesciunt sed! Adipisci',
    data: '',
    type: 'MESG',
    created_at: 1496155463865,
    mention_type: 'users',
    channel_url:
      'sendbird_group_channel_2935_8baf88c0ba356313cdc27c63358335eff0feb397',
    message_id: 347958,
  },
  {
    custom_type: '',
    mentioned_users: [],
    translations: {},
    updated_at: 0,
    is_removed: false,
    user: {
      is_blocked_by_me: false,
      nickname: 'larse',
      user_id: null,
      profile_url:
        'https://sendbird.com/main/img/profiles/profile_04_512px.png',
      metadata: {},
    },
    file: {},
    message:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus quasi, suscipit vero non expedita vitae nihil numquam ex voluptate molestiae, voluptatem necessitatibus velit inventore consequatur quia ducimus nesciunt sed! Adipisci',
    data: '',
    type: 'MESG',
    created_at: 1496155508324,
    mention_type: 'users',
    channel_url:
      'sendbird_group_channel_2935_8baf88c0ba356313cdc27c63358335eff0feb397',
    message_id: 347959,
  },
  {
    custom_type: '',
    mentioned_users: [],
    translations: {},
    updated_at: 0,
    is_removed: false,
    user: {
      is_blocked_by_me: false,
      nickname: '6789',
      user_id: null,
      profile_url:
        'https://sendbird.com/main/img/profiles/profile_09_512px.png',
      metadata: {},
    },
    file: {},
    message:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus quasi, suscipit vero non expedita vitae nihil numquam ex voluptate molestiae, voluptatem necessitatibus velit inventore consequatur quia ducimus nesciunt sed! Adipisci',
    data: '',
    type: 'MESG',
    created_at: 1496159523267,
    mention_type: 'users',
    channel_url:
      'sendbird_group_channel_2935_8baf88c0ba356313cdc27c63358335eff0feb397',
    message_id: 348096,
  },
  {
    custom_type: '',
    mentioned_users: [],
    translations: {},
    updated_at: 0,
    is_removed: false,
    user: {
      is_blocked_by_me: false,
      nickname: '6789',
      user_id: null,
      profile_url:
        'https://sendbird.com/main/img/profiles/profile_09_512px.png',
      metadata: {},
    },
    file: {},
    message:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus quasi, suscipit vero non expedita vitae nihil numquam ex voluptate molestiae, voluptatem necessitatibus velit inventore consequatur quia ducimus nesciunt sed! Adipisci',
    data: '',
    type: 'MESG',
    created_at: 1496225676073,
    mention_type: 'users',
    channel_url:
      'sendbird_group_channel_2935_8baf88c0ba356313cdc27c63358335eff0feb397',
    message_id: 353827,
  },
  {
    custom_type: '',
    mentioned_users: [],
    translations: {},
    updated_at: 0,
    is_removed: false,
    user: {
      is_blocked_by_me: false,
      nickname: 'larse',
      user_id: null,
      profile_url:
        'https://sendbird.com/main/img/profiles/profile_04_512px.png',
      metadata: {},
    },
    file: {},
    message:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus quasi, suscipit vero non expedita vitae nihil numquam ex voluptate molestiae, voluptatem necessitatibus velit inventore consequatur quia ducimus nesciunt sed! Adipisci',
    data: '',
    type: 'MESG',
    created_at: 1496225816566,
    mention_type: 'users',
    channel_url:
      'sendbird_group_channel_2935_8baf88c0ba356313cdc27c63358335eff0feb397',
    message_id: 353832,
  },
];
