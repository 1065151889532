import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo-hooks';
import { useGlobalState } from '../store';
import store from 'store';

export const ADD_TO_CART = gql`
  mutation ADD_TO_CART($input: AddToCartInput) {
    addToCart(input: $input) {
      id
    }
  }
`;

export const REMOVE_FROM_CART = gql`
  mutation REMOVE_FROM_CART($input: RemoveFromCartInput) {
    removeFromCart(input: $input) {
      id
      items {
        id
        firstName
        lastName
        price
        photo
        title
      }
    }
  }
`;

export const GET_CART = gql`
  query GET_CART($id: ID, $checkout: Boolean) {
    getCart(id: $id, checkout: $checkout) {
      id
      stripePaymentIntent
      stripePaymentIntentClientSecret
      items {
        id
        firstName
        lastName
        price
        photo
        title
        company
        averageRating
      }
    }
  }
`;

export function useAddToCart() {
  const [, setNotifications] = useGlobalState('notifications');
  const [cartID, setCartID] = useGlobalState('cart');
  const addToCart = useMutation(ADD_TO_CART);

  async function exec(id, person) {
    try {
      const { data } = await addToCart({
        variables: {
          input: {
            person: id,
            id: cartID,
          },
        },
        refetchQueries: [{ query: GET_CART, variables: { id: cartID } }],
      });

      if (data.addToCart.id) {
        setCartID(data.addToCart.id);
        store.set('cart', data.addToCart.id);
      }
    } catch (e) {
      setNotifications([
        {
          message: `There was an error adding ${person.firstName} ${person.lastName} to your cart.`,
          error: true,
          time: 3000,
        },
      ]);
    }
  }

  return exec;
}

export function useItemIsInCart(id, checkout = false) {
  const [cartID] = useGlobalState('cart');
  const { data, loading } = useQuery(GET_CART, {
    fetchPolicy: 'cache-only',
    variables: { id: cartID },
  });

  function checkItemIsInCart() {
    if (loading) return false;
    if (!data || !data.getCart || !data.getCart.items) return false;

    return data.getCart.items.filter(item => item.id === id).length > 0;
  }

  return checkItemIsInCart();
}

export function useGetCart() {
  const [cartID] = useGlobalState('cart');
  const { data, loading, error } = useQuery(GET_CART, {
    fetchPolicy: 'newtork-and-cache',
    variables: { id: cartID },
  });

  return { data, loading, error };
}
