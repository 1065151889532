import './reviewer-detail.scss';
import React, { useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import {
  Container,
  Grid,
  Cell,
  StarRating,
  Paragraph,
  Button,
  WorldIcon,
} from '../../components/ui';
import { useQuery } from 'react-apollo-hooks';
import { GET_PERSON } from '../../graphql/scouts';
import { useAddToCart, useItemIsInCart } from '../../graphql/cart';
import moment from 'moment';
import { AppError } from '../../components/ui/errors/errors';
import {
  useCheckFeatureToken,
  useCanViewReviewers,
} from '../reviewers/reviewers';
import queryString from 'query-string';

import missingImage from '../../images/photo-missing.png';
import { ReviewerPhoto } from '../../components/reviewer';
import HeartIcon from '../../components/ui/heart-icon/heart-icon';
import { useNotifications } from '../../hooks/notifications';
import { ButtonLoading } from '../../components/ui/button/button';
import { useSession } from '../../hooks/session';

export function CartButton(props) {
  const session = useSession({});
  const { profile = {} } = session;
  const notifications = useNotifications();
  const { scout } = props;
  const [loading, setLoading] = useState(false);

  const addToCart = useAddToCart();
  const itemIsInCart = useItemIsInCart(scout.id);

  function canAddToCart() {
    if (scout.away) {
      return false;
    }

    return (
      (!profile.user && process.env.REACT_APP_CART_ENABLED) ||
      (profile.user &&
        profile.user.type !== 'SCOUT' &&
        process.env.REACT_APP_CART_ENABLED) ||
      (profile && profile.features && profile.features.cart === true)
    );
  }

  async function handleCartAddition() {
    if (!canAddToCart()) return null;

    if (loading) return;

    try {
      setLoading(true);
      await addToCart(scout.id, scout);
    } catch (e) {
      notifications.notify(
        `There was an error adding ${scout.firstName} to your cart`,
        { error: true, time: 3000 }
      );
    }

    setLoading(false);
  }

  const buttonText = itemIsInCart ? 'Added' : `Pitch now for $${scout.price}`;

  return (
    <Button
      onClick={handleCartAddition}
      disabled={itemIsInCart || scout.away}
      full
    >
      {loading ? (
        <ButtonLoading>Adding {scout.firstName}</ButtonLoading>
      ) : (
        <span>{buttonText}</span>
      )}
    </Button>
  );
}

export function ReviewerDetail(props) {
  const { scout } = props;

  function renderList(list = [], filter = null) {
    list = filter ? list.filter(filter) : list;
    list = list.filter(tag => tag !== null && tag !== undefined);

    return list.map(item => {
      return (
        <span className="ReviewerDetail__tag" key={item.id}>
          {item.name}
        </span>
      );
    });
  }

  const interests = renderList(scout.tags || [], tag => {
    return tag.type === 'subindustry' || tag.type === 'industry';
  });

  const emergingVerticals = renderList(scout.tags || [], tag => {
    return tag.type === 'vertical';
  });

  const sdg = renderList(scout.tags || [], tag => {
    return tag.type === 'sdg';
  });

  return (
    <section className="ReviewerDetail">
      <header className="ReviewerDetail__header">
        <Container>
          <Grid grid="x" margin>
            <Cell large={3} medium={4}>
              <ReviewerPhoto
                photo={scout.photo || missingImage}
                photoCrop={scout.photoCrop}
                logo={scout.companyLogo}
                companyLogoCrop={scout.companyLogoCrop}
              />
            </Cell>
            <Cell large={6} medium={8}>
              <div className="ReviewerDetail__reviewerDescription">
                {/*<div>
                  <Badge>Verified Expert</Badge>
                </div>*/}
                <h1 className="ReviewerDetail__heading">
                  {scout.firstName} {scout.lastName}
                </h1>
                <h2 className="ReviewerDetail__subheading">
                  <span className="ReviewerDetail__company">
                    {scout.company}
                  </span>
                </h2>
                <hr />
                <h3 className="ReviewerDetail__subheading">
                  <span className="ReviewerDetail__title">{scout.title}</span>
                </h3>
                {scout.locality && scout.locality.country ? (
                  <div className="ReviewerDetail__flag">
                    <ReactCountryFlag
                      svg
                      code={scout.locality.country}
                      styleProps={{
                        width: '30px',
                        height: '30px',
                      }}
                    />
                    {scout.locality.city}, {scout.locality.country}
                  </div>
                ) : null}
              </div>
            </Cell>
            <Cell large={3} medium={12} align="self-bottom">
              <div className="ReviewerDetail__cta">
                <div className="ReviewerDetail__ctaButton">
                  <CartButton scout={scout} />
                </div>
                <Grid margin>
                  <Cell auto>
                    <div className="Stat">
                      <StarRating rating={scout.averageRating || 5} />
                      <div className="ReviewerDetail__ctaDescription">
                        {scout.totalRatings || 0} Reviews
                      </div>
                    </div>
                  </Cell>
                  {scout.averageResponseTime && (
                    <Cell>
                      <div>
                        <strong className="ReviewerDetail__ctaDescription">
                          {moment
                            .duration(scout.averageResponseTime)
                            .humanize()}
                        </strong>
                        <div className="ReviewerDetail__ctaDescription">
                          Avg. Response Time
                        </div>
                      </div>
                    </Cell>
                  )}
                </Grid>
              </div>
            </Cell>
          </Grid>
        </Container>
      </header>

      <Container>
        <Grid margin>
          <Cell large="4">
            <div className="ReviewerDetail__section">
              {scout.types.length > 0 ? (
                <div className="ReviewerDetail__sectionItem">
                  <h4 className="ReviewerDetail__sectionHeading">
                    Associated With
                  </h4>
                  <ul className="ReviewerDetail__typeList">
                    {scout.types.map((type, idx) => {
                      return (
                        <li key={idx}>
                          <Grid>
                            <Cell shrink>
                              <div className="ReviewerDetail__worldIcon">
                                <WorldIcon />
                              </div>
                            </Cell>
                            <Cell auto>{type}</Cell>
                          </Grid>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}
              {interests.length > 0 ? (
                <div className="ReviewerDetail__sectionItem">
                  <h4 className="ReviewerDetail__sectionSubheading">
                    Industrial Interests
                  </h4>
                  <div style={{ marginBottom: '20px' }}>{interests}</div>
                </div>
              ) : null}
              {emergingVerticals.length > 0 ? (
                <div className="ReviewerDetail__sectionItem">
                  <h4 className="ReviewerDetail__sectionSubheading">
                    Emerging Verticals
                  </h4>
                  <div style={{ marginBottom: '20px' }}>
                    {emergingVerticals}
                  </div>
                </div>
              ) : null}
              {sdg.length > 0 ? (
                <div className="ReviewerDetail__sectionItem">
                  <h4 className="ReviewerDetail__sectionSubheading">
                    Social Causes and Global Problems
                  </h4>
                  <div style={{ marginBottom: '20px' }}>{sdg}</div>
                </div>
              ) : null}
            </div>
          </Cell>
          <Cell large={scout.isDonatingToCharity ? '5' : '8'}>
            <div className="ReviewerDetail__section">
              <div className="ReviewerDetail__sectionItem">
                <h4 className="ReviewerDetail__sectionSubheading">About</h4>
                {(scout.bio || '').split('\n').map((text, i) => {
                  return <Paragraph key={i}>{text}</Paragraph>;
                })}
              </div>
              <div className="ReviewerDetail__sectionItem">
                <h4 className="ReviewerDetail__sectionSubheading">
                  What I'm Looking For
                </h4>
                {(scout.bioLookingFor || '').split('\n').map((text, i) => {
                  return <Paragraph key={i}>{text}</Paragraph>;
                })}
              </div>
            </div>
          </Cell>
          {scout.isDonatingToCharity && (
            <Cell className="ReviewerDetail__donationCtaCell" large="3">
              <div className="ReviewerDetail__donationCta">
                <h4 className="ReviewerDetail__donationHeading">
                  Vintro Give Back Program
                </h4>
                <h5 className="ReviewerDetail__donationName">
                  <HeartIcon /> {scout.donatingToCharityName}
                </h5>
                <p className="ReviewerDetail__donationText">
                  {scout.firstName} commits to donating all or parts of their
                  Vintro proceeds to the charity.
                </p>
              </div>
            </Cell>
          )}
        </Grid>
      </Container>
    </section>
  );
}

export function ReviewerDetailLoader(props) {
  const { data, loading, error } = useQuery(GET_PERSON, {
    variables: { id: props.match.params.id },
  });

  if (loading) return null;
  if (error) return <AppError />;

  return <ReviewerDetail {...props} scout={data.getPerson} />;
}

export function ReviewerDetailFeatureSwitch(props) {
  const canView = useCheckFeatureToken();

  if (canView) {
    return <ReviewerDetailLoader {...props} />;
  }

  return null;
}

export function ReviewerFeatureBlock(props) {
  const q = queryString.parse(props.location.search);
  const canView = useCanViewReviewers(q.preview);

  if (!canView) {
    return <ReviewerDetailFeatureSwitch {...props} />;
  }

  return <ReviewerDetailLoader {...props} />;
}

export default ReviewerFeatureBlock;
