import React, { useState } from 'react';
import validate from 'validate.js';
import {
  Grid,
  Cell,
  Button,
  Heading,
  Container,
  EmailInput,
  PasswordInput,
  StrongText,
  Paragraph,
} from '../../components/ui';

import { FormError } from '../../components/forms';
import { Link } from 'react-router-dom';

import { useLogin } from '../../graphql/auth';
import { useForm } from '../../hooks/forms';
import { useNotifications } from '../../hooks/notifications';
import { ButtonLoading } from '../../components/ui/button/button';

export function SignIn(props) {
  const notifications = useNotifications();
  const login = useLogin();
  const [loading, setLoading] = useState(false);

  const { form, errors, setErrors, handleFormChange } = useForm(
    {
      company: '',
      email: '',
      password: '',
    },
    {
      validate: validateForm,
    }
  );

  const validationRules = {
    email: {
      presence: { allowEmpty: false },
      email: true,
    },
    password: {
      presence: true,
      length: {
        minimum: 6,
        message: 'must be at least 6 characters',
      },
    },
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const response = validateForm(form);

    setErrors(errors => ({
      ...errors,
      ...response,
    }));

    setLoading(true);

    if (response === undefined) {
      const input = {
        email: form.email.toLowerCase(),
        password: form.password,
      };
      try {
        await login(input);
        notifications.clear();
      } catch (e) {
        if (e.graphQLErrors && e.graphQLErrors[0]) {
          notifications.notify(e.graphQLErrors[0].message, {
            error: true,
            time: 3000,
          });
        } else {
          notifications.notify('Something went wrong', {
            error: true,
            time: 3000,
          });
        }
      }
    }

    setLoading(false);

    return false;
  }

  function validateForm(form) {
    return validate(form, validationRules);
  }

  return (
    <section className="SignUp">
      <Container>
        <Grid align="center">
          <Cell large={6} medium={8} small={12}>
            <Heading level={1}>
              Login to <b>Vintro</b>
            </Heading>
            <hr />
            {errors.server && <FormError>{errors.server}</FormError>}
            <form action="/" onSubmit={handleSubmit}>
              <EmailInput
                value={form.email}
                name={'email'}
                errors={errors.email}
                onChange={handleFormChange}
                placeholder={'Your Email'}
                label={'Email'}
                spaced
              />
              <PasswordInput
                value={form.password}
                name={'password'}
                errors={errors.password}
                onChange={handleFormChange}
                placeholder={'Enter your password'}
                label={'Password'}
                spaced
                canToggle
                canReset
              />
              <Grid margin align={'center'} style={{ alignItems: 'center' }}>
                <Cell small={6} medium={8}>
                  <Paragraph style={{ margin: '0' }}>
                    <StrongText>
                      Don't have an account.{' '}
                      <Link to="/sign-up">Create One Here.</Link>
                    </StrongText>
                  </Paragraph>
                </Cell>
                <Cell small={6} medium={4}>
                  <div className="text-right">
                    <Button type="submit">
                      {loading ? (
                        <ButtonLoading>Submit</ButtonLoading>
                      ) : (
                        <span>Submit</span>
                      )}
                    </Button>
                  </div>
                </Cell>
              </Grid>
              {/*<Link>Forgot Password?</Link>*/}
            </form>
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export default SignIn;
