import './notifications.scss';
import React, { useEffect } from 'react';
import { useGlobalState } from '../../../store';
import cc from 'classnames';
import { Grid, Cell, Button } from '../../ui';

export function Notifications() {
  const [notifications, setNotifications] = useGlobalState('notifications');
  const classes = cc({
    Notifications: true,
    'Notifications--show': notifications.length > 0,
  });

  useEffect(() => {
    if (notifications.length > 0) {
      setTimeout(
        () => {
          setNotifications([]);
        },
        notifications[0] ? notifications[0].time : 3000
      );
    }
  }, [notifications.length, setNotifications, notifications]);

  const all = (notifications || []).map((n, i) => {
    const classes = cc({
      Notifications__banner: true,
      Notifications__error: n.error,
      Notifications__confirm: n.confirm,
    });

    function confirm() {
      setNotifications([]);
      if (n.confirm) n.confirm();
    }

    if (n.confirm) {
      return (
        <div key={i} className={classes}>
          <Grid>
            <Cell small={6}>{n.message}</Cell>
            <Cell small={6}>
              <div className="text-right">
                <Button
                  onClick={e => setNotifications([])}
                  size={'small'}
                  hollow
                >
                  No
                </Button>
                <Button onClick={confirm} size={'small'} theme={'primary'}>
                  Yes
                </Button>
              </div>
            </Cell>
          </Grid>
        </div>
      );
    }

    return (
      <div key={i} className={classes}>
        {n.message}
      </div>
    );
  });

  return (
    <div className={classes}>
      <div className="Notifications__wrapper">
        {all.length > 0 ? all : null}
      </div>
    </div>
  );
}

export default Notifications;
