import React from 'react';
import { useJumioVerification } from '../../../graphql/scouts';
import {
  Loader,
  Grid,
  Cell,
  Heading,
  Button,
  Paragraph,
} from '../../../components/ui';

export const JumioIdentity = () => {
  const { loading, data } = useJumioVerification();

  if (loading) {
    return <Loader />;
  }

  return (
    <Grid margin>
      <Cell small={12}>
        <Heading level={1}>Identity Verification</Heading>
        <Heading level={2}>
          We need to verify your identity before reviewing your profile
          <br />
          Have a government issued ID or Passport ready.
        </Heading>
        <Paragraph>
          After you complete the process you will be re-directed back to the
          Vintro Website
        </Paragraph>
        <a href={data.jumioVerify}>
          <Button>Start Identity Verification</Button>
        </a>
      </Cell>
    </Grid>
  );
};

export default JumioIdentity;
