import React, { useState } from 'react';
import validate from 'validate.js';
import {
  Grid,
  Cell,
  Button,
  Heading,
  Container,
  TextInput,
  EmailInput,
  PasswordInput,
  Paragraph,
  StrongText,
  Label,
} from '../../components/ui';

import { REGISTER_AS_VENTURE } from '../../graphql/ventures';
import { useMutation } from 'react-apollo-hooks';

import { useSession } from '../../hooks/session';

import { Link } from 'react-router-dom';
import { useNotifications } from '../../hooks/notifications';

export function SignUp(props) {
  const notifications = useNotifications();
  const register = useMutation(REGISTER_AS_VENTURE);
  const session = useSession();

  const [form, setForm] = useState({
    company: '',
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
  });

  const [errors, setErrors] = useState({
    company: [],
    email: [],
    password: [],
    confirmPassword: [],
    firstName: [],
    lastName: [],
  });

  const validationRules = {
    /*company: {
      presence: { allowEmpty: false },
    },*/
    firstName: {
      presence: { allowEmpty: false },
    },
    lastName: {
      presence: { allowEmpty: false },
    },
    email: {
      presence: { allowEmpty: false },
      email: true,
    },
    password: {
      presence: true,
      length: {
        minimum: 6,
        message: 'must be at least 6 characters',
      },
      password: {
        message:
          'must contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character',
      },
    },
    confirmPassword: {
      presence: { allowEmpty: false },
      equality: 'password',
    },
  };

  function handleFormChange(event) {
    const key = event.target.name;
    const value = event.target.value;

    if (errors[key] && errors[key].length > 0) {
      const result = validate({ ...form, [key]: value }, validationRules);
      setErrors({
        ...errors,
        [key]: result ? result[key] : [],
      });

      if (key === 'password') {
      }
    }

    setForm(form => ({ ...form, [key]: value }));
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const formIsValid = validateForm();
    if (formIsValid) {
      const input = {
        // company: form.company,
        email: form.email,
        password: form.password,
        firstName: form.firstName,
        lastName: form.lastName,
      };
      try {
        const { data } = await register({ variables: { input } });
        if (data.registerAsVenture) {
          session.startSession(data.registerAsVenture.token);
          props.history.push('/create-company');
        }
      } catch (e) {
        notifications.notify(`Email is already in use.`, {
          time: 3000,
          error: true,
        });
        console.log(e);
      }
      // props.history.push('/create-company');
    }
  }

  function validateForm() {
    const result = validate(form, validationRules);
    const isValid = result === undefined;
    const fields = result || {};

    setErrors({
      // company: fields.company || [],
      email: fields.email || [],
      password: fields.password || [],
      confirmPassword: fields.confirmPassword || [],
      firstName: fields.firstName || [],
      lastName: fields.lastName || [],
    });

    return isValid;
  }

  if (!process.env.REACT_APP_BUYER_SIGNUP_ENABLED) {
    return (
      <section className="SignUp">
        <Container>
          <Grid align="center">
            <Cell large={6} medium={8} small={12}>
              <Heading level={1}>
                Join <b>Vintro</b> to Pitch
              </Heading>
              <Heading level={2}>It'll take 5 minutes or less.</Heading>
              <div>
                <form
                  action="https://getform.io/f/4929e21d-5505-48b4-b978-beaae8cf4dae"
                  method="POST"
                  target="_blank"
                >
                  <div className="Input Input--spaced">
                    <Label>Name</Label>
                    <input placeholder="Name" type="text" name="name" />
                  </div>
                  <div className="Input Input--spaced">
                    <Label>Email</Label>
                    <input
                      placeholder="Enter your email"
                      type="email"
                      name="email"
                    />
                  </div>
                  <div className="Input Input--spaced">
                    <Label>Phone Number</Label>
                    <input placeholder="Phone Number" type="tel" name="tel" />
                  </div>
                  <div className="text-right">
                    <button className="Button Button--primary" type="submit">
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </Cell>
          </Grid>
        </Container>
      </section>
    );
  }

  return (
    <section className="SignUp">
      <Container>
        <Grid align="center">
          <Cell large={6} medium={8} small={12}>
            <Heading level={1}>
              Join <b>Vintro</b> to Pitch
            </Heading>
            <Heading level={2}>It'll take 5 minutes or less.</Heading>
            <hr />
            <form onSubmit={handleSubmit}>
              <Grid margin>
                <Cell small={12} medium={6}>
                  <TextInput
                    value={form.firstName}
                    name={'firstName'}
                    errors={errors.firstName}
                    onChange={handleFormChange}
                    placeholder={'Enter your first name'}
                    label={'First Name'}
                    spaced
                  />
                </Cell>
                <Cell small={12} medium={6}>
                  <TextInput
                    value={form.lastName}
                    name={'lastName'}
                    errors={errors.lastName}
                    onChange={handleFormChange}
                    placeholder={'Enter your last name'}
                    label={'Last Name'}
                    spaced
                  />
                </Cell>
                {/*<Cell small={12} medium={6}>
                  <TextInput
                    value={form.company}
                    name={'company'}
                    errors={errors.company}
                    onChange={handleFormChange}
                    placeholder={'Enter your company name'}
                    label={'Company Name'}
                    spaced
                  />
  </Cell>*/}
                <Cell small={12} medium={12}>
                  <EmailInput
                    value={form.email}
                    name={'email'}
                    errors={errors.email}
                    onChange={handleFormChange}
                    placeholder={'Enter your email'}
                    label={'Email'}
                    spaced
                  />
                </Cell>
                <Cell small={12} medium={6}>
                  <PasswordInput
                    value={form.password}
                    name={'password'}
                    errors={errors.password}
                    onChange={handleFormChange}
                    placeholder={'Enter your password'}
                    label={'Password'}
                    showHelp={true}
                    spaced
                    canToggle
                  />
                </Cell>
                <Cell small={12} medium={6}>
                  <PasswordInput
                    value={form.confirmPassword}
                    name={'confirmPassword'}
                    errors={errors.confirmPassword}
                    onChange={handleFormChange}
                    placeholder={'Password'}
                    label={'Re-Enter your Password'}
                    spaced
                  />
                </Cell>
              </Grid>
              <Grid margin align={'center'} style={{ alignItems: 'center' }}>
                <Cell small={6} medium={8}>
                  <Paragraph style={{ margin: '0' }}>
                    <StrongText>
                      Already have an account? <Link to="/sign-in">Login.</Link>
                    </StrongText>
                  </Paragraph>
                </Cell>
                <Cell small={6} medium={4}>
                  <div className="text-right">
                    <Button type="submit">Create Account</Button>
                  </div>
                </Cell>
              </Grid>
            </form>
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export default SignUp;
