import './pitch.scss';
import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { MY_VENTURE_PITCHES } from '../../../../graphql/ventures';
import Loader from '../../loader/loader';
import SelectInput from '../select/select';
import VideoPlayer from '../../video-player/video-player';

export function PitchInput(props) {
  const options = props.pitches.map(pitch => ({
    value: pitch.id,
    label: pitch.name,
  }));

  const pitch = props.value
    ? props.pitches.find(pitch => pitch.id === props.value)
    : null;
  const job = pitch ? pitch.job : null;

  return (
    <div>
      {props.value && props.preview && (
        <VideoPlayer
          job={job}
          video={job.video.url}
          cover={job.thumbnail.url}
        />
      )}
      {!props.preview && <SelectInput {...props} options={options} />}
    </div>
  );
}

export function PitchInputLoader(props) {
  const { data, error, loading } = useQuery(MY_VENTURE_PITCHES, {
    fetchPolicy: 'network-only',
  });

  if (error) return null;
  if (loading) return <Loader />;

  return <PitchInput {...props} pitches={data.myVenture.pitches} />;
}

export default PitchInputLoader;
