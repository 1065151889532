import React from 'react';
import { CheckboxInput } from '../../../ui';
import baseInput from '../base-input/base-input';

function OfferingInput(props) {
  const value = props.value || [];
  const name = props.name;

  function handleChange(e) {
    let _value = [...value];

    if (_value.includes(e.target.value)) {
      _value = _value.filter(value => value !== e.target.value);
    } else {
      _value = [...value, e.target.value];
    }

    props.onChange({ target: { value: _value, name: name } });
  }

  return (
    <div>
      <CheckboxInput
        checked={value.includes('Investment')}
        onChange={handleChange}
        value="Investment"
        text="Investment"
      />
      <CheckboxInput
        checked={value.includes('PurchasingProcurement')}
        onChange={handleChange}
        value="PurchasingProcurement"
        text="Purchasing/Procurement"
      />
      <CheckboxInput
        checked={value.includes('Referrals')}
        onChange={handleChange}
        value="Referrals"
        text="Referrals & Introductions"
      />
      <CheckboxInput
        checked={value.includes('Mentorship')}
        onChange={handleChange}
        value="Mentorship"
        text="Mentorship"
      />
    </div>
  );
}

export default baseInput(OfferingInput);
