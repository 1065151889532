import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Container,
  Grid,
  Cell,
  Heading,
  Image,
  StrongText,
  Paragraph,
  ReviewerCard,
} from '../../components/ui';

export function CompanyDetail() {
  const company = {
    name: 'Investor Name',
    image: '//via.placeholder.com/160x160',
    rating: 4,
    company: 'Example Company',
    title: 'Example Title',
    city: 'Chicago',
    country: 'USA',
    reviews: 1345,
    investments: 4,
    average: '2 days',
    bio:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    sectors: ['Sector 1', 'Sector 2', 'Sector 3', 'Sector 4'],
    interests: ['Interest 1', 'Interest 2', 'Interest 3', 'Interest 4'],
  };

  const teamMembers = Array.apply(null, { length: 3 }).map((_, idx) => {
    return {
      id: idx + 1,
      image: '//via.placeholder.com/120x120',
      name: 'Investor Name',
      url: `/reviewers/${idx + 1}`,
      title: 'Example Title',
      company: 'Company Name',
      sectors: ['Sector 1', 'Sector 2', 'Sector 3', 'Sector 4', 'Sector 5'],
      pitches: ['Ventures', 'Products', 'Services'],
      rating: 4,
      service: '5 min review',
      price: 500,
    };
  });

  /*const suggestions = Array.apply(null, { length: 2 }).map((_, idx) => {
    return {
      id: idx + 1,
      image: '//via.placeholder.com/120x120',
      name: 'Investor Name',
      url: `/reviewers/${idx + 1}`,
      title: 'Example Title',
      company: 'Company Name',
      sectors: ['Sector 1', 'Sector 2', 'Sector 3', 'Sector 4', 'Sector 5'],
      pitches: ['Ventures', 'Products', 'Services'],
      rating: 4,
      service: '5 min review',
      price: 500,
    };
  });*/

  function renderList(list) {
    return list.join(', ');
  }

  return (
    <section className="CompanyDetail">
      <Container>
        <Grid align={'center'}>
          <Cell large={8}>
            <Grid auto spaced>
              <NavLink to={'/reviewers'}>
                <StrongText>Back to Results</StrongText>
              </NavLink>
            </Grid>
            <Grid spaced margin>
              <Cell medium={'shrink'} align={'center'}>
                <Image round src={company.image} alt={company.name} />
              </Cell>
              <Cell medium={'auto'}>
                <Heading level={1}>{company.name}</Heading>
                <Paragraph>
                  <StrongText>
                    {company.city}, {company.country}
                  </StrongText>
                </Paragraph>
                <br />
                <Paragraph>
                  <StrongText>Interested in the following sectors: </StrongText>
                  {renderList(company.interests)}
                </Paragraph>
              </Cell>
            </Grid>
            <br />
            <div>
              <Heading level={5}>About {company.name}</Heading>
              <Paragraph>{company.bio}</Paragraph>
              <button type="button">More</button>
            </div>
            <br />
            {teamMembers.map(member => {
              return (
                <ReviewerCard transparent={true} {...member} key={member.id} />
              );
            })}
          </Cell>
        </Grid>
        <br />
      </Container>
    </section>
  );
}

export default CompanyDetail;
