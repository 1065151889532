import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export function Container(props) {
  const classes = classNames({
    Container: true,
    'grid-container': true,
    fluid: props.fluid,
    full: props.full,
    'full-height': props.fullHeight,
  });

  return (
    <div style={props.style || {}} className={classes}>
      {props.children}
    </div>
  );
}

Container.propTypes = {
  fluid: PropTypes.bool,
  full: PropTypes.bool,
  fullHeight: PropTypes.bool,
};

export function Grid(props) {
  const classes = classNames({
    Grid: true,
    [`grid-${props.grid}`]: props.grid,
    'grid-margin-x': props.margin,
    'grid-padding-x': props.padding,
    'grid-margin-y': props.marginY,
    'grid-padding-y': props.paddingY,
    [`small-up-${props.smallBlock}`]: props.smallBlock,
    [`medium-up-${props.mediumBlock}`]: props.mediumBlock,
    [`large-up-${props.largeBlock}`]: props.largeBlock,
    [`align-${props.align}`]: props.align,
    'full-height': props.fullHeight,
    spaced: props.spaced,
  });

  return (
    <div style={props.style || {}} className={classes}>
      {props.children}
    </div>
  );
}

Grid.defaultProps = {
  grid: 'x',
  margin: false,
  padding: false,
  marginX: false,
  paddingX: false,
  smallBlock: null,
  mediumBlock: null,
  largeBlock: null,
  align: null,
  fullHeight: false,
  spaced: false,
};

Grid.propTypes = {
  grid: PropTypes.oneOf(['x', 'y']),
  margin: PropTypes.bool,
  padding: PropTypes.bool,
  marginX: PropTypes.bool,
  paddingX: PropTypes.bool,
  smallBlock: PropTypes.number,
  mediumBlock: PropTypes.number,
  largeBlock: PropTypes.number,
  align: PropTypes.oneOf([
    'top',
    'bottom',
    'middle',
    'center',
    'right',
    'left',
  ]),
  fullHeight: PropTypes.bool,
  spaced: PropTypes.bool,
};

export function Cell(props) {
  const classes = classNames({
    cell: true,
    [`small-${props.small}`]: props.small,
    [`medium-${props.medium}`]: props.medium,
    [`large-${props.large}`]: props.large,
    [`small-offset-${props.smallOffset}`]: props.smallOffset,
    [`medium-offset-${props.mediumOffset}`]: props.mediumOffset,
    [`large-offset-${props.largeOffset}`]: props.largeOffset,
    [`small-order-${props.smallOrder}`]: props.smallOrder,
    [`medium-order-${props.mediumOrder}`]: props.mediumOrder,
    [`large-order-${props.largeOrder}`]: props.largeOrder,
    auto: props.auto,
    shrink: props.shrink,
    [props.className]: props.className ? props.className : null,
  });

  return <div className={classes}>{props.children}</div>;
}

Cell.defaultProps = {
  small: null,
  medium: null,
  large: null,
  auto: false,
  shrink: false,
  smallOffset: null,
  mediumOffset: null,
  largeOffset: null,
  smallOrder: null,
  mediumOrder: null,
  largeOrder: null,
};

Cell.propTypes = {
  small: PropTypes.any,
  medium: PropTypes.any,
  large: PropTypes.any,
  auto: PropTypes.bool,
  shrink: PropTypes.bool,
  smallOffset: PropTypes.any,
  mediumOffset: PropTypes.any,
  largeOffset: PropTypes.any,
  smallOrder: PropTypes.any,
  mediumOrder: PropTypes.any,
  largeOrder: PropTypes.any,
};

export default {
  Grid,
  Cell,
  Container,
};
