import React from 'react';
import { Grid, Cell, TextInput } from '../../ui';
import './chat-message-form.scss';
import { useGlobalState } from '../../../store';
import { useForm } from '../../../hooks/forms';
import validate from 'validate.js';

const validationRules = {
  message: {
    presence: { allowEmpty: false },
  },
};

export function useSendMessage(sendbird) {
  const [activeChannel] = useGlobalState('activeChannel');
  const [, setMessages] = useGlobalState('activeMessages');

  function sendMessage(text) {
    const params = new sendbird.UserMessageParams();

    params.message = text;
    params.pushNotificationDeliveryOption = 'default';

    activeChannel.sendUserMessage(params, function(message, error) {
      if (error) {
        return;
      }

      setMessages(messages => {
        return [...messages, message];
      });
    });
  }

  return sendMessage;
}

export function ChatMessageForm(props) {
  const sendMessage = useSendMessage(props.sendbird);
  const { form, errors, setErrors, handleFormChange } = useForm(
    { message: '' },
    {
      validate: validateForm,
    }
  );

  function validateForm(form) {
    return validate(form, validationRules);
  }

  const inputProps = {
    placeholder: 'Enter your message',
    value: form.message,
    name: 'message',
    onChange: handleFormChange,
    errors: errors.message,
  };

  function submit(e) {
    e.preventDefault();

    const response = validateForm(form);

    if (response) {
      setErrors(response);
      return;
    } else {
      setErrors({});
    }

    sendMessage(form.message);
    handleFormChange({ target: { name: 'message', value: '' } });
    return false;
  }

  return (
    <form onSubmit={submit} className="ChatMessageForm">
      <Grid>
        <Cell auto>
          <TextInput {...inputProps} />
        </Cell>
        <Cell shrink>
          <button className="ChatMessageForm__button" type="submit">
            Send
          </button>
        </Cell>
      </Grid>
    </form>
  );
}

export default ChatMessageForm;
