import React, { useEffect } from 'react';
import { Container, Grid, Cell } from '../../components/ui';

var loadScript = function(src) {
  var tag = document.createElement('script');
  tag.async = false;
  tag.src = src;
  document.getElementsByTagName('body')[0].appendChild(tag);
};

export function Privacy(props) {
  useEffect(() => {
    loadScript('https://app.termly.io/embed-policy.min.js');
  }, []);

  return (
    <section className="Terms">
      <Container>
        <Grid align="center">
          <Cell auto small={8}>
            <div
              name="termly-embed"
              data-id="f77b444c-ca5c-4dc3-861f-e0e6af0aef2b"
              data-type="iframe"
            />
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export default Privacy;
