import React, { useState, useRef } from 'react';
import moment from 'moment';
import {
  SEARCH_PREVIEW_TOKENS,
  ADD_PREVIEW_TOKEN,
} from '../../../graphql/scouts';
import {
  Loader,
  Button,
  Grid,
  Cell,
  Container,
  Heading,
  Card,
  Pagination,
} from '../../../components/ui';
import { AdminSecondaryNav } from '../../../components/layout';
import { useQuery } from 'react-apollo-hooks';
import { useNotifications } from '../../../hooks/notifications';
import { useMutation } from '@apollo/react-hooks';
import { Tags, Tag } from '../../../components/ui/tags/tags';

export function Spaced(props) {
  return <div className="Spaced">{props.children}</div>;
}

export function AdminPreviewToken({ scout }) {
  const notifications = useNotifications();
  const copy = useRef();
  const link = `https://${window.location.hostname}/?preview=${scout.id}`;

  function copyLink() {
    copy.current.select();
    document.execCommand('copy');
    notifications.notify('Copied Invite Link', { time: 3000 });
  }

  return (
    <Card>
      <div>
        <Spaced>
          <Button onClick={copyLink} size={'small'}>
            {link}
          </Button>
        </Spaced>
        {scout.expires ? (
          <Tags>
            <Tag>
              Expires{' '}
              {moment(scout.expires * 1000).format('MM/DD/YYYY hh:mm a')}
            </Tag>
            {scout.user && <Tag>Created by: {scout.user.email}</Tag>}
            <Tag>
              Created at: {moment(scout.createdAt).format('MM/DD/YYYY hh:mm a')}
            </Tag>
          </Tags>
        ) : (
          <Tags>
            <Tag>Valid 4 hours from first use</Tag>
            {scout.user && <Tag>Created by: {scout.user.email}</Tag>}
            <Tag>
              Created at: {moment(scout.createdAt).format('MM/DD/YYYY hh:mm a')}
            </Tag>
          </Tags>
        )}
        <input
          onChange={e => {}}
          ref={copy}
          value={link}
          style={{
            position: 'absolute',
            top: '-9999px',
            left: '-9999px',
          }}
        />
      </div>
    </Card>
  );
}

export function AdminReviewers(props) {
  return props.scouts
    .sort((a, b) => {
      return moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf();
    })
    .reverse()
    .map(scout => {
      return <AdminPreviewToken key={scout.id} scout={scout} />;
    });
}

export function AdminReviewersLoader(props) {
  const [, setPage] = useState(1);

  const { data, loading, error } = useQuery(SEARCH_PREVIEW_TOKENS, {
    variables: {},
    fetchPolicy: 'network-only',
  });

  // if (loading) return <Loader />;
  if (error) return null;

  return (
    <div>
      {/*<Grid margin>
        <Cell small={12} medium={12}>
          <SearchInput
            value={filters.text}
            name="text"
            onChange={handleChange}
            spaced
            placeholder="Search Invites"
          />
        </Cell>
      </Grid>*/}

      {!loading ? (
        <AdminReviewers scouts={data.searchFeatureTokens.results} />
      ) : (
        <Loader />
      )}

      <div className="text-center">
        {!loading && data.searchFeatureTokens.pages > 1 && (
          <Pagination
            currentPage={data.searchPersonInvites.page}
            totalItems={data.searchPersonInvites.total}
            pageSize={data.searchPersonInvites.limit}
            maxPages={data.searchPersonInvites.pages}
            onPageChange={page => setPage(page)}
          />
        )}
      </div>
    </div>
  );
}

export function AdminReviewersWrapper(props) {
  const [addFeatureToken] = useMutation(ADD_PREVIEW_TOKEN, {
    refetchQueries: [{ query: SEARCH_PREVIEW_TOKENS, variables: {} }],
  });

  return (
    <Container>
      <Grid>
        <Cell small={6}>
          <Heading level={1}>
            <b>Vintro</b> Admin
          </Heading>
        </Cell>
        <Cell small={6}>
          <div className="text-right">
            <Button onClick={addFeatureToken}>Add Preview Token</Button>
          </div>
        </Cell>
        <Cell>
          <hr />
        </Cell>
      </Grid>
      <Grid margin>
        <Cell small={12} medium={3}>
          <AdminSecondaryNav />
        </Cell>
        <Cell small={12} medium={9}>
          <AdminReviewersLoader />
        </Cell>
      </Grid>
    </Container>
  );
}

export default AdminReviewersWrapper;
