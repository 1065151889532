import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import baseInput from '../base-input/base-input';

export function SelectInput(props) {
  const styles = {};
  if (props.minWidth) {
    styles.minWidth = props.minWidth;
  }

  const classes = classNames({
    Field: true,
    'Field--select': true,
    // 'Input--spaced': props.spaced,
  });

  return (
    <div className={classes} style={styles}>
      <select
        className={props.value ? 'select--has-value' : ''}
        name={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
        required={props.required}
        autoFocus={props.autoFocus}
        multiple={props.multiple}
        value={props.value}
      >
        {props.emptyOption && <option value="">{props.emptyOption}</option>}
        {props.options.map((o, idx) => (
          <option value={o.value} key={idx}>
            {o.label}
          </option>
        ))}
      </select>
    </div>
  );
}

SelectInput.defaultProps = {
  value: '',
  options: [],
  emptyOption: null,
  multiple: false,
  minWidth: null,
};

SelectInput.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  emptyOption: PropTypes.string,
  multiple: PropTypes.bool,
  minWidth: PropTypes.number,
};

export default baseInput(SelectInput);
