import './modal.scss';
import React from 'react';

export function Modal(props) {
  return (
    <div className="Modal">
      <div
        className="Modal--background"
        onClick={props.close ? props.close : e => null}
      />
      <div className="Modal--content">{props.children}</div>
    </div>
  );
}

export default Modal;
