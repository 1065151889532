import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import pusher from '../../../pusher/client';
import { useSession } from '../../../hooks/session';
import { Animated } from 'react-animated-css';
import { useMyVenturePitches } from '../../../graphql/ventures';
import history from '../../../util/history';

export function createPrimaryLinks(session = {}, profile = {}) {
  const links = [
    {
      text: 'Browse',
      component: NavLink,
      show: true,
      attributes: {
        to: '/',
        exact: true,
        activeClassName: 'PrimaryNav__link--active',
      },
      children: [],
    },
    {
      text: 'Join',
      component: 'span',
      show: !session.isAuthenticated,
      attributes: {},
      children: [
        {
          text: 'As Reviewer',
          component: NavLink,
          show: true,
          attributes: {
            to: '/apply',
            exact: true,
            activeClassName: 'PrimaryNav__link--active',
          },
          children: [],
        },
        {
          text: 'To Pitch',
          component: NavLink,
          show: true,
          attributes: {
            to: '/sign-up',
            exact: true,
            activeClassName: 'PrimaryNav__link--active',
          },
          children: [],
        },
      ],
    },
    {
      text: 'Why Vintro',
      component: 'span',
      show: true,
      attributes: {},
      children: [
        {
          text: "What's a Vintro",
          component: 'a',
          show: true,
          attributes: {
            href: 'https://support.myvintro.com/hc/en-us/articles/360035913093',
            target: '_blank',
            rel: 'noopener noreferrer',
          },
          children: [],
        },
        {
          text: 'Opportunity Analysis',
          component: 'a',
          show: true,
          attributes: {
            href: 'https://support.myvintro.com/hc/en-us/articles/360036067634',
            target: '_blank',
            rel: 'noopener noreferrer',
          },
          children: [],
        },
        {
          text: 'Cost Analysis',
          component: 'a',
          show: true,
          attributes: {
            href: 'https://support.myvintro.com/hc/en-us/articles/360035678474',
            target: '_blank',
            rel: 'noopener noreferrer',
          },
          children: [],
        },
        {
          text: 'Use Cases',
          component: 'a',
          show: true,
          attributes: {
            href: 'https://support.myvintro.com/hc/en-us/articles/360035140054',
            target: '_blank',
            rel: 'noopener noreferrer',
          },
          children: [],
        },
        {
          text: 'Buyer Problem Solved',
          component: 'a',
          show: true,
          attributes: {
            href:
              'https://support.myvintro.com/hc/en-us/articles/360035431054-Value-to-Ventures',
            target: '_blank',
            rel: 'noopener noreferrer',
          },
          children: [],
        },
        {
          text: 'Seller Problem Solved',
          component: 'a',
          show: true,
          attributes: {
            href: 'https://support.myvintro.com/hc/en-us/articles/360035431054',
            target: '_blank',
            rel: 'noopener noreferrer',
          },
          children: [],
        },
      ],
    },
    {
      text: 'Earn with Vintro',
      component: 'span',
      show: true,
      attributes: {},
      children: [
        {
          text: 'Join as Reviewer',
          component: 'a',
          show: true,
          attributes: {
            href:
              'https://support.myvintro.com/hc/en-us/articles/360035914393-',
            target: '_blank',
            rel: 'noopener noreferrer',
          },
          children: [],
        },
        {
          text: 'Referral Rewards',
          component: 'a',
          show: true,
          attributes: {
            href: 'https://support.myvintro.com/hc/en-us/articles/360035424354',
            target: '_blank',
            rel: 'noopener noreferrer',
          },
          children: [],
        },
      ],
    },
    {
      text: 'Sign In',
      component: NavLink,
      show: !session.isAuthenticated,
      attributes: {
        to: '/sign-in',
        exact: true,
        activeClassName: 'PrimaryNav__link--active',
      },
      children: [],
    },
  ];

  if (profile.features.dashboard) {
    links.push({
      text: <NavLinkBeacon />,
      component: NavLink,
      show: true,
      attributes: {
        to: '/dashboard',
        exact: true,
        activeClassName: 'PrimaryNav__link--active',
      },
      children: [],
    });
  }

  return links;
}

export const GET_NOTIFICATIONS = gql`
  query {
    getNotifications {
      messages
    }
  }
`;

export function UploadPitch() {
  const { data, loading, error } = useMyVenturePitches();

  if (loading) return null;
  if (error) return null;

  if (data && data.myVenture && data.myVenture.pitches.length === 0) {
    return (
      <li className="PrimaryNav__item">
        <span
          onClick={e => history.push('/dashboard/my-pitches')}
          className="PrimaryNav__link"
        >
          <span className="PrimaryNav__link-text">Upload Pitch</span>
        </span>
      </li>
    );
  }

  return null;
}

export function useListenForPaymentMethod() {
  const session = useSession();
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    var channel = pusher.subscribe('notifications');
    channel.bind(session.profile.id, function(data) {
      setEnabled(true);
    });
  }, [enabled, setEnabled, session.profile.id]);

  return enabled;
}

export function NavLinkBeacon() {
  const [hasNotifications, setHasNotifications] = useState();
  const session = useSession();
  const client = useApolloClient();

  useEffect(() => {
    async function checkNotifications() {
      console.log('checking!');
      try {
        const { data } = await client.query({
          query: GET_NOTIFICATIONS,
          fetchPolicy: 'network-only',
        });

        if (data.getNotifications.messages > 0) {
          setHasNotifications(true);
        } else {
          setHasNotifications(false);
        }
      } catch (e) {
        console.log(e);
      }
    }

    // const interval = setInterval(checkNotifications, 10000);
    checkNotifications();

    var channel = pusher.subscribe('notifications');
    channel.bind(session.profile.id, function(data) {
      checkNotifications();
    });

    return () => {
      console.log(pusher);
      pusher.unsubscribe('notifications');
    };
    // eslint-disable-next-line
  }, []);

  return (
    <span>
      Dashboard
      {hasNotifications && (
        <Animated
          animateOnMount={true}
          className="BeaconAnimated"
          animationOut={'fadeOut'}
          animationIn={'fadeIn'}
          isVisible={true}
        >
          <span className="Beacon" />{' '}
        </Animated>
      )}
    </span>
  );
}

export default createPrimaryLinks({}, { features: {} });
