import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const MY_INBOX = gql`
  query MY_INBOX {
    myInbox {
      items {
        ... on OrderRequest {
          id
          scout {
            id
            firstName
            lastName
            company
            companyLogo
            photo
          }
          status
          response {
            comment
            pitchQuality
            commercialViability
            openToCommunication
            type
            job {
              status
              thumbnail {
                url
              }
              audio {
                url
              }
              video {
                width
                height
                url
              }
            }
          }
          order {
            id
            pitch {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export function useGetMyInbox() {
  const { data, loading, error } = useQuery(MY_INBOX, {
    fetchPolicy: 'network-only',
  });

  return { data, loading, error };
}
