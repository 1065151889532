import React from 'react';
import PropTypes from 'prop-types';

export function FormStep(props) {
  if (props.step !== props.currentStep) return null;

  return <div className="FormStep">{props.children}</div>;
}

FormStep.propTypes = {
  step: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
};

export default FormStep;
