import React, { useState } from 'react';
import validate from 'validate.js';
import {
  Grid,
  Cell,
  Button,
  Heading,
  Container,
  Paragraph,
  Callout,
  PasswordInput,
} from '../../components/ui';

import { FormError } from '../../components/forms';

import { usePasswordReset } from '../../graphql/auth';
import { useForm } from '../../hooks/forms';
import { Link } from 'react-router-dom';

const validationRules = {
  password: {
    presence: true,
    length: {
      minimum: 6,
      message: 'must be at least 6 characters',
    },
  },
  confirmPassword: {
    presence: { allowEmpty: false },
    equality: 'password',
  },
};

export function ResetPasswordSuccess() {
  return (
    <section className="ReestPassword">
      <Container>
        <Grid align="center">
          <Cell large={6} medium={8} small={12}>
            <Heading level={1}>
              Reset <b>Password</b>
            </Heading>
            <Callout>
              <Heading level={2}>Success</Heading>
              <Paragraph>
                Your password has been reset. Click below to login with your new
                password.
              </Paragraph>
              <Link to="/sign-in">
                <Button>Login</Button>
              </Link>
            </Callout>
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export function ResetPassword(props) {
  const [success, setSuccess] = useState(false);
  const passwordReset = usePasswordReset();

  const { form, errors, setErrors, handleFormChange } = useForm(
    {
      password: '',
      confirmPassword: '',
    },
    { validate: validateForm }
  );

  function validateForm(form) {
    return validate(form, validationRules);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const response = validateForm(form);

    setErrors(errors => ({
      ...errors,
      ...response,
      server: null,
    }));

    if (response === undefined) {
      try {
        await passwordReset(props.match.params.token, form.password);
        setSuccess(true);
      } catch (e) {
        if (e.graphQLErrors && e.graphQLErrors[0]) {
          setErrors(errors => ({
            ...errors,
            server: e.graphQLErrors[0].message,
          }));
        }
      }
    }
  }

  if (success) {
    return <ResetPasswordSuccess />;
  }

  return (
    <section className="SignUp">
      <Container>
        <Grid align="center">
          <Cell large={6} medium={8} small={12}>
            <Heading level={1}>
              Reset <b>Password</b>
            </Heading>
            <Heading level={2}>
              Enter your email below to reset your password.
            </Heading>
            <hr />
            {errors.server && <FormError>{errors.server}</FormError>}
            <form onSubmit={handleSubmit}>
              <PasswordInput
                value={form.password}
                name={'password'}
                errors={errors.password}
                onChange={handleFormChange}
                placeholder={'Your Password'}
                label={'Password'}
                spaced
              />
              <PasswordInput
                value={form.confirmPassword}
                name={'confirmPassword'}
                errors={errors.confirmPassword}
                onChange={handleFormChange}
                placeholder={'Confirm Password'}
                label={'Confirm Password'}
                spaced
              />
              <Grid margin align={'center'} style={{ alignItems: 'center' }}>
                <Cell small={12} medium={12}>
                  <div className="text-right">
                    <Button type="submit">Submit</Button>
                  </div>
                </Cell>
              </Grid>
              {/*<Link>Forgot Password?</Link>*/}
            </form>
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export default ResetPassword;
