import './apply-wizard.scss';
import React from 'react';
import { Container, Grid, Cell, Heading, Loader } from '../../components/ui';

import { AppError } from '../../components/ui/errors/errors';
import { useMyProfile } from '../../graphql/scouts';

import PendingApproval from './steps/pending-approval';

export function getInitialStep(profile) {
  console.log(profile);

  if (!profile.familiar) {
    return 0;
  }

  if (profile.familiar && !profile.identified && !profile.known) {
    return 1;
  }

  if (profile.familiar && profile.identified && !profile.known) {
    return 2;
  }

  if (profile.familiar && !profile.payable) {
    return 2;
  }

  if (profile.familiar && profile.payable && !profile.approved) {
    return 2;
  }

  return 2;
}

export function getTotalSteps(profile) {
  if (profile.known) {
    return 2;
  } else {
    return 3;
  }
}

export function ApplyWizard(props) {
  // const [step, setStep] = useState(getInitialStep(props.profile));
  // const totalSteps = getTotalSteps(props.profile);
  // const Component = steps[step];

  return (
    <section className="ApplyWizard">
      <Container>
        <Grid spaced align="center">
          <Cell large={8}>
            <Heading level={6}>
              <b>Step {3}</b> of {3}
            </Heading>
            <PendingApproval />
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export function ApplyWizardLoader(props) {
  const { data, error, loading } = useMyProfile();

  if (loading) {
    return (
      <Container>
        <Grid spaced align="center">
          <Cell large={8}>
            <Loader />
          </Cell>
        </Grid>
      </Container>
    );
  }

  if (error) return <AppError error={`An application error occured`} />;
  if (!data.myProfile) return null;

  return <ApplyWizard profile={data.myProfile} {...props} />;
}

export default ApplyWizardLoader;
