import './label.scss';
import React from 'react';

export function Label(props) {
  return <label className="Label">{props.children}</label>;
}

export function Help(props) {
  return <label className="Help">{props.children}</label>;
}

export default Label;
