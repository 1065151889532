import React from 'react';
import './base-input.scss';
import Label from '../label/label';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputError from '../input-error/input-error';

function renderHelpText(text = null) {
  if (!text) return null;
  return <div className={'Input__helpText'}>{text}</div>;
}

function renderHelpTextTop(text = null) {
  if (!text) return null;
  return <div className={'Input__helpText Input__helpTextTop'}>{text}</div>;
}

export function renderErrors(errors = []) {
  if (errors.length === 0) return null;
  return errors.map((error, idx) => <InputError message={error} key={idx} />);
}

function renderLabel(text = null) {
  if (!text) return null;
  return <Label>{text}</Label>;
}

export const baseInput = Component => {
  const Wrapper = function(props) {
    const classes = classNames({
      Input: true,
      'Input--spaced': props.spaced,
      'Input--valid': props.valid,
      'Input--invalid': props.errors.length > 0 || props.invalid,
    });

    return (
      <div className={classes}>
        {renderLabel(props.label)}
        {renderHelpTextTop(props.helpTextTop)}
        <Component {...props} />
        {renderHelpText(props.helpText)}
        {renderErrors(props.errors)}
        <span />
      </div>
    );
  };

  Wrapper.displayName = `baseInput(${Component.displayName || Component.name})`;

  Wrapper.defaultProps = {
    label: null,
    errors: [],
    valid: false,
    invalid: false,
    disabled: false,
    onChange: () => null,
    onBlur: () => null,
    required: false,
    autoFocus: false,
    helpText: null,
    spaced: false,
    name: null,
  };

  Wrapper.propTypes = {
    label: PropTypes.string,
    errors: PropTypes.array,
    valid: PropTypes.bool,
    invalid: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    autoFocus: PropTypes.bool,
    helpText: PropTypes.string,
    spaced: PropTypes.bool,
    name: PropTypes.string,
  };

  return Wrapper;
};

export default baseInput;
