import React, { useState, useEffect } from 'react';
import { Container, Grid, Cell, Loader, Heading } from '../../components/ui';
import {
  ChatWindow,
  ChatSidebar,
  ChatContext,
  ChatMessageForm,
} from '../../components/chat';
import './chat.scss';
import dummyMessages from './dummy-messages';
import { useSession } from '../../hooks/session';
import SendBird from 'sendbird';
import { SecondaryNav } from '../../components/layout/secondary-nav/secondary-nav';
import { ReviewerNav } from '../../components/layout';
import { ReviewerDashboardHeader } from '../../components/ui/dashboard/header/header';

export function Chat(props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const { profile } = useSession();
  const [messages] = useState(
    dummyMessages.map(o => {
      return Object.assign(o, {
        user: {
          ...o.user,
          user_id: Math.random() >= 0.5 ? profile.user.id : null,
        },
      });
    })
  );

  const mobileContext = {
    menuOpen: menuOpen,
    onMobileToggle: onMobileToggle,
  };

  function onMobileToggle() {
    setMenuOpen(menuOpen => !menuOpen);
  }

  return (
    <div className="Chat">
      <Grid>
        <Cell>
          <div className="Chat__content">
            <Grid fullHeight>
              <Cell shrink>
                <ChatSidebar sendbird={props.sendbird} {...mobileContext} />
              </Cell>
              <Cell auto>
                <div className="Chat__display">
                  <Grid grid="y" fullHeight>
                    <Cell shrink>
                      <ChatContext {...mobileContext} />
                    </Cell>
                    <Cell auto>
                      <ChatWindow
                        sendbird={props.sendbird}
                        messages={messages}
                      />
                    </Cell>
                    <Cell shrink>
                      <ChatMessageForm sendbird={props.sendbird} />
                    </Cell>
                  </Grid>
                </div>
              </Cell>
            </Grid>
          </div>
        </Cell>
      </Grid>
    </div>
  );
}

export function useSendBird() {
  const [ready, setReady] = useState(false);
  const session = useSession();
  const sb = new SendBird({ appId: process.env.REACT_APP_SENDBIRD_APP });

  useEffect(() => {
    sb.connect(
      session.profile.id,
      session.profile.sendBirdAccessToken,
      (user, error) => {
        setReady(true);
      }
    );
    // eslint-disable-next-line
  }, []);

  return ready ? sb : false;
}

export function SendBirdLoader(props) {
  const session = useSession();
  const sendbird = useSendBird();

  return (
    <Container>
      <Grid>
        <Cell small={12}>
          {session.profile.type !== 'VENTURE' ? (
            <ReviewerDashboardHeader />
          ) : (
            <Heading level={1}>Chat</Heading>
          )}
          <hr />
        </Cell>
        <Cell small={12} large={2}>
          <Cell small={12} medium={3}>
            {session.profile.type === 'VENTURE' ? (
              <SecondaryNav />
            ) : (
              <ReviewerNav />
            )}
          </Cell>
        </Cell>
        <Cell small={12} large={10}>
          {!sendbird ? <Loader /> : <Chat {...props} sendbird={sendbird} />}
        </Cell>
      </Grid>
    </Container>
  );
}

export default SendBirdLoader;
