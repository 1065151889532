import gql from 'graphql-tag';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { useMutation } from 'react-apollo-hooks';

export const SEARCH_AFFILIATE_NETWORKS = gql`
  query {
    searchAffiliateNetworks {
      results {
        id
        name
      }
    }
  }
`;

export const SEARCH_AFFILIATES = gql`
  query($search: AffiliateSearchInput) {
    searchAffiliates(search: $search) {
      results {
        id
        shortid
        network {
          id
          name
        }
        parent {
          id
          shortid
        }
      }
    }
  }
`;

export const GET_AFFILIATE_NETWORK = gql`
  query($id: ID!) {
    getAffiliateNetwork(id: $id) {
      id
      name
    }
  }
`;

export function useGetAffiliateNetwork(id) {
  const { data, loading, error } = useQuery(GET_AFFILIATE_NETWORK, {
    variables: { id },
  });
  return { data, loading, error };
}

export const ADD_AFFILIATE = gql`
  mutation($total: Int = 1, $input: AffiliateInput) {
    addManyAffiliates(total: $total, input: $input) {
      id
    }
  }
`;

export function useAddAffiliate() {
  const addAffiliate = useMutation(ADD_AFFILIATE);

  async function exec(input, total = 1) {
    try {
      return await addAffiliate({
        variables: { input, total },
        refetchQueries: [
          {
            query: SEARCH_AFFILIATES,
            variables: { search: { network: input.network } },
          },
        ],
      });
    } catch (e) {
      throw e;
    }
  }

  return exec;
}

export const ADD_AFFILIATE_NETWORK = gql`
  mutation($input: AffiliateNetworkInput) {
    addAffiliateNetwork(input: $input) {
      id
    }
  }
`;

export function useAddAffiliateNetwork() {
  const addAffiliateNetwork = useMutation(ADD_AFFILIATE_NETWORK);

  async function exec(input) {
    try {
      return await addAffiliateNetwork({
        variables: { input },
      });
    } catch (e) {
      throw e;
    }
  }

  return exec;
}

export const GET_AFFILIATE = gql`
  query($id: ID!) {
    getAffiliate(id: $id) {
      id
      shortid
    }
  }
`;

export function useGetAffiliate(id) {
  const { data, loading, error } = useQuery(GET_AFFILIATE, {
    variables: { id },
  });
  return { data, loading, error };
}

export const EXPORT_AFFILIATE_NETWORK = gql`
  query($network: ID) {
    exportAffiliateNetwork(network: $network)
  }
`;

export function useExportAffiliateNetwork() {
  const client = useApolloClient();
  return async network => {
    const { data } = await client.query({
      query: EXPORT_AFFILIATE_NETWORK,
      variables: { network: network },
    });

    return data.exportAffiliateNetwork;
  };
}
