import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Cell, Heading, StarRating, StrongText, Loader } from '../../ui';
import './checkout-item.scss';
import { Link } from 'react-router-dom';
import * as store from 'store';

import missingImage from '../../../images/photo-missing.png';

import { useMutation } from '@apollo/react-hooks';
import { REMOVE_FROM_CART } from '../../../graphql/cart';
import { useNotifications } from '../../../hooks/notifications';

function renderTrashCanIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs />
      <title>bin</title>
      <path
        class="a"
        d="M17.25,21H6.75a1.5,1.5,0,0,1-1.5-1.5V6h13.5V19.5A1.5,1.5,0,0,1,17.25,21Z"
      />
      <line class="a" x1="9.75" y1="16.5" x2="9.75" y2="10.5" />
      <line class="a" x1="14.25" y1="16.5" x2="14.25" y2="10.5" />
      <line class="a" x1="2.25" y1="6" x2="21.75" y2="6" />
      <path
        class="a"
        d="M14.25,3H9.75a1.5,1.5,0,0,0-1.5,1.5V6h7.5V4.5A1.5,1.5,0,0,0,14.25,3Z"
      />
    </svg>
  );
}

export function CheckoutItem(props) {
  return (
    <div className="CheckoutItem">
      <Grid padding>
        <Cell shrink>
          <Link to={`/reviewers/${props.id}`}>
            <div className="CheckoutItem__photo">
              <div
                className="CheckoutItem__photo_background"
                style={{
                  backgroundImage: `url(${props.photo || missingImage})`,
                }}
              />
            </div>
          </Link>
        </Cell>
        <Cell auto>
          <Link to={`/reviewers/${props.id}`}>
            <Heading level={2} collapsed={true}>
              {props.firstName} {props.lastName}
            </Heading>
          </Link>
          <Heading level={3}>
            <b>{props.title}</b> @ {props.company}
          </Heading>

          <StarRating rating={props.averageRating || 5} />
        </Cell>
        <Cell shrink>
          <StrongText>{`$${props.price}`}</StrongText>
        </Cell>
        {props.onRemove && (
          <Cell shrink>
            <CheckoutItemRemove id={props.id} />
          </Cell>
        )}
      </Grid>
    </div>
  );
}

export function CheckoutItemRemove({ id }) {
  const notifications = useNotifications();
  const [removeFromCart, { loading }] = useMutation(REMOVE_FROM_CART);

  async function submit() {
    if (loading) return;

    try {
      await removeFromCart({
        variables: {
          input: {
            person: id,
            id: store.get('cart'),
          },
        },
      });
    } catch (e) {
      notifications.notify(
        `There was an error removing your item from the cart.`,
        { error: true, time: 3000 }
      );
    }
  }

  return (
    <button className="CheckoutItem__button" type="button" onClick={submit}>
      {loading ? <Loader /> : renderTrashCanIcon()}
    </button>
  );
}

CheckoutItem.defaultProps = {
  image: '',
  name: '',
  title: '',
  rating: 0,
  price: null,
  onRemove: null,
};

CheckoutItem.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  rating: PropTypes.number,
  price: PropTypes.any,
  onRemove: PropTypes.any,
};

export default CheckoutItem;
