import React from 'react';
import { Container, Grid, Cell } from '../../components/ui';

export function Home() {
  return (
    <Container>
      <Grid>
        <Cell>
          <h1>Home Page</h1>
        </Cell>
      </Grid>
    </Container>
  );
}

export default Home;
