import React from 'react';
import {
  Container,
  Grid,
  Cell,
  Loader,
  Paragraph,
  Heading,
  Button,
} from '../../components/ui';
import { useQuery } from 'react-apollo-hooks';
import { MY_VENTURE, useUpdateVenture } from '../../graphql/ventures';
import 'dragula/dist/dragula.min.css';

import FormStepTwo from '../create-company/steps/step.two';
import { useGlobalState } from '../../store';

import { useForm } from '../../hooks/forms';
import { AppError } from '../../components/ui/errors/errors';
import { SecondaryNav } from '../../components/layout/secondary-nav/secondary-nav';

export function CreateAccount(props) {
  const [, setNotifications] = useGlobalState('notifications');
  const updateVenture = useUpdateVenture();
  const { venture = {} } = props;
  // const updateVenture = useMutation(UPDATE_VENTURE);
  const {
    form,
    errors,
    handleNestedFormChange,
    handledRemoveNestedForm,
    handleAddNestedForm,
  } = useForm({
    name: venture.name,
    description: venture.description || '',
    website: venture.website || '',
    country: venture.country || '',
    state: venture.state || '',
    city: venture.city || '',
    logo: venture.logo || '',
    team: (venture.team || [{ social: {} }]).map(member => {
      return {
        firstName: member.firstName || '',
        lastName: member.lastName || '',
        title: member.title || '',
        bio: member.bio || '',
        photo: member.photo || '',
        linkedIn: member.social.linkedIn,
      };
    }),
    idea_name: '',
    idea_type: '',
    idea_description: '',
    industry: '',
    vertical: '',
    ask: [],
    pitch: '',
  });

  async function submit() {
    const input = {
      id: venture.id,
      team: form.team.map(member => ({
        firstName: member.firstName || '',
        lastName: member.lastName || '',
        title: member.title || '',
        bio: member.bio || '',
        photo: member.photo || null,
        social: {
          linkedIn: member.linkedIn,
        },
      })),
    };

    try {
      updateVenture(input);
      setNotifications([
        { message: `Your team has been updated.`, time: 3000 },
      ]);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <section className="CreateAccount">
      <Grid spaced align="center">
        <Cell large={12}>
          <Heading level={3}>Your Team</Heading>
          <hr />
          <Paragraph>
            Sometimes the team is as important as the idea. Add your team so
            investors can get to know you.
          </Paragraph>
          <FormStepTwo
            form={form}
            errors={errors}
            handleAddTeamMember={handleAddNestedForm}
            handleRemoveTeamMember={handledRemoveNestedForm}
            handleUpdateTeamMember={handleNestedFormChange}
          />
        </Cell>
        <Cell>
          <Button onClick={submit}>Save Team</Button>
        </Cell>
      </Grid>
    </section>
  );
}

export function MyTeamLoader(props) {
  const { data, error, loading } = useQuery(MY_VENTURE, {
    suspend: false,
    fetchPolicy: 'network-only',
  });

  if (loading)
    return (
      <Container>
        <Grid>
          <Cell>
            <Loader />
          </Cell>
        </Grid>
      </Container>
    );

  if (error) return <AppError />;

  return <CreateAccount venture={data.myVenture} {...props} />;
}

export function MyTeamWrapper(props) {
  return (
    <section className="MyTeam">
      <Container>
        <Grid>
          <Cell>
            <Heading level={1}>
              My <b>Company</b>
            </Heading>
            <hr />
          </Cell>
        </Grid>
        <Grid>
          <Cell small={12} medium={3}>
            <SecondaryNav />
          </Cell>
          <Cell small={12} medium={9}>
            <MyTeamLoader />
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export default MyTeamWrapper;
