import React, { useRef } from 'react';
import { Grid, Cell, Heading, Button } from '../../../components/ui';
import TextInput from '../../../components/ui/inputs/text/text';
import LogoInput from '../../../components/ui/inputs/logo/logo';
import CheckboxInput from '../../../components/ui/inputs/checkbox/checkbox';
import Label, { Help } from '../../../components/ui/inputs/label/label';
import TextareaInput from '../../../components/ui/inputs/textarea/textarea';
import NumberInput from '../../../components/ui/inputs/number/number';
import { useForm } from '../../../hooks/forms';
import SelectInput from '../../../components/ui/inputs/select/select';
import { useUpdatePerson } from '../../../graphql/scouts';
import validate from 'validate.js';
import { useNotifications } from '../../../hooks/notifications';
import { renderErrors } from '../../../components/ui/inputs/base-input/base-input';
import countryOptions from '../../../util/country-options';
import TagsInput from '../../../components/ui/inputs/tags/tags';

const investmentRanges = [
  {
    label: '$1K - $10K',
    value: '$1K - $10K',
    range: { low: 1000, high: 10000 },
  },
  {
    label: '$10K - $50K',
    value: '$10K - $50K',
    range: { low: 10000, high: 50000 },
  },
  {
    label: '$50K - $100K',
    value: '$50K - $100K',
    range: { low: 50000, high: 100000 },
  },
  {
    label: '$100K - $250K',
    value: '$100K - $250K',
    range: { low: 100000, high: 250000 },
  },
  {
    label: '$250K - $500K',
    value: '$250K - $500K',
    range: { low: 250000, high: 500000 },
  },
  {
    label: '$500K - $1M',
    value: '$500K - $1M',
    range: { low: 500000, high: 1000000 },
  },
  {
    label: '$1M - $5M',
    value: '$1M - $5M',
    range: { low: 1000000, high: 5000000 },
  },
  {
    label: '$5M - $10M',
    value: '$5M - $10M,',
    range: { low: 5000000, high: 10000000 },
  },
  {
    label: '$10M - $25M',
    value: '$10M - $25M,',
    range: { low: 10000000, high: 25000000 },
  },
  {
    label: '$25M - $100M',
    value: '$25M - $100M',
    range: { low: 25000000, high: 100000000 },
  },
];

export function Spaced(props) {
  return <div className="Spaced">{props.children}</div>;
}

export function findInvestmentRange(desired = {}) {
  const option = investmentRanges.find(option => {
    return (
      option.range.high === desired.high && option.range.low === desired.low
    );
  });

  if (option) {
    return option.value;
  }

  return '';
}

export function getDefaultFormState(profile) {
  return {
    firstName: profile.firstName || '',
    lastName: profile.lastName || '',
    photo: profile.photo || '',
    photoCrop: profile.photoCrop ? { url: profile.photoCrop.url } : {},
    title: profile.title || '',
    company: profile.company || '',
    linkedIn: profile.linkedIn || '',
    companyLogo: profile.companyLogo || '',
    companyLogoCrop: profile.companyLogoCrop
      ? { url: profile.companyLogoCrop.url }
      : {},
    country: profile.locality ? profile.locality.country || '' : '',
    city: profile.locality ? profile.locality.city || '' : '',
    postal: profile.locality ? profile.locality.postal || '' : '',
    bio: profile.bio || '',
    bioLookingFor: profile.bioLookingFor || '',
    bioCanProvide: profile.bioCanProvide || '',
    isDonatingToCharity: profile.isDonatingToCharity ? 'true' : 'false',
    donatingToCharityName: profile.donatingToCharityName || '',
    isFollowable: profile.isFollowable ? 'true' : 'false',
    investmentDesiredRange: findInvestmentRange(
      profile.investmentDesiredRange || {}
    ),
    investmentLargestRange: findInvestmentRange(
      profile.investmentLargestRange || {}
    ),
    investmentDesiredStage: profile.investmentDesiredStage || [],
    price: profile.cost,
    tags: profile.tags ? profile.tags.map(tag => tag.id) : [],
    types: profile.types || [],
    offering: profile.offering || [],
  };
}

export function DisplayInfo(props) {
  const notifications = useNotifications();
  const updateProfile = useUpdatePerson();
  const { form, setForm, errors, setErrors, handleFormChange } = useForm(
    getDefaultFormState(props.profile),
    {
      validate: validateForm,
    }
  );

  const refs = {
    firstName: useRef(),
    lastName: useRef(),
    photo: useRef(),
    title: useRef(),
    company: useRef(),
    country: useRef(),
    city: useRef(),
    postal: useRef(),
    state: useRef(),
    types: useRef(),
    bio: useRef(),
    bioLookingFor: useRef(),
    bioCanProvide: useRef(),
    offering: useRef(),
    investmentDesiredRange: useRef(),
    investmentLargestRange: useRef(),
    investmentDesiredStage: useRef(),
    price: useRef(),
    isDonatingToCharity: useRef(),
    donatingToCharityName: useRef(),
    isFollowable: useRef(),
  };

  const validationRules = {
    firstName: {
      presence: { allowEmpty: false },
    },
    lastName: {
      presence: { allowEmpty: false },
    },
    photo: {
      presence: { allowEmpty: false },
    },
    title: {
      presence: { allowEmpty: false },
    },
    price: {
      presence: { allowEmpty: false },
      numericality: {
        greaterThan: 24,
      },
    },
    company: {
      presence: { allowEmpty: false },
    },
    country: {
      presence: { allowEmpty: false },
    },
    city: {
      presence: { allowEmpty: false },
    },
    postal: {
      presence: { allowEmpty: false },
    },
    types: {
      length: {
        minimum: 1,
        tooShort: '%{count} or more is required',
      },
    },
    bio: {
      presence: { allowEmpty: false },
    },
    /* bioLookingFor: {
      presence: { allowEmpty: false },
    },*/
    /* bioCanProvide: {
      presence: { allowEmpty: false },
    },*/
    isDonatingToCharity: {
      presence: { allowEmpty: false },
    },
    /*isFollowable: {
      presence: { allowEmpty: false },
    },*/
    offering: {
      length: {
        minimum: 1,
        tooShort: '%{count} or more is required',
      },
    },
  };

  if (form.offering.includes('Investment')) {
    validationRules.investmentDesiredRange = {
      presence: {
        allowEmpty: false,
        message: `Average check size should not be blank. If you are not sure or haven't made an investment yet, please give your best estimate.`,
      },
    };

    validationRules.investmentDesiredStage = {
      length: {
        minimum: 1,
        tooShort: '%{count} or more is required',
      },
    };
  }

  if (form.isDonatingToCharity === 'true') {
    validationRules.donatingToCharityName = {
      presence: { allowEmpty: false },
    };
  }

  function handleFormChangeWithSync(e) {
    handleFormChange(e);
    handleSyncState(e, { ...form, [e.target.name]: e.target.value });
  }

  function validateForm(form) {
    return validate(form, validationRules);
  }

  function getPersonInput(form) {
    const input = {
      id: props.profile.id,
      firstName: form.firstName,
      lastName: form.lastName,
      photo: form.photo,
      photoCrop: form.photoCrop,
      linkedIn: form.linkedIn,
      title: form.title,
      company: form.company,
      companyLogo: form.companyLogo,
      companyLogoCrop: form.companyLogoCrop,
      locality: {
        country: form.country,
        city: form.city,
        postal: form.postal,
      },
      // first bracket
      bio: form.bio,
      bioLookingFor: form.bioLookingFor,
      bioCanProvide: form.bioCanProvide,
      // second bracket,
      isDonatingToCharity: form.isDonatingToCharity === 'true' ? true : false,
      donatingToCharityName: form.donatingToCharityName,
      isFollowable: form.isFollowable === 'true' ? true : false,
      tags: form.tags,
      offering: form.offering,
      investmentDesiredStage: form.investmentDesiredStage || [],
      types: form.types,
      price: Math.round(form.price),
    };

    if (form.offering.includes('Investment')) {
      if (form.investmentDesiredRange) {
        input.investmentDesiredRange = investmentRanges.find(option => {
          return option.value === form.investmentDesiredRange;
        }).range;
      }

      if (form.investmentLargestRange) {
        input.investmentLargestRange = investmentRanges.find(option => {
          return option.value === form.investmentLargestRange;
        }).range;
      }
    }

    return input;
  }

  function handleChangeBracket(e) {
    const value = e.target.value;

    setForm(form => {
      let types;
      if (form.types.includes(value)) {
        types = [...form.types].filter(v => v !== value);
      } else {
        types = [...form.types, value];
      }

      handleSyncState(e, { ...form, types });

      if (errors.types) {
        setErrors(validateForm({ ...form, types }) || {});
      }

      return { ...form, types };
    });
  }

  function handleChangeOffering(e) {
    const value = e.target.value;

    setForm(form => {
      let offering;
      if (form.offering.includes(value)) {
        offering = [...form.offering].filter(v => v !== value);
      } else {
        offering = [...form.offering, value];
      }

      handleSyncState(e, { ...form, offering });

      if (errors.offering) {
        setErrors(validateForm({ ...form, offering }) || {});
      }

      return { ...form, offering };
    });

    handleSyncState();
  }

  function handleChangeInvestmentDesiredStage(e) {
    const value = e.target.value;

    setForm(form => {
      let investmentDesiredStage;
      if (form.investmentDesiredStage.includes(value)) {
        investmentDesiredStage = [...form.investmentDesiredStage].filter(
          v => v !== value
        );
      } else {
        investmentDesiredStage = [...form.investmentDesiredStage, value];
      }

      if (errors.investmentDesiredStage) {
        setErrors(validateForm({ ...form, investmentDesiredStage }) || {});
      }

      handleSyncState(e, { ...form, investmentDesiredStage });

      return { ...form, investmentDesiredStage };
    });
  }

  async function handleSyncState(e, formState) {
    const input = getPersonInput(formState || form);

    try {
      await updateProfile(input);
    } catch (e) {
      console.log(e);
    }
  }

  async function submit() {
    const input = getPersonInput(form);

    const response = validateForm(form);

    setErrors(errors => ({
      ...errors,
      ...response,
    }));

    const keys = Object.keys(response || []);

    if (keys[0] && refs[keys[0]] && refs[keys[0]].current) {
      window.scrollTo({
        top:
          refs[keys[0]].current.getBoundingClientRect().top +
          window.scrollY -
          170,
        behavior: 'smooth',
      });
    }

    if (response !== undefined) {
      notifications.notify('One or more of your fields are required', {
        time: 3000,
        error: true,
      });
      return;
    }

    try {
      input.familiar = true;
      await updateProfile(input);
      props.nextStep();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Grid margin>
      <Cell small={12}>
        <Heading level={1}>Tell us about yourself</Heading>
        <Heading level={2}>
          Your profile helps the right people find you and pitch to you. <br />
          This information will be displayed publicly on your profile.
        </Heading>
      </Cell>
      <Cell small={12} medium={6}>
        <div ref={refs.firstName}>
          <TextInput
            spaced
            name="firstName"
            errors={errors.firstName}
            value={form.firstName}
            onChange={handleFormChange}
            onBlur={handleSyncState}
            label="First Name"
            placeholder="First Name"
          />
        </div>
      </Cell>
      <Cell small={12} medium={6}>
        <div ref={refs.lastName}>
          <TextInput
            spaced
            name="lastName"
            errors={errors.lastName}
            value={form.lastName}
            onChange={handleFormChange}
            onBlur={handleSyncState}
            label="Last Name"
            placeholder="Last Name"
          />
        </div>
      </Cell>
      <Cell small={12} medium={12}>
        <div ref={refs.photo}>
          <LogoInput
            spaced
            name="photo"
            cropName="photoCrop"
            onCropSuccessMessage={`Your profile photo edit has been saved successfully. It will become available momentarily`}
            crop={form.photoCrop}
            errors={errors.photo}
            value={form.photo}
            onChange={handleFormChangeWithSync}
            onCropChange={handleFormChangeWithSync}
            label="Profile Picture"
          />
        </div>
      </Cell>
      <Cell small={12} medium={12}>
        <TextInput
          spaced
          name="linkedIn"
          value={form.linkedIn}
          onChange={handleFormChange}
          errors={errors.linkedIn}
          onBlur={handleSyncState}
          label="LinkedIn (Optional)"
          placeholder="LinkedIn"
        />
      </Cell>
      <Cell small={12} medium={6}>
        <div ref={refs.title}>
          <TextInput
            spaced
            name="title"
            value={form.title}
            errors={errors.title}
            onChange={handleFormChange}
            onBlur={handleSyncState}
            label="Title"
            placeholder="Title"
          />
        </div>
      </Cell>
      <Cell small={12} medium={6}>
        <div ref={refs.company}>
          <TextInput
            spaced
            name="company"
            value={form.company}
            errors={errors.company}
            onChange={handleFormChange}
            onBlur={handleSyncState}
            label="Organization"
            placeholder="Organization"
          />
        </div>
      </Cell>
      <Cell small={12} medium={12}>
        <LogoInput
          name="companyLogo"
          onCropSuccessMessage={`Your profile logo edit has been saved successfully. It will become available momentarily`}
          value={form.companyLogo}
          errors={errors.companyLogo}
          onChange={handleFormChangeWithSync}
          onCropChange={handleFormChangeWithSync}
          cropName="companyLogoCrop"
          crop={form.companyLogoCrop}
          cropType={'round'}
          spaced
          label="Organization Logo (Optional)"
        />
      </Cell>
      <Cell small={12} medium={4}>
        <div ref={refs.country}>
          <SelectInput
            options={countryOptions}
            spaced
            value={form.country}
            name="country"
            errors={errors.country}
            onChange={handleFormChange}
            onBlur={handleSyncState}
            label="Country/Region"
            placeholder="Country"
          />
        </div>
      </Cell>
      <Cell small={12} medium={4}>
        <div ref={refs.city}>
          <TextInput
            spaced
            name="city"
            value={form.city}
            errors={errors.city}
            onChange={handleFormChange}
            onBlur={handleSyncState}
            label="City"
            placeholder="City"
          />
        </div>
      </Cell>
      <Cell small={12} medium={4}>
        <div ref={refs.postal}>
          <TextInput
            spaced
            name="postal"
            value={form.postal}
            errors={errors.postal}
            onChange={handleFormChange}
            onBlur={handleSyncState}
            label="Postal"
            placeholder="Postal"
          />
        </div>
      </Cell>
      <Cell>
        <div ref={refs.types}>
          <Label>
            I should appear when users search for (check all that apply):
          </Label>
          {renderErrors(errors.types)}
          <Spaced>
            <CheckboxInput
              checked={form.types.includes('HighNetWorthIndividual')}
              onChange={handleChangeBracket}
              value="HighNetWorthIndividual"
              text="High Net-Worth Individuals"
            />
            <CheckboxInput
              checked={form.types.includes('Corporations')}
              onChange={handleChangeBracket}
              value="Corporations"
              text="Corporations"
            />
            <CheckboxInput
              checked={form.types.includes('InvestmentFirms')}
              onChange={handleChangeBracket}
              value="InvestmentFirms"
              text="Investment Firms"
            />
            <CheckboxInput
              checked={form.types.includes('NonGovermentalOrganizations')}
              onChange={handleChangeBracket}
              value="NonGovermentalOrganizations"
              text="Non-governmental Organizations"
            />
            <CheckboxInput
              checked={form.types.includes('SocialImpactInvestors')}
              onChange={handleChangeBracket}
              value="SocialImpactInvestors"
              text="Social Impact Investors"
            />
            <CheckboxInput
              checked={form.types.includes('MassAffluentorAccreditedInvestors')}
              onChange={handleChangeBracket}
              value="MassAffluentorAccreditedInvestors"
              text="Mass Affluent/Accredited Investors"
            />
            <CheckboxInput
              checked={form.types.includes('StrategicAdvisor')}
              onChange={handleChangeBracket}
              value="StrategicAdvisor"
              text="Strategic Advisers"
            />
            <CheckboxInput
              checked={form.types.includes('CreativeTalents')}
              onChange={handleChangeBracket}
              value="CreativeTalents"
              text="Creative Talents"
            />
          </Spaced>
        </div>
      </Cell>
      <Cell small={12}>
        <Heading level={1}>Profile Text</Heading>
      </Cell>
      <Cell small={12}>
        <div ref={refs.bio}>
          <Label>Who I am</Label>
          <Help>Share your professional background/personal story.</Help>
          <TextareaInput
            name="bio"
            value={form.bio}
            errors={errors.bio}
            onChange={handleFormChange}
            onBlur={handleSyncState}
            spaced
          />
        </div>
      </Cell>
      <Cell small={12}>
        <div ref={refs.bioLookingFor}>
          <Label>What I look for</Label>
          <Help>
            Who should pitch to you? What resources can you mobilize to help
            them?
          </Help>
          <TextareaInput
            name="bioLookingFor"
            errors={errors.bioLookingFor}
            value={form.bioLookingFor}
            onChange={handleFormChange}
            onBlur={handleSyncState}
            spaced
          />
        </div>
      </Cell>
      {/*<Cell small={12}>
        <Label>
          What help/resource/input I can provide besides one-time advice and
          feedback?
        </Label>
      </Cell>
      <Cell small={12}>
        <div ref={refs.bioCanProvide}>
          <TextareaInput
            name="bioCanProvide"
            errors={errors.bioCanProvide}
            value={form.bioCanProvide}
            onChange={handleFormChange}
            onBlur={handleSyncState}
            spaced
          />
        </div>
  </Cell>*/}
      <Cell small={12}>
        <div ref={refs.offering}>
          <Label>
            Users should pitch to me if they are looking for (check all that
            apply):
          </Label>
          {renderErrors(errors.offering)}
          <Spaced>
            <CheckboxInput
              checked={form.offering.includes('Investment')}
              onChange={handleChangeOffering}
              value="Investment"
              text="Investment"
            />
            <CheckboxInput
              checked={form.offering.includes('PurchasingProcurement')}
              onChange={handleChangeOffering}
              value="PurchasingProcurement"
              text="Purchasing/Procurement"
            />
            <CheckboxInput
              checked={form.offering.includes('Referrals')}
              onChange={handleChangeOffering}
              value="Referrals"
              text="Referrals & Introductions"
            />
            <CheckboxInput
              checked={form.offering.includes('Mentorship')}
              onChange={handleChangeOffering}
              value="Mentorship"
              text="Mentorship"
            />
          </Spaced>
        </div>
      </Cell>
      {form.offering.includes('Investment') && (
        <Cell small={12}>
          <div ref={refs.investmentDesiredRange}>
            <SelectInput
              emptyOption="Select a range"
              errors={errors.investmentDesiredRange}
              options={investmentRanges}
              value={form.investmentDesiredRange}
              name="investmentDesiredRange"
              onChange={handleFormChange}
              onBlur={handleSyncState}
              spaced
              label="Average check size (not published to the public)?"
            />
          </div>
        </Cell>
      )}
      {form.offering.includes('Investment') && (
        <Cell small={12}>
          <div ref={refs.investmentDesiredStage}>
            <Label>
              What stage(s) do you prefer to invest in? (check all that apply):
            </Label>
            {renderErrors(errors.investmentDesiredStage)}
            <Spaced>
              <CheckboxInput
                checked={form.investmentDesiredStage.includes('PreSeedFunding')}
                onChange={handleChangeInvestmentDesiredStage}
                value="PreSeedFunding"
                text="Pre-Seed Funding"
              />
              <CheckboxInput
                checked={form.investmentDesiredStage.includes('SeedFunding')}
                onChange={handleChangeInvestmentDesiredStage}
                value="SeedFunding"
                text="Seed Funding"
              />
              <CheckboxInput
                checked={form.investmentDesiredStage.includes('SeriesA')}
                onChange={handleChangeInvestmentDesiredStage}
                value="SeriesA"
                text="Series A"
              />
              <CheckboxInput
                checked={form.investmentDesiredStage.includes('SeriesB')}
                onChange={handleChangeInvestmentDesiredStage}
                value="SeriesB"
                text="Series B"
              />
              <CheckboxInput
                checked={form.investmentDesiredStage.includes('SeriesC')}
                onChange={handleChangeInvestmentDesiredStage}
                value="SeriesC"
                text="Series C"
              />
              <CheckboxInput
                checked={form.investmentDesiredStage.includes(
                  'LateStageVentureFinancing'
                )}
                onChange={handleChangeInvestmentDesiredStage}
                value="LateStageVentureFinancing"
                text="Late-stage Venture Financing"
              />
              <CheckboxInput
                checked={form.investmentDesiredStage.includes(
                  'MezzanineFinancing'
                )}
                onChange={handleChangeInvestmentDesiredStage}
                value="MezzanineFinancing"
                text="Mezzanine Financing"
              />
            </Spaced>
          </div>
        </Cell>
      )}
      {/*{form.offering.includes('Investment') && (
        <Cell small={12}>
          <Label>
            If you have made investments in the past, what’s the largest sum you
            have ever put in?
          </Label>
          <Help>Please leave this field empty you have not invested yet.</Help>
          <div ref={refs.investmentLargestRange}>
            <SelectInput
              emptyOption="Select a range"
              options={investmentRanges}
              value={form.investmentLargestRange}
              name="investmentLargestRange"
              onChange={handleFormChange}
              onBlur={handleSyncState}
              spaced
              placeholder="Choose a range"
            />
          </div>
        </Cell>
      )}*/}
      <Cell small={12}>
        <Heading level={1}>What do you want to charge for a Vintro?</Heading>
        <Label>
          A Vintro is a unit of interaction between two people. Someone pays for
          your time and sends over a 60-90 second pitch video. Any time in the
          next 2 weeks, you spend around 3 to 4 minutes responding to that video
          with actionable feedback and then decide if you want to follow up
          about the opportunity. In total this will take around 5 minutes of
          your time. (minimum $25)
        </Label>
        {parseInt(form.price) > 0 && (
          <Help>
            Great! You will earn $<b>{parseInt(form.price) * 0.75}</b> per
            review. Vintro will take <b>25%</b> in each charged transaction to
            cover any costs related to platform usage and development.
          </Help>
        )}
        <div ref={refs.price}>
          <NumberInput
            spaced
            name="price"
            errors={errors.price}
            value={form.price}
            onChange={handleFormChange}
            onBlur={handleSyncState}
          />
        </div>
      </Cell>
      <Cell small={12}>
        <div ref={refs.isDonatingToCharity}>
          <Label>
            Would you pledge to support a charity with your proceeds from
            Vintro? (We will support one-click charity donations soon after
            launch.)
          </Label>
          {renderErrors(errors.isDonatingToCharity)}
          <Spaced>
            <CheckboxInput
              checked={form.isDonatingToCharity === 'true'}
              onChange={handleFormChangeWithSync}
              name="isDonatingToCharity"
              value={true}
              text="Yes"
            />
            <CheckboxInput
              checked={form.isDonatingToCharity === 'false'}
              onChange={handleFormChangeWithSync}
              name="isDonatingToCharity"
              value={false}
              text="No"
            />
          </Spaced>
        </div>
      </Cell>
      {form.isDonatingToCharity === 'true' && (
        <Cell small={12}>
          <div ref={refs.donatingToCharityName}>
            <TextInput
              spaced
              value={form.donatingToCharityName}
              onChange={handleFormChange}
              onBlur={handleSyncState}
              errors={errors.donatingToCharityName}
              name="donatingToCharityName"
              label="Name of charity"
              placeholder=""
            />
          </div>
        </Cell>
      )}
      <Cell small={12}>
        {/*<div ref={refs.isFollowable}>
          <Label>Consent to being followed?</Label>
          {renderErrors(errors.isFollowable)}
          <Help>
            If the buyers also give their consent , would you agree to let third
            party investors also watch the videos being pitched specifically to
            you? There will be a fee charged to the third party investors for
            this ability, if you agree, of which 50% of the fee (after bank
            charges/transaction fees) would be credited to you.
          </Help>
          <Spaced>
            <CheckboxInput
              checked={form.isFollowable === 'true'}
              onChange={handleFormChangeWithSync}
              name="isFollowable"
              value={true}
              text="Yes"
            />
            <CheckboxInput
              checked={form.isFollowable === 'false'}
              onChange={handleFormChangeWithSync}
              name="isFollowable"
              value={false}
              text="No"
            />
          </Spaced>
            </div>*/}
      </Cell>
      <Cell>
        <Spaced>
          <Heading level={1}>Interest Tags</Heading>
          <Label>
            Let ventures know your interests. Please select all that apply.
          </Label>
        </Spaced>
      </Cell>
      <Cell>
        <Spaced>
          <TagsInput
            name="tags"
            onChange={handleFormChangeWithSync}
            value={form.tags}
          />
        </Spaced>
      </Cell>
      <Cell>
        <div className="text-right">
          <Button onClick={submit}>Next Step</Button>
        </div>
      </Cell>
    </Grid>
  );
}

export default DisplayInfo;
