import React from 'react';
import baseInput from '../base-input/base-input';

import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/dist/style.css';
import './phone.scss';

export function PhoneInput(props) {
  function onChange(value) {
    if (props.onChange) {
      props.onChange({
        target: {
          name: props.name,
          value: value,
        },
      });
    }
  }

  return (
    <ReactPhoneInput
      disableAreaCodes={true}
      defaultCountry={'us'}
      value={props.value}
      preferredCountries={['us']}
      onChange={onChange}
    />
  );
}

export default baseInput(PhoneInput);
