import './terms.scss';
import React from 'react';
import { Container, Grid, Cell, Heading } from '../../components/ui';

export function Terms(props) {
  return (
    <section className="Terms">
      <Container>
        <Grid align="center">
          <Cell auto small={8}>
            <p className="p2">&nbsp;</p>
            <Heading level={1}>
              <span className="s1">Talent Terms of Service</span>
            </Heading>
            <p className="p1">&nbsp;</p>

            <p className="p4">
              <span className="s1">Revised: August 22, 2019</span>
            </p>

            <p className="p4">
              <span className="s1">
                These Terms of Service ("
                <strong>
                  <em>Terms</em>
                </strong>
                ") govern your use of the Vintro website ("
                <strong>
                  <em>Site</em>
                </strong>
                ") and any Vintro mobile application and other services offered
                by The Krowd, Inc ("
                <strong>
                  <em>Services</em>
                </strong>
                "). The Krowd, Inc ("
                <strong>
                  <em>we</em>
                </strong>
                ." "
                <strong>
                  <em>us</em>
                </strong>
                ," or "
                <strong>
                  <em>Vintro</em>
                </strong>
                ") provides the Site and Services. "
                <strong>
                  <em>You</em>
                </strong>
                " refers to you as a high net worth investor, corporations,
                celebrity, and other person who control resources, user of the
                Site or Services.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                BY CLICKING "I AGREE" OR BY USING THE SITE OR SERVICES, YOU ARE
                AGREEING TO THESE TERMS. PLEASE READ THEM CAREFULLY.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                1. <strong>Your Obligations</strong>
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                Vintro Feedback. You agree to watch and consider an
                entrepreneurs or product or service provider&rsquo;s or other
                person who is using our site&rsquo;s, 60-90 second pitch video
                uploaded to the Site using the Services and provide one (1)
                typed, or audio recorded reply of your thought, reaction,
                advice, and feedback via our website for each user ("
                <strong>
                  <em>End User</em>
                </strong>
                ") who requests such from you ("
                <strong>
                  <em>Vintro Feedback</em>
                </strong>
                "). You should expect to use 5 minutes of your time to complete
                the interaction broken down into 1-1.5min watching the video
                submitted by the End User and 3.5-4min to produce your reply.
                After you deliver Vintro Feedback, your feedback will be given a
                1-5 star rating (from the ("
                <strong>
                  <em>End User</em>
                </strong>
                "), so please be as thoughtful and constructive as possible.
                Good feedback is specific and actionable and more information is
                available on our website in the FAQ on best practices for
                feedback. You can decide to take the conversation further if you
                are interested in doing so (but there is no obligation), this is
                done via a chat function that the platform contains and you can
                opt to open communication through after leaving feedback. You
                are free to reject any request within the two week window s. You
                will have sole discretion over the content of any Vintro
                Feedback. You may refuse to create or upload Vintro Feedback if
                an End User&rsquo;s request is objectionable or otherwise
                offensive to you in your sole discretion, if this is the case,
                please use the reporting functionality in the platform to flag
                the content so our internal review team can take appropriate
                steps. No money will be paid to you for any rejected Vintro
                Feedback request. All Vintro Feedback must be uploaded within
                fourteen days (14 days) of the request being approved by our
                internal review team and your subsequent notification thereof.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                Additional Promotional Materials. You will provide to us the
                following promotional materials ("
                <strong>
                  <em>Promotional Materials</em>
                </strong>
                ") upon completion of account signup and creation: (1) one high
                resolution image of yourself, (2) your Site profile bio, (3)
                your areas of interest or expertise, (4) your price per hour for
                your time and (5) your employer (if any). From time-to-time we
                may request additional Promotional Materials.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                2. <strong>Fees and Payment</strong>
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                Fees: Subject to these Terms, we will pay you seventy five
                percent (75%) of the revenue received by Vintro from each End
                User for each Vintro Feedback sold by you on the Site through
                the Services. You will set your own price for each Vintro
                Feedback, provided, however, that your price must be at least
                twenty-five dollars ($25) per Vintro Feedback. Vintro Feedback
                are non-guild activities and there will be no residual or any
                other type of payment due in connection with them.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                Payment: You must register with Payment Rails and provide the
                required bank account information in order to receive payment
                from Vintro. Funds become <strong>&ldquo;</strong>available
                <strong>&rdquo;</strong> 1-2 weeks after the End User has
                purchased feedback (after the transaction has finished pending
                from the bank), and the Vintro Feedback is completed. Once per
                month all available funds are paid out to Talent User&rsquo;s
                bank accounts they have submitted to Payment Rails during the
                onboarding process sans the appropriate payout fee imposed by
                the Payment Rails platform. Payout fees to bank accounts in the
                USA (via ACH) or Canada (via EFT) are 1 USD for each transfer.
                Payout fees to bank accounts in most other regions are 4 USD per
                transfer. Details on payout fees for all other types of accounts
                can be found in the FAQ on our website and on Payment Rails
                website. Payment Rails is not operated by or associated with
                Vintro and your use of Payment Rails is subject to their terms.
                We reserve the right to change payment providers at any time and
                to require you to sign up with that new payment provider. We are
                not responsible for any delays or failures to receive payment
                caused by a third party payment provider or by your failure to
                timely or properly set up an account with the payment provider
                or to otherwise provide requested information for payment.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                3. <strong>User Content</strong>
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                The Site and some of our Services allow you to upload, submit,
                store, send, or receive content and data, including your Vintro
                Feedback and Promotional Materials ("
                <strong>
                  <em>User Content</em>
                </strong>
                "). You retain ownership of any intellectual property rights
                that you hold in your User Content.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                When you upload, submit, store, send, or receive User Content to
                or through the Site or Services, you give us permission to
                reproduce and use your User Content as follows: you grant to us
                and those we work with a license to use, host, store, reproduce,
                modify, create derivative works (such as translations,
                adaptations, or other changes we make so that User Content works
                better with the Site and Services), publicly perform, publicly
                display, and distribute your User Content on the Site and also
                on social media and on other websites and media. This license is
                for the limited purpose of operating, promoting, and improving
                the Site and Services, and to develop new Services. Our license
                to your User Content is non-exclusive, meaning you may use the
                User Content for your own purposes or let others use your User
                Content for their purposes. This license is fully-paid and
                royalty free, meaning we do not owe you anything else in
                connection with our use of your User Content. We may exercise
                our rights under this license anywhere in the world. We may
                sublicense our rights to End Users and otherwise as needed to
                provide the Site and Services. Lastly, this license is
                perpetual, meaning that our rights under this license continue
                even after you stop using the Site and Services. You agree that
                we may display advertising with or in connection with your User
                Content.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">You represent and warrant that:</span>
            </p>
            <p className="p6">&nbsp;</p>
            <ul className="ul1">
              <li className="li4">
                <span className="s1">
                  you own all rights in and to your User Content and that you
                  have the right to give us the rights described above;
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li4">
                <span className="s1">
                  you have paid and will pay in full any fees or other payments
                  that may be related to the use of your User Content; and
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li5">
                <span className="s1">
                  your User Content does not infringe the intellectual property
                  rights, privacy rights, publicity rights, or other legal
                  rights of any third party
                </span>
              </li>
            </ul>
            <p className="p5">
              <span className="s1">
                Any User Content will be non-confidential and non-proprietary
                and we will not be liable for any use or disclosure of User
                Content. You acknowledge and agree that your relationship with
                us is not a confidential, fiduciary, or other type of special
                relationship, and that your decision to submit any User Content
                does not place us in a position that is any different from the
                position held by members of the general public, including with
                regard to your User Content. None of your User Content will be
                subject to any obligation of confidence on our part and we will
                not be liable for any use or disclosure of any User Content you
                provide.
                <br /> <span className="Apple-converted-space">&nbsp; </span>
                <br /> We may refuse to accept or transmit User Content for any
                reason. We may remove User Content from the Site or Services for
                any reason.
                <br /> <span className="Apple-converted-space">&nbsp; </span>
                <br /> If you cancel your Vintro account, you may request that
                Vintro no longer display your Vintro Feedback on the Site or
                through the Services and that we not make any new uses of your
                Vintro Feedback. We cannot, however, restrict the use of your
                Vintro Feedback by the End Users for whom you created them and
                we cannot remove any other existing uses of your Vintro
                Feedback.
                <br />{' '}
              </span>
              <span className="s2">
                {' '}
                <span className="Apple-converted-space">&nbsp; </span>
              </span>
            </p>
            <ol className="ol1">
              <li className="li4">
                <span className="s1">
                  <strong>
                    Ownership
                    <br />{' '}
                  </strong>{' '}
                  <br /> Other than User Content, we own or license all right,
                  title, and interest in and to (a) the Site and Services,
                  including all software, text, media, and other content
                  available on the Site and Services ("
                  <strong>
                    <em>Our Content</em>
                  </strong>
                  "); and (b) our trademarks, logos, and brand elements ("
                  <strong>
                    <em>Marks</em>
                  </strong>
                  "). The Site and Services, Our Content, and Marks are all
                  protected under U.S. and international laws. The look and feel
                  of the Site and Services are copyright &copy; The Krowd, Inc
                  All rights reserved. You may not duplicate, copy, or reuse any
                  portion of the HTML/CSS, Javascript, or visual design elements
                  or concepts without express written permission from us.
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li5">
                <span className="s1">
                  <strong>
                    Additional Terms
                    <br />{' '}
                  </strong>{' '}
                </span>
              </li>
            </ol>
            <p className="p1">
              <span className="s1">
                {' '}
                <span className="Apple-converted-space">&nbsp; </span>
              </span>
            </p>
            <p className="p4">
              <span className="s1">
                Some of our Services have additional terms and conditions ("
                <strong>
                  <em>Additional Terms</em>
                </strong>
                "). Where Additional Terms apply to a Service, we will make them
                available for you to read through your use of that Service. By
                using that Service, you agree to the Additional Terms.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                6. <strong>Eligibility</strong>
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                You must be at least 16 years old to use the Site or the
                Services. If you are under the age of majority in your state of
                residence, a minor, your parent or legal guardian must agree to
                these Terms on your
              </span>{' '}
              <span className="s1">
                behalf and you may only access and use the Site and Services
                with permission from your parent or legal guardian.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                7. <strong>Acceptable Use of the Site and Services</strong>
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                You are responsible for your use of the Site and Services, and
                for any use of the Site or Services made using your account. Our
                goal is to create a positive, useful, and safe user experience.
                To promote this goal, we prohibit certain kinds of conduct that
                may be harmful to other users or to us. When you use the Site or
                Services, you may not:
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">&bull; violate any law or regulation;</span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                &bull; violate, infringe, or misappropriate other people&rsquo;s
                intellectual property, privacy, publicity, or other legal
                rights;
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                &bull; post or share anything that is illegal, abusive,
                harassing, harmful to reputation, pornographic, indecent,
                profane, obscene, hateful, racist, or otherwise objectionable;
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                &bull; send unsolicited or unauthorized advertising or
                commercial communications, such as spam;
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                &bull; engage in spidering or harvesting, or participate in the
                use of software, including spyware, designed to collect data
                from the Site or Services;
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                &bull; transmit any viruses or other computer instructions or
                technological means whose purpose is to disrupt, damage, or
                interfere with the use of computers or related systems;
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                &bull; stalk, harass, or harm another individual;
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                &bull; impersonate any person or entity or perform any other
                similar fraudulent activity, such as phishing;
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                &bull; use any means to scrape or crawl any Web pages contained
                in the Site;
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                &bull; attempt to circumvent any technological measure
                implemented by us or any of our providers or any other third
                party (including another user) to protect the Site or Services;
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                &bull; attempt to decipher, decompile, disassemble, or reverse
                engineer any of the software or other underlying code used to
                provide the Site or Services; or
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                &bull; advocate, encourage, or assist any third party in doing
                any of the foregoing.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                You acknowledge that we are not obligated to monitor your (or
                any other person&rsquo;s) access to or use of the Site or
                Services, but we have the right to do so in order to operate the
                Site or Services, to ensure your compliance with these Terms, or
                to comply with applicable law or the order or requirement of a
                court, administrative agency or other governmental body.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                8. <strong>Copyright and Intellectual Property Policy</strong>
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                We respond to notices of alleged copyright infringement and
                terminate accounts of repeat infringers according to the process
                set out in the U.S. Digital Millennium Copyright Act. If you
                believe that your work has been copied in a way that constitutes
                copyright infringement, please forward the following information
                to the Copyright Agent named below:
              </span>
            </p>
            <p className="p6">&nbsp;</p>
            <ul className="ul1">
              <li className="li4">
                <span className="s1">
                  Your address, telephone number, and email address.
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li4">
                <span className="s1">
                  A description of the copyrighted work that you claim has been
                  infringed.
                  <br />{' '}
                </span>
              </li>
              <li className="li4">
                <span className="s1">
                  A description of where the alleged infringing material is
                  located.
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li4">
                <span className="s1">
                  A statement by you that you have a good faith belief that the
                  disputed use is not authorized by
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                  <br /> you, the copyright owner, its agent, or the law.
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li4">
                <span className="s1">
                  An electronic or physical signature of the person authorized
                  to act on behalf of the owner of the copyright interest.
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li5">
                <span className="s1">
                  A statement by you, made under penalty of perjury, that the
                  above information is accurate and that you are the copyright
                  owner or authorized to act on behalf of the copyright owner.
                </span>
              </li>
            </ul>
            <p className="p5">
              <span className="s1">
                Copyright Agent:
                <br /> <span className="Apple-converted-space">&nbsp; </span>
                <br /> Max Sugrue
                <br /> The Krowd, Inc. 420 N Wabash Ave Chicago,
                max@myvintro.com
                <br /> <span className="Apple-converted-space">&nbsp; </span>
                <br /> For clarity, only copyright infringement notices should
                go to our Copyright Agent. You acknowledge that if you fail to
                comply with all of the requirements of this section your notice
                may not be valid.
                <br /> <span className="Apple-converted-space">&nbsp; </span>
                <br /> If you believe the content that was removed (or to which
                access was disabled) is not infringing, or that you have the
                authorization from the copyright owner, the copyright owner's
                agent, or pursuant to the law, to post and use such content, you
                may submit a counter-notice to the address listed above
                containing the following information:
              </span>
              <span className="s2">
                {' '}
                <span className="Apple-converted-space">&nbsp; </span>
              </span>
            </p>
            <ul className="ul1">
              <li className="li4">
                <span className="s1">
                  Your physical or electronic signature;
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li4">
                <span className="s1">
                  Identification of the content that has been removed or to
                  which access has been disabled and
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                  <br /> the location at which the content appeared before it
                  was removed or disabled;
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li4">
                <span className="s1">
                  A statement that you have a good faith belief that the content
                  was removed or disabled as a result of mistake or a
                  misidentification of the content; and
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li5">
                <span className="s1">
                  Your name, physical address, telephone number, and e-mail
                  address, a statement that you consent to the jurisdiction of
                  the federal court in Chicago, Illinois, and a statement that
                  you will accept service of process from the person who
                  provided notification of the alleged infringement.
                </span>
                <span className="s2">
                  {' '}
                  <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
            </ul>
            <p className="p4">
              <span className="s1">
                After we receive your counter-notification, we will forward it
                to the party who submitted the original claim of copyright
                infringement. Please note that when we forward the
                counter-notification, it includes your personal information. By
                submitting a counter-notification, you consent to having your
                information revealed in this way. We will not forward the
                counter-notification to any party other than the original
                claimant.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                After we send out the counter-notification, the claimant must
                then notify us within 10 days that he or she has filed an action
                seeking a court order to restrain you from engaging in
                infringing activity relating to the content that was removed or
                disabled. If we receive such notification we will be unable to
                restore the material. If we do not receive such notification, we
                may reinstate the material.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                9. <strong>Privacy</strong>
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                Your privacy is very important to us. Our Privacy Policy
                explains how we collect, use, protect, and when we share
                personal information and other data with others. You are
                responsible for maintaining the confidentiality of your account
                information, including your username and password. You are
                responsible for all activities that occur under your account and
                you agree to notify us immediately of
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p1">&nbsp;</p>
            <p className="p1">
              <span className="s1">
                {' '}
                <span className="Apple-converted-space">&nbsp; </span>
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p1">&nbsp;</p>
            <p className="p1">&nbsp;</p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                any unauthorized access or use of your account. We are not
                responsible or liable for any damage or loss related to any
                unauthorized access or use of your account.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                10. <strong>Third Party Content and Interactions</strong>
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                The Site and Services may contain features and functionalities
                that may link you or provide you with access to third party
                content which is completely independent of us, including
                websites, directories, servers, networks, systems, information
                and databases, applications, software, programs, products or
                services, and the Internet in general. Your interactions with
                organizations or individuals, including End Users, found on or
                through the Site and Services are solely between you and such
                organizations or individuals. You should make whatever
                investigation you feel necessary or appropriate before
                proceeding with any interaction with any of these third parties.
                You agree that we are not responsible or liable for any loss or
                damage of any kind or nature incurred as the result of any such
                dealings. If there is a dispute between users of the Site or
                Services, or between users and any third party, you understand
                and agree that we are under no obligation to become involved. In
                the event that you have a dispute with any other user of the
                Site or Services, you hereby release us and our affiliates, and
                all of our officers, employees, agents, and successors from
                claims, demands, and damages (actual and consequential) of every
                kind or nature, known and unknown, suspected and unsuspected,
                disclosed and undisclosed, arising out of or in any way related
                to such disputes or the Site and Services. IF YOU ARE A ILLINOIS
                RESIDENT, YOU WAIVE ILLINOIS CIVIL CODE SECTION 1542, WHICH
                PROVIDES: A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE
                CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE
                TIME OF EXECUTING THE RELEASE, WHICH, IF KNOWN BY HIM MUST HAVE
                MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                11. <strong>Independent Contractor</strong>
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                You and Vintro agree and declare that these Terms create an
                independent contractor relationship and it is the parties&rsquo;
                express intent that their relationship be interpreted and held
                to be that of independent contractor for all purposes. You are
                not a joint venturer with, or franchisee, partner, agent, or
                employee of Vintro. You are solely and exclusively responsible
                for determining the manner, method, details, and means of your
                performance under these Terms. We have no right to, and will
                not, control the manner or determine the method of accomplishing
                your performance. You assume sole liability for and will pay all
                employment taxes (including Social Security), income taxes, and
                other reports required by your activities under these Terms and
                will abide by all federal, state, and local laws governing your
                performance under these Terms. You will use your own equipment
                to perform your obligations under these Terms. You are solely
                responsible for making any disclosures required by law or any
                agreements you may have with any third parties to any person or
                entity regarding your performance under this Agreement. The
                parties&rsquo; relationship is non-exclusive, meaning that you
                can provide similar services to other organizations on terms and
                at times determined by you and that we can and do engage others
                to provide similar services to those contemplated by these
                Terms.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                12. <strong>Links</strong>
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                The Site and Services may contain links to other websites and
                online resources. A link to a third party&rsquo;s website does
                not mean that we endorse it or that we are affiliated with it.
                We are not responsible or liable for any damage or loss related
                to the use of any third-party website. You should always read
                the terms and conditions and privacy policy of a third-party
                website before using it.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                13. <strong>Changes to the Site or Services</strong>
              </span>
            </p>
            <p className="p4">
              <span className="s1">
                We enhance and update the Site and Services often. We may change
                or discontinue the Site or Services
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                at any time, with or without notice to you.{' '}
              </span>
            </p>
            <p className="p4">
              <span className="s1">
                14. <strong>Termination</strong>
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                You may cancel your account at any time through a link provided
                in your account on the Site. We reserve the right to not provide
                the Site or Services to any person. We also reserve the right to
                terminate any user&rsquo;s right to access the Site or Services
                at any time, in our discretion. If you violate any of these
                Terms, your permission to use the Site and Services
                automatically terminates.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                15. <strong>Disclaimer and Limitations on Our Liability</strong>
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                YOU USE THE SITE AND SERVICES AT YOUR OWN RISK. THE SITE AND
                SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. TO
                THE EXTENT PERMITTED BY APPLICABLE LAW, VINTRO AND ITS OFFICERS,
                EMPLOYEES, DIRECTORS, SHAREHOLDERS, PARENTS, SUBSIDIARIES,
                AFFILIATES, AGENTS, AND LICENSORS ("
                <strong>
                  <em>AFFILIATES</em>
                </strong>
                ") DISCLAIM ALL WARRANTIES, CONDITIONS, AND REPRESENTATIONS OF
                ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE,
                INCLUDING THOSE RELATED TO MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, AND NON-INFRINGEMENT AND THOSE ARISING OUT
                OF COURSE OF DEALING OR USAGE OF TRADE.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                IN PARTICULAR, VINTRO AND ITS AFFILIATES MAKE NO REPRESENTATIONS
                OR WARRANTIES ABOUT THE ACCURACY OR COMPLETENESS OF CONTENT
                AVAILABLE ON OR THROUGH THE SITE OR SERVICES, OR THE CONTENT OF
                ANY WEBSITES OR ONLINE SERVICES LINKED TO OR INTEGRATED WITH THE
                SITE OR SERVICES. VINTRO AND ITS AFFILIATES WILL HAVE NO
                LIABILITY FOR ANY: (A) ERRORS, MISTAKES, OR INACCURACIES OF
                CONTENT; (b) PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM
                YOUR ACCESS TO OR USE OF THE SITE OR SERVICES; (c) ANY
                UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS OR OF ANY PERSONAL
                INFORMATION OR USER DATA; (d) ANY INTERRUPTION OF TRANSMISSION
                TO OR FROM THE SITE OR SERVICES; (e) ANY BUGS, VIRUSES, TROJAN
                HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED ON OR THROUGH THE
                SITE OR SERVICES BY ANY THIRD PARTY; OR (f) ANY LOSS OR DAMAGE
                OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
                POSTED OR SHARED THROUGH THE SITE OR SERVICES.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                YOU UNDERSTAND AND AGREE THAT ANY MATERIAL OR INFORMATION
                DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SITE OR
                SERVICES IS DONE AT YOUR OWN RISK AND THAT YOU WILL BE SOLELY
                RESPONSIBLE FOR ANY DAMAGE ARISING FROM DOING SO. NO ADVICE OR
                INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR
                THROUGH THE SITE OR SERVICES WILL CREATE ANY WARRANTY NOT
                EXPRESSLY MADE
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                WILL WE BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT,
                SPECIAL, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES
                (INCLUDING FOR LOSS OF PROFITS, REVENUE, OR DATA) OR FOR THE
                COST OF OBTAINING SUBSTITUTE PRODUCTS ARISING OUT OF OR IN
                CONNECTION WITH THESE TERMS, HOWEVER CAUSED, WHETHER SUCH
                LIABILITY ARISES FROM ANY CLAIM BASED UPON CONTRACT, WARRANTY,
                TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, AND
                WHETHER OR NOT WE&rsquo;VE BEEN ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, OUR TOTAL
                CUMULATIVE LIABILITY TO YOU OR ANY THIRD PARTY UNDER THESE
                TERMS, FROM ALL CAUSES OF ACTION AND ALL THEORIES OF LIABILITY,
                WILL BE LIMITED TO AND WILL NOT EXCEED THE FEES YOU HAVE
                ACTUALLY PAID US DURING THE TWELVE (12) MONTHS PRECEDING THE
                CLAIM GIVING RISE TO SUCH LIABILITY.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                You understand and agree that we have set our prices and entered
                into these Terms with you in reliance upon the limitations of
                liability set forth in these Terms, which allocate risk between
                us and form the basis of a bargain between the parties.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                16. <strong>Indemnification </strong>
              </span>
            </p>
            <p className="p4">
              <span className="s1">
                You agree to indemnify and hold harmless Vintro and its
                Affiliates from and against any and all claims, costs,
                proceedings, demands, losses, damages, and expenses (including,
                without limitation, reasonable attorney&rsquo;s fees and legal
                costs) of any kind or nature, arising from or relating to, (a)
                any actual or alleged breach of these Terms by you or anyone
                using your account, (b) your, or anyone using your account,
                violation of any laws or regulations, or (c) your gross
                negligence or willful misconduct. If we assume the defense of
                such a matter, you will reasonably cooperate with us in such
                defense.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                17.{' '}
                <strong>
                  Arbitration Agreement &amp; Waiver of Certain Rights
                </strong>
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                You and Vintro agree that we will resolve any disputes between
                us through binding and final arbitration instead of through
                court proceedings. You and Vintro hereby waive any right to a
                jury trial of any Claim (defined below). All controversies,
                claims, counterclaims, or other disputes arising between you and
                Vintro relating to these Terms, the Site, and the Services (each
                a "
                <strong>
                  <em>Claim</em>
                </strong>
                ") shall be submitted for binding arbitration in accordance with
                the Rules of the American Arbitration Association ("
                <strong>
                  <em>AAA Rules</em>
                </strong>
                "). The arbitration will be heard and determined by a single
                arbitrator. The arbitrator's decision in any such arbitration
                will be final and binding upon the parties and may be enforced
                in any court of competent jurisdiction. The parties agree that
                the arbitration will be kept confidential and that the existence
                of the proceeding and any element of it (including, without
                limitation, any pleadings, briefs or other documents submitted
                or exchanged and any testimony or other oral submissions and
                awards) will not be disclosed beyond the arbitration
                proceedings, except as may lawfully be required in judicial
                proceedings relating to the arbitration or by applicable
                disclosure rules and regulations of securities regulatory
                authorities or other governmental agencies.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                If you demonstrate that the costs of arbitration will be
                prohibitive as compared to the costs of litigation, Vintro will
                pay as much of the administrative costs and arbitrator's fees
                required for the arbitration as the arbitrator deems necessary
                to prevent the cost of the arbitration from being prohibitive.
                In the final award, the arbitrator may apportion the costs of
                arbitration and the compensation of the arbitrator among the
                parties in such amounts as the arbitrator deems appropriate.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                This arbitration agreement does not preclude you or Vintro from
                seeking action by federal, state, or local government agencies.
                You and Vintro also have the right to bring qualifying claims in
                small claims court. In addition, you and Vintro retain the right
                to apply to any court of competent jurisdiction for provisional
                relief, including pre-arbitral attachments or preliminary
                injunctions, and any such request shall not be deemed
                incompatible with these Terms, nor a waiver of the right to have
                disputes submitted to arbitration as provided in these Terms.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                Neither you nor Vintro may act as a class representative or
                private attorney general, nor participate as a member of a class
                of claimants, with respect to any Claim. Claims may not be
                arbitrated on a class or representative basis. The arbitrator
                can decide only your and/or Vintro&rsquo;s individual Claims.
                The arbitrator may not consolidate or join the claims of other
                persons or parties who may be similarly situated.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                If any provision of this Section is found to be invalid or
                unenforceable, then that specific provision shall be of no force
                and effect and shall be severed, but the remainder of this
                Section shall continue in full force and effect. No waiver of
                any provision of this Section of the Terms will be effective or
                enforceable unless recorded in a writing signed by the party
                waiving such a right or requirement. Such a waiver shall not
                waive or affect any other portion of this Terms. This Section of
                the Terms will survive the termination of your relationship with
                Vintro
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                THIS SECTION LIMITS CERTAIN RIGHTS, INCLUDING THE RIGHT TO
                MAINTAIN A COURT ACTION, THE RIGHT TO A JURY TRIAL, THE RIGHT TO
                PARTICIPATE IN ANY FORM OF CLASS OR REPRESENTATIVE CLAIM, THE
                RIGHT TO ENGAGE IN DISCOVERY EXCEPT AS PROVIDED IN AAA RULES,
                AND THE RIGHT TO CERTAIN REMEDIES AND FORMS OF RELIEF. OTHER
                RIGHTS THAT YOU OR VINTRO WOULD HAVE IN COURT ALSO MAY NOT BE
                AVAILABLE IN ARBITRATION.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                18. <strong>Other Provisions</strong>
              </span>
            </p>
            <p className="p4">
              <span className="s1">
                Under no circumstances will we be held liable for any delay or
                failure in performance due in whole or in part to any acts of
                nature or other causes beyond our reasonable control.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                These Terms will be governed by and construed in accordance with
                the laws of the State of Illinois, without giving effect to any
                conflict of laws rules or provisions.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                You agree that any action of whatever nature arising from or
                relating to these Terms, the Site, or Services will be filed
                only in the state or federal courts located in Chicago,
                Illinois. You consent and submit to the personal jurisdiction of
                such courts for the purposes of any such action.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                If any provision of these Terms is found to be unlawful or
                unenforceable, then that provision will be deemed severable from
                these Terms and will not affect the enforceability of any other
                provisions.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                The failure by us to enforce any right or provision of these
                Terms will not prevent us from enforcing such right or provision
                in the future.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                We may assign our rights and obligations under these Terms,
                including in connection with a merger, acquisition, sale of
                assets or equity, or by operation of law.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                18. <strong>Politically Exposed Person (PEP)</strong>.
              </span>
            </p>
            <p className="p4">
              <span className="s1">
                There are special concerns regarding use of the Site if you
                qualify as a PEP based on the definition
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">below.</span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                If you are a foreign PEP. A foreign PEP is a person who holds or
                has held one of the following offices or positions in or on
                behalf of a foreign state:
              </span>
            </p>
            <p className="p6">&nbsp;</p>
            <ul className="ul1">
              <li className="li4">
                <span className="s1">
                  Head of State or Head of Government;
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li4">
                <span className="s1">
                  Member of the Executive Council of Government or Member of a
                  Legislature;
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li4">
                <span className="s1">
                  Deputy Minister;
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li4">
                <span className="s1">
                  Ambassador, or Attach&eacute; or Counsellor of an Ambassador;
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li4">
                <span className="s1">
                  Military Officer with a rank of General or above;
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li4">
                <span className="s1">
                  President of a State-Owned Company or a State-Owned Bank;
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li4">
                <span className="s1">
                  Head of a Government Agency;
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li4">
                <span className="s1">
                  Judge of a Supreme Court, Constitutional Court or other Court
                  of last resort; or
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li5">
                <span className="s1">
                  Leader or President of a Political Party represented in a
                  Legislature.
                </span>
              </li>
            </ul>
            <p className="p5">
              <span className="s1">
                If you are a domestic PEP. A domestic PEP is a person who holds
                or has held one of the following offices:
              </span>
              <span className="s2">
                {' '}
                <span className="Apple-converted-space">&nbsp; </span>
              </span>
            </p>
            <ul className="ul1">
              <li className="li4">
                <span className="s1">
                  President or Vice President
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li4">
                <span className="s1">
                  State Governor, or Lieutenant Governor;
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li4">
                <span className="s1">
                  Member of the Senate or House of Representatives or member of
                  a State Legislature;
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li4">
                <span className="s1">
                  Ambassador
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li4">
                <span className="s1">
                  Military Officer with a rank of General or above;
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li5">
                <span className="s1">
                  Head of a Government Agency;
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                  <br /> Certain family members must also be regarded as PEPs.
                  These family members of the above are:
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
            </ul>
            <p className="p6">
              <span className="s1">
                {' '}
                <span className="Apple-converted-space">&nbsp; </span>
              </span>
            </p>
            <ul className="ul1">
              <li className="li4">
                <span className="s1">
                  their spouse or common-law partner;
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li4">
                <span className="s1">
                  their child;
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li4">
                <span className="s1">
                  their mother or father;
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li4">
                <span className="s1">
                  the mother or father of their spouse or common-law partner;
                  and
                  <br /> <span className="Apple-converted-space">&nbsp; </span>
                </span>
              </li>
              <li className="li5">
                <span className="s1">
                  a child of their mother or father (sibling).
                </span>
              </li>
            </ul>
            <p className="p5">
              <span className="s1">
                If you qualify as a PEP, before using the site, you must
                disclose your status and contact:
              </span>
            </p>
            <p className="p4">
              <span className="s1">Max Sugrue</span>
            </p>
            <p className="p4">
              <span className="s1">The Krowd, Inc. </span>
            </p>
            <p className="p4">
              <span className="s1">420 N Wabash Ave Chicago, </span>
            </p>
            <p className="p4">
              <span className="s1">max@myvintro.com</span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                19. <strong>Changes to these Terms</strong>
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                From time to time, we may change these Terms. If we change these
                Terms, we will give you notice by posting the revised Terms on
                the Site. Those changes will go into effect on the Revision Date
                shown in the revised Terms. By continuing to use the Site or
                Services, you are agreeing to the revised Terms.
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                <strong>
                  PLEASE PRINT A COPY OF THESE TERMS FOR YOU RECORDS AND PLEASE
                  CHECK THE SITE FREQUENTLY FOR ANY CHANGES TO THESE TERMS.
                </strong>
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p4">
              <span className="s1">
                Must register agent with U.S. Copyright Office for DMCA safe
                harbor to be effective.{' '}
              </span>
            </p>
            <p className="p1">&nbsp;</p>
            <p className="p1">&nbsp;</p>
            <p className="p1">&nbsp;</p>
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export default Terms;
