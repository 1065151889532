import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './callout.scss';

export function Callout(props) {
  const classes = classNames({
    Callout: true,
    'Callout--collapsed': props.collapsed,
    'Callout--alignCenter': props.align === 'center',
    'Callout--alignRight': props.align === 'right',
    'Callout--alignLeft': props.align === 'left',
  });

  return <div className={classes}>{props.children}</div>;
}

Callout.propTypes = {
  collapsed: PropTypes.bool,
  children: PropTypes.any,
  align: PropTypes.oneOf(['center', 'right', 'left']),
};

Callout.defaultProps = {
  collapsed: false,
  children: null,
  align: null,
};

export default Callout;
