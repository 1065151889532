import React, { useState, useRef, useEffect } from 'react';

import './video-player.scss';

export const playIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>controls-play</title>
    <path
      className="a"
      d="M2.338,3.255v17.49a1.5,1.5,0,0,0,2.209,1.322L20.87,13.322a1.5,1.5,0,0,0,0-2.644L4.547,1.933A1.5,1.5,0,0,0,2.338,3.255Z"
    />
  </svg>
);

export function calculateVideoSize(wrapper, video) {
  const maxWidth = wrapper.current.offsetWidth - 32;
  const maxHeight = 720 - 32;
  const { width, height } = video;

  var ratio = Math.min(maxWidth / width, maxHeight / height);

  return {
    width: Math.round(width * ratio),
    height: Math.round(height * ratio),
  };
}

export function VideoPlayer(props) {
  const video = useRef(null);
  const wrapper = useRef(null);
  const [size, setSize] = useState({});

  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (props.job) {
      console.log(props.job);
      setSize(calculateVideoSize(wrapper, props.job.video));
    }
    // eslint-disable-next-line
  }, []);

  function videoEnded() {
    if (props.onEnded) props.onEnded();
  }

  function onTimeUpdate() {
    //
  }

  function togglePlay() {
    if (props.controls) return;
    if (!playing) {
      video.current.play();
      setPlaying(true);
    } else {
      video.current.pause();
      setPlaying(false);
    }
  }

  return (
    <div
      style={{ width: '100%', height: size.height }}
      ref={wrapper}
      onClick={togglePlay}
      className="VideoPlayer"
    >
      {!playing && !props.controls && <div className="Play">{playIcon}</div>}
      <div className="Preview">
        <video
          poster={props.poster}
          ref={video}
          width={size.width}
          height={size.height}
          onTimeUpdate={onTimeUpdate}
          onEnded={videoEnded}
          playsInline
          controls={props.controls || false}
        >
          <source src={props.video} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

export default VideoPlayer;
