import './apply-wizard.scss';
import React, { useState } from 'react';
import { Container, Grid, Cell, Heading, Loader } from '../../components/ui';

import { AppError } from '../../components/ui/errors/errors';
import { useMyProfile } from '../../graphql/scouts';
import DisplayInfo from './steps/display-info';
import PendingApproval from './steps/pending-approval';
import JumioIdentity from './steps/identity';

const steps = [DisplayInfo, JumioIdentity, PendingApproval];
const stepsKnown = [DisplayInfo, PendingApproval];

// PaymentInfo,

export function getInitialStep(profile) {
  console.log(profile);

  if (!profile.familiar) {
    return 0;
  }

  if (profile.known && profile.familiar) {
    return 1;
  }

  if (profile.familiar && !profile.identified && !profile.known) {
    return 1;
  }

  if (profile.familiar && profile.identified && !profile.known) {
    return 2;
  }

  if (profile.familiar && !profile.payable) {
    return 2;
  }

  if (profile.familiar && profile.payable && !profile.approved) {
    return 2;
  }

  return 2;
}

export function getTotalSteps(profile) {
  if (profile.known) {
    return 2;
  } else {
    return 3;
  }
}

export function ApplyWizard(props) {
  const [step, setStep] = useState(getInitialStep(props.profile));
  const totalSteps = getTotalSteps(props.profile);
  const Component = props.profile.known ? stepsKnown[step] : steps[step];

  function nextStep() {
    setStep(step => {
      return step + 1;
    });
  }

  return (
    <section className="ApplyWizard">
      <Container>
        <Grid spaced align="center">
          <Cell large={8}>
            <Heading level={6}>
              <b>Step {step + 1}</b> of {totalSteps}
            </Heading>
            <Component nextStep={nextStep} profile={props.profile} />
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export function ApplyWizardLoader(props) {
  const { data, error, loading } = useMyProfile();

  if (loading) {
    return (
      <Container>
        <Grid spaced align="center">
          <Cell large={8}>
            <Loader />
          </Cell>
        </Grid>
      </Container>
    );
  }

  if (error) return <AppError error={`An application error occured`} />;
  if (!data.myProfile) return null;

  return <ApplyWizard profile={data.myProfile} {...props} />;
}

export default ApplyWizardLoader;
