import React from 'react';
import PropTypes from 'prop-types';
import baseInput from '../base-input/base-input';

export function NumberInput(props) {
  function onKeyPress(e) {
    if (e.key === '.') {
      e.preventDefault();
      return false;
    }

    if (e.key === '-') {
      e.preventDefault();
      return false;
    }

    if (e.key === 'e') {
      e.preventDefault();
      return false;
    }

    return true;
  }

  function onChange(e) {
    if (props.onChange) {
      props.onChange({
        target: {
          value: e.target.value.replace(/\D/g, ''),
          name: props.name,
        },
      });
    }
  }

  function blockScrollEvent(e) {
    e.preventDefault();
    return false;
  }

  return (
    <div className="Field Field--number">
      <input
        type="number"
        min="0"
        step="1"
        pattern="[0-9]*"
        onKeyPress={onKeyPress}
        name={props.name}
        value={props.value || ''}
        onChange={onChange}
        onScroll={blockScrollEvent}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        required={props.required}
      />
    </div>
  );
}

NumberInput.defaultProps = {
  value: undefined,
};

NumberInput.propTypes = {
  value: PropTypes.any,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default baseInput(NumberInput);
