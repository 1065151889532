import React from 'react';
import Responsive from 'react-responsive';

export const Desktop = props => <Responsive {...props} minWidth={992} />;
export const Tablet = props => (
  <Responsive {...props} minWidth={768} maxWidth={991} />
);
export const Mobile = props => <Responsive {...props} maxWidth={799} />;
export const Default = props => <Responsive {...props} minWidth={800} />;

export default {
  Desktop,
  Tablet,
  Mobile,
  Default,
};
