import React from 'react';
import { Grid, Cell, Heading, Paragraph } from '../../../components/ui';

export function PendingApproval(props) {
  if (props.profile && props.profile.approved) {
    return (
      <Grid>
        <Cell small={12}>
          <Heading level={1}>Your Approved</Heading>
          <Heading level={2}>
            You can now review pitches on <b>Vintro</b>.
          </Heading>
        </Cell>
      </Grid>
    );
  }

  return (
    <Grid>
      <Cell small={12}>
        <Heading level={1}>Welcome to the Vintro family!</Heading>
        <Heading level={2}>
          We’re thrilled you’ve joined us and the people waiting to contact you
          through Vintro are even more excited.
        </Heading>
        <Heading level={6}>Here are 3 important pieces of information:</Heading>
        <Paragraph>
          1) <b>We’re launching soon</b> - Right now we’re onboarding global
          leaders and decision-makers. Look for an email in a couple of weeks
          with the public launch date and details on your preferred method of
          payment.
        </Paragraph>
        <Paragraph>
          2) <b>Earn $ through referrals</b> - In the coming weeks, we’ll send
          you a unique link. When your friends apply to join Vintro through that
          link you will earn 20% of the revenue Vintro gets from their
          engagements on our platform. You’ll also earn 10% from the people your
          direct friends bring on.
        </Paragraph>
        <Paragraph>
          3) <b>Say hi!</b> - (optional) - send us a short (30-60sec) video
          saying hi and sharing that you’ll be on Vintro. We’ll add this to your
          profile and share it on our social media channels.
        </Paragraph>
        <Paragraph>
          If you have any questions, contact us at{' '}
          <a href="mailto:support@myvintro.com">support@myvintro.com</a>
        </Paragraph>
      </Cell>
    </Grid>
  );
}

export default PendingApproval;
