import React, { useState } from 'react';
import PropTypes from 'prop-types';
import baseInput from '../base-input/base-input';
import './password.scss';
import { Grid, Cell } from '../../grid/grid';
import { Link } from 'react-router-dom';
import { Help } from '../label/label';

export function PasswordInput(props) {
  const [showPassword, setShowPassword] = useState(false);

  function handleShowPassword() {
    setShowPassword(value => !value);
  }

  return (
    <div className="Field Field--password">
      <input
        type={showPassword ? 'text' : 'password'}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        required={props.required}
      />

      <Grid margin>
        <Cell small={12} medium={6}>
          {props.canToggle && (
            <span
              className="Field--password__toggle"
              onClick={handleShowPassword}
            >
              {showPassword ? 'Hide' : 'Show'} Password
            </span>
          )}
        </Cell>
        {props.errors.length === 0 && props.showHelp && (
          <Cell small={12} medium={12}>
            <span className="Field--password__help">
              <Help>
                Password must be at least 6 characters must contain at least 1
                lowercase letter, 1 uppercase letter, 1 number and 1 special
                character
              </Help>
            </span>
          </Cell>
        )}
        <Cell small={12} medium={6}>
          {props.canReset && (
            <div className="text-right">
              <Link to="/reset-password">
                <span className="Field--password__forget">
                  Forgot Password?
                </span>
              </Link>
            </div>
          )}
        </Cell>
      </Grid>
    </div>
  );
}

PasswordInput.defaultProps = {
  value: '',
  canToggle: false,
  canReset: false,
};

PasswordInput.propTypes = {
  value: PropTypes.string,
  canToggle: PropTypes.bool,
  canReset: PropTypes.bool,
};

export default baseInput(PasswordInput);
