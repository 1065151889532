import React, { useState } from 'react';
import validate from 'validate.js';
import {
  Grid,
  Cell,
  Button,
  Heading,
  Container,
  EmailInput,
  Callout,
  Paragraph,
} from '../../components/ui';

import { FormError } from '../../components/forms';

import { useRequestPasswordReset } from '../../graphql/auth';
import { useForm } from '../../hooks/forms';

const validationRules = {
  email: {
    presence: { allowEmpty: false },
    email: true,
  },
};

export function ResetPasswordSuccess() {
  return (
    <section className="ReestPassword">
      <Container>
        <Grid align="center">
          <Cell large={6} medium={8} small={12}>
            <Heading level={1}>
              Reset <b>Password</b>
            </Heading>
            <Callout>
              <Heading level={2}>Success</Heading>
              <Paragraph>
                We've sent instructions to your email address on file. Check
                your email and follow the instructions to reset your password.
              </Paragraph>
            </Callout>
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export function ResetPassword(props) {
  const [success, setSuccess] = useState(false);
  const requestPasswordReset = useRequestPasswordReset();

  const { form, errors, setErrors, handleFormChange } = useForm(
    {
      email: '',
    },
    { validate: validateForm }
  );

  function validateForm(form) {
    return validate(form, validationRules);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const response = validateForm(form);

    setErrors(errors => ({
      ...errors,
      ...response,
      server: null,
    }));

    if (response === undefined) {
      try {
        await requestPasswordReset(form.email);
        setSuccess(true);
      } catch (e) {
        if (e.graphQLErrors && e.graphQLErrors[0]) {
          setErrors(errors => ({
            ...errors,
            server: e.graphQLErrors[0].message,
          }));
        }
      }
    }
  }

  if (success) {
    return <ResetPasswordSuccess />;
  }

  return (
    <section className="SignUp">
      <Container>
        <Grid align="center">
          <Cell large={6} medium={8} small={12}>
            <Heading level={1}>
              Reset <b>Password</b>
            </Heading>
            <Heading level={2}>
              Enter your email below to reset your password.
            </Heading>
            <hr />
            {errors.server && <FormError>{errors.server}</FormError>}
            <form onSubmit={handleSubmit}>
              <EmailInput
                value={form.email}
                name={'email'}
                errors={errors.email}
                onChange={handleFormChange}
                placeholder={'Your Email'}
                label={'Email'}
                spaced
              />
              <Grid margin align={'center'} style={{ alignItems: 'center' }}>
                <Cell small={12} medium={12}>
                  <div className="text-right">
                    <Button type="submit">Submit</Button>
                  </div>
                </Cell>
              </Grid>
              {/*<Link>Forgot Password?</Link>*/}
            </form>
          </Cell>
        </Grid>
      </Container>
    </section>
  );
}

export default ResetPassword;
