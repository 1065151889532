import React from 'react';
import { AdminSecondaryNav } from '../../../components/layout';
import {
  Container,
  Grid,
  Cell,
  Heading,
  Loader,
  Button,
} from '../../../components/ui';
import { useForm } from '../../../hooks/forms';
import EmailInput from '../../../components/ui/inputs/email/email';
import PhoneInput from '../../../components/ui/inputs/phone/phone';
import PasswordInput from '../../../components/ui/inputs/password/password';
import SelectInput from '../../../components/ui/inputs/select/select';
import { useGetUser, useUpdateUser } from '../../../graphql/user';
import { useNotifications } from '../../../hooks/notifications';
import { getRelevantGraphQLError } from '../../../graphql/util';
import validate from 'validate.js';

const roles = [
  { value: 'ADMIN', label: 'ADMIN' },
  { value: 'SUPERADMIN', label: 'SUPERADMIN' },
  { value: 'SCOUT', label: 'REVIEWER' },
  { value: 'VENTURE', label: 'VENTURE' },
  { value: 'SYSTEM', label: 'SYSTEM' },
];

export function EditUser(props) {
  const validationRules = {
    email: {
      presence: { allowEmpty: false, message: `Email can't be blank` },
      email: { message: 'Email is not a valid email' },
    },
  };

  const notifications = useNotifications();
  const updateUser = useUpdateUser();
  const { form, handleFormChange, errors, setErrors } = useForm(
    {
      email: props.user.email || '',
      type: props.user.type,
      password: '',
      phone: props.user.phone || '',
    },
    { validate: validateForm }
  );

  if (form.password) {
    validationRules.password = {
      presence: true,
      length: {
        minimum: 6,
        message: 'must be at least 6 characters',
      },
    };
  }

  async function submit() {
    try {
      const response = validateForm(form);

      setErrors({});

      setErrors(errors => ({
        ...errors,
        ...response,
      }));

      if (response) return;

      await updateUser({
        id: props.user.id,
        email: form.email,
        password: form.password,
        phone: form.phone,
        type: form.type,
      });
      notifications.notify(`Succesfully updated ${form.email}`, { time: 3000 });
    } catch (e) {
      notifications.notify(getRelevantGraphQLError(e).message, {
        error: true,
        time: 3000,
      });
    }
  }

  function validateForm(form) {
    return validate(form, validationRules);
  }

  return (
    <Grid>
      <Cell small={12}>
        <EmailInput
          name="email"
          errors={errors.email}
          value={form.email}
          onChange={handleFormChange}
          spaced
          label={'Email Address'}
        />
      </Cell>
      <Cell small={12}>
        <PhoneInput
          errors={errors.phone}
          name={'phone'}
          value={form.phone}
          onChange={handleFormChange}
          spaced
          label={'Phone'}
        />
      </Cell>
      <Cell small={12}>
        <PasswordInput
          errors={errors.password}
          name="password"
          onChange={handleFormChange}
          value={form.password}
          spaced
          label={'Password'}
        />
      </Cell>
      <Cell small={12}>
        <SelectInput
          label="User Type"
          name="type"
          onChange={handleFormChange}
          emptyOption="Choose a User Type"
          value={form.type}
          spaced
          options={roles}
        />
      </Cell>
      <Cell small={12}>
        <div className="text-right">
          <Button onClick={submit}>Save</Button>
        </div>
      </Cell>
    </Grid>
  );
}

export function EditUserLoader(props) {
  const { data, loading, error } = useGetUser(props.id);

  if (loading) return <Loader />;
  if (error) return null;
  if (!data) return <Loader />;

  return <EditUser user={data.getUser} />;
}

export function EditUserWrapper(props) {
  return (
    <Container>
      <Grid>
        <Cell small={6}>
          <Heading level={1}>
            <b>Vintro</b> Admin
          </Heading>
        </Cell>
        <Cell small={6} />
        <Cell>
          <hr />
        </Cell>
      </Grid>
      <Grid margin>
        <Cell small={12} medium={3}>
          <AdminSecondaryNav />
        </Cell>
        <Cell small={12} medium={9}>
          <EditUserLoader id={props.match.params.id} />
        </Cell>
      </Grid>
    </Container>
  );
}

export default EditUserWrapper;
