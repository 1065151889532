import React from 'react';
import { Button, Grid, Cell } from '../../../components/ui';
import { TeamMember } from '../../../components/forms';

// import Dragula from 'react-dragula';
// import 'dragula/dist/dragula.min.css';

export function FormStepTwo(props) {
  const {
    form,
    handleAddTeamMember,
    handleRemoveTeamMember,
    handleUpdateTeamMember,
  } = props;

  /*function dragulaDecorator(componentBackingInstance) {
    if (componentBackingInstance) {
      let options = {};

      const dragula = Dragula([componentBackingInstance], options);
      dragula.on('drop', function() {});
    }
  }*/

  function getBlankTeamMember() {
    return {
      firstName: '',
      lastName: '',
      title: '',
      linkedIn: '',
      bio: '',
      photo: '',
    };
  }

  return (
    <div>
      <div className="container">
        {form.team.map((member, idx) => {
          const showRemoveButton = form.team.length > 1;

          return (
            <TeamMember
              {...member}
              onChange={event => handleUpdateTeamMember('team', idx, event)}
              removeTeamMember={() => handleRemoveTeamMember('team', idx)}
              showRemoveButton={showRemoveButton}
              key={idx}
            />
          );
        })}
      </div>
      <Grid align={'right'}>
        <Cell shrink>
          <Button
            size="small"
            onClick={e => handleAddTeamMember('team', getBlankTeamMember())}
          >
            + Add Person
          </Button>
        </Cell>
      </Grid>
    </div>
  );
}

export const validationRules = {};

export default FormStepTwo;
