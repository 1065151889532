import React from 'react';
import PropTypes from 'prop-types';
import { Button, Heading, Grid, Cell } from '../../ui';
import './form-wizard.scss';

export function FormWizard(props) {
  function renderPrevButton() {
    if (props.currentStep === 1) return null;
    return (
      <Button hollow onClick={props.handlePrevStep}>
        Back
      </Button>
    );
  }

  function renderNextButton() {
    if (props.currentStep === props.totalSteps) return null;

    return (
      <Button
        onClick={props.handleNextStep}
        disabled={
          props.currentStep === props.totalSteps || props.totalSteps === 1
        }
      >
        {props.nextText ? `${props.nextText}` : 'Next'}
      </Button>
    );
  }

  function renderSubmitButton() {
    const hide = props.currentStep !== props.totalSteps;
    if (hide) return null;
    return <Button type={'submit'}>{props.submitText}</Button>;
  }

  function handleSubmit(event) {
    event.preventDefault();
    props.handleSubmit();
  }

  return (
    <form className="FormWizard" onSubmit={handleSubmit}>
      {props.totalSteps > 1 && (
        <header className="FormWizard__header">
          <Heading level={6}>
            <b>Step {props.currentStep}</b> of {props.totalSteps}
          </Heading>
        </header>
      )}
      <div className="FormWizard__steps">{props.children}</div>
      <div className="FormWizard__actions">
        <Grid>
          <Cell auto>{renderPrevButton()}</Cell>
          <Cell shrink>
            {renderNextButton()}
            {renderSubmitButton()}
          </Cell>
        </Grid>
      </div>
    </form>
  );
}

FormWizard.defaultProps = {
  currentStep: 1,
  totalSteps: 1,
  submitText: 'Submit',
  handleSubmit: () => null,
  handleNextStep: () => null,
  handlePrevStep: () => null,
  nextText: null,
};

FormWizard.propTypes = {
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
  submitText: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleNextStep: PropTypes.func,
  handlePrevStep: PropTypes.func,
  nextText: PropTypes.string,
};

export default FormWizard;
