import React from 'react';
import { Container, Grid, Cell, Heading } from '../../components/ui';
import MyInbox from '../my-inbox/my-inbox';
import './dashboard-shopper.scss';
import { SecondaryNav } from '../../components/layout/secondary-nav/secondary-nav';
import ScoutNav from '../../components/layout/reviewer-nav/reviewer-nav';
import { useSession } from '../../hooks/session';
import MyRequests from '../my-requests/my-requests';

export function DashboardShopper() {
  return (
    <Container>
      <Grid>
        <Cell>
          <Heading level={1}>
            Your <b>Dashboard</b>
          </Heading>
          <hr />
        </Cell>
      </Grid>
      <Grid>
        <Cell small={12} medium={3}>
          <SecondaryNav />
        </Cell>
        <Cell small={12} medium={9}>
          <MyInbox />
        </Cell>
      </Grid>
    </Container>
  );
}

export function DashboardScout() {
  return (
    <Container>
      <Grid>
        <Cell>
          <Heading level={2}>Dashboard</Heading>
          <hr />
        </Cell>
      </Grid>
      <Grid>
        <Cell small={12} medium={3}>
          <ScoutNav />
        </Cell>
        <Cell small={12} medium={9}>
          Scout
        </Cell>
      </Grid>
    </Container>
  );
}

export function DashboardSplitter() {
  const session = useSession({});
  const { profile, isAuthenticated } = session;

  if (!isAuthenticated) {
    return null;
  }

  if (profile.user.type === 'SCOUT') {
    return <MyRequests />;
  }

  if (profile.user.type === 'VENTURE') {
    return <MyInbox />;
  }

  return null;
}

export default DashboardSplitter;
