import React, { useState, useEffect } from 'react';

import classNames from 'classnames';
import { Button, Grid, Cell, Heading, Paragraph, StrongText } from '../ui';
import './cart.scss';
import history from '../../util/history';
import { useQuery } from 'react-apollo-hooks';
import { GET_CART } from '../../graphql/cart';
import { Link } from 'react-router-dom';

import { useGlobalState } from '../../store';

import missingImage from '../../images/photo-missing.png';

export function CartItem(props) {
  const { item } = props;
  return (
    <li className="Cart__item" key={item.id}>
      <Grid padding>
        <Cell shrink>
          <Link to={`/reviewers/${item.id}`}>
            <div className="Cart__photo">
              <div
                className="Cart__photo_background"
                style={{
                  backgroundImage: `url(${item.photo || missingImage})`,
                }}
              />
            </div>
          </Link>
        </Cell>
        <Cell auto>
          <Heading level={6}>
            <Link to={`/reviewers/${item.id}`}>
              {item.firstName} {item.lastName}
            </Link>
          </Heading>
          <Paragraph>
            <b>{item.title}</b> @ {item.company}
          </Paragraph>
        </Cell>
        <Cell shrink>
          <StrongText>{`$${item.price}`}</StrongText>
        </Cell>
      </Grid>
    </li>
  );
}

export function CartItems(props) {
  const { items } = props;
  return (
    <ul className="Cart__items">
      {items.map(item => (
        <CartItem
          handleCartToggle={props.handleCartToggle}
          item={item}
          key={item.id}
        />
      ))}
    </ul>
  );
}

export function Cart(props) {
  const cart = props.cart;
  const [open, setOpen] = useState(false);
  const [cartItems, setCartItems] = useState(cart.items.length);

  const classes = classNames({
    Cart: true,
    'Cart--isOpen': open,
  });

  function handleCartToggle() {
    setOpen(open => !open);
    return false;
  }

  function handleCheckoutRedirect() {
    setOpen(false);
    history.push('/checkout', true);
  }

  useEffect(() => {
    if (cart.items.length === cartItems) {
      return;
    }

    if (cart.items.length > cartItems) {
      setCartItems(cart.items.length);
      return setOpen(true);
    }
    setCartItems(cart.items.length);
  }, [cart.items.length, cartItems]);

  useEffect(() => {
    if (props.hasCartOnFirstLoad === false) {
      setOpen(true);
    }
  }, [props.hasCartOnFirstLoad]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (open) {
        setOpen(false);
      }
    });

    return () => {
      unlisten();
    };
  }, [open, setOpen]);

  return (
    <div className={classes}>
      <Button className="Button--cart" onClick={handleCartToggle}>
        Cart ({cart.items.length})
      </Button>
      {open && (
        <div className="Cart__panel">
          <div className="Cart__panelContent">
            <CartItems handleCartToggle items={cart.items} />
            <div className="Cart__actions">
              <Grid padding>
                <Cell large={12}>
                  <Button full onClick={handleCheckoutRedirect}>
                    Checkout
                  </Button>
                </Cell>
                {/*<Cell large={4}>
                  <Button
                    full
                    onClick={handleCheckoutRedirect}
                    theme={'hollow'}
                    hollow
                  >
                    Edit Cart
                  </Button>
              </Cell>*/}
              </Grid>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export function CartLoader(props) {
  const { data, loading } = useQuery(GET_CART, {
    variables: { id: props.cartID },
  });

  if (loading) return null;

  return (
    <Cart
      {...props}
      hasCartOnFirstLoad={props.hasCartOnFirstLoad}
      cart={data.getCart || { items: [] }}
    />
  );
}

export function CartSplitter(props) {
  const [cartID] = useGlobalState('cart');
  const [hasCartOnFirstLoad] = useState(
    cartID !== null && cartID !== undefined
  );

  if (!cartID) {
    return <Cart hasCartOnFirstLoad={true} {...props} cart={{ items: [] }} />;
  }

  return (
    <CartLoader
      hasCartOnFirstLoad={hasCartOnFirstLoad}
      cartID={cartID}
      {...props}
    />
  );
}

export default CartSplitter;
