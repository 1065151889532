import React from 'react';
import { AdminSecondaryNav } from '../../../components/layout';
import { Container, Grid, Cell, Heading, Loader } from '../../../components/ui';
import { useUpdatePerson } from '../../../graphql/scouts';

import PersonForm from '../invites/components/person.form';
import { useNotifications } from '../../../hooks/notifications';

import { GET_PERSON_ADMIN } from '../../../graphql/scouts';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';

export function Invite(props) {
  const notifications = useNotifications();
  const updatePerson = useUpdatePerson();

  async function onSubmit(input) {
    try {
      await updatePerson(input, true);
      notifications.notify(
        `Updated ${props.invite.firstName} ${props.invite.lastName}`,
        { time: 3000 }
      );
    } catch (e) {
      notifications.notify(
        `There was an error updating ${props.invite.firstName} ${props.invite.lastName}`,
        { time: 3000 }
      );
    }
  }

  return (
    <Grid margin>
      <Cell small={12}>
        <Link to={`/admin/reviewers/view/${props.invite.id}`}>
          Back to Profile
        </Link>
      </Cell>
      <Cell small={12}>
        <hr />
      </Cell>
      <Cell small={12}>
        <PersonForm
          onSubmit={onSubmit}
          buttonText={'Save Profile'}
          profile={{ ...props.invite, id: props.invite.id }}
        />
      </Cell>
    </Grid>
  );
}

export function InviteLoader(props) {
  const { data, loading, error } = useQuery(GET_PERSON_ADMIN, {
    variables: { id: props.id },
  });

  if (loading) return <Loader />;
  if (error) return null;

  return <Invite id={props.id} invite={data.getPerson} />;
}

export function InviteWrapper(props) {
  return (
    <Container>
      <Grid>
        <Cell small={6}>
          <Heading level={1}>
            <b>Vintro</b> Admin
          </Heading>
        </Cell>
        <Cell small={6} />
        <Cell>
          <hr />
        </Cell>
      </Grid>
      <Grid margin>
        <Cell small={12} medium={3}>
          <AdminSecondaryNav />
        </Cell>
        <Cell small={12} medium={9}>
          <InviteLoader id={props.match.params.id} />
        </Cell>
      </Grid>
    </Container>
  );
}

export default InviteWrapper;
