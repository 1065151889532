import './reviewer-filters.scss';
import React from 'react';
import cc from 'classnames';
import {
  Heading,
  SelectInput,
  CheckboxInput,
  StrongText,
} from '../../../components/ui';

import { Tags, Tag } from '../../../components/ui/tags/tags';
import sdg from '../../../util/sdg';
import verticals from '../../../util/verticals';
import sectorOptions from '../../../util/sector-options';
import countryOptions from '../../../util/country-options';

import { useGlobalState } from '../../../store';

export function FilterGroup(props) {
  const [filters, setFilters] = useGlobalState('filters');
  const className = cc([
    'ReviewerFilters-FilterGroup',
    filters.open[props.id] ? 'open' : null,
    props.fixedHeight ? 'ReviewerFilters-FilterGroup--fixed' : null,
  ]);

  function toggle() {
    setFilters(filters => {
      return {
        ...filters,
        open: {
          ...filters.open,
          [props.id]: !filters.open[props.id],
        },
      };
    });
  }

  return (
    <div className={className}>
      <div onClick={toggle} className="ReviewerFilters-FilterGroup__name">
        <Heading level={5}>{props.name}</Heading>
        <i
          className={`fa fa-chevron-${filters.open[props.id] ? 'up' : 'down'}`}
        ></i>
      </div>
      <div className="ReviewerFilters-FilterGroup__filters">
        {filters.open[props.id] ? props.children : null}
      </div>
    </div>
  );
}

export function ReviewerFilters() {
  const [filters, setFilters] = useGlobalState('filters');
  const classNames = cc([
    'ReviewerFilters',
    filters.open.mobile ? 'open' : null,
  ]);

  function selectTag(id) {
    if (hasTag(id)) {
      setFilters(filters => {
        return {
          ...filters,
          tags: filters.tags.filter(tag => tag.toString() !== id.toString()),
        };
      });
    } else {
      setFilters(filters => {
        return {
          ...filters,
          tags: [...filters.tags, id],
        };
      });
    }
  }

  function hasTag(id) {
    const tag = filters.tags.find(tag => tag === id);
    if (tag) return true;
    return false;
  }

  function toggle() {
    setFilters(filters => {
      return {
        ...filters,
        open: {
          ...filters.open,
          mobile: !filters.open.mobile,
        },
      };
    });
  }

  return (
    <div className={classNames}>
      <div onClick={toggle} className="ReviewerFilters-FilterHeader">
        <Heading level={6}>
          Filters{' '}
          <i
            className={`fa fa-chevron-${filters.open.mobile ? 'up' : 'down'}`}
          ></i>
        </Heading>
      </div>
      <div className="ReviewerFilters-FilterContent">
        <FilterGroup
          fixedHeight={true}
          id="industrial"
          name="Industrial Interests"
        >
          {sectorOptions.map(tag => {
            return (
              <div key={tag.code}>
                <span className="TagCategory">
                  <StrongText>{tag.name}</StrongText>
                </span>
                <Tags>
                  {tag.list.map(tag => {
                    return (
                      <Tag
                        onClick={selectTag}
                        selected={hasTag(tag.code)}
                        value={tag.code}
                        key={tag.code}
                      >
                        {tag.name}
                      </Tag>
                    );
                  })}
                </Tags>
              </div>
            );
          })}
        </FilterGroup>
        <FilterGroup fixedHeight={true} id="vertical" name="Emerging Verticals">
          <Tags>
            {verticals.map(tag => {
              return (
                <Tag
                  onClick={selectTag}
                  selected={hasTag(tag.code)}
                  value={tag.code}
                  key={tag.code}
                >
                  {tag.name}
                </Tag>
              );
            })}
          </Tags>
        </FilterGroup>
        <FilterGroup
          fixedHeight={true}
          id="sdg"
          name="Sustainable Development Goals"
        >
          <Tags>
            {sdg.map(tag => {
              return (
                <Tag
                  onClick={selectTag}
                  selected={hasTag(tag.code)}
                  value={tag.code}
                  key={tag.code}
                >
                  {tag.name}
                </Tag>
              );
            })}
          </Tags>
        </FilterGroup>
        <FilterGroup
          id="offering"
          name="Type of Substantial Engagement Offered "
        >
          <ReviewFilterOffering />
        </FilterGroup>
        <FilterGroup id="investment" name="Investment Stage">
          <ReviewFilterInvestmentStage />
        </FilterGroup>
        <FilterGroup id="location" name="Location">
          <SelectInput
            value={filters.country}
            onChange={e =>
              setFilters(filters => ({ ...filters, country: e.target.value }))
            }
            options={countryOptions}
            spaced
            emptyOption="Country"
          />
          {/*
          <TextInput
            value={filters.city}
            onChange={e =>
              setFilters(filters => ({ ...filters, city: e.target.value }))
            }
            placeholder="City"
          />*/}
        </FilterGroup>
        <FilterGroup id="type" name="Type of reviewer">
          <ReviewFilterPersonType />
        </FilterGroup>
        {/*<FilterGroup id="organization" name="Organization name">
          <TextInput
            value={filters.company}
            onChange={e =>
              setFilters(filters => ({ ...filters, company: e.target.value }))
            }
            spaced
            placeholder="Organization name"
          />
          </FilterGroup>*/}
      </div>
    </div>
  );
}

export function Spaced(props) {
  return <div className="Spaced">{props.children}</div>;
}

function ReviewFilterPersonType(props) {
  const [filters, setFilters] = useGlobalState('filters');
  const form = { types: filters.types || [] };
  function handleChangeBracket(e) {
    const { value } = e.target;

    changeArrayValue(filters, setFilters, 'types', value);
  }

  return (
    <div>
      <CheckboxInput
        checked={form.types.includes('HighNetWorthIndividual')}
        onChange={handleChangeBracket}
        value="HighNetWorthIndividual"
        text="High Net-Worth Individuals"
      />
      <CheckboxInput
        checked={form.types.includes('Corporations')}
        onChange={handleChangeBracket}
        value="Corporations"
        text="Corporations"
      />
      <CheckboxInput
        checked={form.types.includes('InvestmentFirms')}
        onChange={handleChangeBracket}
        value="InvestmentFirms"
        text="Investment Firms"
      />
      <CheckboxInput
        checked={form.types.includes('NonGovermentalOrganizations')}
        onChange={handleChangeBracket}
        value="NonGovermentalOrganizations"
        text="Non-governmental Organizations"
      />
      <CheckboxInput
        checked={form.types.includes('SocialImpactInvestors')}
        onChange={handleChangeBracket}
        value="SocialImpactInvestors"
        text="Social Impact Investors"
      />
      <CheckboxInput
        checked={form.types.includes('MassAffluentorAccreditedInvestors')}
        onChange={handleChangeBracket}
        value="MassAffluentorAccreditedInvestors"
        text="Mass Affluent/Accredited Investors"
      />
      <CheckboxInput
        checked={form.types.includes('StrategicAdvisor')}
        onChange={handleChangeBracket}
        value="StrategicAdvisor"
        text="Strategic Advisers"
      />
      <CheckboxInput
        checked={form.types.includes('CreativeTalents')}
        onChange={handleChangeBracket}
        value="CreativeTalents"
        text="Creative Talents"
      />
    </div>
  );
}

function changeArrayValue(filters, setFilters, key, value) {
  const existing = (filters[key] || []).find(item => item === value);
  if (existing) {
    setFilters(filters => ({
      ...filters,
      [key]: filters[key].filter(item => item !== value),
    }));
  } else {
    setFilters(filters => ({
      ...filters,
      [key]: [...(filters[key] || []), value],
    }));
  }
}

function ReviewFilterOffering(props) {
  const [filters, setFilters] = useGlobalState('filters');
  const form = { offering: filters.offering || [] };
  function handleChangeOffering(e) {
    const { value } = e.target;

    changeArrayValue(filters, setFilters, 'offering', value);
  }

  return (
    <div>
      <CheckboxInput
        checked={form.offering.includes('Investment')}
        onChange={handleChangeOffering}
        value="Investment"
        text="Investment"
      />
      <CheckboxInput
        checked={form.offering.includes('PurchasingProcurement')}
        onChange={handleChangeOffering}
        value="PurchasingProcurement"
        text="Purchasing/Procurement"
      />
      <CheckboxInput
        checked={form.offering.includes('Referrals')}
        onChange={handleChangeOffering}
        value="Referrals"
        text="Referrals & Introductions"
      />
      <CheckboxInput
        checked={form.offering.includes('Mentorship')}
        onChange={handleChangeOffering}
        value="Mentorship"
        text="Mentorship"
      />
    </div>
  );
}

function ReviewFilterInvestmentStage(props) {
  const [filters, setFilters] = useGlobalState('filters');
  const form = { investmentDesiredStage: filters.investmentDesiredStage || [] };
  function handleChangeInvestmentDesiredStage(e) {
    const { value } = e.target;

    changeArrayValue(filters, setFilters, 'investmentDesiredStage', value);
  }

  return (
    <div>
      <CheckboxInput
        checked={form.investmentDesiredStage.includes('PreSeedFunding')}
        onChange={handleChangeInvestmentDesiredStage}
        value="PreSeedFunding"
        text="Pre-Seed Funding"
      />
      <CheckboxInput
        checked={form.investmentDesiredStage.includes('SeedFunding')}
        onChange={handleChangeInvestmentDesiredStage}
        value="SeedFunding"
        text="Seed Funding"
      />
      <CheckboxInput
        checked={form.investmentDesiredStage.includes('SeriesA')}
        onChange={handleChangeInvestmentDesiredStage}
        value="SeriesA"
        text="Series A"
      />
      <CheckboxInput
        checked={form.investmentDesiredStage.includes('SeriesB')}
        onChange={handleChangeInvestmentDesiredStage}
        value="SeriesB"
        text="Series B"
      />
      <CheckboxInput
        checked={form.investmentDesiredStage.includes('SeriesC')}
        onChange={handleChangeInvestmentDesiredStage}
        value="SeriesC"
        text="Series C"
      />
      <CheckboxInput
        checked={form.investmentDesiredStage.includes(
          'LateStageVentureFinancing'
        )}
        onChange={handleChangeInvestmentDesiredStage}
        value="LateStageVentureFinancing"
        text="Late-stage Venture Financing"
      />
      <CheckboxInput
        checked={form.investmentDesiredStage.includes('MezzanineFinancing')}
        onChange={handleChangeInvestmentDesiredStage}
        value="MezzanineFinancing"
        text="Mezzanine Financing"
      />
    </div>
  );
}

export default ReviewerFilters;
