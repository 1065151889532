import React from 'react';
import './chat-message.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSession } from '../../../hooks/session';
import moment from 'moment';

export function ChatMessage(props) {
  const { profile } = useSession();

  const classes = classNames({
    ChatMessage: true,
    'ChatMessage--isOwned': props.message._sender.userId === profile.user.id,
  });

  const createdAt = moment(props.message.createdAt);

  return (
    <div className={classes}>
      <div className="ChatMessage__content">
        <div className="ChatMessage__bubble">{props.message.message}</div>
        <p className="ChatMessage__timestamp">
          {createdAt.format('MMMM D, Y h:mm a')}
        </p>
      </div>
    </div>
  );
}

ChatMessage.propTypes = {
  message: PropTypes.object,
};

ChatMessage.defaultProps = {
  message: null,
};

export default ChatMessage;
