import React, { useRef } from 'react';
import {
  GET_PERSON_ADMIN,
  useApprovePerson,
  useUnApprovePerson,
  useConvertPersonToInvite,
} from '../../../graphql/scouts';
import {
  Loader,
  Grid,
  Cell,
  Container,
  Heading,
  Paragraph,
  Button,
  Card,
  ReviewerCard,
} from '../../../components/ui';

import { AdminSecondaryNav } from '../../../components/layout';
import { useQuery } from 'react-apollo-hooks';

import Spaced from '../../../components/util/spaced/spaced';

import { Link } from 'react-router-dom';
import { useNotifications } from '../../../hooks/notifications';
import { ReviewerDetail } from '../../reviewer-detail/reviewer-detail';
import { getRelevantGraphQLError } from '../../../graphql/util';
import history from '../../../util/history';
import { useSession } from '../../../hooks/session';

export function AdminReviewers(props) {
  const { scout } = props;
  const session = useSession();
  const { profile } = session;

  const copy = useRef();

  function copyLink() {
    copy.current.select();
    document.execCommand('copy');
    notifications.notify('Copied Affiliate Link', { time: 3000 });
  }

  const approvePerson = useApprovePerson();
  const unApprovePerson = useUnApprovePerson();
  const convertPersonToInvite = useConvertPersonToInvite();
  const notifications = useNotifications();

  async function handleApprove() {
    try {
      await approvePerson(scout.id);
      notifications.notify(
        `${scout.firstName} ${scout.lastName} is Approved!`,
        { time: 3000 }
      );
    } catch (e) {
      notifications.notify(
        `There was an error approving ${scout.firstName} ${scout.lastName}`,
        { time: 3000, erorr: true }
      );
    }
  }

  async function handleUnApprove() {
    try {
      await unApprovePerson(scout.id);
      notifications.notify(
        `${scout.firstName} ${scout.lastName} is Unapproved!`,
        { time: 3000 }
      );
    } catch (e) {
      notifications.notify(
        `There was an error Unapproving ${scout.firstName} ${scout.lastName}`,
        { time: 3000, erorr: true }
      );
    }
  }

  function handleConvertToInvite() {
    notifications.notify(
      `Are you sure you want to convert ${scout.firstName} ${scout.lastName} to a concierge invite? The user profile, login and affiliate information will be deleted.`,
      { confirm: confirmConvertToInvite, time: 30000 }
    );
  }

  async function confirmConvertToInvite() {
    try {
      const { data } = await convertPersonToInvite(scout.id);
      if (data && data.convertPersonToInvite) {
        history.push(`/admin/invites/view/${data.convertPersonToInvite.id}`);
        notifications.notify(
          `Successfully converted ${scout.firstName} ${scout.lastName} to invite`,
          { time: 3000 }
        );
      }
    } catch (e) {
      const { message } = getRelevantGraphQLError(e);
      notifications.notify(message, { error: true, time: 3000 });
    }
  }

  return (
    <section className="EditReviewer">
      <Grid>
        <Cell small={12}>
          <Heading level={1}>
            {scout.firstName} {scout.lastName}
          </Heading>
          <hr />
        </Cell>
      </Grid>
      <Grid>
        {!scout.approved ? (
          <Cell small={12}>
            <Card>
              <Grid margin>
                <Cell small={12}>
                  <div className="text-right">
                    {profile.type === 'SUPERADMIN' && (
                      <Button
                        onClick={handleConvertToInvite}
                        theme={'primary'}
                        hollow
                        size={'small'}
                      >
                        Convert to Invite
                      </Button>
                    )}
                    {profile.type === 'SUPERADMIN' && (
                      <Link to={`/admin/users/edit/${scout.id}`}>
                        <Button hollow theme={'primary'} size={'small'}>
                          Edit User
                        </Button>
                      </Link>
                    )}
                    <Link to={`/admin/reviewers/edit/${scout.id}`}>
                      <Button theme={'primary'} hollow size={'small'}>
                        Edit Profile
                      </Button>
                    </Link>
                    <Button onClick={handleApprove} size={'small'}>
                      Approve {scout.firstName} {scout.lastName}
                    </Button>
                  </div>
                </Cell>
              </Grid>
            </Card>
          </Cell>
        ) : (
          <Cell small={12}>
            <Card>
              <Grid margin>
                <Cell small={12}>
                  <div className="text-right">
                    {/*<Link to={`/admin/reviewers/edit/${scout.id}`}>
                      <Button theme={'primary'} hollow size={'small'}>
                        Archive Person
                      </Button>
                      </Link>*/}
                    {profile.type === 'SUPERADMIN' && (
                      <Button
                        onClick={handleConvertToInvite}
                        theme={'primary'}
                        hollow
                        size={'small'}
                      >
                        Convert to Invite
                      </Button>
                    )}
                    {profile.type === 'SUPERADMIN' && (
                      <Link to={`/admin/users/edit/${scout.id}`}>
                        <Button hollow theme={'primary'} size={'small'}>
                          Edit User
                        </Button>
                      </Link>
                    )}
                    <Link to={`/admin/reviewers/edit/${scout.id}`}>
                      <Button hollow theme={'primary'} size={'small'}>
                        Edit Profile
                      </Button>
                    </Link>

                    <Button onClick={handleUnApprove} size={'small'}>
                      Unapprove {scout.firstName} {scout.lastName}
                    </Button>
                  </div>
                </Cell>
              </Grid>
            </Card>
          </Cell>
        )}

        {!scout.approved && (
          <Cell small={12}>
            <hr />
          </Cell>
        )}
        <Cell small={12}>
          <Spaced>
            <Heading level={6}>Onboarding Steps Completed</Heading>
            <div className="ReviewerStatus">
              <span className={scout.familiar ? 'complete' : null}>
                Display Info
              </span>
              <span className={scout.payable ? 'complete' : null}>
                Payout Info
              </span>
              <span>Identity</span>
              <span>Acreditation</span>
            </div>
          </Spaced>
          <hr />
        </Cell>
        {scout.affiliate && scout.affiliate.parent && (
          <Cell small={12}>
            <Spaced>
              <Heading level={6}>Affiliate Attribution (1st)</Heading>
              <div className="ReviewerStatus">
                <span className={'complete'}>
                  <Link
                    to={`/admin/affiliates/view/${scout.affiliate.parent.id}`}
                  >
                    {scout.affiliate.parent.shortid}
                  </Link>
                </span>
                {scout.affiliate.parent.network && (
                  <span className={'complete'}>
                    <Link
                      to={`/admin/networks/view/${scout.affiliate.parent.network.id}`}
                    >
                      {scout.affiliate.parent.network.name}
                    </Link>
                  </span>
                )}
              </div>
            </Spaced>
            <hr />
          </Cell>
        )}
        {scout.affiliate && (
          <Cell small={12}>
            <Spaced>
              <Heading level={6}>Affiliate Link</Heading>
              <div className="AffiliateLink">
                <Button onClick={copyLink} size={'small'}>
                  {`https://${window.location.hostname}/apply/${scout.affiliate.shortid}`}
                </Button>
                <input
                  onChange={e => {}}
                  ref={copy}
                  value={`https://${window.location.hostname}/apply/${scout.affiliate.shortid}`}
                  style={{
                    position: 'absolute',
                    top: '-9999px',
                    left: '-9999px',
                  }}
                />
              </div>
            </Spaced>
            <hr />
          </Cell>
        )}
        <Cell small={4}>
          <Spaced>
            <Heading level={6}>Name</Heading>
            <Heading level={4}>
              {scout.firstName} {scout.lastName}
            </Heading>
          </Spaced>
        </Cell>
        <Cell small={4}>
          <Spaced>
            <Heading level={6}>Title</Heading>
            <Heading level={4}>{scout.title || 'Not Answered'}</Heading>
          </Spaced>
        </Cell>
        <Cell small={4}>
          <Spaced>
            <Heading level={6}>Organization</Heading>
            <Heading level={4}>{scout.company || 'Not Answered'}</Heading>
          </Spaced>
        </Cell>
        <Cell small={4}>
          <Spaced>
            <Heading level={6}>Country</Heading>
            <Heading level={4}>
              {scout.locality ? scout.locality.country : 'Not Answered'}
            </Heading>
          </Spaced>
        </Cell>
        <Cell small={4}>
          <Spaced>
            <Heading level={6}>City</Heading>
            <Heading level={4}>
              {scout.locality ? scout.locality.city : 'Not Answered'}
            </Heading>
          </Spaced>
        </Cell>
        <Cell small={4}>
          <Spaced>
            <Heading level={6}>Postal</Heading>
            <Heading level={4}>
              {scout.locality ? scout.locality.postal : 'Not Answered'}
            </Heading>
          </Spaced>
        </Cell>
        {scout.user && scout.user.email && (
          <Cell small={12}>
            <Spaced>
              <Heading level={6}>Email</Heading>
              <Heading level={4}>{scout.user.email}</Heading>
            </Spaced>
          </Cell>
        )}
        <Cell small={12}>
          <Spaced>
            <Heading level={6}>
              Which of the following brackets do you represent or apply to you?
            </Heading>
            <div className="ReviewerTags">
              <Spaced>
                {scout.types && scout.types.length > 0 ? (
                  scout.types.map(tag => {
                    return <span key={tag}>{tag}</span>;
                  })
                ) : (
                  <Heading level={4}>Not Answered</Heading>
                )}
              </Spaced>
            </div>
          </Spaced>
        </Cell>
        <Cell small={12}>
          <Spaced>
            <Heading level={6}>Professional Background</Heading>
            <Paragraph>{scout.bio || 'Not Answered'}</Paragraph>
          </Spaced>
        </Cell>
        <Cell small={12}>
          <Spaced>
            <Heading level={6}>What opportunities I look for</Heading>
            <Paragraph>{scout.bioLookingFor || 'Not Answered'}</Paragraph>
          </Spaced>
        </Cell>
        <Cell small={12}>
          <Spaced>
            <Heading level={6}>
              What help/resource/input I can provide besides one-time advice and
              feedback?
            </Heading>
            <Paragraph>{scout.bioCanProvide || 'Not Answered'}</Paragraph>
          </Spaced>
        </Cell>
        <Cell small={12}>
          <Spaced>
            <Heading level={6}>
              Which of the following brackets do you represent or apply to you?
            </Heading>
            <div className="ReviewerTags">
              <Spaced>
                {scout.offering && scout.offering.length > 0 ? (
                  scout.offering.map(tag => {
                    return <span key={tag}>{tag}</span>;
                  })
                ) : (
                  <Heading level={4}>Not Answered</Heading>
                )}
              </Spaced>
            </div>
          </Spaced>
        </Cell>
        <Cell small={12}>
          <Spaced>
            <Heading level={6}>
              What’s your typical/desired size per investment opportunity?
            </Heading>
            <Heading level={4}>
              {scout.investmentDesiredRange ? (
                <span>
                  {scout.investmentDesiredRange.low} -{' '}
                  {scout.investmentDesiredRange.high}
                </span>
              ) : (
                'Not Answered'
              )}
            </Heading>
          </Spaced>
        </Cell>
        <Cell small={12}>
          <Spaced>
            <Heading level={6}>
              What stage(s) do you prefer to invest in?
            </Heading>
            <div className="ReviewerTags">
              <Spaced>
                {scout.investmentDesiredStage &&
                scout.investmentDesiredStage.length > 0 ? (
                  scout.investmentDesiredStage.map(tag => {
                    return <span key={tag}>{tag}</span>;
                  })
                ) : (
                  <Heading level={4}>Not Answered</Heading>
                )}
              </Spaced>
            </div>
          </Spaced>
        </Cell>
        <Cell small={12}>
          <Spaced>
            <Heading level={6}>
              If you have made investments in the past, what’s the largest sum
              you have ever put in?
            </Heading>
            <Heading level={4}>
              {scout.investmentLargestRange ? (
                <span>
                  {scout.investmentLargestRange.low} -{' '}
                  {scout.investmentLargestRange.high}
                </span>
              ) : (
                'Not Answered'
              )}
            </Heading>
          </Spaced>
        </Cell>
        <Cell small={12}>
          <Spaced>
            <Heading level={6}>Desired price per review</Heading>
            <Heading level={4}>
              {parseInt(scout.cost) > 0 ? scout.cost : 'Not Answered'}
            </Heading>
          </Spaced>
        </Cell>

        <Cell small={12}>
          <Spaced>
            <Heading level={6}>Are you donating proceeds to charity?</Heading>
            <Heading level={4}>
              {scout.isDonatingToCharity ? 'Yes' : 'No'}
            </Heading>
          </Spaced>
        </Cell>

        <Cell small={12}>
          <Spaced>
            <Heading level={6}>Consent to being followed?</Heading>
            <Heading level={4}>{scout.isFollowable ? 'Yes' : 'No'}</Heading>
          </Spaced>
        </Cell>

        <Cell small={12}>
          <div className="ReviewerTags">
            <Spaced>
              <Heading level={6}>Interest Tags</Heading>
              {scout.tags.length > 0 ? (
                scout.tags.map(tag => {
                  return <span key={tag.id}>{tag.name}</span>;
                })
              ) : (
                <Heading level={4}>Not Answered</Heading>
              )}
            </Spaced>
          </div>
        </Cell>
      </Grid>
      <Grid>
        <Cell small={12}>
          <Heading level={6}>Card View</Heading>
        </Cell>
        <Cell small={5}>
          <Spaced>
            <ReviewerCard compact {...scout} />
          </Spaced>
        </Cell>
      </Grid>
      <Grid>
        <Cell small={12}>
          <Heading level={6}>Detail View</Heading>
        </Cell>
        <Cell small={12}>
          <Spaced>
            <ReviewerDetail size={12} hideHeader={true} scout={scout} />
          </Spaced>
        </Cell>
      </Grid>
    </section>
  );
}

export function AdminEditReviewerLoader(props) {
  const { data, loading, error } = useQuery(GET_PERSON_ADMIN, {
    variables: { id: props.match.params.id },
  });

  if (loading) return <Loader />;
  if (error) return null;

  return <AdminReviewers scout={data.getPerson} />;
}

export function AdminReviewersWrapper(props) {
  return (
    <Container>
      <Grid>
        <Cell small={6}>
          <Heading level={1}>
            <b>Vintro</b> Admin
          </Heading>
        </Cell>

        <Cell>
          <hr />
        </Cell>
      </Grid>
      <Grid margin>
        <Cell small={12} medium={3}>
          <AdminSecondaryNav />
        </Cell>
        <Cell small={12} medium={9}>
          <AdminEditReviewerLoader {...props} />
        </Cell>
      </Grid>
    </Container>
  );
}

export default AdminReviewersWrapper;
