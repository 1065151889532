export default [
  {
    code: 101,
    name: '3D printing',
    type: 'vertical',
  },
  {
    code: 102,
    name: 'Adtech',
    type: 'vertical',
  },
  {
    code: 103,
    name: 'Advanced Manufacturing',
    type: 'vertical',
  },
  {
    code: 104,
    name: 'Agtech',
    type: 'vertical',
  },
  {
    code: 105,
    name: 'Artificial intelligence and Machine Learning',
    type: 'vertical',
  },
  {
    code: 106,
    name: 'Audiotech',
    type: 'vertical',
  },
  {
    code: 107,
    name: 'Augmented Reality',
    type: 'vertical',
  },
  {
    code: 108,
    name: 'Autonomous cars',
    type: 'vertical',
  },
  {
    code: 109,
    name: 'B2B Payments',
    type: 'vertical',
  },
  {
    code: 110,
    name: 'Beauty',
    type: 'vertical',
  },
  {
    code: 111,
    name: 'Big data',
    type: 'vertical',
  },
  {
    code: 112,
    name: 'Cannabis',
    type: 'vertical',
  },
  {
    code: 113,
    name: 'Cleantech',
    type: 'vertical',
  },
  {
    code: 114,
    name: 'Construction technology',
    type: 'vertical',
  },
  {
    code: 115,
    name: 'Cryptocurrency/Blockchain',
    type: 'vertical',
  },
  {
    code: 116,
    name: 'Cybersecurity',
    type: 'vertical',
  },
  {
    code: 117,
    name: 'Ecommerce',
    type: 'vertical',
  },
  {
    code: 118,
    name: 'Edtech',
    type: 'vertical',
  },
  {
    code: 119,
    name: 'Ephemeral content',
    type: 'vertical',
  },
  {
    code: 120,
    name: 'eSports',
    type: 'vertical',
  },
  {
    code: 121,
    name: 'Fintech',
    type: 'vertical',
  },
  {
    code: 122,
    name: 'Foodtech',
    type: 'vertical',
  },
  {
    code: 123,
    name: 'Gaming',
    type: 'vertical',
  },
  {
    code: 124,
    name: 'Healthtech',
    type: 'vertical',
  },
  {
    code: 125,
    name: 'HR Tech',
    type: 'vertical',
  },
  {
    code: 126,
    name: 'Impact investing',
    type: 'vertical',
  },
  {
    code: 127,
    name: 'Industrials',
    type: 'vertical',
  },
  {
    code: 128,
    name: 'Infrastructure',
    type: 'vertical',
  },
  {
    code: 129,
    name: 'Insurtech',
    type: 'vertical',
  },
  {
    code: 130,
    name: 'Internet of Things (IoT)',
    type: 'vertical',
  },
  {
    code: 131,
    name: 'Life sciences',
    type: 'vertical',
  },
  {
    code: 132,
    name: 'LOHAS and wellness',
    type: 'vertical',
  },
  {
    code: 133,
    name: 'Manufacturing',
    type: 'vertical',
  },
  {
    code: 134,
    name: 'Marketing tech',
    type: 'vertical',
  },
  {
    code: 135,
    name: 'Mobile',
    type: 'vertical',
  },
  {
    code: 136,
    name: 'Mobile Commerce',
    type: 'vertical',
  },
  {
    code: 137,
    name: 'Mortgage Tech',
    type: 'vertical',
  },
  {
    code: 138,
    name: 'Nanotechnology',
    type: 'vertical',
  },
  {
    code: 139,
    name: 'Oil and Gas',
    type: 'vertical',
  },
  {
    code: 140,
    name: 'Oncology',
    type: 'vertical',
  },
  {
    code: 141,
    name: 'Pet Technology',
    type: 'vertical',
  },
  {
    code: 142,
    name: 'Real Estate Technology',
    type: 'vertical',
  },
  {
    code: 143,
    name: 'Restaurant Technology',
    type: 'vertical',
  },
  {
    code: 144,
    name: 'Ridesharing',
    type: 'vertical',
  },
  {
    code: 145,
    name: 'Robotics and drones',
    type: 'vertical',
  },
  {
    code: 146,
    name: 'SaaS (software as a service)',
    type: 'vertical',
  },
  {
    code: 147,
    name: 'Space Technology',
    type: 'vertical',
  },
  {
    code: 148,
    name: 'TMT (Technology, media and telecommunications)',
    type: 'vertical',
  },
  {
    code: 149,
    name: 'Virtual reality',
    type: 'vertical',
  },
  {
    code: 150,
    name: 'Wearables and quantified self',
    type: 'vertical',
  },
];
